import { Module } from "vuex";
import { RootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import ProblemsState from "./types";

export const state: ProblemsState = {
  isProblemPanelOpen: false,
  problems: [],
};

const namespaced: boolean = true;

export const problems: Module<ProblemsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
