import CoreBaseBackedObject from "../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { getEntityAnalyticProperties } from "../../../../common/src/api/document/analytics/utils";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { TRACKER } from "../../../../common/src/lib/analytics";
import {
  CorruptEntityReason,
  getEntityCorruptions,
} from "../../../../common/src/lib/corruption-detection";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import { getUiStateAnalyticProperties } from "../analytic-utils";

const seen: Set<string> = new Set();

export function trackAllCorruptEntities(globalStore: GlobalStore): void {
  for (const uid of globalStore.keys()) {
    trackIfCorrupted(globalStore, uid, "Project Load");
  }
}

export function trackIfCorrupted(
  globalStore: GlobalStore,
  uid: string,
  detection: "Change" | "Project Load",
  findFixes: boolean = false,
): void {
  // Don't spam mixpanel events for the same entity
  if (seen.has(uid)) {
    return;
  }

  const coreObject = globalStore.getSafe(uid) as
    | CoreBaseBackedObject<DrawableEntityConcrete>
    | undefined;

  // Deletions
  if (!coreObject) {
    return;
  }

  // Note: Change events can cause temporary missing references,
  // before the full cascade of deletions happen.
  getEntityCorruptions(coreObject, findFixes)
    .filter(
      (x) =>
        detection !== "Change" ||
        x.reason !== CorruptEntityReason.MissingReference,
    )
    .forEach((details) => {
      TRACKER?.trackEvent({
        type: "Corrupt Entity",
        props: {
          ...getEntityAnalyticProperties(coreObject.context, coreObject.entity),
          ...getUiStateAnalyticProperties(),
          detection,
          errorMessage: details.errorMessage,
          reason: details.reason,
        },
      });
      // Sentry will give us a full stacktrace and also we can list all the details,
      // also as a console error we can see it in a running local application
      console.error("Tracking Corrupt Entity", {
        ...getEntityAnalyticProperties(coreObject.context, coreObject.entity),
        ...getUiStateAnalyticProperties(),
        details,
      });
    });
}
