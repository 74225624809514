import { Units, UnitsContext } from "../../../lib/measurements";
import { MarketplaceAuditTrailType } from "../../../models/marketplace/MarketplaceItemAuditTrail";
import { DrawingLayout } from "../../calculations/types";
import { SupportedLocales } from "../../locale";
import { RoomResultsSettings } from "../drawing";

export interface ProjectCreated {
  type: "Project Created";
  props: {
    locale: SupportedLocales;
    usedTemplates: boolean;
    workflows: string[];
  };
}

export interface SettingsChanged {
  type: "Settings Changed";
  props: {
    name: string;
    value: string;
    category: string;
    subcategory: string;
    type: string;
    [k: string]: string;
  };
}

export interface EntityAnalyticProperties {
  uid: string;
  entityName: string;
  entityType: string;
  subtype: string;
  tertiaryType: string;
}

export interface UIStateAnalyticProperties {
  drawingLayout?: DrawingLayout;
  activeflowSystemUid?: string;
  toolHandlerName?: string | null;
}

export interface PropertyChanged {
  type: "Property Changed";
  props: EntityAnalyticProperties & {
    name: string;
    property: string;
    units: Units;
    unitContext: UnitsContext;
    value: string;
    type: string;
  };
}

export interface HistoryRestored {
  type: "History Restored";
}

export interface CalculationSucceeded {
  type: "Calculations Succeeded";
}

export interface CalculationFailed {
  type: "Calculations Failed";
  props: {
    message: string;
  };
}

export interface LiveCalculationFailed {
  type: "Live Calculations Failed";
  props: {
    mode: "result-fast-live" | "result-full-live";
    message: string;
  };
}

export interface LiveCalculationSucceeded {
  type: "Live Calculations Succeeded";
  props: {
    mode: "result-fast-live" | "result-full-live";
  };
}

export interface EntityRenderFailed {
  type: "Entity Render Failed";
  props: EntityAnalyticProperties &
    UIStateAnalyticProperties & {
      forExport: boolean;
      withCalculation: boolean;
      selected: boolean;
      layerActive: boolean;
      layerName: string;
    };
}
export interface LayerRenderFailed {
  type: "Layer Render Failed";
  props: UIStateAnalyticProperties & {
    active: boolean;
    layer: string;
    mode: string;
    forExport: boolean;
    withCalculation: boolean;
    drawingLayout?: string;
    activeflowSystemUid?: string;
    toolHandlerName?: string;
  };
}

export interface PDFUploaded {
  type: "PDF Uploaded";
}

export interface HydraulicsToolUsed {
  type: "Hydraulics Tool Used";
  props: {
    name: string;
    flowsystemType?: string;
    flowsystemRole?: string;
  };
}

export interface HeatLoadToolUsed {
  type: "HeatLoad Tool Used";
  props: {
    name: string;
  };
}

export interface MetaPDFNewPage {
  type: "MetaPDF Page Created";
}

export interface MetaPDFToolUsed {
  type: "MetaPDF Tool Used";
  props: {
    name: string;
  };
}

export interface MetaPDFWorkflowChosen {
  type: "MetaPDF Workflow Chosen";
  props: {
    workflow: string;
  };
}

export interface MetaPDFEnabledClick {
  type: "MetaPDF Enabled Click";
}

export interface MetaPDFDisabledClick {
  type: "MetaPDF Disabled Click";
}

export interface MetaPDFSaveAndExitClick {
  type: "MetaPDF Save & Exit Click";
}

export interface PDFExported {
  type: "PDF Exported";
  props: {
    paperSizeName: string;
    scale: string;
    coverSheet: boolean;
    metaPdf: {
      enabled: boolean;
      coverSheet: boolean;
      titleBlock: boolean;
      appendix: boolean;
    };
    floorPlans: boolean;
    incAllLevels: boolean;
    titleBLockView: string;
    drawingLayout: DrawingLayout;
    roomResultsSettings: RoomResultsSettings;
    workflows: string[];
  };
}

export interface HeatLossExported {
  type: "Heat Load Exported";
  props: {
    separated: boolean;
    combined: boolean;
    docx: boolean;
    pdf: boolean;
    sections: string[];
    workflows: string[];
  };
}

export interface BillOfMaterialsExported {
  type: "BoM Exported";
  props: {
    workflows: string[];
  };
}

export interface DesignReportExported {
  type: "Design Report Exported";
  props: {
    workflows: string[];
  };
}

export interface AutocadExported {
  type: "Autocad Exported";
  props: {
    workflows: string[];
  };
}

export interface RevitExportClicked {
  type: "Revit Export Clicked";
  props: {
    workflows: string[];
  };
}

export interface DrawingModeChanged {
  type: "Drawing Mode Changed";
  props: {
    fromMode: string;
    toMode: string;
    workflows: string[];
  };
}

export interface CorruptEntity {
  type: "Corrupt Entity";
  props: EntityAnalyticProperties &
    UIStateAnalyticProperties & {
      uid: string;
      detection: "Change" | "Project Load";
      reason: string;
      errorMessage?: string;
    };
}

export interface OnboardingRouteClicked {
  type: "Onboarding Route Clicked";
  props: {
    path: string;
  };
}

export interface MarketplaceAction {
  type: "Marketplace Action";
  props: {
    action: MarketplaceAuditTrailType;
    marketplaceItemId: string;
    fromVersionId: string | null;
    toVersionId: string | null;
  };
}

// Aha moment metrics logging style
export interface AhaMomentAction {
  type: "Aha Moment Action";
  props: {
    action: string;
  };
}

export enum ManufacturerDashboardAction {
  Cumulative = "Cumulative",
  TrendPeriod = "Trend Period",
  ShowGenerics = "Show Generics",
}

export interface ManufacturerDashboardEvent {
  type: "Manufacturer Dashboard Event";
  props: {
    action: ManufacturerDashboardAction;
    newValue: string;
  };
}

/**
 * This is for buttons, which have mandatory tracking, see HButton
 */
export interface ButtonClick {
  type: "Button Click";
  props: {
    context: string;
    action: string;
  };
}

/**
 * This is for items in menus, see HItem
 */
export interface ItemClick {
  type: "Item Click";
  props: {
    context: string;
    action: string;
  };
}

export type AnalyticsPayload =
  | ProjectCreated
  | ButtonClick
  | ItemClick
  | SettingsChanged
  | PropertyChanged
  | HistoryRestored
  | CalculationSucceeded
  | CalculationFailed
  | LiveCalculationFailed
  | LiveCalculationSucceeded
  | EntityRenderFailed
  | LayerRenderFailed
  | PDFUploaded
  | HydraulicsToolUsed
  | HeatLoadToolUsed
  | PDFExported
  | HeatLossExported
  | BillOfMaterialsExported
  | DesignReportExported
  | AutocadExported
  | RevitExportClicked
  | DrawingModeChanged
  | CorruptEntity
  | MetaPDFNewPage
  | OnboardingRouteClicked
  | MarketplaceAction
  | MetaPDFToolUsed
  | MetaPDFWorkflowChosen
  | MetaPDFEnabledClick
  | MetaPDFDisabledClick
  | MetaPDFSaveAndExitClick
  | AhaMomentAction
  | ManufacturerDashboardEvent;

const ONE_MINUTE_MS = 60 * 1000;

/**
 * The cooldown period for each analytics event in milliseconds.
 * -1 = no cooldown
 */
export function getTrackingCooldownMS(type: AnalyticsPayload["type"]): number {
  switch (type) {
    case "Live Calculations Succeeded":
    case "Live Calculations Failed":
      return ONE_MINUTE_MS;
    default:
      return -1;
  }
}
