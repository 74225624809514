<template>
  <button class="hero-btn" :type="asAny($props.type)" @click="handleClick">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { asAny } from "../../../../common/src/lib/utils";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: "submit",
    },
  },
  data() {
    return {
      scale: 20,
      asAny,
    };
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
});
</script>

<style scoped>
.hero-btn {
  background-image: linear-gradient(
    90deg,
    rgba(0, 111, 190, 1) 0%,
    rgba(130, 186, 223, 1) 100%
  );
  background-color: #006fbe;
  width: 100%;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 15px;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0px 12px 16px #006fbe24;
}

.hero-btn:active {
  transform: translateY(1px);
}

.hero-btn:hover {
  background-image: linear-gradient(
    90deg,
    rgba(0, 111, 190, 1) 0%,
    rgba(0, 111, 190, 1) 100%
  );
}
</style>
