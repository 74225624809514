import {
  MetadataCatalog,
  SelectedMaterialManufacturer,
} from "../../document/drawing";
import { ManufacturerConcrete } from "../types";

export interface ManufactureredPartTypeSpecBase {
  partType: string;
  path: string;
  selectedManufacturerPath:
    | `${keyof MetadataCatalog}.${SelectedMaterialManufacturer["uid"]}`
    | `${keyof MetadataCatalog}`;
  filter?: (manufacturer: ManufacturerConcrete) => boolean;
}

export interface MPTName extends ManufactureredPartTypeSpecBase {
  categoryId: string;
  name: string;
}

export interface MPTNamePath extends ManufactureredPartTypeSpecBase {
  categoryId: string;
  name: string;
  namePath: string;
}

export type ManufactureredPartTypeSpec = MPTName | MPTNamePath;

export const MANUFACTURERED_PART_TYPES: ManufactureredPartTypeSpec[] = [
  {
    categoryId: "backflowValves",
    path: "backflowValves.{type}.manufacturer",
    partType: "backflowValves.{type}",
    namePath: "backflowValves.{type}.name",
    name: "Backflow Valves",
    selectedManufacturerPath: "backflowValves.{type}",
  },
  {
    categoryId: "greaseInterceptorTrap",
    path: "greaseInterceptorTrap.manufacturer",
    partType: "greaseInterceptorTrap",
    name: "Grease Interceptor Trap",
    selectedManufacturerPath: "greaseInterceptorTrap.greaseInterceptorTrap",
  },
  {
    categoryId: "pump",
    path: "pump.manufacturer",
    partType: "pump",
    name: "Pump",
    selectedManufacturerPath: "pump",
  },
  {
    categoryId: "pumpTank",
    path: "pumpTank.manufacturer",
    partType: "pumpTank",
    name: "Pump Tank",
    selectedManufacturerPath: "pumpTank",
  },
  {
    categoryId: "balancingValves",
    path: "balancingValves.manufacturer",
    partType: "balancingValves",
    name: "Balancing Valve",
    selectedManufacturerPath: "balancingValves.balancingValves",
  },
  {
    categoryId: "hotWaterPlant",
    path: "hotWaterPlant.manufacturer",
    partType: "hotWaterPlant",
    name: "Hot Water Plant",
    selectedManufacturerPath: "hotWaterPlant.hotWaterPlant",
    filter: (manufacturer: ManufacturerConcrete) =>
      !("isCirculatingPump" in manufacturer) || !manufacturer.isCirculatingPump,
  },
  {
    categoryId: "circulatingPumps",
    path: "hotWaterPlant.manufacturer",
    partType: "circulatingPumps",
    name: "Circulating Pumps",
    selectedManufacturerPath: "hotWaterPlant.circulatingPumps",
    filter: (manufacturer: ManufacturerConcrete) =>
      "isCirculatingPump" in manufacturer &&
      Boolean(manufacturer.isCirculatingPump),
  },
  {
    categoryId: "prv",
    path: "prv.manufacturer",
    partType: "prv",
    name: "PRV",
    selectedManufacturerPath: "prv.prv",
  },
  {
    categoryId: "fixtures",
    path: "fixtures.{type}.manufacturer",
    partType: "fixtures.{type}",
    namePath: "fixtures.{type}.name",
    name: "Fixtures",
    selectedManufacturerPath: "fixtures.{type}",
  },
  {
    categoryId: "pipes",
    path: "pipes.{material}.manufacturer",
    partType: "pipes.{material}",
    namePath: "pipes.{material}.name",
    name: "Pipes",
    selectedManufacturerPath: "pipes.{material}",
  },
  {
    categoryId: "mixingValves",
    path: "mixingValves.{type}.manufacturer",
    partType: "mixingValves.{type}",
    namePath: "mixingValves.{type}.name",
    name: "Mixing Valves",
    selectedManufacturerPath: "mixingValves.{type}",
  },
  {
    categoryId: "floorWaste",
    path: "floorWaste.manufacturer",
    partType: "floorWaste",
    name: "Floor Waste",
    selectedManufacturerPath: "floorWaste.floorWaste",
  },
  {
    categoryId: "inspectionOpening",
    path: "inspectionOpening.manufacturer",
    partType: "inspectionOpening",
    name: "Inspection Opening",
    selectedManufacturerPath: "inspectionOpening.inspectionOpening",
  },
  {
    categoryId: "filters",
    path: "filters.{type}.manufacturer",
    partType: "filters.{type}",
    namePath: "filters.{type}.name",
    name: "Filters",
    selectedManufacturerPath: "filters.{type}",
  },
  {
    categoryId: "roPlant",
    path: "roPlant.manufacturer",
    partType: "roPlant",
    name: "RO Plant",
    selectedManufacturerPath: "roPlant.roPlant",
  },
  {
    categoryId: "heatEmitters",
    path: "heatEmitters.{type}.manufacturer",
    partType: "heatEmitters.{type}",
    namePath: "heatEmitters.{type}.name",
    name: "Heat Emitters",
    selectedManufacturerPath: "heatEmitters.{type}",
  },
  {
    categoryId: "underfloorHeating",
    path: "underfloorHeating.{type}.manufacturer",
    partType: "underfloorHeating.{type}",
    namePath: "underfloorHeating.{type}.name",
    name: "Underfloor Heating",
    selectedManufacturerPath: "underfloorHeating.{type}",
  },
];

export interface ManufacturerDirectoryEntry {
  manufacturers: {
    name: string;
    uid: string;
    displayOnWizard: boolean;
    isGeneric: boolean;
  }[];
  partType: string;
  partName: string;
  catalogPath: string;
  selectedManufacturerPath: string;
}
