<template>
  <div style="height: 100%">
    <MainNavBar />
    <div style="overflow: auto; max-height: calc(100% - 61px)">
      <b-container class="home">
        <b-row>
          <b-col>
            <h1 class="title">Organizations</h1>
          </b-col>
        </b-row>
        <b-row style="margin-top: 30px; margin-bottom: 30px">
          <b-col>
            <b-button size="lg" variant="primary" to="/organizations/create">
              Create Organization
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert
              v-if="organizations.length === 0 && isLoaded"
              variant="success"
              show
            >
              There are no organizations right now.
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item
                v-for="org in organizations"
                :key="org.id"
                :to="'/organizations/id/' + org.id"
              >
                <b-row>
                  <!-- <b-col
                    cols="3"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                    ><OrgLogo :orgId="org.id"
                  /></b-col> -->
                  <b-col
                    cols="3"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ org.id }}
                  </b-col>
                  <b-col
                    cols="3"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ org.name }}
                  </b-col>
                  <b-col
                    cols="3"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ orgTypeToName[org.type] }}
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, onMounted, ref } from "vue";
import {
  Organization,
  OrganizationType,
} from "../../../common/src/models/Organization";
import MainNavBar from "../../src/components/MainNavBar.vue";
import { getOrganizations } from "../api/organizations";

const orgTypeToName: Record<OrganizationType, string> = {
  [OrganizationType.BUILDING_SERVICES]: "Building Services",
  [OrganizationType.MANUFACTURER]: "Manufacturer",
};
const isLoaded = ref<boolean>(false);
const organizations = ref<Organization[]>([]);
const instance = getCurrentInstance();
onMounted(() => {
  // fill documents
  getOrganizations().then((res) => {
    if (res.success) {
      organizations.value.splice(0, organizations.value.length, ...res.data);
      isLoaded.value = true;
    } else {
      instance?.proxy.$bvToast.toast(res.message, {
        variant: "danger",
        title: "Error retrieving org list",
      });
    }
  });
});
</script>

<style lang="less">
h1 {
  padding-top: 50px;
}
</style>
