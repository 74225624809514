import { ActionTree } from "vuex";
import { getProjectProblems } from "../../lib/problems/problems";
import { RootState } from "../types";
import ProblemsState from "./types";

export const actions: ActionTree<ProblemsState, RootState> = {
  toggle({ commit }) {
    commit("toggle");
  },

  async calculate({ commit }) {
    const problems = await getProjectProblems();
    commit("setProblems", problems);
  },
};
