import Flatten from "@flatten-js/core";
import { GetPressureLossOptions } from "../../../../common/src/api/calculations/entity-pressure-drops";
import { PressureLossResult } from "../../../../common/src/api/calculations/types";
import { isUnderfloor } from "../../../../common/src/api/config";
import CoreEdge from "../../../../common/src/api/coreObjects/coreEdge";
import {
  externalSegmentDetermineDirectionCW,
  isValidSegment,
} from "../../../../common/src/api/coreObjects/utils";
import {
  DrawableEntityConcrete,
  EdgeEntityConcrete,
} from "../../../../common/src/api/document/entities/concrete-entity";
import { EdgeEntity } from "../../../../common/src/api/document/entities/edge-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { Coord } from "../../../../common/src/lib/coord";
import {
  angleDiffRad,
  lerp,
} from "../../../../common/src/lib/mathUtils/mathutils";
import {
  assertUnreachable,
  cloneNaive,
} from "../../../../common/src/lib/utils";
import {
  MoveIntent,
  STRAIGHT_PIPES_THRESHOLD_RAD,
} from "../lib/black-magic/cool-drag";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { DraggableObject } from "../lib/object-traits/draggable-object";
import { EdgeObject } from "../lib/object-traits/edge-object";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { CollisionLayer } from "../lib/object-traits/magic-draggable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { DrawingMode } from "../types";
import {
  DrawableObjectConcrete,
  PolygonObjectConcrete,
} from "./concrete-object";
import { MIN_PIPE_PIXEL_WIDTH } from "./drawableConduit";
import DrawableFen from "./drawableFenestration";
import DrawableRoom from "./drawableRoom";
import DrawableVertex from "./drawableVertex";
import DrawableWall from "./drawableWall";
import { breakLineIntoSegments, drawDimensionBaseLevel } from "./utils";

const Base = CalculatedObject(
  SelectableObject(
    DraggableObject(
      HoverableObject(SnappableObject(EdgeObject(Core2Drawable(CoreEdge)))),
    ),
  ),
);

export default class DrawableEdge extends Base {
  type: EntityType.EDGE = EntityType.EDGE;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<EdgeEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  // @ts-ignore 2611
  get collisionLayers(): CollisionLayer[] {
    switch (this.entity.edgeContext) {
      case "room":
        return [CollisionLayer.ROOM];
      case "unheated-area":
        return [CollisionLayer.UNHEATED_AREA];
      case "heated-area":
        return [CollisionLayer.HEATED_AREA];
    }
    assertUnreachable(this.entity.edgeContext);
    return [];
  }

  getFrictionPressureLossKPA(
    _options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }
  getHoverSiblings(): HoverSiblingResult[] {
    const polygons = this.globalStore.getPolygonsByEdge(this.entity.uid)!;
    const result: HoverSiblingResult[] = [];
    for (const polygon of polygons) {
      const object = this.globalStore.get(polygon) as DrawableRoom;
      if (object) {
        result.concat(object.getHoverSiblings());
      } else {
        throw new Error("Unexpected type");
      }
    }
    return result;
  }
  getPopupContent(): EntityPopupContent[] | null {
    return null;
  }

  drawMeasurement(
    context: DrawingContext,
    ctx: CanvasRenderingContext2D,
    renderWall: boolean,
    fensUids: string[],
  ) {
    const oldStrokeStyle = ctx.strokeStyle;
    const oldFillStyle = ctx.fillStyle;
    // Not yet advance enough to support 3d
    const drawCoords: [Coord, Coord] = [
      {
        x: this.worldEndpoints()[0].x,
        y: this.worldEndpoints()[0].y,
      },
      {
        x: this.worldEndpoints()[1].x,
        y: this.worldEndpoints()[1].y,
      },
    ];
    if (!isValidSegment(drawCoords)) return;

    const polygon = context.globalStore.getPolygonsByEdge(this.entity.uid)[0];
    const drawablePolygon =
      context.globalStore.get<PolygonObjectConcrete>(polygon);
    const wallUids = context.globalStore.getWallsByRoomEdge(this.entity.uid);
    const wallSelect = wallUids.some((wallUid) => {
      return context.selectedUids.has(wallUid);
    });
    const fenSelect = fensUids.some((fenUid) => {
      return context.selectedUids.has(fenUid);
    });

    if (
      drawablePolygon.shouldShowDimension(context) ||
      wallSelect ||
      fenSelect
    ) {
      drawDimensionBaseLevel(
        context,
        ctx,
        externalSegmentDetermineDirectionCW(
          context,
          drawCoords,
          drawablePolygon
            .collectEdgesInOrder()
            .map((entity: { uid: string }) => entity.uid),
        ),
        100,
        300 + (fensUids.length > 0 ? 600 : 0) + (renderWall ? 200 : 0),
      );

      if (fensUids.length > 0 || wallUids.length > 0) {
        const coords: Coord[] = [];
        fensUids.forEach((fenUid) => {
          const drawableFen = context.globalStore.get(fenUid) as DrawableFen;
          if (!drawableFen || !drawableFen.isManifested) return;

          for (const segment of drawableFen.getWorldSegments()) {
            coords.push(segment[0], segment[1]);
          }
        });

        let segRatio: number[] = [];

        const startPoint = new Flatten.Point(
          this.worldEndpoints()[0].x,
          this.worldEndpoints()[0].y,
        );
        const endPoint = new Flatten.Point(
          this.worldEndpoints()[1].x,
          this.worldEndpoints()[1].y,
        );

        const line = new Flatten.Line(
          new Flatten.Point(
            this.worldEndpoints()[0].x,
            this.worldEndpoints()[0].y,
          ),
          new Flatten.Point(
            this.worldEndpoints()[1].x,
            this.worldEndpoints()[1].y,
          ),
        );
        const totalLengthMM = startPoint.distanceTo(endPoint)[0];
        coords.forEach((coords) => {
          const point = new Flatten.Point(coords.x, coords.y);
          const projPoint = point.projectionOn(line);

          const lengthToStart = projPoint.distanceTo(startPoint)[0];
          const lengthToEnd = projPoint.distanceTo(endPoint)[0];
          if (lengthToStart + lengthToEnd > totalLengthMM + 1) {
            return;
          }
          segRatio.push(lengthToStart / totalLengthMM);
        });
        segRatio.push(0);
        segRatio.push(1);

        segRatio = segRatio.sort((a, b) => a - b);
        for (let i = segRatio.length - 1; i > 0; i--) {
          segRatio[i] = segRatio[i] - segRatio[i - 1];
        }

        const segments = breakLineIntoSegments(
          [drawCoords[0], drawCoords[1]],
          segRatio,
        );

        segments.forEach((segment) => {
          drawDimensionBaseLevel(
            context,
            ctx,
            externalSegmentDetermineDirectionCW(
              context,
              segment,
              drawablePolygon
                .collectEdgesInOrder()
                .map((entity: { uid: string }) => entity.uid),
            ),
            100,
            300 + (fensUids.length > 0 ? 200 : 0) + (renderWall ? 200 : 0),
          );
        });
      }
    }

    ctx.strokeStyle = oldStrokeStyle;
    ctx.fillStyle = oldFillStyle;
  }
  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    switch (this.entity.edgeContext) {
      case "room":
        return this.drawRoomEdge(context, args);
      case "unheated-area":
        return this.drawUFHUnheatedAreaEdge(context, args);
      case "heated-area":
        return this.drawUFHHeatedAreaEdge(context, args);
    }
    assertUnreachable(this.entity.edgeContext);
  }

  drawUFHUnheatedAreaEdge(context: DrawingContext, args: EntityDrawingArgs) {
    if (args.forExport) {
      return;
    }

    if (context.featureAccess.fullUnderfloorHeatingLoops) {
      if (context.doc.uiState.drawingMode !== DrawingMode.Design) {
        return;
      }

      if (
        !isUnderfloor(
          getFlowSystem(
            this.document.drawing,
            this.document.activeflowSystemUid,
          ),
        )
      ) {
        return;
      }
    }

    const { ctx } = context;

    this.drawMeasurement(context, ctx, false, []);

    const oldTrans = ctx.getTransform();
    ctx.strokeStyle = "#000";
    ctx.fillStyle = "#000";
    ctx.beginPath();
    ctx.lineWidth = 10;

    ctx.moveTo(this.worldEndpoints()[0].x, this.worldEndpoints()[0].y);
    ctx.lineTo(this.worldEndpoints()[1].x, this.worldEndpoints()[1].y);
    ctx.stroke();
    ctx.closePath();

    ctx.setTransform(oldTrans);
  }

  drawUFHHeatedAreaEdge(context: DrawingContext, args: EntityDrawingArgs) {
    if (args.forExport) {
      return;
    }

    if (context.doc.uiState.drawingMode !== DrawingMode.Design) {
      return;
    }

    if (
      !isUnderfloor(
        getFlowSystem(this.document.drawing, this.document.activeflowSystemUid),
      )
    ) {
      return;
    }

    const { ctx } = context;

    this.drawMeasurement(context, ctx, false, []);

    const dashLength = Math.max(context.vp.toWorldLength(5), 20);

    const oldTrans = ctx.getTransform();
    ctx.strokeStyle = "#000a8f";
    ctx.setLineDash([dashLength, dashLength]);
    ctx.fillStyle = "#000";
    ctx.beginPath();
    ctx.lineWidth = 10;

    ctx.moveTo(this.worldEndpoints()[0].x, this.worldEndpoints()[0].y);
    ctx.lineTo(this.worldEndpoints()[1].x, this.worldEndpoints()[1].y);
    ctx.stroke();
    ctx.closePath();

    ctx.setLineDash([]);
    ctx.setTransform(oldTrans);
  }

  drawRoomEdge(context: DrawingContext, args: EntityDrawingArgs) {
    if (args.forExport) {
      return;
    }

    // easier when pipes are same as world coord.
    const { graphics, ctx } = context;
    let oldTrans = ctx.getTransform();
    const renderWall =
      !context.doc.uiState.toolHandlerName &&
      context.doc.uiState.draggingEntities.length === 0;
    const fensUids = context.globalStore.getFensByRoomEdge(this.entity.uid);
    this.drawMeasurement(context, ctx, renderWall, fensUids);

    if (renderWall) {
      ctx.setTransform(oldTrans);
      return;
    }
    ctx.setTransform(oldTrans);

    const s = graphics.worldToSurfaceScale;

    oldTrans = ctx.getTransform();
    const targetWWidth = 15;

    const baseWidth = Math.max(
      MIN_PIPE_PIXEL_WIDTH / s,
      targetWWidth / graphics.unitWorldLength,
      (MIN_PIPE_PIXEL_WIDTH / s) * (5 + Math.log(s)),
    );
    this.lastDrawnWidthInternal = baseWidth;
    // We need bigger hit boxes for this for its main interaction use: Drawing fenestrations.
    this.lastDrawnWidthInternal *= 3;

    ctx.lineCap = "round";

    const baseColor = "#002266";
    const oldStrokeStyle = ctx.strokeStyle;
    const oldFillStyle = ctx.fillStyle;

    ctx.strokeStyle = baseColor;
    ctx.fillStyle = baseColor;
    ctx.beginPath();
    ctx.lineWidth = baseWidth;

    ctx.moveTo(this.worldEndpoints()[0].x, this.worldEndpoints()[0].y);
    ctx.lineTo(this.worldEndpoints()[1].x, this.worldEndpoints()[1].y);
    ctx.stroke();
    ctx.setLineDash([]);
    ctx.closePath();

    ctx.strokeStyle = oldStrokeStyle;
    ctx.fillStyle = oldFillStyle;
    ctx.setTransform(oldTrans);
  }

  isActive() {
    return true;
  }

  prepareDelete(context: CanvasContext): DrawableObjectConcrete[] {
    const uidsOfDirectDependents = [
      ...context.globalStore.getPolygonsByEdge(this.entity.uid),
      ...context.globalStore.getWallsByRoomEdge(this.entity.uid),
      ...context.globalStore.getFensByRoomEdge(this.entity.uid),
    ];

    const directDependents = uidsOfDirectDependents.map((uid) =>
      context.globalStore.get(uid),
    );

    const allDependents = directDependents.flatMap((dep) =>
      dep.prepareDelete(context),
    );

    return [...Array.from(new Set(allDependents)), this];
  }

  getCoolDragCorrelations(
    _myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    const res = [...this.entity.endpointUid];
    const [a, b] = this.worldEndpoints();
    const norm = new Flatten.Vector(a.x - b.x, a.y - b.y).normalize();
    const res2: { object: DrawableObjectConcrete; move: MoveIntent }[] =
      res.map((x) => {
        const obj = this.globalStore.get(x) as DrawableVertex;

        const positives: EdgeEntityConcrete[] = this.context.globalStore
          .getConnections(x)
          .map(
            (x) => this.context.globalStore.get(x).entity as EdgeEntityConcrete,
          );
        return {
          object: obj,
          move: {
            type: "slide",
            normal: norm,
            positives,
            negatives: [],
          },
        };
      });
    return res2;
  }
  lastDragCoords: [Coord, Coord] | null = null;
  initialOverlapM2: number | null = null;
  dragMethod: "fixAngle" | "fixLength" = "fixLength";
  onDragStart(
    _event: MouseEvent,
    _objectCoord: Coord,
    context: CanvasContext,
    _isMultiDrag: boolean,
  ): any {
    const points = this.entity.endpointUid.map((x) =>
      context.globalStore.get<DrawableVertex>(x),
    );

    const lines = points.map((x) => {
      const obj = context.globalStore
        .getConnections(x.uid)
        .filter((x) => x !== this.uid)
        .map((x) => context.globalStore.get<DrawableEdge>(x))[0];
      const vec = obj.vector;
      const line = new Flatten.Line(
        new Flatten.Point(x.entity.center.x, x.entity.center.y),
        new Flatten.Point(x.entity.center.x + vec.x, x.entity.center.y + vec.y),
      );
      return line;
    });
    const thisLine = new Flatten.Line(this.segment.ps, this.segment.pe);
    let angles = [
      Math.abs(thisLine.norm.angleTo(lines[0].norm)),
      Math.abs(thisLine.norm.angleTo(lines[1].norm)),
    ];
    angles[0] = ((angles[0] % Math.PI) + Math.PI) % Math.PI;
    angles[1] = ((angles[1] % Math.PI) + Math.PI) % Math.PI;
    angles = angles.map((x) => Math.min(x, Math.PI - x));
    const angle = Math.min(...angles);
    if (angle > STRAIGHT_PIPES_THRESHOLD_RAD) this.dragMethod = "fixAngle";
    else this.dragMethod = "fixLength";

    const room = context.globalStore.getPolygonsByEdge(this.uid)[0];
    this.initialOverlapM2 = context.globalStore
      .get<DrawableRoom>(room)
      .invalidOverlappingAreaM2();
  }
  onDrag(
    event: MouseEvent,
    grabbedObjectCoord: Coord,
    eventObjectCoord: Coord,
    grabState: any,
    context: CanvasContext,
    isMultiDrag: boolean,
  ): void {
    const eventWC = this.toWorldCoord(eventObjectCoord);
    const { wallSpec } = context.drawing.metadata.heatLoss;

    // snap - suss around for nearby walls to snap to.
    const tree = context.globalStore.spatialIndex.get(
      context.document.uiState.levelUid!,
    )!;
    const query = tree.search({
      minX: eventWC.x - 10000,
      minY: eventWC.y - 10000,
      maxX: eventWC.x + 10000,
      maxY: eventWC.y + 10000,
    });

    let bestWC = eventWC;
    let bestDist = lerp(
      context.viewPort.toWorldLength(10),
      Math.max(context.viewPort.toWorldLength(10), 100),
      0.5,
    );

    for (const q of query) {
      if (q.uid === this.uid) continue;
      const obj = context.globalStore.get(q.uid);
      if (obj.type !== EntityType.EDGE) {
        continue;
      }

      // if there is significant overlap, don't snap to point, but snap to past it
      // let overlapped = false;
      // const s1 = this.segment;
      // const s2 = obj.segment;
      // const l1 = s1.length;
      // const l2 = s2.length;
      // const overlapThreshold = Math.min(l1, l2) * 0.1;
      // const o1 = s1.pe.distanceTo(s2)[1].pe;
      // const o2 = s1.ps.distanceTo(s2)[1].pe;
      // const overlap = o1.distanceTo(o2)[0];
      // if (overlap > overlapThreshold) {
      //   overlapped = true;
      // }
      const me = this.normal!; // Assume zero length edge can't be dragged
      const other = obj.normal;

      const angle = other ? me.angleTo(other) : null;
      let extend = 0;
      if (angle != null && Math.abs(angleDiffRad(angle, 0)) > Math.PI / 4) {
        extend = wallSpec.internalWidthMM;
      }

      const line = Flatten.line(
        obj.segment.ps.translate(this.normal!.multiply(extend)),
        obj.segment.pe.translate(this.normal!.multiply(extend)),
      );

      const dist = line.distanceTo(Flatten.point(eventWC.x, eventWC.y));
      if (dist[0] < bestDist) {
        bestDist = dist[0];
        bestWC = dist[1].ps;
      }
    }

    if (context.$store.getters["document/isViewOnly"] || isMultiDrag) {
      return;
    }
    const vertices = this.entity.endpointUid.map((x) =>
      context.globalStore.get<DrawableVertex>(x),
    );

    const norm = this.vector;
    const mouseWC = new Flatten.Point(eventWC.x, eventWC.y);

    const old1 = this.lastDragCoords
        ? cloneNaive(this.lastDragCoords[0])
        : vertices[0].toWorldCoord(),
      old2 = this.lastDragCoords
        ? cloneNaive(this.lastDragCoords[1])
        : vertices[1].toWorldCoord();

    if (this.dragMethod === "fixLength") {
      for (let i = 0; i < 2; i++) {
        const transformed = vertices[i].toWorldCoord();
        const normLine = Flatten.line(
          Flatten.point(transformed.x, transformed.y),
          norm,
        );
        const proj = vertices[i].toParentCoord(
          vertices[i].toObjectCoord(mouseWC.projectionOn(normLine)),
        );

        vertices[i].entity.center = cloneNaive({ x: proj.x, y: proj.y });
      }
    } else {
      const lines = vertices.map((x) => {
        const obj = context.globalStore
          .getConnections(x.uid)
          .filter((x) => x !== this.uid)
          .map((x) => context.globalStore.get<DrawableEdge>(x))[0];
        const vec = obj.vector;
        const a = x.toWorldCoord();

        const line = new Flatten.Line(
          new Flatten.Point(a.x, a.y),
          new Flatten.Point(a.x + vec.x, a.y + vec.y),
        );
        return line;
      });
      const thisLine = new Flatten.Line(
        new Flatten.Point(bestWC.x, bestWC.y),
        new Flatten.Point(bestWC.x + this.vector.x, bestWC.y + this.vector.y),
      );
      let p = thisLine.intersect(lines[0])[0];

      vertices[0].entity.parentUid = null;
      vertices[0].entity.center = { x: p.x, y: p.y };
      p = thisLine.intersect(lines[1])[0];
      vertices[1].entity.parentUid = null;
      vertices[1].entity.center = { x: p.x, y: p.y };
    }

    if (
      (
        this.globalStore.get(
          this.globalStore.getPolygonsByEdge(this.uid)[0],
        ) as DrawableRoom
      ).invalidOverlappingAreaM2() > this.initialOverlapM2!
    ) {
      vertices[0].entity.center = old1;
      vertices[1].entity.center = old2;
      vertices[0].entity.parentUid = null;
      vertices[1].entity.parentUid = null;
    } else {
      this.lastDragCoords = cloneNaive([
        vertices[0].toWorldCoord(),
        vertices[1].toWorldCoord(),
      ]);
    }
    context.scheduleDraw();
  }
  onDragFinish(
    event: MouseEvent,
    _context: CanvasContext,
    _isMultiDrag: boolean,
  ): void {
    this.onInteractionComplete(event);
    this.lastDragCoords = null;
  }
  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    if (!this.isActive()) return null;
    switch (interaction.type) {
      case InteractionType.INSERT:
        return [this.entity];
      case InteractionType.SNAP_ONTO_RECEIVE:
      case InteractionType.EXTEND_NETWORK:
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
      case InteractionType.SNAP_ONTO_SEND:
        return null;
    }

    return null;
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    return this.entity.endpointUid
      .map((uid) => this.globalStore.get(uid)! as DrawableObjectConcrete)
      .concat(
        this.globalStore
          .getFensByRoomEdge(this.uid)
          .map((uid) => this.globalStore.get(uid) as DrawableObjectConcrete),
        this.globalStore
          .getWallsByRoomEdge(this.uid)
          .map((uid) => this.globalStore.get(uid) as DrawableWall)
          .filter((x) => x.entity.polygonEdgeUid.length === 1),
      )
      .concat(
        this.globalStore
          .getPolygonsByEdge(this.uid)
          .map((x) => this.globalStore.get(x) as DrawableRoom),
      );
  }
}
