import { Units, UnitsContext, chooseByUnitsMetric } from "../lib/measurements";
import { NodeProps } from "../models/CustomEntity";
import { UnitsParameters } from "./document/drawing";
import { EntityType } from "./document/entities/types";

export function initialNodes(units: UnitsParameters): NodeProps[] {
  return [
    {
      name: "Ensuite",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 250,
          [Units.Pascals]: 250000,
          [Units.Bar]: 2.5,
          [Units.Mbar]: 2500,
          [Units.GasKiloPascals]: 250,
          [Units.Psi]: 36,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["basin", "shower", "wc"],
      uid: "ensuite",
      dwelling: "",
      type: EntityType.LOAD_NODE,
    },
    {
      name: "WC & Basin",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 250,
          [Units.Pascals]: 250000,
          [Units.Bar]: 2.5,
          [Units.Mbar]: 2500,
          [Units.GasKiloPascals]: 250,
          [Units.Psi]: 36,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["basin", "wc"],
      uid: "wc&basin",
      dwelling: "",
      type: EntityType.LOAD_NODE,
    },
    {
      name: "Dwelling",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 250,
          [Units.Pascals]: 250000,
          [Units.Bar]: 2.5,
          [Units.Mbar]: 2500,
          [Units.GasKiloPascals]: 250,
          [Units.Psi]: 36,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 450,
          [Units.Pascals]: 450000,
          [Units.Bar]: 4.5,
          [Units.Mbar]: 4500,
          [Units.GasKiloPascals]: 450,
          [Units.Psi]: 65,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: [
        "basin",
        "shower",
        "wc",
        "kitchenSink",
        "laundryTrough",
        "dishwasher",
        "washingMachine",
      ],
      uid: "dwelling",
      dwelling: true,
      type: EntityType.LOAD_NODE,
    },
    {
      name: "Basin",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 200,
          [Units.Pascals]: 200000,
          [Units.Bar]: 2,
          [Units.Mbar]: 2000,
          [Units.GasKiloPascals]: 200,
          [Units.Psi]: 29,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["basin"],
      uid: "basin",
      dwelling: false,
      type: EntityType.LOAD_NODE,
    },
    {
      name: "Bath",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 200,
          [Units.Pascals]: 200000,
          [Units.Bar]: 2,
          [Units.Mbar]: 2000,
          [Units.GasKiloPascals]: 200,
          [Units.Psi]: 29,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["bath"],
      uid: "bath",
      dwelling: false,
      type: EntityType.LOAD_NODE,
    },
    {
      name: "Shower",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 200,
          [Units.Pascals]: 200000,
          [Units.Bar]: 2,
          [Units.Mbar]: 2000,
          [Units.GasKiloPascals]: 200,
          [Units.Psi]: 29,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["shower"],
      uid: "shower",
      dwelling: false,
      type: EntityType.LOAD_NODE,
    },
    {
      name: "Cleaners Sink",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 200,
          [Units.Pascals]: 200000,
          [Units.Bar]: 2,
          [Units.Mbar]: 2000,
          [Units.GasKiloPascals]: 200,
          [Units.Psi]: 29,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["cleanersSink"],
      uid: "cleanersSink",
      dwelling: false,
      type: EntityType.LOAD_NODE,
    },
    {
      name: "Kitchen Sink",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 200,
          [Units.Pascals]: 200000,
          [Units.Bar]: 2,
          [Units.Mbar]: 2000,
          [Units.GasKiloPascals]: 200,
          [Units.Psi]: 29,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["kitchenSink"],
      uid: "kitchenSink",
      dwelling: false,
      type: EntityType.LOAD_NODE,
    },
    {
      name: "Dishwasher",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 200,
          [Units.Pascals]: 200000,
          [Units.Bar]: 2,
          [Units.Mbar]: 2000,
          [Units.GasKiloPascals]: 200,
          [Units.Psi]: 29,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["dishwasher"],
      uid: "dishwasher",
      dwelling: false,
      type: EntityType.LOAD_NODE,
    },
    {
      name: "Washing Machine",
      minPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 200,
          [Units.Pascals]: 200000,
          [Units.Bar]: 2,
          [Units.Mbar]: 2000,
          [Units.GasKiloPascals]: 200,
          [Units.Psi]: 29,
        },
        UnitsContext.NONE,
      )[1],
      maxPressure: chooseByUnitsMetric(
        units,
        {
          [Units.KiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
          [Units.GasKiloPascals]: 500,
          [Units.Psi]: 73,
        },
        UnitsContext.NONE,
      )[1],
      fixtures: ["washingMachine"],
      uid: "washingMachine",
      dwelling: false,
      type: EntityType.LOAD_NODE,
    },
  ];
}
