import { CoreCalculatableObject } from "../api/coreObjects/lib/CoreCalculatableObject";
import CoreBaseBackedObject from "../api/coreObjects/lib/coreBaseBackedObject";
import { isCalculated } from "../api/document/calculations-objects";
import { DrawableEntityConcrete } from "../api/document/entities/concrete-entity";
import { isSpecifyRadiator } from "../api/document/entities/plants/plant-entity";
import { isRadiatorPlantEntity } from "../api/document/entities/plants/utils";
import { FlatPropertyFields } from "../api/document/entities/property-field";
import { DrawableEntity } from "../api/document/entities/simple-entities";
import {
  EntityType,
  getEntityIndividualName,
} from "../api/document/entities/types";
import { flattenTabFields } from "../api/document/entities/utils";
import { EntityResource, EntityResourceType } from "../api/document/types";
import {
  contextContainsResource,
  fillEntityDefaults,
  getEntityResourcesForCopy,
  makeInertEntityFields,
} from "../api/document/utils";
import { collect } from "./array-utils";
import { GlobalStore } from "./globalstore/global-store";
import { MultiMap } from "./multi-map";
import { PossibleFix } from "./possible-fix";
import { createMissingCustomHeatLossMaterial } from "./project-fixes";
import {
  assertType,
  cloneSimple,
  getPropertyByString,
  setPropertyByString,
} from "./utils";

export interface CorruptEntityDetails {
  entity: DrawableEntity;
  uid: string;
  individualName: string | null;
  type: EntityType;
  errorMessage?: string;
  subtype: string;
  tertiaryType: string;
  reason: CorruptEntityReason;
  levelUid: string | null;
  levelName: string;
  possibleFixes: PossibleFix[];
}

export enum CorruptEntityReason {
  FillFailure = "Failed to fill",
  PropertyListFailure = "Failed to list properties",
  MissingResource = "Missing Resource",
  MissingMarketplaceCatalogInformation = "Missing Marketplace Catalog Information",
  CalculationFieldListFailure = "Failed to list calculation fields",
  MissingReference = "Missing Reference",
}

export function getEntityCorruptions(
  coreObject: CoreBaseBackedObject<DrawableEntityConcrete>,
  findFixes: boolean,
): CorruptEntityDetails[] {
  const corrupted: CorruptEntityDetails[] = [];

  const corruptReferences = coreObject.references.filter(
    (ref) => coreObject.globalStore.getSafe(ref.reference) === undefined,
  );

  for (let corruptReference of corruptReferences) {
    // Marketplace Manufacturers are also uids, and will become false positives.
    if (corruptReference.propertyPath.endsWith("manufacturer")) {
      continue;
    }
    // Custom Flow Systems are also uids, and will become false positives.
    // inletSystemUid, systemUids, systemUid will all hit this case
    if (corruptReference.propertyPath.toLowerCase().includes("systemuid")) {
      continue;
    }
    // Ignore calc results. We wont use those references anyway.
    // These include things like lvlUid
    if (
      corruptReference.propertyPath.toLowerCase().includes("frozenLoopsStats")
    ) {
      continue;
    }
    // Currently, Manifolds are designed to be missingable, we may want to revisit that,
    // but for now they just create false positives
    if (corruptReference.propertyPath.toLowerCase().endsWith("manifoldUid")) {
      continue;
    }
    corrupted.push(
      corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.MissingReference,
        `Property ${corruptReference.propertyPath} References Missing Object: ${corruptReference.reference}`,
        findFixes,
        () => [],
      ),
    );
  }

  for (let resource of getEntityResourcesForCopy(
    coreObject.context,
    coreObject.entity,
  )) {
    if (!contextContainsResource(coreObject.context, resource)) {
      corrupted.push(corruptResourceDetails(coreObject, resource, findFixes));
    }
  }

  // FUTURE: Make this work for all marketplace manufacturers
  if (
    isRadiatorPlantEntity(coreObject.entity) &&
    isSpecifyRadiator(coreObject.entity.plant)
  ) {
    const manufacturer = coreObject.entity.plant.manufacturer;
    const radiatorDatasheet =
      coreObject.context.catalog.heatEmitters.radiators.datasheet;
    if (!radiatorDatasheet[manufacturer]) {
      corrupted.push(
        corruptEntitiesDetails(
          coreObject,
          CorruptEntityReason.MissingMarketplaceCatalogInformation,
          `Heat Emitter Datasheet for Manufacturer "${manufacturer}" not found`,
          findFixes,
          () => [],
        ),
      );
    }
  }

  try {
    coreObject.flatProperties;
  } catch (e: unknown) {
    corrupted.push(
      corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.PropertyListFailure,
        (e as Error).message,
        findFixes,
        () => [],
      ),
    );
  }

  if (isCalculated(coreObject.entity)) {
    assertType<CoreCalculatableObject>(coreObject);
    try {
      coreObject.getCoreCalculationFields(null);
    } catch (e: unknown) {
      corrupted.push(
        corruptEntitiesDetails(
          coreObject,
          CorruptEntityReason.CalculationFieldListFailure,
          (e as Error).message,
          findFixes,
          () => [],
        ),
      );
    }
  }

  try {
    fillEntityDefaults(coreObject.context, coreObject.entity);
  } catch (e: unknown) {
    corrupted.push(
      corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.FillFailure,
        (e as Error).message,
        findFixes,
        () => getOverridingPropertyFix(coreObject),
      ),
    );
  }

  return corrupted;
}

/**
 * For now we are just checking whether or not entities can be filled.
 * Later we likely will want to do more checks.
 */
export function calculateCorruptEntities(
  globalStore: GlobalStore,
  findFixes: boolean,
): MultiMap<string, CorruptEntityDetails> {
  const corrupted: MultiMap<string, CorruptEntityDetails> = new MultiMap();
  for (let uid of globalStore.keys()) {
    const coreObject = globalStore.getOrThrow(
      uid,
    ) as CoreBaseBackedObject<DrawableEntityConcrete>;
    corrupted.add(uid, ...getEntityCorruptions(coreObject, findFixes));
  }

  return corrupted;
}

function corruptResourceDetails(
  coreObject: CoreBaseBackedObject<DrawableEntityConcrete>,
  resource: EntityResource,
  findFixes: boolean,
): CorruptEntityDetails {
  switch (resource.type) {
    case EntityResourceType.FIRE_NODE:
      return corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.MissingResource,
        `Fire Node Not Found: ${resource.customNodeId}`,
        findFixes,
        () => getPotentialResourceFixes(coreObject, resource),
      );
    case EntityResourceType.LOAD_NODE:
      return corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.MissingResource,
        `Load Node Not Found: ${resource.customNodeId}`,
        findFixes,
        () => getPotentialResourceFixes(coreObject, resource),
      );
    case EntityResourceType.FLOW_SYSTEM:
      return corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.MissingResource,
        `Custom Flow System Not Found: ${resource.uid}`,
        findFixes,
        () => getPotentialResourceFixes(coreObject, resource),
      );
    case EntityResourceType.MATERIAL:
      return corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.MissingResource,
        `Custom Heat Loss Material Not Found: ${resource.role} - ${resource.uid}`,
        findFixes,
        () => getPotentialResourceFixes(coreObject, resource),
      );
  }
}

function corruptEntitiesDetails(
  coreObject: CoreBaseBackedObject<DrawableEntityConcrete>,
  reason: CorruptEntityReason,
  message: string,
  findFixes: boolean,
  potentialFixFn: () => PossibleFix[],
): CorruptEntityDetails {
  const levelUid =
    coreObject.globalStore.levelOfEntity.get(coreObject.uid) ?? null;
  return {
    entity: coreObject.entity,
    uid: coreObject.uid,
    individualName: getEntityIndividualName(coreObject.entity),
    type: coreObject.type,
    subtype: coreObject.subtype ?? "",
    tertiaryType: coreObject.tertiaryType ?? "",
    errorMessage: message,
    reason,
    levelUid,
    levelName: levelUid
      ? (coreObject.drawing.levels[levelUid]?.name ?? "Missing level")
      : "Entity not on a level",
    possibleFixes: findFixes ? potentialFixFn() : [],
  };
}

function getOverridingPropertyFix(
  obj: CoreBaseBackedObject<DrawableEntityConcrete>,
): PossibleFix[] {
  return collect(obj.flatProperties, (property) =>
    checkIfOverridingPropertyWorks(obj, property),
  );
}

export function getPotentialResourceFixes(
  obj: CoreBaseBackedObject<DrawableEntityConcrete>,
  resource: EntityResource,
): PossibleFix[] {
  switch (resource.type) {
    case EntityResourceType.MATERIAL:
      return [
        {
          title: `Recreate Missing Material`,
          details: `
        A new ${resource.role} "${resource.uid}" material will be created with a thermal transmittance of 0.1 W/m²K.
        Please revise this value and edit it to suit your design after this fix is applied.
        `,
          action: async () => {
            createMissingCustomHeatLossMaterial(obj.context.drawing, resource);
          },
        },
      ];
    case EntityResourceType.FIRE_NODE:
    case EntityResourceType.LOAD_NODE:
    case EntityResourceType.FLOW_SYSTEM:
      // Note: We probably could support Fire/Load Node creation as a fix in the future
      return [];
  }
}

function getPropertyChangeToNullDetails(
  property: FlatPropertyFields,
  oldValueAsString: string,
) {
  if (property.isCalculated) {
    return `The property ${property.title} will be changed from its overridden value ${oldValueAsString} back to a computed value. 
    Please review and edit this property to suit your design after this fix is applied.`;
  }
  return `The property ${property.title} will be changed from ${oldValueAsString} to an empty value. 
  Please review and edit this property to suit your design after this fix is applied.`;
}

export function checkIfOverridingPropertyWorks(
  obj: CoreBaseBackedObject<DrawableEntityConcrete>,
  property: FlatPropertyFields,
): PossibleFix | undefined {
  if (property.readonly) {
    return undefined;
  }
  const oldValueAsString = stringifyProperty(
    getPropertyByString(obj.entity, property.property, true),
  );
  if (property.hasDefault && property.defaultValue) {
    if (
      doesOverridingPropertyValueWork(obj, property, property.defaultValue())
    ) {
      return {
        title: `Property Change To Default`,
        details: `The property ${property.title} will be changed from ${oldValueAsString} to its default value. ${stringifyProperty(property.defaultValue())}`,
        action: async () => {
          setPropertyByString(
            obj.entity,
            property.property,
            property.defaultValue!(),
          );
        },
      };
    }
  }
  if (!property.requiresInput) {
    if (doesOverridingPropertyValueWork(obj, property, null)) {
      return {
        title: `Property Change To Default`,
        details: getPropertyChangeToNullDetails(property, oldValueAsString),
        action: async () => {
          setPropertyByString(
            obj.entity,
            property.property,
            property.defaultValue!(),
          );
        },
      };
    }
  }
  return undefined;
}

export function stringifyProperty(prop: any): string {
  if (prop === undefined) {
    return "undefined";
  } else if (prop === null) {
    return "null";
  } else if (prop === "") {
    return "Empty";
  }
  return prop.toString();
}

export function doesOverridingPropertyValueWork(
  obj: CoreBaseBackedObject<DrawableEntityConcrete>,
  property: FlatPropertyFields,
  newValue: any,
): boolean {
  const cloned = cloneSimple(obj.entity);
  // This is pretty horrible. tl;dr is that beforeSet mutates the original entity from when we created the property list.
  // Since we want to avoiding modifying any existing entities, we need to recreate the properties themselves.
  const newProperty = flattenTabFields(
    makeInertEntityFields(obj.context, cloned),
  ).find((x) => x.property === property.property);
  if (!newProperty) {
    throw new Error(
      `Could find property ${property.property} after creating a copy`,
    );
  }
  // This is really important, some fields have dependants they reset when changed.
  property?.beforeSet?.call(property, newValue);
  setPropertyByString(cloned, property.property, newValue);

  try {
    fillEntityDefaults(obj.context, cloned);
    return true;
  } catch (e) {
    return false;
  }
}
