import mixpanel from "mixpanel-browser";
import { CURRENT_VERSION } from "../../../common/src/api/config";
import {
  AnalyticsPayload,
  getTrackingCooldownMS,
} from "../../../common/src/api/document/analytics/analytics";
import { ENABLE_MIXPANEL } from "../config";
import { useDocument } from "../lib/hooks/use-document";

const lastTracked: Map<AnalyticsPayload["type"], Date> = new Map();

export function trackEvent(payload: AnalyticsPayload) {
  if (!ENABLE_MIXPANEL) return;

  if (lastTracked.has(payload.type)) {
    const msSinceEpoch = lastTracked.get(payload.type)?.getTime();
    if (
      msSinceEpoch &&
      msSinceEpoch + getTrackingCooldownMS(payload.type) < Date.now()
    ) {
      return;
    }
  }

  const properties = {
    ...getCommonProps(),
    ...("props" in payload ? payload.props : {}),
  };

  try {
    mixpanel.track(payload.type, properties);
    lastTracked.set(payload.type, new Date());
    console.log("Trying to track:", payload.type, properties);
  } catch (error) {
    console.error("Tracking Failed:", error);
  }
}

function getCommonProps(): Record<string, any> {
  const document = useDocument();

  return {
    documentId: String(document.documentId),
    historyIndex: document.uiState.historyIndex,
    documentVersion: CURRENT_VERSION,
  };
}
