import { isNotNullAndUndefined } from "../../../../common/src/lib/utils";
import { getGPUProblem } from "./performance";
import { Problem } from "./types";

/**
 * Calculates what the current problems with the project are
 *
 * Future thought: This might be better served in a service worker.
 */
export async function getProjectProblems(): Promise<Problem[]> {
  const results = await Promise.all([getGPUProblem()]);
  return results.filter(isNotNullAndUndefined);
}
