import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { catalog } from "./catalog";
import { customEntity } from "./custom-entity";
import { document } from "./document";
import { featureFlags } from "./feature-flags";
import { getters } from "./getters";
import { hotKey } from "./hot-key";
import { problems } from "./problems";
import { profile } from "./profile";
import { projectSetup } from "./project-set-up";
import { tools } from "./tools";
import { RootState } from "./types";
const pjson = require("../../package.json");

Vue.use(Vuex);

// export for testing
export const store: StoreOptions<RootState> = {
  state: () => ({
    packageVersion: pjson ? pjson.version : "0.0.0",
  }),
  getters,
  modules: {
    document,
    projectSetup,
    profile,
    tools,
    catalog,
    hotKey,
    customEntity,
    featureFlags,
    problems,
  },
};

export default new Vuex.Store<RootState>(store);
