export const PLANT_HEIGHT_MM = 300;
export const PLANT_HEIGHT_IN = 12;
export const RADIATOR_PIPE_EXTENSION_MM = 100;
export const RADIATOR_PIPE_EXTENSION_IN = 4;

export const PREHEAT_PIPE_EXTENSION_MM = 300;
export const PREHEAT_PIPE_EXTENSION_IN = 12;

export const PREHEAT_VALVE_SIZE = 42.7777777777767;

export const VOLUMISER_DEFAULT_VOLUME_L = 100;
export const VOLUMISER_DEFAULT_VOLUME_US_GALLONS = 26;
export const VOLUMISER_DEFAULT_VOLUME_UK_GALLONS = 22;
export const VOLUMISER_DEFAULT_PRESSURE_DROP_KPA = 50;
export const VOLUMISER_DEFAULT_PRESSURE_DROP_PSI = 7.5;
