<template>
  <label v-if="shouldShowDocAndOperation" id="doc-and-operation">{{
    docAndOperation
  }}</label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AccessLevel, User } from "../../../common/src/models/User";
import { DocumentState } from "../store/document/types";

export default defineComponent({
  computed: {
    profile(): User {
      return this.$store.getters["profile/profile"];
    },

    isAdmin(): boolean {
      return this.profile?.accessLevel <= AccessLevel.ADMIN;
    },

    shouldShowDocAndOperation(): boolean {
      return this.isAdmin && this.documentState.documentLoaded;
    },

    documentState(): DocumentState {
      return this.$store.getters["document/document"];
    },

    docAndOperation(): string {
      return `${this.documentState.documentId}@${this.documentState.nextId}`;
    },
  },
});
</script>

<style lang="less">
// designed to be just below the title block on the top right
// Loom logo covers it when it's on the bottom left after the version number
// so we just put it on the top right instead
#doc-and-operation {
  font-size: 11px;
  position: absolute;
  color: rgba(0, 0, 0, 0.3);
  right: 10px;
  top: 60px;
}
</style>
