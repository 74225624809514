// http://www.metrication.com/drafting/paper.html
import { Catalog } from "../../common/src/api/catalog/types";
import { SupportedDwellingStandards } from "../../common/src/api/config";
import { Choice, cloneSimple } from "../../common/src/lib/utils";

export const DEFAULT_FONT_NAME: string = "Helvetica";
export const DEFAULT_FONT_NAME_BOLD: string = "Helvetica-Bold";

export const isLocalHost = window.location.hostname === "localhost";
// This can run in webworkers too which lack the window object. In calculations, just do it.
const isProduction =
  typeof window === "undefined" ||
  window.location.hostname.includes("h2xengineering.com");
export const isTestOrDev = !isProduction;
const isE2ETest = window.navigator.webdriver; // https://github.com/microsoft/playwright/issues/19584#issuecomment-2239539361
const isJestTest = typeof jest !== "undefined" || typeof expect !== "undefined";

// Remember to also adjust useLocalCognito in node/config.ts. This is because frontend can't read backend env variables.
export const useLocalCognito =
  isTestOrDev && // safeguard against possibility of a dev accidentally setting boolean to true and deploying to prod
  false; // adjust this boolean
const isProdStrict =
  typeof window !== "undefined" &&
  window.location.hostname.includes("h2xengineering.com");

export function isSupportedDwellingStandard(
  arg: any,
): arg is SupportedDwellingStandards {
  return Object.values(SupportedDwellingStandards).includes(arg);
}

// These are some temporary configs while we don't have a database yet.

export const DISPLAY_DWELLING_METHODS: Choice[] = [
  {
    name: "AS3500 2021 Dwellings",
    key: SupportedDwellingStandards.as35002021Dwellings,
  },
  {
    name: "Barrie's Book Dwellings",
    key: SupportedDwellingStandards.barriesBookDwellings,
  },
  { name: "None", key: null },
];

export function getDwellingMethods(catalog: Catalog): Choice[] {
  const methods: Choice[] = cloneSimple(DISPLAY_DWELLING_METHODS);

  for (const key of Object.keys(catalog.dwellingStandards)) {
    const standard = catalog.dwellingStandards[key];
    const index = methods.findIndex((p) => p.key === key);
    if (index === -1) {
      methods.push({
        disabled: false,
        key,
        name: standard.name,
      });
    } else {
      methods[index].disabled = false;
    }
  }

  return methods;
}

export const PIPE_HEIGHT_GRAPHIC_EPS_MM = 50;

// Feature flag frontend
// Enable if not on h2xengineering.com
export const RELEASE_VENTILATION_FRONTEND = true;
export const RELEASE_UNDERFLOOR_HEATING_FRONTEND = false;
export const RELEASE_UNDERFLOOR_MVP_FRONTEND = true;
export const ENABLE_COMMAND_AI = !isE2ETest && !isJestTest;
export const ENABLE_MIXPANEL = isProdStrict;
export const ENABLE_SENTRY = isProdStrict;
export const ENABLE_PENDO = isProdStrict;
export const ENABLE_CANNY = !isE2ETest && !isJestTest;

// Note: This is a client side public key, it's not a secret.
// Please do not replace this with an SDK Key
// If your flags aren't working, it's because you didn't select "Allow Client-side SDKs" in your LaunchDarkly Flag.
// WE currently only have 2 environments: Prod and Test, we might make more later.
export const LAUNCH_DARKLY_PUBLIC_CLIENT_ID = isProdStrict
  ? "673ab636fb3be50844e74ff3"
  : "673ab636fb3be50844e74ff2";

export const RELEASE_MAGICPLAN = isTestOrDev;

export const VUE_WARN_AS_EXCEPTION = true;
