<template>
  <b-container>
    <div class="initial-container">
      <div>
        <div class="logo-container">
          <img
            src="@/assets/h2x.png"
            class="d-inline-block align-top"
            height="70"
          />
        </div>

        <!-- Enter Username/Email Screen -->
        <b-card
          v-if="state === 'enterUsername'"
          class="login-card"
          body-class="login-body"
        >
          <div class="login-header">
            <h4>Reset your password</h4>
          </div>

          <b-form style="text-align: left" @submit.prevent="reset">
            <b-form-group label="Username or Email" class="login-group">
              <b-form-input
                v-model="username"
                :disabled="isLoading"
                type="text"
                label="Username or Email"
                placeholder="Enter here"
                class="login-inputs"
              />
            </b-form-group>

            <div class="mt-5">
              <HeroButton type="submit">
                <b-spinner v-if="isLoading" style="width: 1rem; height: 1rem" />
                <span v-else>Send code</span>
              </HeroButton>
            </div>
          </b-form>
        </b-card>

        <!-- Enter Username/Email Screen -->
        <b-card
          v-if="state === 'enterCode'"
          class="login-card"
          body-class="login-body"
        >
          <div class="login-header">
            <b-alert variant="info" show>
              A code has been sent to your email
              <span v-if="sentEmail">({{ sentEmail }})</span>. Please enter the
              code below along with your new password.
            </b-alert>
          </div>

          <b-form style="text-align: left" @submit.prevent="confirmReset">
            <b-form-group label="Code" class="login-group">
              <b-form-input
                v-model="code"
                :disabled="isLoading"
                type="text"
                label="Code"
                placeholder="Enter here"
                class="login-inputs"
              />
            </b-form-group>
            <b-form-group label="New Password" class="login-group">
              <b-form-input
                v-model="password"
                :disabled="isLoading"
                type="password"
                label="New Password"
                placeholder="Enter here"
                class="login-inputs"
              />
            </b-form-group>

            <div class="mt-5">
              <HeroButton type="submit">
                <b-spinner v-if="isLoading" style="width: 1rem; height: 1rem" />
                <span v-else>Confirm</span>
              </HeroButton>
            </div>
          </b-form>
        </b-card>

        <!-- Success -->
        <b-card
          v-if="state === 'success'"
          class="login-card"
          body-class="login-body"
        >
          <div class="login-header">
            <b-alert variant="info" show>
              <b-icon icon="check" variant="success" /> Password reset
              successfully. You can now login with your new password.
            </b-alert>
          </div>

          <div class="mt-5">
            <HeroButton @click="goToLogin"> Go to Login </HeroButton>
          </div>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script lang="ts">
import {
  ResetPasswordOutput,
  confirmResetPassword,
  resetPassword,
} from "aws-amplify/auth";
import { defineComponent } from "vue";
import { assertUnreachable } from "../../../common/src/lib/utils";
import { emailValidation } from "../../../common/src/models/User.validation";
import { CLIENT_METADATA } from "../auth/amplify.config";
import HeroButton from "../components/molecules/HeroButton.vue";

export default defineComponent({
  components: { HeroButton },
  data() {
    const state = "enterUsername" as "enterUsername" | "enterCode" | "success";
    const isLoading: boolean = false;

    return {
      isLoading,
      state,
      username: "",
      code: "",
      password: "",
      sentEmail: "",
    };
  },
  mounted() {
    const username = this.$route.query.username;
    if (username) {
      this.state = "enterCode";
      this.username = String(username);
      this.reset();
    }
  },
  methods: {
    getValidUsername() {
      const { success: isValidEmail } = emailValidation(this.username);
      return isValidEmail ? this.username.toLowerCase() : this.username;
    },
    async reset() {
      try {
        this.isLoading = true;
        const username = this.getValidUsername();
        const res = await resetPassword({
          username,
          options: {
            clientMetadata: CLIENT_METADATA,
          },
        });
        this.handleResetPassRes(res);
      } catch (error: any) {
        console.error(error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async confirmReset() {
      try {
        this.isLoading = true;
        const username = this.getValidUsername();
        await confirmResetPassword({
          username: username,
          confirmationCode: this.code,
          newPassword: this.password,
          options: {
            clientMetadata: CLIENT_METADATA,
          },
        });
        this.state = "success";
      } catch (error: any) {
        console.error(error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleResetPassRes(res: ResetPasswordOutput) {
      const { nextStep } = res;
      switch (nextStep.resetPasswordStep) {
        case "CONFIRM_RESET_PASSWORD_WITH_CODE":
          const deliveryDetails = nextStep.codeDeliveryDetails;
          this.sentEmail = deliveryDetails.destination ?? "";
          this.state = "enterCode";
          break;
        case "DONE":
          return this.$router.push({ name: "login" });
        default:
          assertUnreachable(nextStep.resetPasswordStep);
      }
    },
    handleError(e: Error) {
      this.$bvToast.toast(e.message, {
        variant: "warning",
        toaster: " b-toaster-top-center",
        title: "Something is not right",
        noAutoHide: true,
      });
    },
    goToLogin() {
      return this.$router.push({ name: "login" });
    },
  },
});
</script>

<style>
.login-header {
  margin-bottom: 30px;
}

.login-card {
  border: none;
  margin-top: 20px;
  width: 460px;
  padding: 35px 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.login-body {
  padding: 5px;
}

@media (max-width: 770px) {
  .login-card {
    padding: 0;
    width: 350px;
  }

  .login-body {
    padding: 30px;
  }
}

.login-left-side {
  background-color: white;
  text-align: right;
  z-index: -1;
  padding-right: 50px;
}

.login-links {
  margin: 10px;
  color: #606e79;
  padding-top: 4px;
  display: block;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.login-inputs {
  padding: 25px 20px;
  font-size: 14px;
}

.login-inputs:focus {
  border: 1px solid #006fbe;
  box-shadow: none;
  border-radius: 4px;
  color: #374047;
}

.login-inputs::placeholder {
  color: #adb4b9;
}

.login-inputs:-webkit-autofill,
.login-inputs:-webkit-autofill:hover,
.login-inputs:-webkit-autofill:focus,
.login-inputs:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333333 !important;
}

.initial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hide {
  display: none;
}

.forgot-password-help-container {
  position: absolute;
  top: calc(50% + 220px);
  left: 0;
  width: 100%;
}

.forgot-password-help-text {
  max-width: 28rem;
  display: inline-block;
  line-height: 100%;
}

.login-group {
  color: #374047;
  font-size: 14px;
}

.password-link {
  text-decoration: underline;
}
</style>
