<template>
  <div>
    <MainNavBar />
    <b-container>
      <b-row>
        <b-col>
          <b-button class="float-left" @click="back()"> Back </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2>Create User</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <template v-if="user">
            <b-form>
              <b-form-group :label-cols="2" label="First Name">
                <b-form-input v-model="user.firstname" />
              </b-form-group>

              <b-form-group :label-cols="2" label="Last Name">
                <b-form-input v-model="user.lastname" />
              </b-form-group>

              <b-form-group :label-cols="2" label="Username">
                <b-form-input
                  v-model="editedOrSuggestedUserName"
                  type="search"
                  autocomplete="off"
                  :state="isSubmitted ? usernameValidationResult.success : null"
                />
                <b-form-invalid-feedback>
                  {{
                    usernameValidationResult.success
                      ? ""
                      : usernameValidationResult.message
                  }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label-cols="2" label="Email">
                <b-form-input
                  v-model="user.email"
                  type="email"
                  :state="isSubmitted ? emailValidationResult.success : null"
                />
                <b-form-invalid-feedback>
                  {{
                    emailValidationResult.success
                      ? ""
                      : emailValidationResult.message
                  }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label-cols="2" label="Initial Password">
                <b-form-input
                  v-model="user.password"
                  type="password"
                  autocomplete="off"
                  :disabled="user.noInitialPassword"
                  :state="isSubmitted ? passwordValidationResult.success : null"
                />
                <b-form-invalid-feedback>
                  {{
                    passwordValidationResult.success
                      ? ""
                      : passwordValidationResult.message + " "
                  }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                v-if="profile && profile.accessLevel <= AccessLevel.ADMIN"
                :label-cols="2"
                label="Organization"
              >
                <MultiSelect
                  v-model="selectedOrganization"
                  placeholder="Search..."
                  :options="organizations"
                  label="name"
                  track-by="id"
                  :custom-label="customOrgsSelectLabel"
                />
              </b-form-group>

              <b-form-group v-else :label-cols="2" label="Organization">
                <b-form-input
                  type="text"
                  :disabled="true"
                  :value="
                    profile && profile.organization && profile.organization.name
                  "
                />
              </b-form-group>

              <b-form-group :label-cols="2" label="Access Level">
                <b-form-radio-group
                  v-model="user.accessLevel"
                  :options="levels"
                  value-field="level"
                  text-field="name"
                  disabled-field="disabled"
                  size="lg"
                >
                  <b-form-radio value="2">
                    Manager
                    <b-icon
                      v-b-popover.hover.top="
                        'Managers can create and deactivate user in their organization'
                      "
                      icon="exclamation-circle"
                    />
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group :label-cols="2">
                <b-form-checkbox
                  v-model="user.forcePasswordChangeOnSignup"
                  :disabled="user.noInitialPassword"
                  size="lg"
                >
                  Force password change on signup
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="user.noInitialPassword"
                  size="lg"
                  @change="user.forcePasswordChangeOnSignup = true"
                >
                  No initial password
                </b-form-checkbox>
              </b-form-group>
            </b-form>
            <b-button
              variant="primary"
              style="margin-top: 50px"
              @click="create(false)"
            >
              <strong>Create</strong>
            </b-button>
            &nbsp;
            <b-button
              variant="primary"
              style="margin-top: 50px"
              @click="create(true)"
            >
              <strong>Create and New</strong>
            </b-button>
          </template>
          <b-alert v-else variant="success" show> Loading... </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AccessLevel, User as IUser } from "../../../common/src/models/User";
import { getOrganizations } from "../api/organizations";
import { createUser } from "../api/users";
import MainNavBar from "../components/MainNavBar.vue";
// import Organization from "./Organization.vue";
import {
  customOrgsLabel,
  defaultPasswordRequirements,
  Organization,
} from "../../../common/src/models/Organization";
import {
  emailValidation,
  passwordValidation,
  usernameValidation,
  UserValidationResult,
  validateUser,
} from "../../../common/src/models/User.validation";

export default defineComponent({
  components: {
    MainNavBar,
  },
  data() {
    const organizations: Organization[] = [];
    const selectedOrganization = null as Organization | null;
    const isSubmitted: boolean = false;

    return {
      user: {
        accessLevel: AccessLevel.USER,
        firstname: "",
        lastname: "",
        password: "",
        username: "",
        email: "",
        subscribed: true,
        eulaAcceptedVersion: 0,
        eulaAcceptedOn: null,
        lastActivityOn: null,
        lastNoticeSeenOn: null,
        temporaryOrganizationName: null,
        temporaryUser: false,
        forcePasswordChangeOnSignup: true,
        noInitialPassword: true,
      },
      isSubmitted,
      selectedOrganization,
      organizations,
    };
  },
  computed: {
    usernameValidationResult(): UserValidationResult {
      return usernameValidation(this.editedOrSuggestedUserName);
    },
    emailValidationResult(): UserValidationResult {
      return emailValidation(this.user.email);
    },
    passwordValidationResult(): UserValidationResult {
      if (this.user.noInitialPassword) {
        return { success: true };
      } else {
        let passwordRequirements = defaultPasswordRequirements;
        if (this.selectedOrganization) {
          passwordRequirements = this.selectedOrganization.passwordRequirements;
        }

        return passwordValidation(this.user.password, passwordRequirements);
      }
    },
    editedOrSuggestedUserName: {
      get(): string {
        return (
          this.user.username ||
          (this.user.firstname && this.user.lastname
            ? this.userNameFrom(this.user.firstname + this.user.lastname)
            : "")
        );
      },
      set(value: string) {
        this.user.username = value;
      },
    },
    customOrgsSelectLabel() {
      return customOrgsLabel;
    },
    profile(): IUser {
      return this.$store.getters["profile/profile"];
    },
    levels() {
      if (this.profile) {
        return [
          {
            name: "Super User",
            level: AccessLevel.SUPERUSER,
            disabled: this.profile.accessLevel > AccessLevel.SUPERUSER,
          },
          {
            name: "Admin",
            level: AccessLevel.ADMIN,
            disabled: this.profile.accessLevel >= AccessLevel.ADMIN,
          },
          {
            name: "User",
            level: AccessLevel.USER,
            disabled: this.profile.accessLevel > AccessLevel.MANAGER,
          },
        ];
      } else {
        return [];
      }
    },
    AccessLevel() {
      return AccessLevel;
    },
  },
  mounted() {
    if (this.profile && this.profile.accessLevel <= AccessLevel.ADMIN) {
      // fill documents

      getOrganizations().then((res) => {
        if (res.success) {
          this.organizations.splice(0, this.organizations.length, ...res.data);
        } else {
          this.$bvToast.toast(res.message, {
            variant: "danger",
            title: "Error retrieving org list",
          });
        }
      });
    } else if (this.profile) {
      this.selectedOrganization = this.profile.organization;
    }
  },
  methods: {
    userNameFrom(name: string): string {
      return name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    },
    create(andNew: boolean) {
      this.isSubmitted = true;
      this.user.username = this.editedOrSuggestedUserName;
      let passwordRequirements = defaultPasswordRequirements;
      if (this.selectedOrganization) {
        passwordRequirements = this.selectedOrganization.passwordRequirements;
      }
      if (
        validateUser(
          this.user.username,
          this.user.password,
          this.user.email,
          passwordRequirements,
          this.user.noInitialPassword,
        ).success
      ) {
        if (this.selectedOrganization) {
          createUser({
            username: this.user.username,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            email: this.user.email.toLowerCase(),
            password: this.user.password,
            accessLevel: this.user.accessLevel,
            organization: this.selectedOrganization.id,
            subscribed: this.user.subscribed,
            forcePasswordChangeOnSignup: this.user.forcePasswordChangeOnSignup,
            noInitialPassword: this.user.noInitialPassword,
          }).then((res) => {
            if (res.success) {
              if (andNew) {
                window.location.reload();
              } else {
                this.back();
              }
            } else {
              this.$bvToast.toast(res.message, {
                title: "Error creating user",
                variant: "danger",
              });
            }
          });
        }
      }
    },
    back() {
      if (this.$route.query.orgId)
        this.$router.push("/organizations/id/" + this.$route.query.orgId);
      else this.$router.push("/users");
    },
  },
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
