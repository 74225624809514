import { assertUnreachable, cloneSimple } from "../../../lib/utils";
import { isFlowReversed } from "../../config";
import { CoreObjectConcrete } from "../../coreObjects";
import DamperCalculation from "../../document/calculations-objects/damper-calculation";
import { isDuctEntity } from "../../document/entities/conduit-entity";
import { fillDefaultDamperFields } from "../../document/entities/damper-entity";
import { isFanEntity } from "../../document/entities/directed-valves/directed-valve-entity";
import { NodeType } from "../../document/entities/load-node-entity";
import { isAHUVent } from "../../document/entities/plants/plant-entity";
import { EntityType } from "../../document/entities/types";
import CalculationEngine from "../calculation-engine";
import { TraceCalculation } from "../flight-data-recorder";
import { PressurePushMode } from "../types";
import { FLOW_SOURCE_EDGE, isFlowSource } from "../utils";
import { VentRecord } from "./ventilation";

// Minimum pressure difference required between grills to warrant a need for dampers
export const PRESSURE_DIFF_REQ_FOR_DAMPER = 0.005;

export class DamperCalculations {
  // Here we attempt to balance grill pressures by assigning damper pressure drops
  // 1. Find all the AHUs in the system (each ahu has its own circuit)
  // 2. Find the pressure at each grill for each circuit
  // 3. Find the parent/child relationship of dampers for all circuits
  // 4. Find the pressure drop required for each damper by starting from the parent (each damper is responsible for grills it is directly connected to)
  static assignDamperPressureDropsDfsHelper(
    engine: CalculationEngine,
    start: { connectable: string; connection: string },
    vent: VentRecord,
  ) {
    const damperToGrills = new Map<string, number[]>();
    const damperParents = new Map<string, string[]>();
    const damperPressure = new Map<string, number>();
    const order: string[] = [];
    const stack: string[] = [];
    const path: string[] = [];
    let indexPath: string[] = [];
    let lowestPressure = 0;
    let lowestPressurePath: string[] = [];
    const nodePressureKPA = new Map<string, number | null>();
    const entityMaxPressuresKPA = new Map<string, number | null>();
    const fs = engine.drawing.metadata.flowSystems[vent.systemUid];
    const isReverse = isFlowReversed(fs);

    engine.pushPressureThroughNetwork({
      start,
      pressureKPA: 0,
      pressurePushMode: PressurePushMode.PSD,
      entityMaxPressuresKPA: entityMaxPressuresKPA,
      nodePressureKPA: nodePressureKPA,
    });

    engine.flowGraph.dfsRecursive(
      start,
      (node) => {
        // detect grills
        const connectable = engine.globalStore.get(
          node.connectable,
        ) as CoreObjectConcrete;
        if (
          connectable.type === EntityType.LOAD_NODE &&
          connectable.entity.node.type === NodeType.VENTILATION
        ) {
          // note that entityMaxPressuresKPA doesn't actually include the pressure drop of the grill
          // hence we find the pressure drop separately and include it
          const preGrillPressure =
            entityMaxPressuresKPA.get(connectable.uid) || 0;
          const grillPressureDrop =
            connectable.getComponentPressureLossKPA().pressureLossKPA || 0;
          const grillPressure = preGrillPressure - grillPressureDrop;

          if (grillPressure < lowestPressure) {
            lowestPressure = grillPressure;
            lowestPressurePath = cloneSimple(stack);
            indexPath = cloneSimple(path);
          }
          engine.ventLoadNodeToVentRecord.set(connectable.uid, vent.sourceUid);

          if (stack.length > 0) {
            if (!damperToGrills.has(stack.at(-1)!)) {
              damperToGrills.set(stack.at(-1)!, []);
            }
            damperToGrills.get(stack.at(-1)!)!.push(grillPressure ?? 0);
          }
        }

        if (connectable.uid !== vent.sourceUid) {
          if (isFanEntity(connectable.entity)) {
            const fanPressure = entityMaxPressuresKPA.get(connectable.uid) || 0;

            if (fanPressure < lowestPressure) {
              lowestPressure = fanPressure;
              lowestPressurePath = cloneSimple(stack);
              indexPath = cloneSimple(path);
            }
          }
        }

        if (connectable.uid !== vent.sourceUid) {
          // Add upstream AHU vents
          if (connectable.type === EntityType.SYSTEM_NODE) {
            const parent = engine.globalStore.get(
              connectable.entity.parentUid!,
            ) as CoreObjectConcrete;
            if (parent && isAHUVent(parent.entity)) {
              if (!vent.upstreamRecords.includes(connectable.uid)) {
                vent.upstreamRecords.push(connectable.uid);
              }
            }
          }
          // Add upstream Fans
          if (
            isFanEntity(connectable.entity) &&
            (isReverse || vent.parentType !== "ahu")
          ) {
            vent.upstreamRecords.push(connectable.uid);
          }
        }

        // stop the dfs if we reach a fan
        if (connectable.uid !== vent.sourceUid) {
          if (isFanEntity(connectable.entity)) {
            return true;
          }
        }
      },
      undefined,
      (edge) => {
        // detect dampers
        const termini = engine.globalStore.getTerminiByEdge(edge.value.uid);
        for (const term of termini) {
          damperParents.set(term, cloneSimple(stack));
          stack.push(term);
          order.push(term);
        }
        path.push(edge.value.uid);
      },
      (leaveEdge) => {
        const termini = engine.globalStore.getTerminiByEdge(
          leaveEdge.value.uid,
        );
        // there might be multiple termini on the edge
        for (const _ of termini) {
          stack.pop();
        }
        path.pop();
      },
    );

    return {
      damperToGrills,
      damperParents,
      damperPressure,
      order,
      stack,
      path,
      indexPath,
      lowestPressure,
      lowestPressurePath,
      start,
      nodePressureKPA,
      entityMaxPressuresKPA,
      fs,
      isReverse,
    };
  }
  @TraceCalculation("Calculating damper pressure drops")
  static assignDamperPressureDrops(
    engine: CalculationEngine,
    ventRecords: Map<string, VentRecord>,
  ) {
    for (const o of engine.networkObjects()) {
      // set a minimum pressure drop of 3.5kPA to all dampers
      if (o.entity.type === EntityType.DAMPER) {
        const dCalc = engine.globalStore.getOrCreateCalculation(
          o.entity,
        ) as DamperCalculation;
        const filled = fillDefaultDamperFields(engine, o.entity);
        dCalc.pressureDropKPA = filled.minPressureDropKPA;
      }
    }

    for (const vent of ventRecords.values()) {
      if (
        vent.role !== "vent-supply" &&
        vent.role !== "vent-extract" &&
        vent.role !== "vent-fan-exhaust" &&
        vent.role !== "vent-exhaust"
      ) {
        continue;
      }

      const startNode = this.getStartNode(engine, vent);
      // We are not connected to a source, cannot calculate pressure drops
      if (startNode === null) {
        continue;
      }
      const {
        damperToGrills,
        damperParents,
        damperPressure,
        order,
        indexPath,
        lowestPressure,
        lowestPressurePath,
      } = this.assignDamperPressureDropsDfsHelper(engine, startNode, vent);

      // set index node path for ducts
      if (
        vent.role === "vent-supply" ||
        vent.role === "vent-extract" ||
        vent.role === "vent-fan-exhaust"
      ) {
        for (const uid of indexPath) {
          const o = engine.globalStore.get(uid);
          if (isDuctEntity(o.entity)) {
            const conduitCalc = engine.globalStore.getOrCreateCalculation(
              o.entity,
            );
            conduitCalc.isIndexNodePath = true;
          }
        }
      }

      for (const o of order) {
        const damper = engine.globalStore.getObjectOfTypeOrThrow(
          EntityType.DAMPER,
          o,
        );
        const filled = fillDefaultDamperFields(engine, damper.entity);
        let result = filled.minPressureDropKPA!;

        if (damperToGrills.has(o) && !lowestPressurePath.includes(o)) {
          const endPressure = Math.min(...damperToGrills.get(o)!);
          const parentPressures = damperParents
            .get(o)!
            .map((p) => {
              const d = engine.globalStore.getObjectOfTypeOrThrow(
                EntityType.DAMPER,
                p,
              );
              const filled = fillDefaultDamperFields(engine, d.entity);
              return damperPressure.get(p)! - filled.minPressureDropKPA!;
            })
            .reduce((a, c) => a + c, 0);

          const damperCalc = engine.globalStore.getOrCreateCalculation(
            damper.entity,
          );
          result += Math.abs(lowestPressure - (endPressure + parentPressures));
          damperCalc.pressureDropKPA = result;
        }

        damperPressure.set(o, result);
      }

      switch (vent.parentType) {
        case "ahu":
          if (vent.role === "vent-supply" || vent.role === "vent-extract") {
            const ahuCalc = engine.globalStore.getOrCreateCalculation(
              vent.parent,
            );
            if (vent.role === "vent-supply") {
              ahuCalc.supplyIndexCircuitPressureDropKPA =
                Math.abs(lowestPressure);
            } else {
              ahuCalc.extractIndexCircuitPressureDropKPA =
                Math.abs(lowestPressure);
            }
          }
          break;
        case "fan":
          const dCalc = engine.globalStore.getOrCreateCalculation(vent.parent);
          dCalc.interiorPressureDropKPA = Math.abs(lowestPressure);
          break;
        default:
          assertUnreachable(vent);
      }

      // enable warnings processing if there are more than one damper in this loop
      if (damperParents.size > 1) {
        ventRecords.set(vent.sourceUid, {
          ...vent,
          hasMoreThanOneDamper: true,
        });
      }
    }
  }

  static getStartNode(engine: CalculationEngine, vent: VentRecord) {
    switch (vent.parentType) {
      case "ahu":
        return {
          connectable: vent.sourceUid,
          connection: vent.parent.uid,
        };
      case "fan":
        const obj = engine.globalStore.getObjectOfTypeOrThrow(
          EntityType.DIRECTED_VALVE,
          vent.sourceUid,
        );
        const isFlow = isFlowSource(obj.entity, engine);
        // We are not connected to a source, there is no flow.
        if (!isFlow && obj.entity.sourceUid === null) {
          return null;
        }
        return {
          connectable: vent.sourceUid,
          connection: isFlow ? FLOW_SOURCE_EDGE : obj.entity.sourceUid!,
        };
    }
    assertUnreachable(vent);
  }
}
