import {
  Units,
  UnitsContext,
  chooseByUnitsMetric,
} from "../../lib/measurements";
import { Lines } from "../linetypes/types";
import { UnitsParameters } from "./drawing";

export function getDefaultLines(units: UnitsParameters): Lines {
  return {
    belowFloor: {
      name: "Below Floor",
      strokeId: "soild",
      level: {
        lt: 0,
      },
    },
    lowLevel: {
      name: "Low Level",
      strokeId: "soild",
      level: {
        gte: 0,
        lt: chooseByUnitsMetric(
          units,
          {
            [Units.Meters]: 1.5,
            [Units.Feet]: 5,
          },
          UnitsContext.NONE,
        )[1],
      },
    },
    highLevel: {
      name: "High Level",
      strokeId: "soild",
      level: {
        gte: chooseByUnitsMetric(
          units,
          {
            [Units.Meters]: 1.5,
            [Units.Feet]: 5,
          },
          UnitsContext.NONE,
        )[1],
        lt: chooseByUnitsMetric(
          units,
          {
            [Units.Meters]: 3,
            [Units.Feet]: 10,
          },
          UnitsContext.NONE,
        )[1],
      },
    },
    aboveCeiling: {
      name: "Above Ceiling",
      strokeId: "soild",
      level: {
        gte: chooseByUnitsMetric(
          units,
          {
            [Units.Meters]: 3,
            [Units.Feet]: 10,
          },
          UnitsContext.NONE,
        )[1],
      },
    },
  };
}
