<template>
  <div>
    <MainNavBar />
    <div
      class="home"
      style="overflow-y: auto; overflow-x: hidden; height: calc(100vh - 70px)"
    >
      <b-container>
        <b-row>
          <b-col>
            <h1 class="title">Change Logs: see, add.</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form @submit="onSubmit">
              <!-- <label for="tags-basic">Type tags for the new update and press enter</label> -->
              <b-form-group id="input-group-1" label="Version:" label-for="t1">
                <b-form-input
                  id="t1"
                  v-model="version"
                  size="sm"
                  placeholder="version(s) this update message is relevant to."
                />
              </b-form-group>

              <b-form-group id="input-group-2" label="Tags:" label-for="t2">
                <b-form-input
                  id="t2"
                  v-model="tags"
                  size="sm"
                  placeholder="Tags, comma separated"
                />
              </b-form-group>

              <b-row>
                <b-col cols="6">
                  <b-form-group id="input-group-3" label-for="t3">
                    Message
                    <a
                      href="https://www.markdownguide.org/cheat-sheet/#basic-syntax"
                    >
                      (Markdown format) </a
                    >:
                    <b-form-textarea
                      id="t3"
                      v-model="message"
                      placeholder="the message for this update"
                      rows="13"
                      style="font-family: monospace"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  Preview:
                  <b-card style="height: calc(100% - 30px); text-align: left">
                    <Markdown :value="message" />
                  </b-card>
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary"> Submit </b-button>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert
              v-if="logMessages.length === 0 && isLoaded"
              variant="success"
              show
            >
              There are no change log messages right now
            </b-alert>
          </b-col>
        </b-row>
        <b-row v-for="log in logMessages" :key="log.id">
          <b-col>
            <b-card>
              <b-card-text style="text-align: left">
                <b>Version:</b> {{ log.version }}<br />
                <b>Date:</b> {{ new Date(log.createdOn).toLocaleString()
                }}<br />
                <b>Message:</b>
                <Markdown :value="log.message" />
                <b-badge
                  v-for="badge in log.tags.split(',')"
                  :key="badge"
                  pill
                  variant="primary"
                >
                  {{ badge }}
                </b-badge>
                <br />
                <b>Submitted by:</b> {{ log.submittedBy.name }} @{{
                  log.submittedBy.username
                }}<br />
                <b-button
                  v-b-modal.delete-modal
                  variant="danger"
                  size="sm"
                  @click="deleteId = log.id"
                >
                  Delete
                </b-button>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      id="delete-modal"
      title="Delete Change Log Message"
      :ok-disabled="deleteId === null"
      @ok="deleteChangelog(deleteId || 0)"
      @cancel="deleteId = null"
    >
      <p>Are you sure you want to delete this change log message?</p>
    </b-modal>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ChangeLogMessage } from "../../../common/src/models/ChangeLogMessage";
import { User } from "../../../common/src/models/User";
import MainNavBar from "../../src/components/MainNavBar.vue";
import {
  deleteChangeLogMessage,
  getChangeLogMessages,
  saveChangeLogMessage,
} from "../api/change-log";
import Markdown from "../components/molecules/Markdown.vue";

@Component({
  components: {
    MainNavBar,
    Markdown,
  },
})
export default class ChangeLogs extends Vue {
  logMessages: ChangeLogMessage[] = [];
  isLoaded: boolean = false;
  value: string = "";
  version: string = "";
  tags: string = "";
  message: string = "";
  deleteId: number | null = null;

  onSubmit(event: any) {
    saveChangeLogMessage(this.message, this.tags, this.version).then((res) => {
      if (res.success) {
        this.logMessages.unshift(res.data);
        this.message = "";
        this.tags = "";
        this.version = "";
      } else {
        this.$bvToast.toast(res.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    });
    // prevent default action
    event.preventDefault();
  }

  get lastMonth() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  deleteChangelog(id: number) {
    deleteChangeLogMessage(id).then((res) => {
      if (res.success) {
        this.logMessages = this.logMessages.filter((log) => log.id !== id);
      } else {
        this.$bvToast.toast(res.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    });
  }

  mounted() {
    // get all change log messages
    getChangeLogMessages(this.lastMonth).then((res) => {
      if (res.success) {
        this.logMessages.splice(0, this.logMessages.length, ...res.data);
        this.isLoaded = true;
      }
    });
  }

  get profile(): User {
    return this.$store.getters["profile/profile"];
  }
}
</script>

<style lang="less">
h1 {
  padding-top: 50px;
}
</style>
