import { FrontendFeatureFlag } from "../../store/feature-flags/types";
import store from "../../store/store";

/**
 * Reactive field for checking if a feature flag is enabled
 */
export const isFeatureFlagEnabled = (featureFlag: FrontendFeatureFlag) => {
  return store.getters["featureFlags/isFeatureFlagEnabled"](featureFlag);
};

/**
 * Reactive field for checking if a feature flag is enabled
 */
export const isFeatureFlagDisabled = (featureFlag: FrontendFeatureFlag) => {
  return store.getters["featureFlags/isFeatureFlagDisabled"](featureFlag);
};

export function getFeatureFlagValue<T>(featureFlag: FrontendFeatureFlag): T {
  return store.getters["featureFlags/getFeatureFlagValue"](featureFlag);
}
