<template>
  <b-container>
    <div class="initial-container">
      <div>
        <div class="logo-container">
          <img
            src="@/assets/h2x.png"
            class="d-inline-block align-top"
            height="70"
          />
        </div>

        <!-- SignUp Screen -->
        <b-card
          v-if="state === 'signUp'"
          class="login-card"
          body-class="login-body"
        >
          <div class="login-header">
            <h3 style="margin-bottom: 10px; font-weight: bold">
              Sign Up to h2x
            </h3>
            <span style="color: #606e79">
              Mechanical & Plumbing Design Software</span
            >
          </div>

          <b-form style="text-align: left" @submit.prevent="signUp">
            <b-form-group
              v-for="(field, key) in signUpFields"
              :key="key"
              :label="field.label"
              class="login-group"
            >
              <b-form-input
                v-model="field.value"
                :disabled="isLoading"
                :type="field.type"
                :label="field.label"
                :placeholder="field.placeholder"
                :data-testid="field.dataCy"
                class="login-inputs"
              />
            </b-form-group>

            <div class="mt-5">
              <HeroButton type="submit" data-testid="signup-button">
                <b-spinner v-if="isLoading" style="width: 1rem; height: 1rem" />
                <span v-else>Sign Up</span>
              </HeroButton>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script lang="ts">
import { SignUpOutput, signUp } from "aws-amplify/auth";
import { defineComponent } from "vue";
import { assertType, assertUnreachable } from "../../../common/src/lib/utils";
import { CLIENT_METADATA } from "../auth/amplify.config";
import HeroButton from "../components/molecules/HeroButton.vue";

export default defineComponent({
  components: { HeroButton },
  data() {
    const state: "signUp" = "signUp";
    const isLoading: boolean = false;

    return {
      isLoading,
      state,
    };
  },
  computed: {
    signUpFields() {
      return {
        username: {
          type: "text",
          label: "Username",
          placeholder: "Username",
          value: "",
          dataCy: "username-input",
        },
        email: {
          type: "email",
          label: "Email",
          placeholder: "Email",
          value: "",
          dataCy: "email-input",
        },
        given_name: {
          type: "text",
          label: "Given Name",
          placeholder: "First Name",
          value: "",
          dataCy: "given-name-input",
        },
        family_name: {
          type: "text",
          label: "Family Name",
          placeholder: "Last Name",
          value: "",
          dataCy: "family-name-input",
        },
        password: {
          type: "password",
          label: "Password",
          placeholder: "Password",
          value: "",
          dataCy: "signup-password-input",
        },
      };
    },
  },
  methods: {
    trimSignUpFields() {
      for (const key in this.signUpFields) {
        assertType<keyof typeof this.signUpFields>(key);
        this.signUpFields[key].value = this.signUpFields[key].value.trim();
      }
    },
    async signUp() {
      this.trimSignUpFields();
      try {
        this.isLoading = true;
        const res = await signUp({
          username: this.signUpFields.username.value,
          password: this.signUpFields.password.value,
          options: {
            userAttributes: {
              email: this.signUpFields.email.value.toLowerCase(),
              given_name: this.signUpFields.given_name.value,
              family_name: this.signUpFields.family_name.value,
              "custom:domain": CLIENT_METADATA.domain,
            },
            clientMetadata: CLIENT_METADATA,
            autoSignIn: {
              clientMetadata: CLIENT_METADATA,
            },
          },
        });

        this.handleSignUpResponse(res);
      } catch (e: any) {
        this.$bvToast.toast(e.message, {
          variant: "warning",
          toaster: " b-toaster-top-center",
          title: "Something is not right",
          noAutoHide: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleSignUpResponse(res: SignUpOutput) {
      console.log("sign up response", res);
      const { nextStep } = res;

      switch (nextStep.signUpStep) {
        case "COMPLETE_AUTO_SIGN_IN":
          this.navigateToLoginInNewTab();
        case "CONFIRM_SIGN_UP":
        case "DONE":
          break;
        default:
          assertUnreachable(nextStep);
      }
    },
    /**
     * In order to allow opening in top...
     *
     * The following must be added to the iframe
     *
     * <iframe sandbox="allow-top-navigation">
     *
     * There are other useful sandbox items that should be added:
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox
     *
     * - allow-popups
     * - allow-popups-to-escape-sandbox
     */
    isInAccessibleTopIframe(): boolean | undefined {
      try {
        return window.self !== window.top;
      } catch (e) {
        return undefined;
      }
    },
    isInAccessibleParentIframe(): boolean | undefined {
      try {
        return window.self !== window.parent;
      } catch (e) {
        return undefined;
      }
    },
    navigateToLoginInNewTab() {
      const routeData = this.$router.resolve({ name: "/login" });
      const isInIframeWithTopAccessible = this.isInAccessibleTopIframe();
      const isInIframeWithParentAccessible = this.isInAccessibleParentIframe();
      if (isInIframeWithTopAccessible) {
        window.open(routeData.href, "_top");
        return;
      }

      if (isInIframeWithParentAccessible) {
        window.open(routeData.href, "_parent");
        return;
      }

      // Fall back to opening in the same tab, this likely means either the iframe
      // has hardened permissions or there is no iframe present.
      window.open(routeData.href, "_self");
    },
  },
});
</script>

<style>
.login-header {
  margin-bottom: 30px;
}

.login-card {
  border: none;
  margin-top: 20px;
  width: 460px;
  padding: 35px 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.login-body {
  padding: 5px;
}

@media (max-width: 770px) {
  .login-card {
    padding: 0;
    width: 350px;
  }

  .login-body {
    padding: 30px;
  }
}

.login-left-side {
  background-color: white;
  text-align: right;
  z-index: -1;
  padding-right: 50px;
}

.login-links {
  margin: 10px;
  color: #606e79;
  padding-top: 4px;
  display: block;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.login-inputs {
  padding: 25px 20px;
  font-size: 14px;
}

.login-inputs:focus {
  border: 1px solid #006fbe;
  box-shadow: none;
  border-radius: 4px;
  color: #374047;
}

.login-inputs::placeholder {
  color: #adb4b9;
}

.login-inputs:-webkit-autofill,
.login-inputs:-webkit-autofill:hover,
.login-inputs:-webkit-autofill:focus,
.login-inputs:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333333 !important;
}

.initial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hide {
  display: none;
}

.forgot-password-help-container {
  position: absolute;
  top: calc(50% + 220px);
  left: 0;
  width: 100%;
}

.forgot-password-help-text {
  max-width: 28rem;
  display: inline-block;
  line-height: 100%;
}

.login-group {
  color: #374047;
  font-size: 14px;
}

.password-link {
  text-decoration: underline;
}
</style>
