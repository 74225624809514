import Flatten from "@flatten-js/core";
import * as _ from "lodash";

export const objectifyArray = <T>(
  array: T[],
  getKey: (t: T) => string | number,
) => {
  let returner: Record<string | number, T> = {};
  array.forEach((entry) => {
    returner[getKey(entry)] = entry;
  });
  return returner;
};

// https://stackoverflow.com/a/61511955/15166236
export function waitForElement<T>(selector: string): Promise<T> {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector) as T);
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector) as T);
      }
    });

    // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export const dataCySelector = (dataCy: string): string => {
  return `[data-testid="${dataCy}"]`;
};

export const getElementByDataCy = <T>(dataCy: string): T | null => {
  return document.querySelector(`[data-testid="${dataCy}"]`) as T | null;
};

export const falsy = <T>(
  o: T | false | null | undefined,
): o is false | null | undefined => {
  return !o;
};

export const notFalsy = <T>(o: T | false | null | undefined): o is T => {
  return !falsy(o);
};

export const capitalizeEveryWord = (str: string) => {
  return _.startCase(_.toLower(str));
};

export const makeFirstLetterUpper = (str: string) => {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/** logging errors in a try-catch is a pain in the ass */
export const objectifyError = (e: Error) => ({
  error: e,
  name: e.name ?? null,
  message: e.message ?? null,
  stack: e.stack ?? null,
  cause: e.cause ?? null,
});

/** logging errors in a try-catch is a pain in the ass */
export const stringifyError = (e: Error) => JSON.stringify(objectifyError(e));

/** similar to Array.map but synchronously executes a collection of async lambdas.
 * useful when you want 'map' behaviour but don't want all async promises to fire simultaneously (hence 'sync')
 */
export const syncForEach = async <T, Result>(
  collection: T[],
  action: (t: T) => Promise<Result>,
) => {
  const data: Result[] = [];
  for (const t of collection) {
    data.push(await action(t));
  }
  return data;
};

/** similar to Array.reduce but synchronously accumulates a collection of async lambdas.
 * useful when you want 'reduce' behaviour but don't want all async promises to fire simultaneously (hence 'sync')
 */
export const syncReduce = async <T, State>(
  collection: T[],
  initialState: State,
  func: (
    previousState: State,
    currentValue: T,
    currentIndex: number,
  ) => Promise<State>,
) => {
  let state = initialState;
  let i = 0;
  for (const t of collection) {
    state = await func(state, t, i);
    i++;
  }
  return state;
};

/** useful for throwing in ternaries / null coalescing
 * eg. `const a = b ?? Throw("b is undefined");`
 */
export const Throw = (msg: string) => {
  throw new Error(msg);
};

export function assertUnreachable(x: never, shouldThrow: boolean = true) {
  if (shouldThrow) {
    throw new Error(
      "Didn't expect to get here. Object is: " + JSON.stringify(x),
    );
  }
}

// stronger type inference by not allowing the 'shouldThrow' escape hatch...
export function assertUnreachableAggressive(x: never): never {
  throw new Error("Didn't expect to get here. Object is: " + JSON.stringify(x));
}

export const getRandomElementFromArray = <T>(array: T[]) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

export function staticImplements<T>() {
  return <U extends T>(constructor: U) => {
    constructor;
  };
}

export type OmitStatics<T, S extends string> = T extends {
  new (...args: infer A): infer R;
}
  ? { new (...args: A): R } & Omit<T, S>
  : Omit<T, S>;

export function assertType<T>(obj: unknown): asserts obj is T {
  // noop
}

/** same as assertType but returns the object in question */
export const assertTypePure = <T>(obj: any) => obj as T;

/**
 * A faster alternative to lodash cloneDeep for simple JSON-like objects
 */
export function cloneSimple<T>(obj: T, d: number = 0): T {
  if (_.isArray(obj)) {
    const res: any[] = [];
    obj.forEach((o) => {
      res.push(cloneSimple(o, d + 1));
    });
    return res as any as T;
  } else if (_.isObject(obj)) {
    const res: any = {};
    for (const key of Object.keys(obj)) {
      res[key] = cloneSimple((obj as any)[key], d + 1);
    }
    return res;
  } else {
    return obj;
  }
}

/** typescript/js is junk here - type inference on Object.Entries keys gets lost, very annoying */
export const betterObjectEntries = <Key extends string | number, Value>(
  obj: Record<Key, Value>,
) => {
  return Object.entries(obj) as [Key, Value][];
};

/** typescript/js is junk here - type inference on Object.Entries keys gets lost, very annoying */
export const betterObjectFromEntries = <Key extends string | number, Value>(
  entries: [Key, Value][],
) => {
  return Object.fromEntries(entries) as Record<Key, Value>;
};

/** typescript/js is junk here - type inference on Object.Keys gets lost, very annoying */
export const betterObjectKeys = <Key extends string | number>(
  obj: Record<Key, any>,
) => {
  return Object.keys(obj) as Key[];
};

/** typescript/js is fine here but better to have this func available to match usage of the above */
export const betterObjectValues = <T>(obj: Record<any, T>) => {
  return Object.values(obj) as T[];
};

export function cloneNaive<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export const asAny = <T>(obj: T) => obj as any;

export const asNumber = <T>(obj: T) => obj as number;

export const consoleLogWithColour = (msg: string, colour: string) => {
  console.log(`%c${msg}`, `color:${colour}`);
};

export const immutableAppend = <T>(
  array: T[],
  element: T,
  maxNElements?: number,
): T[] => {
  let newArr = [...array, element];
  if (maxNElements != undefined && newArr.length > maxNElements) {
    const nToSlice = newArr.length - maxNElements;
    newArr = newArr.slice(nToSlice, Number.MAX_VALUE);
  }
  return newArr;
};

export const immutableConcat = <T>(
  a: T[],
  b: T[],
  maxNElements?: number,
): T[] => {
  let newArr = [...a, ...b];
  if (maxNElements != undefined && newArr.length > maxNElements) {
    const nToSlice = newArr.length - maxNElements;
    newArr = newArr.slice(nToSlice, Number.MAX_VALUE);
  }
  return newArr;
};

export const lastBy = <T>(
  arr: T[],
  predicate: (t: T) => boolean,
): { element: T | null; index: number } => {
  let lastElement: T | null = null;
  let i: number = 0;
  while (true) {
    if (i >= arr.length) break;
    const element = arr[i];
    const pred = predicate(element);
    if (pred) {
      lastElement = element;
      i++;
      continue;
    } else break; // exit condition - hit an element that fails pred
  }
  return { element: lastElement, index: i };
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export interface Choice<T = string | number | boolean | null> {
  name: string;
  key: T;
  disabled?: boolean;
  softDisabled?: boolean; // option greyed out but still clickable

  // option won't appear but won't fail validation if chosen. Useful during beta testing and feature flags
  // - not for a released feature.
  invisible?: boolean;
}

export function parseCatalogNumberOrMin(
  str: string | number | null,
): number | null {
  if (typeof str === "number") {
    return str;
  }
  if (str === null) {
    return null;
  }
  if (str.indexOf("-", 1) !== -1) {
    const arr = str.split("-");
    if (arr.length > 2) {
      throw new Error("Dunno");
    }
    const n = Number(str.split("-")[0]);
    return isNaN(n) ? null : n;
  } else {
    const n = Number(str);
    return isNaN(n) ? null : n;
  }
}

export function parseCatalogNumberOrMax(
  str: string | number | null,
): number | null {
  if (typeof str === "number") {
    return str;
  }
  if (str === null) {
    return null;
  }
  if (str.indexOf("-", 1) !== -1) {
    const arr = str.split("-");
    if (arr.length > 2) {
      throw new Error("Dunno");
    }
    const n = Number(str.split("-")[1]);
    return isNaN(n) ? null : n;
  } else {
    const n = Number(str);
    return isNaN(n) ? null : n;
  }
}

export function parseCatalogNumberExact(
  str: string | number | null | undefined,
): number | null {
  if (typeof str === "number") {
    return str;
  }
  if (str === null) {
    return null;
  }
  const n = Number(str);
  return isNaN(n) ? null : n;
}

export function interpolateTable<T>(
  table: { [key: string]: string | number },
  index: number,
  strict?: boolean,
): number | null;
export function interpolateTable<T>(
  table: { [key: string]: T },
  index: number,
  strict: boolean,
  fn: (entry: T) => string | number | null,
): number | null;
// assumes keys in table are non overlapping
export function interpolateTable<T>(
  table: { [key: string]: T },
  index: number,
  strict: boolean = false,
  fn?: (entry: T) => string | number | null,
  implyZero: boolean = false,
): number | null {
  let lowKey = -Infinity;
  let highKey = Infinity;
  let lowValue = null;
  let highValue = null;

  for (const key of Object.keys(table)) {
    const min = parseCatalogNumberOrMin(key);
    const max = parseCatalogNumberOrMax(key);
    const value = fn
      ? parseCatalogNumberExact(fn(table[key]))
      : parseCatalogNumberExact(table[key] as any);
    if (value !== null) {
      if (min !== null && max !== null) {
        if (index >= min && index <= max) {
          if (value !== null) {
            return value;
          }
        } else {
          if (min > index && min < highKey) {
            highKey = min;
            highValue = value;
          }
          if (max < index && max >= lowKey) {
            lowKey = max;
            lowValue = value;
          }
        }
      } else {
        throw new Error("table key not a number or range");
      }
    } else {
      throw new Error("table value not a number, cannot interpolate");
    }
  }

  if (lowValue === null) {
    if (highValue !== null) {
      return strict ? null : highValue;
    } else {
      return null;
    }
  } else if (highValue === null) {
    if (lowValue !== null) {
      return strict ? null : lowValue;
    } else {
      return null;
    }
  }

  const lw = (highKey - index) / (highKey - lowKey);
  const hw = (index - lowKey) / (highKey - lowKey);
  return lw * lowValue + hw * highValue;
}

export function lowerBoundTable<T>(
  table: { [key: string | number]: T },
  index: number,
  getVal?: (t: T, isMax?: boolean) => number,
): T | null {
  return lowerBoundTableWithKey(table, index, getVal)?.value ?? null;
}

// returns first table entry with key >= index
// assumes keys in table are non overlapping
export function lowerBoundTableWithKey<T>(
  table: { [key: string | number]: T },
  index: number,
  getVal?: (t: T, isMax?: boolean) => number,
): { key: number | null; value: T | null } {
  let highKey = Infinity;
  let highValue: T | null = null;

  for (const key of Object.keys(table)) {
    const min = getVal
      ? getVal(table[key], false)
      : parseCatalogNumberOrMin(key);
    const max = getVal
      ? getVal(table[key], true)
      : parseCatalogNumberOrMax(key);
    const value = table[key];

    if (min === null || max === null) {
      throw new Error("key is not a number: " + key);
    }

    if (min <= index && max >= index) {
      return {
        key: index,
        value,
      };
    }

    if (min < highKey && min > index) {
      highKey = min;
      highValue = value;
    }
  }

  if (highValue === null) {
    return {
      key: null,
      value: null,
    };
  }

  return {
    key: highKey,
    value: highValue,
  };
}

export function upperBoundTable<T>(
  table: { [key: string]: T },
  index: number,
  getVal?: (t: T, isMax?: boolean) => number,
): T | null {
  return upperBoundTableWithKey(table, index, getVal)?.value ?? null;
}

// returns last table entry with key <= index
// assumes keys in table are non overlapping
export function upperBoundTableWithKey<T>(
  table: { [key: string]: T },
  index: number,
  getVal?: (t: T, isMax?: boolean) => number,
): { key: number | null; value: T | null } {
  let lowKey = -Infinity;
  let lowValue: T | null = null;

  for (const key of Object.keys(table)) {
    const min = getVal
      ? getVal(table[key], false)
      : parseCatalogNumberOrMin(key);
    const max = getVal
      ? getVal(table[key], true)
      : parseCatalogNumberOrMax(key);
    const value = table[key];

    if (min === null || max === null) {
      throw new Error("key is not a number: " + key);
    }

    if (min <= index && max >= index) {
      return {
        key: index,
        value,
      };
    }

    if (min <= index && Math.min(max, index) > lowKey) {
      lowKey = Math.min(max, index);
      lowValue = value;
    }
  }

  if (lowValue === null) {
    return {
      key: null,
      value: null,
    };
  }

  return {
    key: lowKey,
    value: lowValue,
  };
}

export function lowerBoundNumberTable(
  table: { [key: number]: any },
  key: number | null,
): number | null {
  if (key === null) {
    return null;
  }
  const candidates = Object.keys(table)
    .map(Number)
    .sort((a, b) => a - b);
  const result = candidates.find((c) => c >= key);
  return result === undefined ? null : result;
}

export const EPS_ABS = 1e-8;
export const EPS = 1e-8;
(Flatten as any).Utils.setTolerance(EPS / 2);

export interface SelectField {
  value: number | string;
  text: string;
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>;
};

/**
 * If the string contains no lowercase character, returns itself. (a common
 * case is abbreviations).
 * Otherwise, returns the lowercase version of the string.
 */
export function lowerCase(str: string) {
  if (str.toUpperCase() === str) {
    return str;
  } else {
    return str.toLowerCase();
  }
}

function traverseAndFlatten(
  currentNode: object,
  target: object,
  flattenedKey?: string | number,
) {
  for (const key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      let newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = flattenedKey + "." + key;
      }

      const value = (currentNode as any)[key];
      if (typeof value === "object") {
        traverseAndFlatten(value, target, newKey);
      } else {
        (target as any)[newKey] = value;
      }
    }
  }
}

export function flatten(obj: object) {
  const flattenedObject = {};

  traverseAndFlatten(obj, flattenedObject, undefined);

  return flattenedObject;
}

export type KeysOfUnion<T> = T extends T ? keyof T : never;

/**
 * Get the next from goal
 * @param goal the number to be look
 * @param list the list of number or object to be look at
 * @param key the key in object if param `list` is in list of object
 * @param exact return same value as goal if present
 */
export const getNext = (
  goal: number,
  list: ({} | number)[],
  exact: boolean = false,
  key?: string,
): number | {} => {
  const isObject = typeof list[0] === "object";

  if (isObject && !key) {
    throw new Error("Please provide key");
  }

  let condition;
  let extractCurrVal;
  let extractPrevVal;
  let min;
  return list.reduce((prev, curr) => {
    extractCurrVal = curr;
    extractPrevVal = prev;
    if (isObject) {
      extractCurrVal = getPropertyByString(curr, key!);
      extractPrevVal = getPropertyByString(prev, key!);
    }

    min = Math.min(extractCurrVal, extractPrevVal);
    condition = exact ? min >= goal : min > goal;

    if (condition) {
      return extractCurrVal < extractPrevVal ? curr : prev;
    } else {
      return extractCurrVal > extractPrevVal ? curr : prev;
    }
  });
};

export function getPropertyByString(
  obj: any,
  s: string,
  existential: boolean = false,
) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a = s.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (existential) {
      if (obj) {
        obj = obj[k];
      }
    } else {
      if (!obj) {
        throw new Error("Property " + k + " does not exist");
      }
      obj = obj[k]; // ensure an error is thrown
    }
  }
  return obj;
}

export function setPropertyByString(
  obj: any,
  s: string,
  val: any,
  existential: boolean = false,
): boolean {
  let hasChanged = true;

  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a = s.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (i === a.length - 1) {
      hasChanged = obj[k] !== val;
      obj[k] = val;
    } else {
      obj = obj[k];
      if (existential) {
        if (obj == null) {
          obj = {};
        }
      }
    }
  }

  return hasChanged;
}

export type Complete<T> = {
  [P in keyof Required<T>]: Complete<T[P]>;
};

/**
 * Equals within our floating pont error tolerance.
 *
 * Prefer the use of this function over `===` for equality checks on numeric values.
 */
export function floatEq(a: number, b: number, tolerance: number = EPS) {
  return Math.abs(b - a) <= tolerance;
}

export function arrayToMap<T, K extends keyof T>(
  arg: T[],
  key: K,
): Map<string, T> {
  return arg.reduce((map, obj) => map.set(obj[key], obj), new Map());
}

export function mapToArray<T>(arg: Map<string, T>): T[] {
  return Array.from(arg, (v) => v[1]);
}

export function numToPercent(val: number) {
  return val / 100;
}

export function fixedNumber(value: number | string | null | undefined): number {
  if (isNaN(Number(value))) return 0;
  return Math.round(Number(value) * 1e12) / 1e12;
}

// title case
export function tc(str: string | null | undefined) {
  if (str == null) {
    return "";
  }

  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

export function argmax<T>(arr: T[], fn: (t: T) => number): T | null {
  let max = -Infinity;
  let maxItem: T | null = null;
  for (const item of arr) {
    const val = fn(item);
    if (val > max) {
      max = val;
      maxItem = item;
    }
  }
  return maxItem;
}

export function argmin<T>(arr: T[], fn: (t: T) => number): T | null {
  return argmax(arr, (t) => -fn(t));
}

export function closestNumber(arr: number[], num: number, min?: number) {
  let curr = arr[0];
  let diff = Math.abs(num - curr);
  for (let val = 0; val < arr.length; val++) {
    if (min !== undefined && arr[val] < min) {
      if (arr[val] > curr) {
        curr = arr[val];
      }
      continue;
    }
    const newdiff = Math.abs(num - arr[val]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = arr[val];
    }
  }
  return curr;
}

export function ceilModulo(a: number, b: number) {
  return Math.ceil(a / b) * b;
}

export function floorModulo(a: number, b: number) {
  return Math.floor(a / b) * b;
}

export type NonNullableProps<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export function buildRadixString(num: number, chars: string = "0123456789") {
  const radix = chars.length;
  let str = "";
  while (num > 0) {
    const remainder = num % radix;
    str = chars[remainder] + str;
    num = Math.floor(num / radix);
  }
  return str;
}

/**
 * Use in Arrays to get correct typing when filtering out null and undefined values.
 *
 * eg [a, b, c, undefined].filter(IsNotNullAndUndefined) === [a, b, c]
 */
export function isNotNullAndUndefined<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function hasNaNRecursively(obj: any): boolean {
  if (obj === null || obj === undefined) {
    return false;
  }
  if (typeof obj === "object") {
    if (typeof obj[Symbol.iterator] === "function") {
      for (const item of obj) {
        if (hasNaNRecursively(item)) {
          return true;
        }
      }
      return false;
    }
    for (const item of Object.entries(obj)) {
      if (hasNaNRecursively(item[1])) {
        return true;
      }
    }
    return false;
  } else if (typeof obj === "number") {
    return isNaN(obj);
  }
  return false;
}
