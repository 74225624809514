import Flatten from "@flatten-js/core";
import { collect } from "../../lib/array-utils";
import { GlobalStore } from "../../lib/globalstore/global-store";
import {
  FlowRateUnits,
  NoUnits,
  Units,
  VolumeMeasurementSystem,
} from "../../lib/measurements";
import {
  EPS,
  assertUnreachable,
  cloneNaive,
  cloneSimple,
  interpolateTable,
  lowerBoundTable,
  parseCatalogNumberExact,
  upperBoundTable,
} from "../../lib/utils";
import {
  FireNodeProps,
  FireSubGroupProps,
  NodeProps,
} from "../../models/CustomEntity";
import { HeatLoadItem, HeatLoadSpecFiltered } from "../catalog/heatload/types";
import HCAAEquation, {
  HCAAFixtureType,
} from "../catalog/psd-standard/hcaaEquation";
import {
  DwellingStandardType,
  PSDStandardType,
} from "../catalog/psd-standard/types";
import { Catalog, PipeManufacturer } from "../catalog/types";
import { DuctManufacturer } from "../catalog/ventilation/ducts";
import {
  StandardFlowSystemUids,
  StandardFluidUids,
  SupportedDrainageMethods,
  SupportedPsdStandards,
  isClosedSystem,
  isDrainage,
  isGas,
  isGermanStandard,
} from "../config";
import { CoreObjectConcrete } from "../coreObjects";
import CoreConduit from "../coreObjects/coreConduit";
import CoreFen from "../coreObjects/coreFenestration";
import { determineConnectableSystemUid } from "../coreObjects/utils";
import {
  emptyArchitectureElementCalculation,
  emptyArchitectureElementLiveCalculation,
  makeArchitectureElementCalculationFields,
} from "../document/calculations-objects/architectureElement-calculation";
import {
  FastLiveAreaSegmentCalculationFields,
  emptyAreaSegmentCalculation,
  emptyAreaSegmentLiveCalculation,
  makeAreaSegmentCalculationFields,
} from "../document/calculations-objects/area-segment-calculation";
import {
  FastBigValveCalculationFields,
  emptyBigValveCalculations,
  emptyBigValveLiveCalculation,
  makeBigValveCalculationFields,
} from "../document/calculations-objects/big-valve-calculation";
import {
  CalculationForEntity,
  LiveCalculationForEntity,
} from "../document/calculations-objects/calculation-concrete";
import { CalculationField } from "../document/calculations-objects/calculation-field";
import {
  emptyCompoundCalculation,
  emptyCompoundLiveCalculation,
  makeCompoundCalculationFields,
} from "../document/calculations-objects/compound-calculations";
import {
  FastLivePipeCalculationFields,
  PipeCalculation,
  StickyConduitCalculationFields,
  emptyConduitCalculation,
  emptyConduitLiveCalculation,
  makeConduitCalculationFields,
} from "../document/calculations-objects/conduit-calculations";
import {
  FastLiveDamperCalculationFields,
  emptyDamperCalculation,
  emptyDamperLiveCalculation,
  makeDamperCalculationFields,
} from "../document/calculations-objects/damper-calculation";
import {
  FastLiveDirectedValveCalculationFields,
  emptyDirectedValveCalculation,
  emptyDirectedValveLiveCalculation,
  makeDirectedValveCalculationFields,
} from "../document/calculations-objects/directed-valve-calculation";
import {
  FastLiveEdgeCalculationFields,
  StickyEdgeCalculationFields,
  emptyEdgeCalculation,
  emptyEdgeLiveCalculation,
  makeEdgeCalculationFields,
} from "../document/calculations-objects/edge-calculation";
import {
  FastLiveFenCalculationFields,
  StickyFenCalcualtionFields,
  emptyFenCalculation,
  emptyFenLiveCalculation,
  makeFenCalculationFields,
} from "../document/calculations-objects/fenestration-calculation";
import {
  FastLiveFittingCalculationFields,
  emptyFittingCalculation,
  emptyFittingLiveCalculation,
  makeFittingCalculationFields,
} from "../document/calculations-objects/fitting-calculation";
import {
  FastLiveFixtureCalculationFields,
  emptyFixtureCalculation,
  emptyFixtureLiveCalculation,
  makeFixtureCalculationFields,
} from "../document/calculations-objects/fixture-calculation";
import {
  FastLiveFlowSourceCalculationFields,
  emptyFlowSourceCalculation,
  emptyFlowSourceLiveCalculation,
  makeFlowSourceCalculationFields,
} from "../document/calculations-objects/flow-source-calculation";
import {
  FastLiveGasApplianceCalculationFields,
  StickyGasApplianceCalculationFields,
  emptyGasApplianceCalculation,
  emptyGasApplianceLiveCalculation,
  makeGasApplianceCalculationFields,
} from "../document/calculations-objects/gas-appliance-calculation";
import {
  FastLiveLoadNodeCalculationFields,
  StickyLoadNodeCalcualtionFields,
  emptyLoadNodeCalculation,
  emptyLoadNodeLiveCalculation,
  makeLoadNodeCalculationFields,
} from "../document/calculations-objects/load-node-calculation";
import {
  FastLiveMultiwayValveCalculationFields,
  emptyMultiwayValveCalculation,
  emptyMultiwayValveLiveCalculation,
  makeMultiwayValveCalculationFields,
} from "../document/calculations-objects/multiway-valve-calculation";
import {
  FastLivePlantCalculationFields,
  StickyPlantCalcualtionFields,
  emptyPlantCalculation,
  emptyPlantLiveCalculation,
  makePlantCalculationFields,
} from "../document/calculations-objects/plant-calculation";
import {
  FastLiveRiserCalculationFields,
  emptyRiserCalculations,
  emptyRiserLiveCalculation,
  makeRiserCalculationFields,
} from "../document/calculations-objects/riser-calculation";
import {
  FastLiveRoomCalculationFields,
  StickyRoomCalcualtionFields,
  emptyRoomCalculation,
  emptyRoomLiveCalculation,
  makeRoomCalculationFields,
} from "../document/calculations-objects/room-calculation";
import SystemNodeCalculation, {
  FastLiveSystemNodeCalculationFields,
  emptySystemNodeCalculation,
  emptySystemNodeLiveCalculation,
  makeSystemNodeCalculationFields,
} from "../document/calculations-objects/system-node-calculation";
import {
  CalcFieldSelector,
  CalculationType,
} from "../document/calculations-objects/types";
import { applyCalcFieldSelection } from "../document/calculations-objects/utils";
import {
  FastLiveVertexCalculationFields,
  StickyVertexCalcualtionFields,
  emptyVertexCalculation,
  emptyVertexLiveCalculation,
  makeVertexCalculationFields,
} from "../document/calculations-objects/vertex-calculation";
import {
  FastLiveWallCalculationFields,
  StickyWallCalcualtionFields,
  emptyWallCalculation,
  emptyWallLiveCalculation,
  makeWallCalculationFields,
} from "../document/calculations-objects/wall-calculation";
import { addWarning } from "../document/calculations-objects/warnings";
import {
  DrawingState,
  Level,
  SelectedMaterialManufacturer,
  SolarRadiation,
} from "../document/drawing";
import {
  CalculatableEntityConcrete,
  DrawableEntityConcrete,
} from "../document/entities/concrete-entity";
import ConduitEntity, {
  DuctConduitEntity,
  PipeConduitEntity,
  fillDefaultConduitFields,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import DirectedValveEntity from "../document/entities/directed-valves/directed-valve-entity";
import { ValveType } from "../document/entities/directed-valves/valve-types";
import { fillFixtureFields } from "../document/entities/fixtures/fixture-entity";
import FlowSourceEntity from "../document/entities/flow-source-entity";
import {
  FireNode,
  NodeType,
  fillDefaultLoadNodeFields,
  getLoadNodeDrainageUnits,
} from "../document/entities/load-node-entity";
import PlantEntity from "../document/entities/plants/plant-entity";
import {
  HotWaterOutlet,
  PlantType,
  PumpConfiguration,
} from "../document/entities/plants/plant-types";
import {
  isDualSystemNodePlant,
  isMultiOutlets,
  isPlantTank,
} from "../document/entities/plants/utils";
import {
  RoomEntity,
  RoomType,
  fillDefaultRoomFields,
} from "../document/entities/rooms/room-entity";
import { SystemNodeEntity } from "../document/entities/system-node-entity";
import { EntityType } from "../document/entities/types";
import {
  NetworkType,
  flowSystemNetworkHasSpareCapacity,
} from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import { SupportedLocales } from "../locale";
import CalculationEngine from "./calculation-engine";
import { Edge } from "./graph";
import { CoreContext, FlowEdge, FlowNode, PressurePushMode } from "./types";

export const FLOW_SOURCE_EDGE = "FLOW_SOURCE_EDGE";
export const FLOW_SOURCE_ROOT = "FLOW_SOURCE_ROOT";
export const FLOW_SOURCE_ROOT_NODE: FlowNode = {
  connectable: FLOW_SOURCE_ROOT,
  connection: FLOW_SOURCE_EDGE,
};
export const FLOW_SOURCE_ROOT_BRIDGE: Edge<FlowNode | undefined, undefined> = {
  from: undefined,
  to: FLOW_SOURCE_ROOT_NODE,
  value: undefined,
  uid: "FLOW_SOURCE_ROOT_BRIDGE",
  isDirected: true,
  isReversed: false,
};

// To signify wrong direction when calculating directional flow through entities,
// we use a very high numeric kpa value instead of infinite or null.
// The purpose is to allow add a delta to this value to allow
// methods like hardy-cross to converge.
export const CALCULATION_PRESSURE_MAX_KPA = 1e10;

export interface PsdCountEntry {
  // Currently, the behaviro of the units field is Loading Units for LU flow systems,
  // and repurposed (but obviously not renamed) as design flow rate for german DIN
  // systems. Consider separating the two in different fields, or rename it to
  // be more appropriate than "units" which is not suitable for design flow rate.
  units: number;

  continuousFlowLS: number;
  dwellings: number;

  // gas
  gasMJH: number;
  gasUndiversifiedMJH: number;
  gasHighestMJH: number;
  gasDiversifiedMJH: number;

  // drainage
  drainageUnits: number;
  mainDrainageUnits?: number;
  highestDrainageUnits?: number;

  mixedHotCold?: boolean;
  warmTemperature?: number;
}

export interface FinalPsdCountEntry extends PsdCountEntry {
  highestLU: number; // for BS 806
}

export interface CorrelationGroupInfo {
  // Make sure group size for each name is the same
  groupKey: string;
  subGroupKey: string | undefined;
  groupSize: number;
}

export interface ContextualPCE extends PsdCountEntry {
  // The pseudo entity that produced this demand. Will be deduplicated and
  // will throw if calculations encounter the same pseudo entity
  // that produced different demands.
  entityGroup: string;

  // The physical entity that produced this demand. Must be valid network
  // object entity uid.
  entityRef: string;

  // The "group" that this demand goes to - all demand records
  // in the same group don't have to be the same, but only the
  // highest value of each entry of the records in the group
  // will be used.
  correlationGroup: CorrelationGroupInfo;

  hcaaFixtureType: HCAAFixtureType | null;
}

export interface ContextualPCEGroup {
  correlationGroup: CorrelationGroupInfo;
  groups: ContextualPCE[];
}

export class PsdProfile extends Map<string, ContextualPCE> {}

export interface PsdUnitsByFlowSystem {
  [key: string]: FinalPsdCountEntry;
}

export function addPsdUnitsByFlowSystem(
  a: PsdUnitsByFlowSystem,
  b: PsdUnitsByFlowSystem,
): PsdUnitsByFlowSystem {
  const res: PsdUnitsByFlowSystem = cloneSimple(a);
  for (const s of Object.keys(b)) {
    if (res.hasOwnProperty(s)) {
      res[s] = addFinalPsdCounts(res[s], b[s]);
    } else {
      res[s] = b[s];
    }
  }
  return res;
}

export function countPsdUnits(
  context: CoreContext,
  entities: DrawableEntityConcrete[],
): PsdUnitsByFlowSystem {
  const { drawing, locale, catalog, globalStore } = context;
  let result: PsdUnitsByFlowSystem = {};
  entities.forEach((e) => {
    switch (e.type) {
      case EntityType.FIXTURE:
        const mainFixture = fillFixtureFields(context, e);
        if (result === null) {
          result = {};
        }
        mainFixture.roughInsInOrder.forEach((suid) => {
          if (!(suid in result!)) {
            result![suid] = zeroFinalPsdCounts();
          }
        });

        for (const suid of mainFixture.roughInsInOrder) {
          if (isGermanStandard(drawing.metadata.calculationParams.psdMethod)) {
            result[suid].units += mainFixture.roughIns[suid].designFlowRateLS!;
          } else {
            result[suid].units += mainFixture.roughIns[suid].loadingUnits!;
            result[suid].highestLU = Math.max(
              result[suid].highestLU,
              mainFixture.roughIns[suid].loadingUnits!,
            );
          }

          result[suid].continuousFlowLS +=
            mainFixture.roughIns[suid].continuousFlowLS!;

          if (isDrainage(drawing.metadata.flowSystems[suid])) {
            const drainageUnits = mainFixture.drainageFixtureUnits;
            result[suid].drainageUnits += drainageUnits!;
          }
        }
        break;
      case EntityType.LOAD_NODE: {
        const mainLoadNode = fillDefaultLoadNodeFields(context, e);
        const suid = determineConnectableSystemUid(globalStore, mainLoadNode);

        if (suid) {
          if (result === null) {
            result = {};
          }
          if (!result.hasOwnProperty(suid)) {
            result[suid] = zeroFinalPsdCounts();
          }
          if (!result.hasOwnProperty(StandardFlowSystemUids.SewerDrainage)) {
            result[StandardFlowSystemUids.SewerDrainage] = zeroFinalPsdCounts();
          }

          switch (mainLoadNode.node.type) {
            case NodeType.DWELLING:
              result[suid].dwellings += mainLoadNode.node.dwellings;
            case NodeType.LOAD_NODE:
              if (
                isGermanStandard(drawing.metadata.calculationParams.psdMethod)
              ) {
                result[suid].units += mainLoadNode.node.designFlowRateLS!;
              } else {
                result[suid].units += mainLoadNode.node.loadingUnits!;
                result[suid].highestLU = Math.max(
                  result[suid].highestLU,
                  mainLoadNode.node.loadingUnits!,
                );
              }
              result[suid].continuousFlowLS +=
                mainLoadNode.node.continuousFlowLS!;

              let hasDrainagePipe = false;

              // The drainage nodes are repeated for pairs of load nodes, so we apply this only to the root.
              if (
                !mainLoadNode.linkedToUid ||
                !globalStore.get(mainLoadNode.linkedToUid)
              ) {
                const drainageUnits = getLoadNodeDrainageUnits(mainLoadNode);
                result[StandardFlowSystemUids.SewerDrainage].drainageUnits +=
                  drainageUnits!;
              }
              break;
          }
        }
        break;
      }
      case EntityType.GAS_APPLIANCE: {
        const suid = StandardFlowSystemUids.Gas;
        if (!result.hasOwnProperty(suid)) {
          result[suid] = zeroFinalPsdCounts();
        }
        if (e.flowRateMJH) {
          result[suid].gasMJH += e.flowRateMJH;
        }
        break;
      }
      case EntityType.PLANT: {
        switch (e.plant.type) {
          case PlantType.RETURN_SYSTEM:
            const suid = StandardFlowSystemUids.Gas;
            if (!result.hasOwnProperty(suid)) {
              result[suid] = zeroFinalPsdCounts();
            }
            if (e.plant.gasConsumptionMJH) {
              result[suid].gasMJH += e.plant.gasConsumptionMJH;
            }
            break;
          case PlantType.TANK:
          case PlantType.CUSTOM:
          case PlantType.PUMP:
          case PlantType.DRAINAGE_PIT:
          case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
          case PlantType.RADIATOR:
          case PlantType.PUMP_TANK:
          case PlantType.VOLUMISER:
          case PlantType.AHU:
          case PlantType.AHU_VENT:
          case PlantType.FCU:
          case PlantType.MANIFOLD:
          case PlantType.UFH:
          case PlantType.FILTER:
          case PlantType.RO:
          case PlantType.DUCT_MANIFOLD:
            break;
          default:
            assertUnreachable(e.plant);
        }
        break;
      }
      case EntityType.ROOM:
      case EntityType.BACKGROUND_IMAGE:
      case EntityType.FITTING:
      case EntityType.CONDUIT:
      case EntityType.RISER:
      case EntityType.FLOW_SOURCE:
      case EntityType.SYSTEM_NODE:
      case EntityType.BIG_VALVE:
      case EntityType.DIRECTED_VALVE:
      case EntityType.MULTIWAY_VALVE:
      case EntityType.COMPOUND:
      case EntityType.EDGE:
      case EntityType.VERTEX:
      case EntityType.WALL:
      case EntityType.FENESTRATION:
      case EntityType.LINE:
      case EntityType.ANNOTATION:
      case EntityType.ARCHITECTURE_ELEMENT:
      case EntityType.DAMPER:
      case EntityType.AREA_SEGMENT:
        break;
      default:
        assertUnreachable(e);
    }
  });

  return result;
}

export function addPsdCounts(
  context: CoreContext,
  a: PsdCountEntry,
  b: PsdCountEntry,
): PsdCountEntry {
  const { drawing, globalStore, catalog } = context;
  let drainageUnits = a.drainageUnits + b.drainageUnits;
  let mainDrainageUnits;
  let highestDrainageUnits;
  if (
    drawing.metadata.calculationParams.drainageMethod ===
    SupportedDrainageMethods.EN1205622000DischargeUnits
  ) {
    let sum = zeroEN2012DrainageUnitsCount();
    sum = addEN1205622000DrainageUnits(sum, a, drawing, catalog);
    sum = addEN1205622000DrainageUnits(sum, b, drawing, catalog);
    mainDrainageUnits = sum.mainDrainageUnits;
    highestDrainageUnits = sum.highestDrainageUnits;
    drainageUnits = sum.drainageUnits;
  }

  let units = a.units + b.units;
  let mixedHotCold;
  let warmTemperature;
  if (isGermanStandard(drawing.metadata.calculationParams.psdMethod)) {
    mixedHotCold = a.mixedHotCold || b.mixedHotCold;
    warmTemperature = a.warmTemperature || b.warmTemperature;

    if (!!a.mixedHotCold && !!b.mixedHotCold && b.units && warmTemperature) {
      units =
        a.units +
        +resolveDINUnits(drawing, b.units, warmTemperature!).toFixed(3);
    }
  }

  return {
    units: units,
    continuousFlowLS: a.continuousFlowLS + b.continuousFlowLS,
    dwellings: a.dwellings + b.dwellings,
    gasMJH: a.gasMJH + b.gasMJH,
    gasUndiversifiedMJH: a.gasUndiversifiedMJH + b.gasUndiversifiedMJH,
    gasHighestMJH: Math.max(a.gasHighestMJH, b.gasHighestMJH),
    gasDiversifiedMJH: a.gasDiversifiedMJH + b.gasDiversifiedMJH,
    drainageUnits,
    mainDrainageUnits,
    highestDrainageUnits,
    mixedHotCold,
    warmTemperature,
  };
}

export interface EN1205622000DischargeUnitsCount {
  mainDrainageUnits?: number;
  highestDrainageUnits?: number;
  drainageUnits: number;
}

export function zeroEN2012DrainageUnitsCount(): EN1205622000DischargeUnitsCount {
  return {
    mainDrainageUnits: 0,
    highestDrainageUnits: 0,
    drainageUnits: 0,
  };
}

export function addEN1205622000DrainageUnits(
  a: EN1205622000DischargeUnitsCount,
  b: EN1205622000DischargeUnitsCount,
  drawing: DrawingState,
  catalog: Catalog,
): EN1205622000DischargeUnitsCount {
  const sum = zeroEN2012DrainageUnitsCount();

  const aDrainageUnits = a.mainDrainageUnits || a.drainageUnits;
  const bDrainageUnits = b.mainDrainageUnits || b.drainageUnits;
  sum.mainDrainageUnits = aDrainageUnits + bDrainageUnits;
  sum.highestDrainageUnits = Math.max(
    a.highestDrainageUnits || a.drainageUnits,
    b.highestDrainageUnits || b.drainageUnits,
  );
  const calculatedDrainageUnits = resolveEN1205622000DrainageUnits(
    drawing,
    catalog,
    sum.mainDrainageUnits,
  );
  sum.drainageUnits =
    aDrainageUnits > 0 && calculatedDrainageUnits > sum.highestDrainageUnits
      ? calculatedDrainageUnits
      : sum.highestDrainageUnits;

  return sum;
}

export function addFinalPsdCounts(
  a: FinalPsdCountEntry,
  b: FinalPsdCountEntry,
): FinalPsdCountEntry {
  return {
    units: a.units + b.units,
    continuousFlowLS: a.continuousFlowLS + b.continuousFlowLS,
    dwellings: a.dwellings + b.dwellings,
    highestLU: Math.max(a.highestLU, b.highestLU),
    gasMJH: a.gasMJH + b.gasMJH,
    gasUndiversifiedMJH: a.gasUndiversifiedMJH + b.gasUndiversifiedMJH,
    gasHighestMJH: a.gasHighestMJH + b.gasHighestMJH,
    gasDiversifiedMJH: a.gasDiversifiedMJH + b.gasDiversifiedMJH,
    drainageUnits: a.drainageUnits + b.drainageUnits,
  };
}

export function subPsdCounts(
  a: PsdCountEntry,
  b: PsdCountEntry,
): PsdCountEntry {
  return {
    units: a.units - b.units,
    continuousFlowLS: a.continuousFlowLS - b.continuousFlowLS,
    dwellings: a.dwellings - b.dwellings,
    gasMJH: a.gasMJH - b.gasMJH,
    gasUndiversifiedMJH: a.gasUndiversifiedMJH - b.gasUndiversifiedMJH,
    gasHighestMJH: a.gasHighestMJH - b.gasHighestMJH,
    gasDiversifiedMJH: a.gasDiversifiedMJH - b.gasDiversifiedMJH,
    drainageUnits: a.drainageUnits - b.drainageUnits,
  };
}

export function scalePsdCounts(a: PsdCountEntry, scale: number): PsdCountEntry {
  return {
    units: a.units * scale,
    continuousFlowLS: a.continuousFlowLS * scale,
    dwellings: a.dwellings * scale,
    gasMJH: a.gasMJH * scale,
    gasUndiversifiedMJH: a.gasUndiversifiedMJH * scale,
    gasHighestMJH: a.gasHighestMJH * scale,
    gasDiversifiedMJH: a.gasDiversifiedMJH * scale,
    drainageUnits: a.drainageUnits * scale,
  };
}

export function equalPsdCounts(a: PsdCountEntry, b: PsdCountEntry): boolean {
  return (
    Math.abs(a.units - b.units) < EPS &&
    Math.abs(a.continuousFlowLS - b.continuousFlowLS) < EPS &&
    Math.abs(a.dwellings - b.dwellings) < EPS &&
    Math.abs(a.gasMJH - b.gasMJH) < EPS &&
    Math.abs(a.gasUndiversifiedMJH - b.gasUndiversifiedMJH) < EPS &&
    Math.abs(a.gasHighestMJH - b.gasHighestMJH) < EPS &&
    Math.abs(a.gasDiversifiedMJH - b.gasDiversifiedMJH) < EPS &&
    Math.abs(a.drainageUnits - b.drainageUnits) < EPS
  );
}

function isZeroPsdCounts(a: PsdCountEntry): boolean {
  return (
    Math.abs(a.units) < EPS &&
    Math.abs(a.continuousFlowLS) < EPS &&
    Math.abs(a.dwellings) < EPS &&
    Math.abs(a.gasMJH) < EPS &&
    Math.abs(a.gasUndiversifiedMJH) < EPS &&
    Math.abs(a.gasHighestMJH) < EPS &&
    Math.abs(a.gasDiversifiedMJH) < EPS &&
    Math.abs(a.drainageUnits) < EPS
  );
}

export function isZeroWaterPsdCounts(a: PsdCountEntry): boolean {
  return (
    Math.abs(a.units) < EPS &&
    Math.abs(a.continuousFlowLS) < EPS &&
    Math.abs(a.dwellings) < EPS
  );
}

export function isZeroDrainagePsdCounts(a: PsdCountEntry): boolean {
  return Math.abs(a.drainageUnits) < EPS;
}

// Returns >0 if a > b, <0 if a < b.
export function compareWaterPsdCounts(
  a: PsdCountEntry,
  b: PsdCountEntry,
): number | null {
  const unitDiff =
    a.units + EPS < b.units ? -1 : a.units - EPS > b.units ? 1 : 0;
  const cfDiff =
    a.continuousFlowLS + EPS < b.continuousFlowLS
      ? -1
      : a.continuousFlowLS - EPS > b.continuousFlowLS
        ? 1
        : 0;
  const dDiff =
    a.dwellings + EPS < b.dwellings
      ? -1
      : a.dwellings - EPS > b.dwellings
        ? 1
        : 0;

  const small = Math.min(unitDiff, cfDiff, dDiff);
  const large = Math.max(unitDiff, cfDiff, dDiff);

  if (small === 0) {
    return large;
  } else if (large === 0) {
    return small;
  } else if (small !== large) {
    return null;
  } else {
    return small;
  }
}

export function compareDrainagePsdCounts(
  a: PsdCountEntry,
  b: PsdCountEntry,
): number | null {
  const sDiff =
    a.drainageUnits + EPS < b.drainageUnits
      ? -1
      : a.drainageUnits - EPS > b.drainageUnits
        ? 1
        : 0;

  const small = Math.min(sDiff);
  const large = Math.max(sDiff);

  if (small === 0) {
    return large;
  } else if (large === 0) {
    return small;
  } else if (small !== large) {
    return null;
  } else {
    return small;
  }
}

export function insertPsdProfile(profile: PsdProfile, count: ContextualPCE) {
  if (!profile.has(count.entityGroup)) {
    profile.set(count.entityGroup, count);
  } else {
    if (!equalPsdCounts(count, profile.get(count.entityGroup)!)) {
      throw new Error(
        "Psd Profile given inconsistent values, before " +
          JSON.stringify(profile.get(count.entityGroup)) +
          " after " +
          JSON.stringify(count),
      );
    }
  }
}

export function mergePsdProfile(profile: PsdProfile, other: PsdProfile) {
  for (const p of other.values()) {
    insertPsdProfile(profile, p);
  }
}

export function countPsdProfile(
  context: CoreContext,
  profile: PsdProfile,
): FinalPsdCountEntry {
  // Maps correlation group id to PsdCountEntry objects.
  // Track and pick the maximum {group size} nodes.
  const byCorrelated = new Map<string, ContextualPCEGroup>();

  // Insert in profile
  profile.forEach((contextual) => {
    if (byCorrelated.has(contextual.correlationGroup.groupKey)) {
      byCorrelated
        .get(contextual.correlationGroup.groupKey)!
        .groups.push(contextual);
    } else {
      byCorrelated.set(contextual.correlationGroup.groupKey, {
        correlationGroup: contextual.correlationGroup,
        groups: [contextual],
      });
    }
  });

  // Merge each group to according to its groupSize
  let mergedPsdEntries: FinalPsdCountEntry[] = [];
  byCorrelated.forEach((correlationGroup) => {
    mergedPsdEntries.push(mergePsdEntryGroup(context, correlationGroup));
  });

  return countPsdEntries(context, mergedPsdEntries);
}

type PsdCountEntryArrays = Record<keyof PsdCountEntry, number[]>;
function mergeEntries(
  context: CoreContext,
  size: number,
  groups: (ContextualPCE | FinalPsdCountEntry)[],
) {
  const FIELDS: PsdCountEntryArrays = {
    units: [],
    continuousFlowLS: [],
    dwellings: [],
    gasMJH: [],
    gasUndiversifiedMJH: [],
    gasHighestMJH: [],
    gasDiversifiedMJH: [],
    drainageUnits: [],
    mainDrainageUnits: [],
    highestDrainageUnits: [],
    mixedHotCold: [],
    warmTemperature: [],
  };

  // Push each field value from entry into above array
  for (const entry of groups) {
    for (const field in FIELDS) {
      const fieldValue = entry[field as keyof PsdCountEntry];

      // MixedHotCold is boolean field, using Number() to convert to 0 or 1
      // then it will assign to false later if the sum of that field remain 0
      FIELDS[field as keyof PsdCountEntry].push(
        fieldValue ? Number(fieldValue) : 0,
      );
    }
  }

  // Then sort and pick the top GroupSize for each field
  for (const field in FIELDS) {
    const sorted = FIELDS[field as keyof PsdCountEntry].sort((a, b) => b - a);
    const topGroupSizeValue = sorted.slice(0, size);
    FIELDS[field as keyof PsdCountEntry] = [...topGroupSizeValue];
  }

  // Convert to PsdCountEntry[] format so I can call helper function to deal
  const entries: PsdCountEntry[] = [];
  for (let i = 0; i < size && i < FIELDS.units.length; i++) {
    const entry: PsdCountEntry = {
      units: FIELDS.units[i],
      continuousFlowLS: FIELDS.continuousFlowLS[i],
      dwellings: FIELDS.dwellings[i],
      gasMJH: FIELDS.gasMJH[i],
      gasUndiversifiedMJH: FIELDS.gasUndiversifiedMJH[i],
      gasHighestMJH: FIELDS.gasHighestMJH[i],
      gasDiversifiedMJH: FIELDS.gasDiversifiedMJH[i],
      drainageUnits: FIELDS.drainageUnits[i],
      mainDrainageUnits: FIELDS.mainDrainageUnits[i],
      highestDrainageUnits: FIELDS.highestDrainageUnits[i],
      mixedHotCold: FIELDS.mixedHotCold[i] ? true : false,
      warmTemperature: FIELDS.warmTemperature[i],
    };
    entries.push(entry);
  }
  return countPsdEntries(context, entries);
}

function mergePsdNoSubgroup(
  context: CoreContext,
  group: ContextualPCEGroup,
): FinalPsdCountEntry {
  if (group.groups.length <= group.correlationGroup.groupSize) {
    // If the group is small enough, just sum the values
    return countPsdEntries(context, group.groups);
  }

  return mergeEntries(context, group.correlationGroup.groupSize, group.groups);
}

function mergePsdWithSubGroup(
  context: CoreContext,
  group: ContextualPCEGroup,
): FinalPsdCountEntry {
  /**
   * Algorithm:
   * Group consist number of sub-group, count property of sub-group
   * Then sort and pick the top GroupSize for each field
   */
  let subGroups = new Map<string, ContextualPCEGroup>();
  group.groups.forEach((entry) => {
    const subGroupKey = entry.correlationGroup.subGroupKey!;
    if (subGroups.has(subGroupKey)) {
      subGroups.get(subGroupKey)!.groups.push(entry);
    } else {
      subGroups.set(subGroupKey, {
        correlationGroup: entry.correlationGroup,
        groups: [entry],
      });
    }
  });

  let mergedPsdEntries: FinalPsdCountEntry[] = [];
  subGroups.forEach((subGroup) => {
    mergedPsdEntries.push(
      mergeEntries(
        context,
        subGroup.correlationGroup.groupSize,
        subGroup.groups,
      ),
    );
  });

  return mergeEntries(context, 1, mergedPsdEntries);
}

export function mergePsdEntryGroup(
  context: CoreContext,
  group: ContextualPCEGroup,
): FinalPsdCountEntry {
  if (group.correlationGroup.subGroupKey !== undefined) {
    return mergePsdWithSubGroup(context, group);
  } else {
    return mergePsdNoSubgroup(context, group);
  }
}

function countPsdEntries(
  context: CoreContext,
  entries: PsdCountEntry[],
): FinalPsdCountEntry {
  let highestLU = 0;
  let total = zeroPsdCounts();

  entries.forEach((contextual) => {
    total = addPsdCounts(context, total, contextual);
    highestLU = Math.max(highestLU, contextual.units);

    const gasMJHDiversified = Math.max(
      total.gasHighestMJH,
      total.gasDiversifiedMJH,
    );
    total.gasMJH = total.gasUndiversifiedMJH + gasMJHDiversified;
    total.gasHighestMJH = gasMJHDiversified;
  });

  return {
    units: total.units,
    dwellings: total.dwellings,
    continuousFlowLS: total.continuousFlowLS,
    gasMJH: total.gasMJH,
    gasUndiversifiedMJH: total.gasUndiversifiedMJH,
    gasHighestMJH: total.gasHighestMJH,
    gasDiversifiedMJH: total.gasDiversifiedMJH,
    drainageUnits: total.drainageUnits,
    mainDrainageUnits: total.mainDrainageUnits,
    highestDrainageUnits: total.highestDrainageUnits,
    highestLU,
    mixedHotCold: total.mixedHotCold,
    warmTemperature: total.warmTemperature,
  };
}

export function subtractPsdProfiles(
  profile: PsdProfile,
  operand: PsdProfile,
): void {
  operand.forEach((contextual) => {
    if (isZeroPsdCounts(contextual)) {
      return;
    }

    if (!profile.has(contextual.entityGroup)) {
      throw new Error("Subtracting from a value that doesn't exist");
    }

    const prev = profile.get(contextual.entityGroup)!;
    profile.set(contextual.entityGroup, {
      correlationGroup: contextual.correlationGroup,
      entityGroup: contextual.entityGroup,
      entityRef: contextual.entityRef,
      hcaaFixtureType: contextual.hcaaFixtureType,
      units: prev.units - contextual.units,
      continuousFlowLS: prev.continuousFlowLS - contextual.continuousFlowLS,
      dwellings: prev.dwellings - contextual.dwellings,
      gasMJH: prev.gasMJH - contextual.gasMJH,
      gasUndiversifiedMJH:
        prev.gasUndiversifiedMJH - contextual.gasUndiversifiedMJH,
      gasHighestMJH: prev.gasHighestMJH - contextual.gasHighestMJH,
      gasDiversifiedMJH: prev.gasDiversifiedMJH - contextual.gasDiversifiedMJH,
      drainageUnits: prev.drainageUnits - contextual.drainageUnits,
    });
  });
}

export function zeroPsdCounts(): PsdCountEntry {
  return {
    units: 0,
    continuousFlowLS: 0,
    dwellings: 0,
    gasMJH: 0,
    gasUndiversifiedMJH: 0,
    gasHighestMJH: 0,
    gasDiversifiedMJH: 0,
    drainageUnits: 0,
  };
}

export function zeroFinalPsdCounts(): FinalPsdCountEntry {
  return {
    units: 0,
    continuousFlowLS: 0,
    dwellings: 0,
    highestLU: 0,
    gasMJH: 0,
    gasUndiversifiedMJH: 0,
    gasHighestMJH: 0,
    gasDiversifiedMJH: 0,
    drainageUnits: 0,
  };
}

export function zeroContextualPCE(
  entityRef: string,
  entityGroup: string,
  correlationGroup: CorrelationGroupInfo,
  hcaaFixtureType: HCAAFixtureType | null,
): ContextualPCE {
  return {
    entityRef,
    entityGroup,
    correlationGroup,
    hcaaFixtureType,
    continuousFlowLS: 0,
    units: 0,
    dwellings: 0,
    gasMJH: 0,
    gasUndiversifiedMJH: 0,
    gasHighestMJH: 0,
    gasDiversifiedMJH: 0,
    drainageUnits: 0,
  };
}

export function combineMaxPsdEntries(
  a: PsdCountEntry,
  b: PsdCountEntry,
): PsdCountEntry {
  return {
    dwellings: Math.max(a.dwellings, b.dwellings),
    units: Math.max(a.units, b.units),
    continuousFlowLS: Math.max(a.continuousFlowLS, b.continuousFlowLS),
    gasMJH: Math.max(a.gasMJH, b.gasMJH),
    gasUndiversifiedMJH: Math.max(a.gasUndiversifiedMJH, b.gasUndiversifiedMJH),
    gasHighestMJH: Math.max(a.gasHighestMJH, b.gasHighestMJH),
    gasDiversifiedMJH: Math.max(a.gasDiversifiedMJH, b.gasDiversifiedMJH),
    drainageUnits: Math.max(a.drainageUnits, b.drainageUnits),
  };
}

/**
 * TODO: Remove
 */
export function findFirePsdMinIndex(groups: PsdCountEntry[]): number {
  if (groups.length === 0) {
    return -1;
  }
  return -1;
}

export function getPsdUnitName(
  psdMethod: SupportedPsdStandards,
  locale: SupportedLocales,
): { name: string; abbreviation: string } {
  switch (psdMethod) {
    case SupportedPsdStandards.as35002021LoadingUnits:
    case SupportedPsdStandards.barriesBookLoadingUnits:
    case SupportedPsdStandards.upc2018FlushTanks:
    case SupportedPsdStandards.upc2018Flushometer:
    case SupportedPsdStandards.ipc2018FlushTanks:
    case SupportedPsdStandards.ipc2018Flushometer:
    case SupportedPsdStandards.cibseGuideG:
    case SupportedPsdStandards.bs806:
    case SupportedPsdStandards.bs8558:
      switch (locale) {
        case SupportedLocales.UK:
        case SupportedLocales.AU:
          return { name: "Loading Units", abbreviation: "LU" };
        case SupportedLocales.US:
          return { name: "Water Supply Fixture Units", abbreviation: "WSFU" };
      }
      assertUnreachable(locale);
      return { name: "Loading Units", abbreviation: "LU" };
    case SupportedPsdStandards.indianStandardFlushTankPrivate:
    case SupportedPsdStandards.indianStandardFlushTankPublic:
    case SupportedPsdStandards.indianStandardFlushValvePrivate:
    case SupportedPsdStandards.indianStandardFlushValvePublic:
      return { name: "Water Supply Fixture Units", abbreviation: "WSFU" };
    case SupportedPsdStandards.hcaa:
    case SupportedPsdStandards.din1988300Residential:
    case SupportedPsdStandards.din1988300Hospital:
    case SupportedPsdStandards.din1988300Hotel:
    case SupportedPsdStandards.din1988300School:
    case SupportedPsdStandards.din1988300Office:
    case SupportedPsdStandards.din1988300AssistedLiving:
    case SupportedPsdStandards.din1988300NursingHome:
      return { name: "Full Flow Rate", abbreviation: "F. Flow" };
  }
  assertUnreachable(psdMethod);
}

export function getDrainageUnitName(
  drainageMethod: SupportedDrainageMethods,
  measurement: VolumeMeasurementSystem,
): { name: string; abbreviation: string; units: Units } {
  let units: FlowRateUnits | NoUnits = Units.None;
  let abbreviation: string = "FU";
  let name: string = "Fixture Units";
  if (drainageMethod === SupportedDrainageMethods.EN1205622000DischargeUnits) {
    name = "Flow Rate";
    switch (measurement) {
      case VolumeMeasurementSystem.METRIC:
      case VolumeMeasurementSystem.IMPERIAL:
        abbreviation = units = Units.LitersPerSecond;
        break;
      case VolumeMeasurementSystem.US:
        abbreviation = units = Units.USGallonsPerMinute;
        break;
      default:
        assertUnreachable(measurement);
    }
  }
  return { name, abbreviation, units };
}

export interface FlowRateResult {
  flowRateLS: number;
  fromDwellings: boolean;
}

export function lookupFlowRate(
  context: CoreContext,
  psdU: FinalPsdCountEntry,
  systemUid: string,
  profile: PsdProfile | null = null,
  forceDwellings: boolean = false,
): FlowRateResult | null {
  const { drawing, catalog } = context;
  const psd = drawing.metadata.calculationParams.psdMethod;
  const standard = catalog.psdStandards[psd];

  let fromLoading: number | null = null;
  switch (standard.type) {
    case PSDStandardType.LU_LOOKUP_TABLE: {
      const table = standard.table;
      fromLoading = interpolateTable(table, psdU.units, true);
      break;
    }
    case PSDStandardType.LU_HOT_COLD_LOOKUP_TABLE: {
      const table = standard.hotColdTable;
      if (systemUid === StandardFlowSystemUids.ColdWater) {
        fromLoading = interpolateTable(table, psdU.units, true, (e) => e.cold);
      } else if (
        systemUid === StandardFlowSystemUids.HotWater ||
        systemUid === StandardFlowSystemUids.WarmWater
      ) {
        fromLoading = interpolateTable(table, psdU.units, true, (e) => e.hot);
      } else {
        throw new Error("Cannot determine flow rate with this metric");
      }
      break;
    }
    case PSDStandardType.EQUATION: {
      if (standard.equation === "a*(sum(Q,q))^b-c") {
        // DIN 1988-300 equations
        if (psdU.units <= 0.2) {
          fromLoading = psdU.units;
        } else {
          const a = parseCatalogNumberExact(standard.variables.a)!;
          const b = parseCatalogNumberExact(standard.variables.b)!;
          const c = parseCatalogNumberExact(standard.variables.c)!;

          fromLoading = a * psdU.units ** b - c;
        }
      }

      break;
    }

    case PSDStandardType.HCAA_EQUATION: {
      fromLoading = calculateHCAAFlowRateLS(context, psdU, profile, standard);
      break;
    }

    case PSDStandardType.LU_MAX_LOOKUP_TABLE: {
      // lookup by highest LU
      let table = lowerBoundTable(standard.maxLuTable, psdU.highestLU);
      if (!table) {
        table = upperBoundTable(standard.maxLuTable, Infinity)!;
      }

      // finally, lookup by actual PSD units.
      fromLoading = interpolateTable(table, psdU.units, true);
      break;
    }
    default:
      assertUnreachable(standard);
  }

  if (fromLoading === null && psdU.units === 0) {
    fromLoading = 0;
  }

  let fromDwellings: number | null = null;
  const dMethod = drawing.metadata.calculationParams.dwellingMethod;
  if (dMethod) {
    const dStandard = catalog.dwellingStandards[dMethod];

    switch (dStandard.type) {
      case DwellingStandardType.DWELLING_HOT_COLD_LOOKUP_TABLE:
        if (systemUid === StandardFlowSystemUids.ColdWater) {
          fromDwellings = interpolateTable(
            dStandard.hotColdTable,
            psdU.dwellings,
            true,
            (e) => e.cold,
          );
        } else if (
          systemUid === StandardFlowSystemUids.HotWater ||
          systemUid === StandardFlowSystemUids.WarmWater
        ) {
          fromDwellings = interpolateTable(
            dStandard.hotColdTable,
            psdU.dwellings,
            true,
            (e) => e.hot,
          );
        } else if (psdU.dwellings) {
          throw new Error("Cannot determine flow rate with this metric");
        } else {
          fromDwellings = 0;
        }
        break;
      case DwellingStandardType.EQUATION:
        if (dStandard.equation !== "a*D+b*sqrt(D)") {
          throw new Error("Only the equation a*D+b*sqrt(D) is supported");
        }

        const a = parseCatalogNumberExact(dStandard.variables.a)!;
        const b = parseCatalogNumberExact(dStandard.variables.b)!;
        fromDwellings = a * psdU.dwellings + b * Math.sqrt(psdU.dwellings);
        break;
      default:
        assertUnreachable(dStandard);
    }
  } else {
    fromDwellings = 0;
  }

  if (fromDwellings === null && psdU.dwellings === 0) {
    fromDwellings = 0;
  }

  // Add flow rate contribute by fire node
  if (
    fromDwellings === 0 &&
    (!forceDwellings ||
      drawing.metadata.calculationParams.dwellingMethod === null)
  ) {
    if (fromLoading === null) {
      return null;
    }
    return {
      flowRateLS: fromLoading! + psdU.continuousFlowLS,
      fromDwellings: false,
    };
  } else {
    if (fromDwellings === null) {
      return null;
    }
    return {
      flowRateLS: fromDwellings + psdU.continuousFlowLS,
      fromDwellings: true,
    };
  }
}

export function makeCalculationFields(
  context: CoreContext,
  entity: DrawableEntityConcrete,
  levelUid: string | null,
): CalculationField[] {
  switch (entity.type) {
    case EntityType.RISER:
      return makeRiserCalculationFields(context, entity, levelUid);
    case EntityType.CONDUIT:
      return makeConduitCalculationFields(context, entity);
    case EntityType.FITTING:
      return makeFittingCalculationFields(context, entity);
    case EntityType.BIG_VALVE:
      return makeBigValveCalculationFields(context, entity);
    case EntityType.FIXTURE:
      return makeFixtureCalculationFields(context, entity);
    case EntityType.GAS_APPLIANCE:
      return makeGasApplianceCalculationFields(context, entity);
    case EntityType.DIRECTED_VALVE:
      return makeDirectedValveCalculationFields(context, entity);
    case EntityType.MULTIWAY_VALVE:
      return makeMultiwayValveCalculationFields(context, entity);
    case EntityType.SYSTEM_NODE:
      return makeSystemNodeCalculationFields(context, entity);
    case EntityType.LOAD_NODE:
      return makeLoadNodeCalculationFields(context, entity);
    case EntityType.FLOW_SOURCE:
      return makeFlowSourceCalculationFields(context, entity);
    case EntityType.PLANT:
      return makePlantCalculationFields(context, entity);
    case EntityType.COMPOUND:
      return makeCompoundCalculationFields(context, entity);
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
      return [];
    case EntityType.EDGE:
      return makeEdgeCalculationFields(context, entity);
    case EntityType.VERTEX:
      return makeVertexCalculationFields(context, entity);
    case EntityType.ROOM:
      return makeRoomCalculationFields(context, entity);
    case EntityType.WALL:
      return makeWallCalculationFields(context, entity);
    case EntityType.FENESTRATION:
      return makeFenCalculationFields(context, entity);
    case EntityType.ARCHITECTURE_ELEMENT:
      return makeArchitectureElementCalculationFields(context, entity);
    case EntityType.DAMPER:
      return makeDamperCalculationFields(context, entity);
    case EntityType.AREA_SEGMENT:
      return makeAreaSegmentCalculationFields();
  }
  assertUnreachable(entity);
}

export function makeEmptyCalculation<T extends CalculatableEntityConcrete>(
  entity: T,
): CalculationForEntity<T> {
  switch (entity.type) {
    case EntityType.RISER:
      return cloneSimple(emptyRiserCalculations()) as any;
    case EntityType.CONDUIT:
      return cloneSimple(emptyConduitCalculation(entity)) as any;
    case EntityType.BIG_VALVE:
      return cloneSimple(emptyBigValveCalculations(entity)) as any;
    case EntityType.FITTING:
      return cloneSimple(emptyFittingCalculation()) as any;
    case EntityType.FIXTURE:
      return cloneSimple(emptyFixtureCalculation(entity)) as any;
    case EntityType.DIRECTED_VALVE:
      return cloneSimple(emptyDirectedValveCalculation()) as any;
    case EntityType.SYSTEM_NODE:
      return cloneSimple(emptySystemNodeCalculation()) as any;
    case EntityType.LOAD_NODE:
      return cloneSimple(emptyLoadNodeCalculation()) as any;
    case EntityType.FLOW_SOURCE:
      return cloneSimple(emptyFlowSourceCalculation()) as any;
    case EntityType.PLANT:
      return cloneSimple(emptyPlantCalculation()) as any;
    case EntityType.GAS_APPLIANCE:
      return cloneSimple(emptyGasApplianceCalculation()) as any;
    case EntityType.COMPOUND:
      return cloneSimple(emptyCompoundCalculation()) as any;
    case EntityType.MULTIWAY_VALVE:
      return cloneSimple(emptyMultiwayValveCalculation()) as any;
    case EntityType.EDGE:
      return cloneSimple(emptyEdgeCalculation()) as any;
    case EntityType.VERTEX:
      return cloneSimple(emptyVertexCalculation()) as any;
    case EntityType.ROOM:
      return cloneSimple(emptyRoomCalculation()) as any;
    case EntityType.WALL:
      return cloneSimple(emptyWallCalculation()) as any;
    case EntityType.FENESTRATION:
      return cloneSimple(emptyFenCalculation()) as any;
    case EntityType.ARCHITECTURE_ELEMENT:
      return cloneSimple(emptyArchitectureElementCalculation()) as any;
    case EntityType.DAMPER:
      return cloneSimple(emptyDamperCalculation()) as any;
    case EntityType.AREA_SEGMENT:
      return cloneSimple(emptyAreaSegmentCalculation()) as any;
  }
  assertUnreachable(entity);
}

export function makeEmptyLiveCalculation<T extends CalculatableEntityConcrete>(
  entity: T,
): LiveCalculationForEntity<T> {
  switch (entity.type) {
    case EntityType.RISER:
      return cloneSimple(emptyRiserLiveCalculation()) as any;
    case EntityType.CONDUIT:
      return cloneSimple(emptyConduitLiveCalculation()) as any;
    case EntityType.BIG_VALVE:
      return cloneSimple(emptyBigValveLiveCalculation()) as any;
    case EntityType.FITTING:
      return cloneSimple(emptyFittingLiveCalculation()) as any;
    case EntityType.FIXTURE:
      return cloneSimple(emptyFixtureLiveCalculation()) as any;
    case EntityType.DIRECTED_VALVE:
      return cloneSimple(emptyDirectedValveLiveCalculation()) as any;
    case EntityType.SYSTEM_NODE:
      return cloneSimple(emptySystemNodeLiveCalculation()) as any;
    case EntityType.LOAD_NODE:
      return cloneSimple(emptyLoadNodeLiveCalculation()) as any;
    case EntityType.FLOW_SOURCE:
      return cloneSimple(emptyFlowSourceLiveCalculation()) as any;
    case EntityType.PLANT:
      return cloneSimple(emptyPlantLiveCalculation()) as any;
    case EntityType.GAS_APPLIANCE:
      return cloneSimple(emptyGasApplianceLiveCalculation()) as any;
    case EntityType.COMPOUND:
      return cloneSimple(emptyCompoundLiveCalculation()) as any;
    case EntityType.MULTIWAY_VALVE:
      return cloneSimple(emptyMultiwayValveLiveCalculation()) as any;
    case EntityType.EDGE:
      return cloneSimple(emptyEdgeLiveCalculation()) as any;
    case EntityType.VERTEX:
      return cloneSimple(emptyVertexLiveCalculation()) as any;
    case EntityType.ROOM:
      return cloneSimple(emptyRoomLiveCalculation()) as any;
    case EntityType.WALL:
      return cloneSimple(emptyWallLiveCalculation()) as any;
    case EntityType.FENESTRATION:
      return cloneSimple(emptyFenLiveCalculation()) as any;
    case EntityType.ARCHITECTURE_ELEMENT:
      return cloneSimple(emptyArchitectureElementLiveCalculation()) as any;
    case EntityType.DAMPER:
      return cloneSimple(emptyDamperLiveCalculation()) as any;
    case EntityType.AREA_SEGMENT:
      return cloneSimple(emptyAreaSegmentLiveCalculation()) as any;
  }
  assertUnreachable(entity);
}

export function getStickyCalculationFields<
  T extends CalculatableEntityConcrete,
>(entity: T): CalcFieldSelector<CalculationForEntity<T>> | null {
  switch (entity.type) {
    case EntityType.PLANT:
      return StickyPlantCalcualtionFields as any;
    case EntityType.CONDUIT:
      return StickyConduitCalculationFields as any;
    case EntityType.GAS_APPLIANCE:
      return StickyGasApplianceCalculationFields as any;
    case EntityType.LOAD_NODE:
      return StickyLoadNodeCalcualtionFields as any;
    case EntityType.EDGE:
      return StickyEdgeCalculationFields as any;
    case EntityType.VERTEX:
      return StickyVertexCalcualtionFields as any;
    case EntityType.ROOM:
      return StickyRoomCalcualtionFields as any;
    case EntityType.WALL:
      return StickyWallCalcualtionFields as any;
    case EntityType.FENESTRATION:
      return StickyFenCalcualtionFields as any;
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.BIG_VALVE:
    case EntityType.RISER:
    case EntityType.FITTING:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.SYSTEM_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.COMPOUND:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return null;
  }
  assertUnreachable(entity);
}

export function makeEmptyCalculationWithStickyFields<
  T extends CalculatableEntityConcrete,
>(entity: T, source: CalculationForEntity<T>): CalculationForEntity<T> {
  const empty = makeEmptyCalculation(entity);
  const stickyFields = getStickyCalculationFields(entity);
  if (stickyFields) {
    const result = applyCalcFieldSelection(empty, source, stickyFields);
    return result;
  } else {
    return empty;
  }
}

// Unlike sticky calcs, each entitiy has a fast calc.
export function getFastLiveCalculationFields<
  T extends CalculatableEntityConcrete,
>(entity: T): CalcFieldSelector<LiveCalculationForEntity<T>> | null {
  switch (entity.type) {
    case EntityType.PLANT:
      return FastLivePlantCalculationFields as any;
    case EntityType.CONDUIT:
      return FastLivePipeCalculationFields as any;
    case EntityType.GAS_APPLIANCE:
      return FastLiveGasApplianceCalculationFields as any;
    case EntityType.LOAD_NODE:
      return FastLiveLoadNodeCalculationFields as any;
    case EntityType.BIG_VALVE:
      return FastBigValveCalculationFields as any;
    case EntityType.RISER:
      return FastLiveRiserCalculationFields as any;
    case EntityType.FITTING:
      return FastLiveFittingCalculationFields as any;
    case EntityType.FIXTURE:
      return FastLiveFixtureCalculationFields as any;
    case EntityType.DIRECTED_VALVE:
      return FastLiveDirectedValveCalculationFields as any;
    case EntityType.MULTIWAY_VALVE:
      return FastLiveMultiwayValveCalculationFields as any;
    case EntityType.SYSTEM_NODE:
      return FastLiveSystemNodeCalculationFields as any;
    case EntityType.FLOW_SOURCE:
      return FastLiveFlowSourceCalculationFields as any;
    case EntityType.COMPOUND:
      return FastLivePlantCalculationFields as any;
    case EntityType.EDGE:
      return FastLiveEdgeCalculationFields as any;
    case EntityType.VERTEX:
      return FastLiveVertexCalculationFields as any;
    case EntityType.ROOM:
      return FastLiveRoomCalculationFields as any;
    case EntityType.WALL:
      return FastLiveWallCalculationFields as any;
    case EntityType.FENESTRATION:
      return FastLiveFenCalculationFields as any;
    case EntityType.ARCHITECTURE_ELEMENT:
      return {} as any;
    case EntityType.DAMPER:
      return FastLiveDamperCalculationFields as any;
    case EntityType.AREA_SEGMENT:
      return FastLiveAreaSegmentCalculationFields as any;
  }
  assertUnreachable(entity);
}

export function getEntitySystem(
  entity: DrawableEntityConcrete,
  globalStore: GlobalStore,
): string | null {
  switch (entity.type) {
    case EntityType.FITTING:
    case EntityType.CONDUIT:
    case EntityType.RISER:
    case EntityType.FLOW_SOURCE:
    case EntityType.SYSTEM_NODE:
      return entity.systemUid;
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.LOAD_NODE:
      return determineConnectableSystemUid(globalStore, entity)!;
    case EntityType.DAMPER:
      const damper = globalStore.getObjectOfTypeOrThrow(
        EntityType.DAMPER,
        entity.uid,
      );
      return (damper.edge.entity as ConduitEntity).systemUid;
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.BIG_VALVE:
    case EntityType.PLANT:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return null;
  }
  assertUnreachable(entity);
}

export interface Cost {
  value: number;
  exact: boolean;
}

export function zeroCost(): Cost {
  return {
    value: 0,
    exact: true,
  };
}

export function addCosts(a: Cost | null, b: Cost | null): Cost {
  const value = (a ? a.value : 0) + (b ? b.value : 0);
  const exact = a !== null && a.exact && b !== null && b.exact;
  return { value, exact };
}

export function resolveEN1205622000DrainageUnits(
  drawing: DrawingState,
  catalog: Catalog,
  drainageUnits: number,
) {
  const frequencyFactor =
    catalog.en12056FrequencyFactor[
      drawing.metadata.calculationParams.en12056FrequencyFactor
    ];

  return frequencyFactor * Math.sqrt(drainageUnits);
}

export function resolveDINUnits(
  drawing: DrawingState,
  units: number,
  warmTemp: number,
) {
  const cold = drawing.metadata.flowSystems[StandardFlowSystemUids.ColdWater];
  const hot = drawing.metadata.flowSystems[StandardFlowSystemUids.HotWater];
  const coldTemp = cold.temperatureC;
  const hotTemp = hot.temperatureC;

  return ((warmTemp - coldTemp) / (hotTemp - coldTemp)) * units;
}

export function getLevelHeightFloorToVertexM(
  levels: { [key: string]: Level },
  levelUid: string,
): number | null {
  const sortedLevels = Object.values(levels).sort(
    (a, b) => a.floorHeightM - b.floorHeightM,
  );
  const currentLevelIndex = sortedLevels.findIndex(
    (level) => level.uid === levelUid,
  );
  if (!levelUid || currentLevelIndex < 0) {
    throw new Error("Level not found");
  }
  if (currentLevelIndex + 1 >= sortedLevels.length) {
    // Is Top level
    return null;
  }

  return (
    sortedLevels[currentLevelIndex + 1].floorHeightM -
    sortedLevels[currentLevelIndex].floorHeightM
  );
}

export function getAcceptablePressureRange(options: {
  context: CoreContext;
  entity: DrawableEntityConcrete;
}): { minKPA: number; maxKPA: number } | null {
  const { context, entity } = options;

  switch (entity.type) {
    case EntityType.SYSTEM_NODE: {
      const parent = context.globalStore.get(
        entity.parentUid!,
      ) as CoreObjectConcrete;
      switch (parent.entity.type) {
        case EntityType.FIXTURE: {
          if (parent.entity.type === EntityType.FIXTURE) {
            const fixture = fillFixtureFields(context, parent.entity);

            const inlet = Object.values(fixture.roughIns).find(
              (i) => i.uid === entity.uid,
            );

            if (inlet) {
              return {
                minKPA: inlet.minPressureKPA!,
                maxKPA: inlet.maxPressureKPA!,
              };
            } else {
              return null;
            }
          }
        }
        case EntityType.PLANT:
        case EntityType.LOAD_NODE:
        case EntityType.SYSTEM_NODE:
        case EntityType.CONDUIT:
        case EntityType.RISER:
        case EntityType.FITTING:
        case EntityType.DIRECTED_VALVE:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.FLOW_SOURCE:
        case EntityType.COMPOUND:
        case EntityType.BIG_VALVE:
        case EntityType.BACKGROUND_IMAGE:
        case EntityType.GAS_APPLIANCE:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.LINE:
        case EntityType.ANNOTATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          return null;
      }
      assertUnreachable(parent.entity);
      return null; // for typecheck
    }
    case EntityType.LOAD_NODE: {
      const loadNode = fillDefaultLoadNodeFields(context, entity);
      return {
        minKPA: loadNode.minPressureKPA!,
        maxKPA: loadNode.maxPressureKPA!,
      };
    }
    case EntityType.CONDUIT:
    case EntityType.RISER:
    case EntityType.FITTING:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.PLANT:
    case EntityType.COMPOUND:
    case EntityType.BIG_VALVE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return null;
  }
  assertUnreachable(entity);
}

export function flowSourceKPA(
  entity: DrawableEntityConcrete,
  pressurePushMode: PressurePushMode,
  engine: CalculationEngine,
): number | null {
  switch (entity.type) {
    case EntityType.FLOW_SOURCE:
      switch (pressurePushMode) {
        case PressurePushMode.Static:
          return entity.maxPressureKPA;
        case PressurePushMode.PSD:
          return entity.minPressureKPA;
        case PressurePushMode.CirculationFlowOnly:
          return null;
      }
      assertUnreachable(pressurePushMode);
    case EntityType.SYSTEM_NODE:
      const parent = engine.globalStore.getObjectOfType(
        EntityType.PLANT,
        entity.parentUid!,
      );
      if (parent) {
        if (isMultiOutlets(parent.entity.plant)) {
          if (isDualSystemNodePlant(parent.entity.plant)) {
            if (
              entity.uid !== parent.entity.plant.heatingOutletUid &&
              entity.uid !== parent.entity.plant.chilledOutletUid
            ) {
              return null;
            }
            if (parent.entity.plant.type === PlantType.AHU_VENT) {
              if (
                entity.uid === parent.entity.plant.supplyUid ||
                entity.uid === parent.entity.plant.extractUid
              ) {
                return 0;
              } else {
                return null;
              }
            }
          } else if (parent.entity.plant.type === PlantType.DUCT_MANIFOLD) {
            return null;
          } else if (
            parent.entity.plant.outlets.every(
              (outlet) => entity.uid !== outlet.outletUid,
            )
          ) {
            return null;
          }
        } else if (entity.uid !== parent.entity.plant.outletUid) {
          return null;
        }

        if (isPlantTank(parent.entity)) {
          const calculation = engine.globalStore.getOrCreateCalculation(
            parent.entity,
          );
          return (
            parent.entity.plant.pressureLoss.staticPressureKPA ??
            calculation.pumpDutyKPA
          );
        }
      }
    // Tanks with static pressure out should be
    case EntityType.CONDUIT:
    case EntityType.RISER:
    case EntityType.FITTING:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.PLANT:
    case EntityType.COMPOUND:
    case EntityType.BIG_VALVE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.LOAD_NODE:
    case EntityType.VERTEX:
    case EntityType.EDGE:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return null;
  }
  assertUnreachable(entity);
}

export function isFlowSource(
  entity: DrawableEntityConcrete,
  engine: CalculationEngine,
  options?: {
    tanksAlwaysFlowSources?: boolean;
  },
): entity is
  | FlowSourceEntity
  | ({ someBut: "not-all-system-nodes" } & SystemNodeEntity)
  | ({ someBut: "not-all-directed-valves" } & DirectedValveEntity) {
  const tanksAlwaysFlowSources = options?.tanksAlwaysFlowSources ?? false;

  switch (entity.type) {
    case EntityType.FLOW_SOURCE:
      return true;
    case EntityType.SYSTEM_NODE:
      const parent = engine.globalStore.getObjectOfType(
        EntityType.PLANT,
        entity.parentUid!,
      );
      if (parent) {
        if (isMultiOutlets(parent.entity.plant)) {
          if (isDualSystemNodePlant(parent.entity.plant)) {
            if (parent.entity.plant.type === PlantType.AHU_VENT) {
              return (
                entity.uid === parent.entity.plant.supplyUid ||
                entity.uid === parent.entity.plant.extractUid ||
                entity.uid === parent.entity.plant.intakeUid ||
                entity.uid === parent.entity.plant.exhaustUid
              );
            }

            if (
              entity.uid !== parent.entity.plant.heatingOutletUid &&
              entity.uid !== parent.entity.plant.chilledOutletUid
            ) {
              return false;
            }
          } else if (parent.entity.plant.type === PlantType.DUCT_MANIFOLD) {
            return false;
          } else if (
            parent.entity.plant.outlets.every(
              (outlet) => entity.uid !== outlet.outletUid,
            )
          ) {
            return false;
          }
        } else if (entity.uid !== parent.entity.plant.outletUid) {
          return false;
        }

        if (isPlantTank(parent.entity)) {
          if (tanksAlwaysFlowSources) {
            return true;
          } else {
            const calc = engine.globalStore.getOrCreateCalculation(
              parent.entity,
            );
            return !calc.isInletHydrated;
          }
        }
      }
      return false;
    case EntityType.DIRECTED_VALVE:
      const fsUid = determineConnectableSystemUid(engine.globalStore, entity);
      if (!fsUid) {
        return false;
      }
      const fs = engine.drawing.metadata.flowSystems[fsUid];
      switch (entity.valve.type) {
        case ValveType.FAN:
          if (fs.role === "vent-fan-exhaust") {
            return true;
          }
        case ValveType.CHECK_VALVE:
        case ValveType.ISOLATION_VALVE:
        case ValveType.WATER_METER:
        case ValveType.CSV:
        case ValveType.STRAINER:
        case ValveType.RV:
        case ValveType.RPZD_SINGLE:
        case ValveType.RPZD_DOUBLE_SHARED:
        case ValveType.RPZD_DOUBLE_ISOLATED:
        case ValveType.PRV_SINGLE:
        case ValveType.PRV_DOUBLE:
        case ValveType.PRV_TRIPLE:
        case ValveType.FILTER:
        case ValveType.FLOOR_WASTE:
        case ValveType.INSPECTION_OPENING:
        case ValveType.REFLUX_VALVE:
        case ValveType.GAS_REGULATOR:
        case ValveType.TRV:
        case ValveType.CUSTOM_VALVE:
        case ValveType.BALANCING:
        case ValveType.LSV:
        case ValveType.PICV:
        case ValveType.SMOKE_DAMPER:
        case ValveType.FIRE_DAMPER:
        case ValveType.VOLUME_CONTROL_DAMPER:
        case ValveType.ATTENUATOR:
          return false;
        default:
          assertUnreachable(entity.valve);
      }
    case EntityType.CONDUIT:
    case EntityType.RISER:
    case EntityType.FITTING:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.PLANT:
    case EntityType.COMPOUND:
    case EntityType.BIG_VALVE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.LOAD_NODE:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(entity);
}

// Used by connectivityCheck in live calculations to make some entities
// connected and non-transparent even if they are not connected to the
// flow source. Eg, heating loops.
export function isClosedSystemSource(
  entity: DrawableEntityConcrete,
  engine: CalculationEngine,
): entity is PlantEntity {
  switch (entity.type) {
    case EntityType.SYSTEM_NODE:
      const parent = engine.globalStore.getObjectOfType(
        EntityType.PLANT,
        entity.parentUid!,
      );
      if (parent) {
        if (parent.entity.plant.type === PlantType.RETURN_SYSTEM) {
          if (parent.entity.plant.returnType === "heatSource") {
            const outlet = parent.entity.plant.outlets.find(
              (outlet) => entity.uid === outlet.outletUid,
            );
            if (!outlet) {
              return false;
            }

            if (
              isClosedSystem(
                engine.drawing.metadata.flowSystems[outlet.outletSystemUid],
              )
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      return false;
    case EntityType.FLOW_SOURCE:
    case EntityType.CONDUIT:
    case EntityType.RISER:
    case EntityType.FITTING:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.PLANT:
    case EntityType.COMPOUND:
    case EntityType.BIG_VALVE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.LOAD_NODE:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
    default:
      assertUnreachable(entity);
  }
  return false;
}

export function flowSystemsFlowTogether(
  context: CoreContext,
  a: string,
  b: string,
) {
  const { drawing, catalog } = context;
  const systemA = getFlowSystem(drawing, a);
  const systemB = getFlowSystem(drawing, b);

  if (systemA && systemB) {
    const categoryA = isDrainage(drawing.metadata.flowSystems[a])
      ? "d"
      : isGas(drawing.metadata.flowSystems[a])
        ? "g"
        : "w";
    const categoryB = isDrainage(drawing.metadata.flowSystems[b])
      ? "d"
      : isGas(drawing.metadata.flowSystems[b])
        ? "g"
        : "w";

    return categoryA === categoryB;
  } else {
    return false;
  }
}

export function findNextBestPumpConfig(
  config: PumpConfiguration,
): PumpConfiguration | null {
  switch (config) {
    case "duty":
      return "duty-assist";
    case "duty-standby":
      return "duty-assist-standby";
    case "duty-assist":
      return "duty-assist-assist";
    case "duty-assist-standby":
      return "duty-assist-assist-standby";
    case "duty-assist-assist":
      return "duty-assist-assist-assist";
    case "duty-assist-assist-standby":
      return "duty-assist-assist-assist";
    case "duty-assist-assist-assist":
      return null;
    default:
      assertUnreachable(config);
  }
  // this should be unreachable
  return null;
}

export function getPipeMaterialName(
  context: CoreContext,
  pipe: PipeConduitEntity,
): string {
  const pipeFilled = fillDefaultConduitFields(context, pipe);
  const selectedPipes = context.drawing.metadata.catalog.pipes;
  const manufacturer =
    selectedPipes.find(
      (pipeObj: SelectedMaterialManufacturer) =>
        pipeObj.uid === pipeFilled.conduit.material,
    )?.manufacturer || "generic";

  const pipesCatalog = context.catalog.pipes;
  const abbreviation =
    (manufacturer !== "generic" &&
      pipesCatalog[pipeFilled.conduit.material!].manufacturer.find(
        (manufacturerObj: PipeManufacturer) =>
          manufacturerObj.uid === manufacturer,
      )?.abbreviation) ||
    pipesCatalog[pipeFilled.conduit.material!].abbreviation;

  const fluid = getFlowSystem(context.drawing, pipeFilled.systemUid!)?.fluid;
  const materialName =
    handleCoutasSpecialCase(manufacturer, fluid) ?? abbreviation;

  return materialName;
}

export function getDuctMaterialName(
  context: CoreContext,
  duct: DuctConduitEntity,
): string {
  const ductFilled = fillDefaultConduitFields(context, duct);
  const selectedDucts = context.drawing.metadata.catalog.ducts;
  const manufacturer =
    selectedDucts.find(
      (ductObj: SelectedMaterialManufacturer) =>
        ductObj.uid === ductFilled.conduit.material,
    )?.manufacturer || "generic";

  const ductsCatalog = context.catalog.ducts;

  if (!ductsCatalog[ductFilled.conduit.material!]) {
    return ductFilled.conduit.material!;
  }

  const abbreviation =
    (manufacturer !== "generic" &&
      ductsCatalog[ductFilled.conduit.material!]!.manufacturers.find(
        (manufacturerObj: DuctManufacturer) =>
          manufacturerObj.uid === manufacturer,
      )?.abbreviation) ||
    ductsCatalog[ductFilled.conduit.material!]!.abbreviation;

  return abbreviation;
}

export function handleCoutasSpecialCase(
  manufacturerUid: string,
  fluid?: StandardFluidUids | string,
) {
  if (fluid && manufacturerUid === "coutaPex") {
    if (fluid === StandardFluidUids.WATER) {
      return "Couta WaterPEX";
    } else if (
      fluid === StandardFluidUids.LPG ||
      fluid === StandardFluidUids.NATURAL_GAS
    ) {
      return "Couta GasPEX";
    }
  }
  return undefined;
}

export function maybeAddWarningForMisplacedHeatEmitters(
  engine: CalculationEngine,
  plantUid: string,

  // Callers don't necessarily know the direction of nodes so we will avoid
  // using "from" and "to" here. Just trust that the IO Specs are correct.
  nodeUidsToCheck: string[],
) {
  const o = engine.globalStore.getObjectOfType(EntityType.PLANT, plantUid);

  if (!o) {
    return;
  }

  const ioSpecs = o.getInletsOutletSpecs();

  const affectedSystemUids: Set<string> = new Set();
  for (const spec of ioSpecs) {
    if (!nodeUidsToCheck.includes(spec.uid)) {
      continue;
    }

    const conns = engine.globalStore.getConnections(spec.uid);
    if (conns.length === 0) {
      continue;
    }

    const pipeCalc = engine.globalStore.calculationStore.get(conns[0]);
    const shouldBeFromUs = spec.type === "outlet";

    if (
      pipeCalc &&
      pipeCalc.type === CalculationType.PipeCalculation &&
      pipeCalc.flowFrom &&
      (pipeCalc.flowFrom === spec.uid) !== shouldBeFromUs
    ) {
      affectedSystemUids.add(spec.uid);
    }
  }

  if (affectedSystemUids.size > 0) {
    addWarning(
      engine,
      "HEAT_EMITTER_CONNECTED_WRONG_WAY",
      [
        engine.globalStore.getObjectOfTypeOrThrow(EntityType.PLANT, plantUid)
          .entity,
      ],
      {
        mode: "mechanical",
      },
    );

    const plantCalc = engine.globalStore.getOrCreateCalculation(o.entity);
    plantCalc.problemSystemNodes.push(
      ...Array.from(affectedSystemUids.values()).map((uid) => ({
        type: "HEAT_EMITTER_CONNECTED_WRONG_WAY" as const,
        uid,
      })),
    );
  }
}

export function calculatePipeVolumeL(pipeCalc: PipeCalculation) {
  if (pipeCalc.realInternalDiameterMM && pipeCalc.lengthM) {
    const volumeMetresCubed =
      Math.PI *
      (pipeCalc.realInternalDiameterMM / 2000) ** 2 *
      pipeCalc.lengthM;

    return volumeMetresCubed * 1000;
  }

  return 0;
}

export function getConnectedPipeNetwork(
  selectedOutletUid: string,
  context: CoreContext,
): NetworkType | null {
  const cons = context.globalStore.getConnections(selectedOutletUid);
  for (const c of cons) {
    const pipe = context.globalStore.get(c);
    if (pipe && isPipeEntity(pipe.entity)) {
      return pipe.entity.conduit.network;
    }
  }
  return null;
}

export type IndexCircuitPath = {
  path: Edge<unknown, FlowEdge>[];
  pressureDropKPA: number;
  lengthM: number;
};

export function buildIndexCircultPathObj(): IndexCircuitPath {
  return {
    path: [],
    pressureDropKPA: 0,
    lengthM: 0,
  };
}

export function mergePath(
  path1: IndexCircuitPath,
  path2: IndexCircuitPath,
): IndexCircuitPath {
  if (path1.path.length < path2.path.length) {
    const temp = path1;
    path1 = path2;
    path2 = temp;
  }
  path1.path.push(...path2.path);
  path1.pressureDropKPA += path2.pressureDropKPA;
  path1.lengthM += path2.lengthM;
  return path1;
}

export function applySpareCapacity(
  context: CalculationEngine,
  pipe: CoreConduit,
  flowRateLS: number,
) {
  if (!isPipeEntity(pipe.entity)) {
    // TODO: this should apply to non pipes in the future that need spare capacity
    throw new Error("Not a pipe");
  }
  const system = getFlowSystem(context.drawing, pipe.entity.systemUid);
  if (system && flowSystemNetworkHasSpareCapacity(system)) {
    flowRateLS *=
      1 +
      Number(system.networks[pipe.entity.conduit.network]!.spareCapacityPCT) /
        100;
  }
  return flowRateLS;
}

export function fireNodeKey(node: FireNode): string {
  return `firenode-${node.customEntityId}`;
}

export function findFireSubGroup(
  drawing: DrawingState,
  entityId: string,
  subGroupId: string,
): FireSubGroupProps {
  let fireNode = drawing.metadata.nodes.fire.find(
    (fire: FireNodeProps) => fire.customEntityId === entityId,
  );

  return fireNode?.subGroups.find(
    (subGroup: FireSubGroupProps) => subGroup.subGroupId === subGroupId,
  )!;
}

export function findFireNodeName(
  drawing: DrawingState,
  entityId: string,
  subGroupId: string,
): string {
  let fireNode = drawing.metadata.nodes.fire.find(
    (fire: FireNodeProps) => fire.customEntityId === entityId,
  );

  let fireSubGroup = fireNode?.subGroups.find(
    (subGroup: FireSubGroupProps) => subGroup.subGroupId === subGroupId,
  );

  return `${fireNode?.name} - ${fireSubGroup?.name}`;
}
export function isDirectedValveDirected(entity: DirectedValveEntity): boolean {
  switch (entity.valve.type) {
    case ValveType.RPZD_SINGLE:
    case ValveType.RPZD_DOUBLE_ISOLATED:
    case ValveType.RPZD_DOUBLE_SHARED:
    case ValveType.PRV_SINGLE:
    case ValveType.PRV_DOUBLE:
    case ValveType.PRV_TRIPLE:
    case ValveType.GAS_REGULATOR:
    case ValveType.CHECK_VALVE:
    case ValveType.FAN:
      return true;

    case ValveType.ISOLATION_VALVE:
    case ValveType.BALANCING:
    case ValveType.LSV:
    case ValveType.PICV:
    case ValveType.WATER_METER:
    case ValveType.CSV:
    case ValveType.FILTER:
    case ValveType.STRAINER:
    case ValveType.RV:
    case ValveType.FLOOR_WASTE:
    case ValveType.INSPECTION_OPENING:
    case ValveType.REFLUX_VALVE:
    case ValveType.TRV:
    case ValveType.CUSTOM_VALVE:
    case ValveType.SMOKE_DAMPER:
    case ValveType.FIRE_DAMPER:
    case ValveType.VOLUME_CONTROL_DAMPER:
    case ValveType.ATTENUATOR:
      return false;

    default:
      assertUnreachable(entity.valve);
  }
  return false;
}

export type IndexPath = {
  node: FlowNode;
  value: FlowEdge | null;
};

export function trackBackPath(
  node: FlowNode,
  prevEntity: Map<string, IndexPath>,
  engine: CalculationEngine,
): IndexPath[] {
  const path: IndexPath[] = [];
  let cur: string | undefined = engine.serializeNode(node);
  let curNode: FlowNode = node;
  let value: FlowEdge | null = null;
  while (cur && curNode && path.length < 4000) {
    path.push({
      node: curNode,
      value,
    });

    let nextEntity = prevEntity.get(cur);
    if (!nextEntity) {
      break;
    }

    curNode = nextEntity.node;
    value = nextEntity.value;
    if (curNode) {
      cur = engine.serializeNode(curNode);
    }
  }

  return path;
}

export function findReturnSystemNodeCalc(
  context: CoreContext,
  outlet: HotWaterOutlet,
): SystemNodeCalculation | null {
  if (outlet.recircPumpOnReturn) {
    if (!outlet.outletReturnUid) {
      return null;
    }
    const recircNode = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.SYSTEM_NODE,
      outlet.outletReturnUid,
    );
    return context.globalStore.getOrCreateCalculation(recircNode.entity);
  } else {
    if (!outlet.outletUid) {
      return null;
    }
    const returnNode = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.SYSTEM_NODE,
      outlet.outletUid,
    );
    return context.globalStore.getOrCreateCalculation(returnNode.entity);
  }
}

export function findOutletSpec(
  entity: PlantEntity,
  outletUid: string,
): HotWaterOutlet | null {
  if (entity.plant.type !== PlantType.RETURN_SYSTEM) return null;
  const outlet = entity.plant.outlets.find((o) => o.outletUid === outletUid);
  if (!outlet) return null;
  return outlet;
}

export function isHeatLoadItem(input: string): input is HeatLoadItem {
  const heatLoadItems: HeatLoadItem[] = [
    "External Wall",
    "Internal Wall",
    "Party Wall",
    "Window",
    "External Door",
    "Internal Door",
    "Roof",
    "Bottom Floor",
    "Suspended Floor",
    "Party Floor",
  ];
  return heatLoadItems.includes(input as HeatLoadItem);
}

export function getEffectiveHeatLoad(
  catalog: Catalog,
  drawing: DrawingState,
): HeatLoadSpecFiltered {
  const defaultData = cloneNaive(catalog.heatLoad);
  const ventStandard = drawing.metadata.heatLoss.ventAirChangesRateStandard;
  const heatingStandard =
    drawing.metadata.heatLoss.heatingAirChangesRateStandard;

  const effective: HeatLoadSpecFiltered = {
    material: defaultData.material,
    chimneySpec: defaultData.chimneySpec,
    ventAirChangeRate: defaultData.airChangeRate[ventStandard].data,
    heatingAirChangeRate: defaultData.airChangeRate[heatingStandard].data,
    roomTemperatureC: defaultData.roomTemperatureC[ventStandard],
  };

  // Add custom materials and custom rooms
  const { customMaterial, customRoom } = drawing.metadata.heatLoss;

  Object.entries(customMaterial).forEach(([roleKey, value]) => {
    if (isHeatLoadItem(roleKey)) {
      Object.entries(value).forEach(([name, heatLoadMaterialSpec]) => {
        effective.material[roleKey].table[name] = heatLoadMaterialSpec;
      });
    }
  });

  Object.entries(customRoom).forEach(([roomUid, roomSpec]) => {
    effective.ventAirChangeRate[roomSpec.name] = [...roomSpec.airChangeRate];
    effective.heatingAirChangeRate[roomSpec.name] = [...roomSpec.airChangeRate];
    effective.roomTemperatureC[roomSpec.name] = roomSpec.defaultTemperatureC;
  });

  return effective;
}

export function getWallByFen(
  globalStore: GlobalStore,
  fens: CoreFen,
): string | undefined {
  let walls = collect(
    globalStore.getWallsByRoomEdge(fens.entity.polygonEdgeUid![0]),
    (wallUid) => {
      const wall = globalStore.getObjectOfType(EntityType.WALL, wallUid);
      if (!wall) {
        console.warn("Wall not found", wallUid);
      }

      if (wall?.isManifested) {
        return wall;
      }

      return null;
    },
  );
  if (walls.length === 0) return undefined;
  let segment = fens.getWorldSegments()[0];
  let center = {
    x: (segment[0].x + segment[1].x) / 2,
    y: (segment[0].y + segment[1].y) / 2,
  };

  let point = new Flatten.Point(center.x, center.y);
  let ans = walls[0].uid,
    dis = 1e9;
  walls.forEach((wall) => {
    wall
      .getWorldSegments()
      .map((seg) => {
        return new Flatten.Segment(
          new Flatten.Point(seg[0].x, seg[0].y),
          new Flatten.Point(seg[1].x, seg[1].y),
        );
      })
      .forEach((seg) => {
        let d = seg.distanceTo(point)[0];
        if (d < dis) {
          dis = d;
          ans = wall.uid;
        }
      });
  });

  // Verify if ans exist and it's manifested
  if (ans) {
    let coreWall = globalStore.getObjectOfTypeOrThrow(EntityType.WALL, ans);
    if (coreWall.isManifested) return ans;
  }
  return undefined;
}

export function getThermalTransmittance(
  catalog: Catalog,
  drawing: DrawingState,
  heatLoadComponent: HeatLoadItem,
  materialUid: string | null,
): number {
  let material = getEffectiveHeatLoad(catalog, drawing).material;

  if (materialUid) {
    let materialItem = material[heatLoadComponent].table[materialUid];

    if (materialItem) {
      return materialItem.thermal_transmittance_W_per_m2K;
    }
  }

  // Return default value, of default material
  let defaultMaterial =
    drawing.metadata.heatLoss.defaultMaterial[heatLoadComponent];

  return material[heatLoadComponent].table[defaultMaterial]
    .thermal_transmittance_W_per_m2K;
}

function convertSolarRadiationToArray(solar: SolarRadiation): number[] {
  return [
    solar.top,
    solar.rightTop,
    solar.right,
    solar.rightBottom,
    solar.bottom,
    solar.leftBottom,
    solar.left,
    solar.leftTop,
  ];
}

export function getSolarRadiationForDegree(
  solar: SolarRadiation,
  degree: number,
): number {
  const solarArray = convertSolarRadiationToArray(solar);

  // Normalize degree to [0, 360)
  degree = degree % 360;
  if (degree < 0) degree += 360;

  const index = Math.floor(degree / 45);
  const nextIndex = (index + 1) % 8;
  const ratio1 = (45 * (index + 1) - degree) / 45;
  const ratio2 = 1 - ratio1;

  return solarArray[index] * ratio1 + solarArray[nextIndex] * ratio2;
}

// Categorize to HCAA fixture type based on fixture name
export function getHCAAFixtureTypeByName(name: string): HCAAFixtureType | null {
  switch (name) {
    case "shower":
    case "showerHot":
      return "shower";

    case "basin":
    case "basinHot":
      return "basin";

    case "hoseTap":
    case "laundryTrough":
    case "laundryTroughHot":
    case "bedpanSanitiser":
    case "flushingRimSink":
      return "laundry";

    case "cleanersSink":
    case "kitchenSink":
    case "kitchenSinkHot":
    case "drinkingFountain":
    case "beverageBay":
    case "ablutionTrough":
      return "kitchen";

    case "wc":
    case "urinal":
      return "toilet";

    case "washingMachine":
      return "washingMachine";

    case "dishwasher":
      return "dishwasher";

    case "bath":
    case "bathHot":
    case "birthingPool":
      return "bath";

    default:
      // Not a valid fixture name
      return null;
  }
}

function calculateHCAAPk(
  standard: HCAAEquation,
  fixtureType: HCAAFixtureType,
  B: number,
  o: number,
): number {
  const { pk1, c1, c2, c3, c4 } = standard.variables[fixtureType];
  const p_kB = B == 1 ? pk1 : c1 * pk1 * Math.min(B, 20) ** -c2;
  const m_kB = c3 * B ** c4;
  const f_oB = m_kB * (o - B);
  return p_kB + f_oB;
}

function calculateHCAAFlowRateLS(
  context: CoreContext,
  psdU: PsdCountEntry,
  profile: PsdProfile | null,
  standard: HCAAEquation,
): number | null {
  const B = context.drawing.metadata.calculationParams.apartmentNum;
  const o = context.drawing.metadata.calculationParams.occupantNum;

  if (!profile) {
    return null;
  }

  let f3 = 0; // f3 = sum(pk * fr)
  let f4 = 0; // f4 = sum(pk * (1 - pk) * fr^2)
  let P0 = 1; // P0 = prod(1 - pk)

  let uncategorized = 0;

  for (const p of profile.values()) {
    const fr = p.units;
    const fixtureType = p.hcaaFixtureType;

    if (fr === 0) continue;

    if (!fixtureType) {
      console.log("warning: uncategorized fixture type in HCAA", profile, p);
      uncategorized += fr;
      continue;
    }

    const pk = calculateHCAAPk(standard, fixtureType, B, o);

    f3 += pk * fr;
    f4 += pk * (1 - pk) * fr ** 2;
    P0 *= 1 - pk;
  }

  if (uncategorized > 0) {
    return null;
  }

  if (P0 === 1) {
    return 0;
  }

  const f1 = 1 - P0;
  const f2 = (1 + P0) * standard.z99;
  const temp_value = f1 * f4 - P0 * f3 ** 2;

  // Here the imprecision of floating number would result to tiny negative number under sqrt (e.g. -1e-20)
  // We want to round negative value to 0 which doesn't affect the result in general.
  // However, if the value is too negative because of wrong calculation, we want to warn the user.
  if (temp_value < -1e-8) {
    console.log(
      "warning: negative number in sqrt when calculating flow rate with HCAA",
    );
  }

  const Q99 = (1 / f1) * (f3 + f2 * Math.sqrt(Math.max(0, temp_value)));
  return Q99;
}

export function getPlantRoom(
  engine: CalculationEngine,
  entity: PlantEntity,
): RoomEntity | null {
  if (entity.parentUid) {
    const parent = engine.globalStore.getObjectOfType(
      EntityType.ROOM,
      entity.parentUid,
    );
    if (parent && parent.entity.room.roomType === RoomType.ROOM) {
      return fillDefaultRoomFields(engine, parent.entity);
    }
  }

  // fall back to check all the rooms
  const lvlOfEntity = engine.globalStore.levelOfEntity.get(entity.uid);
  if (lvlOfEntity) {
    const entitiesOnLvl = engine.globalStore.entitiesInLevel.get(lvlOfEntity)!;
    for (const e of entitiesOnLvl) {
      const roomObj = engine.globalStore.getObjectOfType(EntityType.ROOM, e);
      if (roomObj && roomObj.entity.room.roomType === RoomType.ROOM) {
        const plantObj = engine.globalStore.get(entity.uid);
        const plantCoords = plantObj.toWorldCoord();
        if (
          roomObj.shape.contains(Flatten.point(plantCoords.x, plantCoords.y))
        ) {
          return fillDefaultRoomFields(engine, roomObj.entity);
        }
      }
    }
  }

  return null;
}

export function toTitleCase(input: string): string {
  return input
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function findNodeProps(
  nodes: NodeProps[],
  customNodeId: string | number,
) {
  return nodes.find(
    (nodeProps) =>
      nodeProps.uid === customNodeId || nodeProps.id === customNodeId,
  );
}

export function findNodePropsInContext(
  context: CoreContext,
  customNodeId: string | number,
) {
  return findNodeProps(context.nodes, customNodeId);
}
