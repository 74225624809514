<template>
  <div v-if="initialLoading">
    <b-skeleton-img no-aspect height="150px" />
  </div>
  <div v-else>
    <div class="preview-container">
      <template v-if="editingMyself">
        <b-alert v-if="!verifiedPhone" show variant="info">
          To provide an extra layer of security for your account, we offer the
          option to set up SMS Multi-Factor Authentication (MFA). By enabling
          SMS MFA, you will receive a verification code on your mobile phone
          whenever you log in, adding an additional safeguard to your account.
          <br />
          <b-button v-b-modal.mfa-setup size="sm" variant="info" class="mt-2">
            Enable MFA
          </b-button>
        </b-alert>

        <b-alert v-if="verifiedPhone" show variant="secondary">
          Your phone number is verified. You can enable or disable SMS MFA
          below.
          <br />
          <b class="mt-2">
            MFA
            {{ mfaStatus ? "Enabled" : "Disabled" }}
          </b>

          <br />
          <b>Phone number: </b> {{ verifiedPhone }}
          <b-button v-b-modal.mfa-setup size="sm" variant="link">
            Change
          </b-button>
          <br />

          <b-button
            v-if="mfaStatus"
            size="sm"
            variant="outline-danger"
            class="mt-2"
            @click="disableMFA"
          >
            <b-spinner v-if="loading2" small type="grow" />
            Disable MFA
          </b-button>

          <b-button
            v-else
            size="sm"
            class="mt-2"
            variant="primary"
            @click="enableMFA"
          >
            <b-spinner v-if="loading" small type="grow" />
            Enable MFA
          </b-button>
        </b-alert>
      </template>

      <template v-else>
        <b-alert show variant="secondary">
          MFA Status: <b> {{ mfaStatus ? "Enabled" : "Disabled" }}</b>
          <br />
          <template v-if="mfaStatus">
            <b-button
              v-if="mfaStatus"
              size="sm"
              variant="outline-danger"
              class="mt-2"
              @click="adminDisableMFA"
            >
              <b-spinner v-if="loading2" small type="grow" />
              Disable MFA
            </b-button>
          </template>
          <template v-else>
            Only the owner of this account can enable MFA.
          </template>
        </b-alert>
      </template>
    </div>

    <!-- MFA Setup Modal -->
    <b-modal
      id="mfa-setup"
      title="Setup SMS MFA"
      centered
      hide-footer
      @hide="modalHidden"
    >
      <div v-if="step === 'enter_number'">
        <b-alert show variant="info">
          To enable SMS MFA, please enter your mobile phone number below. You
          will receive a verification code on your phone to complete the setup.
        </b-alert>

        <vue-tel-input
          v-model="phone"
          auto-format
          mode="international"
          valid-characters-only
          :dropdown-options="dropdownOptions"
          @input="handlePhoneInput"
          @enter="updateUserPhoneNumber"
        />

        <br />

        <b-button
          size="sm"
          variant="primary"
          class="ml-auto d-block"
          :disabled="!phoneIsValid || loading"
          @click="updateUserPhoneNumber"
        >
          <b-spinner v-if="loading" small type="grow" />
          Send Verification Code
        </b-button>
      </div>

      <div v-if="step === 'enter_code'">
        <b-alert show variant="info">
          A verification code has been sent to your phone phone ({{
            validPhone
          }}) Please enter the code below.
        </b-alert>

        <div class="d-flex">
          <b-form-input
            v-model="code"
            type="text"
            placeholder="Enter verification code"
            class="flex-grow-1"
            @keyup.enter="handleConfirmUserPhoneNumber"
          />
          <b-button
            size="sm"
            variant="link"
            class="ml-2"
            @click="sendUserPhoneVerifyCode"
          >
            Resend
          </b-button>
        </div>

        <br />

        <b-button
          size="sm"
          variant="primary"
          class="ml-auto d-block"
          :disabled="loading"
          @click="handleConfirmUserPhoneNumber"
        >
          <b-spinner v-if="loading" small type="grow" />
          Verify
        </b-button>
      </div>

      <div v-if="step === 'number_verified'">
        <b-alert show variant="info">
          <b-icon icon="check-circle-fill" />
          Your phone number has been verified.
        </b-alert>

        <b-button
          size="sm"
          variant="primary"
          class="ml-auto mr-auto d-block"
          :disabled="loading"
          @click="enableMFA"
        >
          <b-spinner v-if="loading" small type="grow" />
          Enable SMS MFA
        </b-button>
      </div>

      <div v-if="step === 'success'">
        <b-alert show variant="success">
          <b-icon icon="check-circle-fill" />
          SMS MFA has been successfully enabled.
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {
  UpdateUserAttributeOutput,
  confirmUserAttribute,
  fetchMFAPreference,
  fetchUserAttributes,
  sendUserAttributeVerificationCode,
  updateMFAPreference,
  updateUserAttribute,
} from "aws-amplify/auth";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { assertUnreachable } from "../../../common/src/lib/utils";
import { adminDisableMFA } from "../api/users";
import { ProfileWithMFA } from "../router";

@Component({
  components: {
    VueTelInput,
  },
})
export default class MFA extends Vue {
  @Prop({ required: true }) editingMyself!: boolean;
  @Prop({ required: true }) mfaStatus!: boolean;
  @Prop({ required: true }) username!: string;

  // Non-modal variables
  initialLoading = true;
  verifiedPhone: string | null = null;
  loading2 = false;

  // Modal variables
  phone = "";
  code = "";
  phoneIsValid = false;
  validPhone = "";
  loading = false;
  step: "enter_number" | "enter_code" | "number_verified" | "success" =
    "enter_number";
  dropdownOptions = {
    disabled: false,
    showDialCodeInList: true,
    showDialCodeInSelection: true,
    showFlags: true,
    showSearchBox: true,
    searchBoxPlaceholder: "Search...",
    tabindex: 0,
  };

  updated() {
    console.log("mfa status", this.mfaStatus);
  }

  async mounted() {
    await this.updateState();
  }

  modalHidden() {
    this.step = "enter_number";
    this.phone = "";
    this.code = "";
    this.phoneIsValid = false;
    this.validPhone = "";
    this.loading = false;
    this.updateState();
  }

  async updateState() {
    this.initialLoading = true;
    const user = await fetchUserAttributes();
    const mfa = await fetchMFAPreference();

    if (mfa.enabled) {
      if (mfa.enabled.includes("SMS")) {
        this.mfaStatus = true;
      }
    }

    if (user.phone_number_verified === "true") {
      this.verifiedPhone = String(user.phone_number);
    }

    this.initialLoading = false;
  }

  handlePhoneInput(number: String, phoneObject: any) {
    this.phoneIsValid = phoneObject.valid;
    this.validPhone = phoneObject.number;
  }

  async updateUserPhoneNumber() {
    this.loading = true;
    try {
      const output = await updateUserAttribute({
        userAttribute: {
          attributeKey: "phone_number",
          value: this.validPhone,
        },
      });
      this.step = "enter_code";
      this.handleUpdateUserAttributeNextSteps(output);
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  handleUpdateUserAttributeNextSteps(output: UpdateUserAttributeOutput) {
    const { nextStep } = output;

    switch (nextStep.updateAttributeStep) {
      case "CONFIRM_ATTRIBUTE_WITH_CODE":
        this.sendUserPhoneVerifyCode();
        break;
      case "DONE":
        this.sendUserPhoneVerifyCode();
        break;
      default:
        assertUnreachable(nextStep.updateAttributeStep);
    }
  }

  async sendUserPhoneVerifyCode() {
    this.loading = true;
    try {
      const output = await sendUserAttributeVerificationCode({
        userAttributeKey: "phone_number",
      });
      console.log("output", output);
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading = false;
    }
  }

  async handleConfirmUserPhoneNumber() {
    this.loading = true;
    try {
      await confirmUserAttribute({
        userAttributeKey: "phone_number",
        confirmationCode: this.code,
      });
      this.step = "number_verified";
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading = false;
    }
  }

  // the reason we need this is the profile takes a bit of time to update the MFA status on the AWS Cognito side
  updateProfileMFA(enabled: boolean) {
    if (this.editingMyself) {
      this.$store.dispatch("profile/setProfile", {
        ...this.profile,
        mfaStatus: enabled,
      });
    }
    this.$emit("updateUserMFA", enabled);
  }

  async enableMFA() {
    this.loading = true;
    try {
      await updateMFAPreference({ sms: "ENABLED" });
      this.updateProfileMFA(true);
      this.step = "success";
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading = false;
    }
  }

  async disableMFA() {
    this.loading2 = true;
    try {
      await updateMFAPreference({ sms: "DISABLED" });
      this.updateProfileMFA(false);
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading2 = false;
    }
  }

  async adminDisableMFA() {
    this.loading2 = true;
    try {
      const { success } = await adminDisableMFA(this.username);
      if (!success) {
        throw new Error("Failed to disable MFA");
      }
      this.mfaStatus = false;
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading2 = false;
    }
  }

  handleError(e: Error) {
    this.$bvToast.toast(e.message, {
      variant: "warning",
      toaster: " b-toaster-top-center",
      title: "Something is not right",
      noAutoHide: true,
    });
  }

  get profile(): ProfileWithMFA {
    return this.$store.getters["profile/profile"];
  }
}
</script>

<style>
.preview-container {
  text-align: left;
}
</style>
