import { createCannyUserData } from "../../../../common/src/api/integrations/canny/cannyUtils";
import { User } from "../../../../common/src/models/User";

export class CannyClient {
  static init(profile: User) {
    // @ts-ignore
    Canny("identify", {
      appID: "67886a1025ea17b5fd1ab6e4",
      user: createCannyUserData(profile),
    });
  }

  static async openCanny() {
    // @ts-ignore
    const authenticatedLink = Canny(
      "authenticateCannyLink",
      "https://feedback.h2xengineering.com/feature-requests",
    );
    window.open(authenticatedLink, "_blank"); // open Canny in a new tab
  }
}
