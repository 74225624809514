import { CoreObjectConcrete } from "../../../../common/src/api/coreObjects";
import {
  CalculationForEntity,
  LiveCalculationForEntity,
} from "../../../../common/src/api/document/calculations-objects/calculation-concrete";
import { CalculatableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import { assertUnreachableAggressive } from "../../../../common/src/lib/utils";
import { getFeatureFlagValue } from "../../lib/hooks/use-feature-flag";
import { trackIfCorrupted } from "../../lib/problems/corruption-detection";
import { ProjectIntegrityReleaseStatus } from "../../store/feature-flags/types";
import store from "../../store/store";
import { DrawableObjectConcrete } from "../objects/concrete-object";

export default class DrawableStore extends GlobalStore {
  searchObj<T = DrawableObjectConcrete>(prefix: string): T[];
  searchObj<T>(prefix: string): T[] {
    return super.searchObj(prefix);
  }

  get<T = DrawableObjectConcrete>(uid: string): T;
  get<T>(uid: string): T {
    return super.get(uid) as T;
  }

  forEach<T = DrawableObjectConcrete>(
    callbackfn: (value: T, key: string, map: Map<string, T>) => void,
    thisArg?: any,
  ): void;
  forEach(
    callbackfn: (
      value: CoreObjectConcrete,
      key: string,
      map: Map<string, CoreObjectConcrete>,
    ) => void,
    thisArg?: any,
  ): void {
    return super.forEach(callbackfn, thisArg);
  }

  values<T = DrawableObjectConcrete>(): IterableIterator<T>;
  values(): IterableIterator<CoreObjectConcrete> {
    return super.values();
  }

  getOrCreateLiveCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): LiveCalculationForEntity<T> {
    // access liveCalculationRenderCounter to trigger re-rendering variables which depends on liveCalcs
    store.getters["document/liveCalculationRenderCounter"];
    return super.getOrCreateLiveCalculation(entity);
  }

  onEntityChange(uid: string): void {
    super.onEntityChange(uid);
    const integrityReleaseStatus =
      getFeatureFlagValue<ProjectIntegrityReleaseStatus>("project-integrity");
    switch (integrityReleaseStatus) {
      case "off":
        return;
      case "observing":
      case "on":
        trackIfCorrupted(this, uid, "Change");
        break;
      default:
        assertUnreachableAggressive(integrityReleaseStatus);
    }
  }

  getLiveCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): LiveCalculationForEntity<T> | undefined {
    // access liveCalculationRenderCounter to trigger re-rendering variables which depends on liveCalcs
    store.getters["document/liveCalculationRenderCounter"];
    return super.getLiveCalculation(entity);
  }

  getOrCreateCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): CalculationForEntity<T> {
    // access liveCalculationRenderCounter to trigger re-rendering variables which depends on liveCalcs
    store.getters["document/liveCalculationRenderCounter"];
    return super.getOrCreateCalculation(entity);
  }

  getCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): CalculationForEntity<T> | undefined {
    // access liveCalculationRenderCounter to trigger re-rendering variables which depends on liveCalcs
    store.getters["document/liveCalculationRenderCounter"];
    return super.getCalculation(entity);
  }
}
