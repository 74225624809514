import { CalculationProgress } from "../../../../common/src/api/calculations/calculation-engine";
import { ExecutionStep } from "../../../../common/src/api/calculations/flight-data-recorder";
import { GrillSchedule } from "../../../../common/src/api/calculations/schedules/grill-schedules";
import { ManifoldSchedule } from "../../../../common/src/api/calculations/schedules/manifold-schedules";
import { RadiatorSchedule } from "../../../../common/src/api/calculations/schedules/radiator-schedules";
import { UFHCoilSchedule } from "../../../../common/src/api/calculations/schedules/ufh-coils-schedules";
import {
  DrawingLayout,
  SkippableCalcs,
} from "../../../../common/src/api/calculations/types";
import { SpaceType } from "../../../../common/src/api/catalog/heatload/types";
import { StandardFlowSystemUids } from "../../../../common/src/api/config";
import { FieldCategory } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import {
  DrawingState,
  RoomResultsSettings,
} from "../../../../common/src/api/document/drawing";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import {
  DoorType,
  WindowType,
} from "../../../../common/src/api/document/entities/fenestration-entity";
import { VentilationNode } from "../../../../common/src/api/document/entities/load-node-entity";
import { RadiatorPlantEntity } from "../../../../common/src/api/document/entities/plants/plant-entity";
import { RadiatorManufacturer } from "../../../../common/src/api/document/entities/plants/plant-types";
import { createRadiatorFromNodeSpec } from "../../../../common/src/api/document/entities/plants/utils";
import {
  DEFAULT_ROOM_RESULTS_SETTINGS,
  initialDrawing,
} from "../../../../common/src/api/document/initial-drawing";
import * as Operations from "../../../../common/src/api/document/operation-transforms";
import { SupportedLocales } from "../../../../common/src/api/locale";
import {
  PAPER_SIZES,
  PaperSize,
  getDefaultPaperScalePdfUpload,
  getDefaultPaperSizePdfUpload,
} from "../../../../common/src/api/paper-config";
import { cloneSimple } from "../../../../common/src/lib/utils";
import { SingleMechanicalNodeProps } from "../../../../common/src/models/CustomEntity";
import { Document, History } from "../../../../common/src/models/Document";
import { Operation } from "../../../../common/src/models/Operation";
import { CanvasMouseEventRecordT } from "../../../../flatbuffers/generated/canvas-mouse-event-record";
import { CanvasMouseMovementRecordT } from "../../../../flatbuffers/generated/canvas-mouse-movement-record";
import { ReplayEventUnionT } from "../../../../flatbuffers/replays-enhanced";
import {
  DEFAULT_TITLE_BAR_VIEW,
  TitleBlockView,
} from "../../components/TitleBlockView";
import CanvasContext from "../../htmlcanvas/lib/canvas-context";
import {
  HeatmapMode,
  HeatmapSetting,
  initialResidualPressureHeatmapSetting,
} from "../../htmlcanvas/lib/heatmap/heatmap";
import { ToolHandler } from "../../htmlcanvas/lib/tool";
import { ValveId } from "../../htmlcanvas/lib/types";
import { BaseKeyHandler, KeySpec } from "../../htmlcanvas/tools/utils";
import { DrawingMode } from "../../htmlcanvas/types";
import { ViewPort } from "../../htmlcanvas/viewport";
import { ReplaySettings } from "../../lib/replays/replays";
import {
  getEnabledDrawingLayouts,
  getSuitableDrawingLayout,
} from "../../lib/workflows-utils";

// Because of how the diffing engine works, there are restrictions on the data structure for the document state.
// Rules are:
// 1. Structure is to remain static, except naturally Arrays.
// 2. Objects in arrays must be the same type.
// 3. Objects with uids can be placed as direct array elements to take advantage of the update, add, and delete
//    operations.
// 4. 'uid' is a special field. Use it only as a uuid and for atomic objects where different operations on it
//    should be combined.

export interface CalculationUiSettings {}

export interface UIState {
  viewPort: ViewPort | null;
  stickyCalculationsLoaded: boolean;
  operationsLoaded: boolean;
  drawingMode: DrawingMode;
  history: History;
  toolHandler: ToolHandler | null;
  toolHandlerName: string | null;
  draggingEntities: DrawableEntityConcrete[];

  heatmapMode: HeatmapMode;
  heatmapSettings: {
    [HeatmapMode.ResidualPressure]: HeatmapSetting;
  };
  gridLines: GridLineMode;

  lastCalculationId: number;
  lastCalculationUiSettings: CalculationUiSettings;
  lastCalculationSuccess: boolean;
  lastCalculationFdrLog: ExecutionStep[] | null;
  lastCalculationErrorMsg: string | null;
  isCalculating: boolean;
  calculationProgress: CalculationProgress | null;
  skippedCalculations: SkippableCalcs;

  selectedUids: string[];
  hoveredResultsUid: string | null;

  lastUsedFixtureUid: string | null;
  lastUsedValveVid: ValveId | null;

  calculationFilters: CalculationFilters;
  calculationFilterSettings: CalculationFilterSettings;
  scheduleFilters: ScheduleFilters;
  pinnedCalcEntityUids: string[];
  warningFilter: WarningFilter;
  systemFilter: {
    hiddenSystemUids: string[];
    tempVisibleSystemUids: string[];
  };
  highlightedGroups: HighlightedGroup[];

  lastLayoutFlowSystem: Record<
    DrawingLayout,
    StandardFlowSystemUids | string | null
  >;

  levelUid: string | null;
  historyIndex: number;

  // Variables related to isViewOnly state
  hasErrorConnecting: boolean;
  isSuperUser: boolean;
  isShareLink: boolean;
  isGlobalSample: boolean;
  isSandbox: boolean;
  isEmbedded: boolean;
  showWarnings: boolean; // only needed for embedded mode - show warnings
  alwaysShowCalculations: boolean; // only needed for embedded mode - calculate and show calculations during drawing
  lockView: boolean; // only needed for embedded mode - lock view only mode
  showAllLayouts: boolean; // only needed for embedded mode - show all layouts
  hideLevelSelector: boolean;
  hideGridLines: boolean;
  expressAhaMomentExport: boolean;
  allowedFlowSystems: string[] | null;
  allowedComponents: string[] | null;
  allowedModes: string[] | null;
  allowedExports: string[] | null;
  disabledExports: string[] | null;
  compactExportUi: boolean;
  hideCalculationsSidebar: boolean;
  hideKeyboardShortcuts: boolean;
  hideFlowSystemPicker: boolean;
  hideDrawingLayoutPicker: boolean;
  bigHitbox: string[]; // uids of entities that should have a bigger hitbox. For tutorial purposes.

  viewOnlyReason: string | null;

  snapTarget: string[];

  pastesByLevel: { [key: string]: number };

  exportSettings: ExportUiSettings;
  costAndLUTableOpen: boolean;

  drawingLayout: DrawingLayout;

  onboardingProgress: OnboardingProgress;
  isOnboardingActive: boolean | null;

  displayIndexCircuit: boolean;

  // TODO: wtf is this, rename
  tempUids: string[];

  floorLockStatus: boolean;
  xMode: boolean;

  // null means all roles
  // This is for debugging so access it via the dev console, no UI for this.
  xModeRoles: string[] | null;

  showArchitecture: boolean;
  /** whether to show the controls / buttons related to drawing rooms, roofs etc */
  roomDrawingActive: boolean;
  lastDrawn: {
    room: SpaceType | null;
    door: DoorType | null;
    window: WindowType | null;
    grillVariant: {
      shape: VentilationNode["shape"];
      orientation: VentilationNode["orientation"];
    } | null;
  };
  clickableBounds: ClickableBounds | null;
  requiredMouseKeyCombo: "shiftKey" | "altKey" | "ctrlKey" | null;

  newRadiatorSpecs: RadiatorPlantEntity | null;
  newRoomSpecs: SpaceType | null;
  ductView: "physical" | "schematic";
  replayState: ReplayState;
  propertiesPath: string | null;
  activeOperationInfoPanel: ToolInfoPanel;
}

export interface ToolInfoPanel {
  keyHandlers: [KeySpec, BaseKeyHandler][];
  information: string[];
}

export function updateToolInfoPanel(
  uiState: UIState,
  toolInfoPanel: ToolInfoPanel | null = null,
) {
  if (toolInfoPanel) {
    Object.assign(uiState.activeOperationInfoPanel, toolInfoPanel);
  } else {
    Object.assign(uiState.activeOperationInfoPanel, {
      keyHandlers: [],
      information: [],
    });
  }
}

export type ReplayLog =
  | ReplayEventUnionT
  | { id: string; _type: "InactivitySkip"; time_ms: number; timestamp: number };

/** the state whilst replaying is actually happening.
 *  this object effectively serves as an export / data dump from the 'startReplay' in replays.ts
 *  do not control this object manually
 */
export type ActiveReplayState = {
  isReplaying: true;
  paused?: {
    timestamp: number;
  };

  /** unique id for this replay instance - used to determine if a restart was called if the id is different */
  id: string;

  /** timestamp in UTC milliseconds of where the replay is up to */
  virtualTime: number | null;

  documentId: number;
  orderIndex: number;

  /** ALL events retrieved from server */
  rawEvents: ReplayEventUnionT[] | null;

  /** list of events to show in the text logs */
  eventsToLog: ReplayLog[];

  /** sequence of recent mouse movements to show trails for */
  lastCanvasMouseMovements: CanvasMouseMovementRecordT[];

  /** the most recent mouse event to show circle for (eg. clicks) */
  lastCanvasMouseEvent: CanvasMouseEventRecordT | null;

  /** internals for cache purposes */
  settings: ReplaySettings;
};

export type ReplayState =
  | {
      isReplaying: false;
    }
  | ActiveReplayState;

export interface NewRadiatorSpecs {
  widthMM: number;
  heightMM: number;
  inletHeightAboveFloorM: number;
}

export interface ClickableBounds {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export interface HighlightedGroup {
  color: string;
  entityUids: string[];
}

export interface OnboardingProgress {
  signUp: boolean;
  createProject: boolean;
  // Floor Plan
  uploadPDF: boolean;

  // Levels
  addNewLevel: boolean;
  openLevelsBox: boolean;

  // Design
  insertHeatSource: boolean;
}

export interface ExportUiSettings {
  paperSize: PaperSize;
  scale: string;
  detail: number;
  transparency: number;
  coverSheet: boolean;
  metaPdf: {
    enabled: boolean;
    coverSheet: boolean;
    titleBlock: boolean;
    appendix: boolean;
  };
  floorPlans: boolean;
  includesHighlight: boolean;
  borderless?: boolean;
  isAppendix?: boolean;
  roomResultsSettings: RoomResultsSettings;
  titleBlockView: TitleBlockView;
}

export enum GridLineMode {
  NONE = "NONE",
  ORIGIN = "ORIGIN",
  FULL = "FULL",
}

export interface ScheduleFilters {
  radiators: ScheduleFilterBase<RadiatorSchedule>;
  grills: ScheduleFilterBase<GrillSchedule>;
  manifolds: ScheduleFilterBase<ManifoldSchedule>;
  coils: ScheduleFilterBase<UFHCoilSchedule>;
}

export type UnionKeys<T> = T extends T ? keyof T : never;
export type ScheduleFields = ScheduleFilters[keyof ScheduleFilters]["fields"];

export interface ScheduleFilterBase<T> {
  name: string;
  enabled: boolean;
  scale: number;
  includeAllLevels: boolean;
  offset: { x: number; y: number };
  fields: { [key in keyof T]: FilterKey };
}

/**
 * Update sanitize functions with any changes to CalculationFilters or
 * CalculationFilterSettings to maintain consistency and prevent errors.
 *
 * node/frontend/src/htmlcanvas/utils.ts
 */

export interface CalculationFilters {
  [key: string]: CalculationFilter;
}
export interface CalculationFilter {
  name: string;
  order?: number;
  enabled: boolean;
  collapsed: boolean;
  filters: { [key: string]: FilterKey };
}
export interface FilterKey {
  name: string;
  enabled: boolean;
  targets?: string[];
  hide?: boolean;
}

export enum CalculationFilterSettingType {
  Systems = "systems",
  View = "view",
}
export type CalculationFilterSettings = {
  [CalculationFilterSettingType.Systems]: {
    enabled: boolean;
    collapsed: boolean;
    name: string;
    filters: { [key in string]: FilterSettingKey };
  };
  [CalculationFilterSettingType.View]: {
    enabled: boolean;
    collapsed: boolean;
    name: string;
    filters: { [key in FilterSettingViewKeyValues]: FilterSettingKey };
  };
};
export type FilterSettingViewKeyValues =
  | "all"
  | "custom"
  | "reference"
  | "pipe-sizing"
  | "pressure"
  | "heat-loss"
  | "grade-fall"
  | "heat-load";

export interface FilterSettingKey extends FilterKey {
  drawingLayout?: DrawingLayout[];
  category?: FieldCategory[];
}

export interface WarningFilter {
  hiddenUids: string[];
  collapsedLevelType: LevelTypeKey[];
  showHiddenWarnings: boolean;
  showWarningsToPDF: boolean;
  activeEntityUids: string[];
  editEntityUids: string[];
}
export interface LevelTypeKey {
  levelUid: string;
  visible: boolean;
  types: string[];
}

/**
 * A document is a drawing + all of its history and meta attributes.
 */
export interface DocumentState {
  isPreview: boolean | undefined;
  activeflowSystemUid: StandardFlowSystemUids | string;
  // This is the drawing that we last received or last sent to the server.
  committedDrawing: DrawingState;
  diffFilter: DiffFilter;
  // This is the current drawing that is connected in real time to vue components.
  // Operations are generated by diffing this view with the drawing state.
  drawing: DrawingState;

  undoStack: Operations.OperationTransformConcrete[][];
  undoIndex: number; // Index of the next undo that gets PUSHED. It is one ahead of the next item to undo itself.

  optimisticHistory: Operations.OperationTransformConcrete[];

  stagedCommits: Operations.OperationTransformConcrete[];

  fullHistory: Operation[];

  // A list of operations that have been performed on the committedDrawing.
  // This implies that changes in the drawing state are not reflected in operations.
  // This also implies that changes are updated from the server.
  history: Operations.OperationTransformConcrete[];
  nextId: number;

  uiState: UIState;
  locale: SupportedLocales;

  documentId: number;
  shareToken: string;
  filterToken: string;
  receivedRemoteOperation: boolean;

  documentRecord: Document | null;

  documentLoaded: boolean;

  liveCalculationRenderCounter: number;
}

export interface DiffFilter {
  metadata: {};
  levels: any;
  shared: any;
}

export const initialWarningFilter = {
  hiddenUids: [],
  collapsedLevelType: [],
  showHiddenWarnings: true,
  showWarningsToPDF: true,
  activeEntityUids: [],
  editEntityUids: [],
};

export const initCalculationFilters: CalculationFilters = {
  Pipe: {
    name: "Pipe",
    enabled: true,
    collapsed: false,
    filters: {
      "Pipe Diameter (\u00f8)": {
        name: "Pipe Diameter (\u00f8)",
        enabled: true,
      },
    },
  },
};

export const initCalculationFilterSettings: CalculationFilterSettings = {
  [CalculationFilterSettingType.Systems]: {
    enabled: true,
    collapsed: false,
    name: "Systems",
    filters: {
      all: {
        name: "Show All",
        enabled: true,
      },
      [StandardFlowSystemUids.ColdWater]: {
        name: "Cold Water",
        enabled: true,
        drawingLayout: ["pressure"],
      },
      [StandardFlowSystemUids.HotWater]: {
        name: "Hot Water",
        enabled: true,
        drawingLayout: ["pressure"],
      },
      [StandardFlowSystemUids.WarmWater]: {
        name: "Warm Water",
        enabled: true,
        drawingLayout: ["pressure"],
      },
      [StandardFlowSystemUids.Gas]: {
        name: "Gas",
        enabled: true,
        drawingLayout: ["pressure"],
      },
      [StandardFlowSystemUids.FireHydrant]: {
        name: "Fire Hydrant",
        enabled: true,
        drawingLayout: ["pressure"],
      },
      [StandardFlowSystemUids.FireHoseReel]: {
        name: "Fire Hose Reel",
        enabled: true,
        drawingLayout: ["pressure"],
      },
      [StandardFlowSystemUids.SewerDrainage]: {
        name: "Sewer Drainage",
        enabled: true,
        drawingLayout: ["drainage"],
      },
      [StandardFlowSystemUids.SanitaryPlumbing]: {
        name: "Sanitary Plumbing",
        enabled: true,
        drawingLayout: ["drainage"],
      },
      [StandardFlowSystemUids.GreaseWaste]: {
        name: "Grease Waste",
        enabled: true,
        drawingLayout: ["drainage"],
      },
      [StandardFlowSystemUids.TradeWaste]: {
        name: "Trade Waste",
        enabled: true,
        drawingLayout: ["drainage"],
      },
      [StandardFlowSystemUids.RisingMain]: {
        name: "Rising Main",
        enabled: true,
        drawingLayout: ["drainage"],
      },
      [StandardFlowSystemUids.Heating]: {
        name: "Heating",
        enabled: true,
        drawingLayout: ["mechanical"],
      },
      [StandardFlowSystemUids.UnderfloorHeating]: {
        name: "Underfloor Heating",
        enabled: true,
        drawingLayout: ["mechanical"],
      },
      [StandardFlowSystemUids.Chilled]: {
        name: "Chilled",
        enabled: true,
        drawingLayout: ["mechanical"],
      },
      [StandardFlowSystemUids.Condenser]: {
        name: "Condenser",
        enabled: true,
        drawingLayout: ["mechanical"],
      },
    },
  },
  [CalculationFilterSettingType.View]: {
    enabled: true,
    collapsed: false,
    name: "View",
    filters: {
      all: {
        name: "All",
        enabled: false,
      },
      custom: {
        name: "Custom",
        enabled: true,
      },
      reference: {
        name: "Reference",
        enabled: false,
        category: [FieldCategory.EntityName],
      },
      "pipe-sizing": {
        name: "Conduit Sizing",
        enabled: false,
        category: [
          FieldCategory.Size,
          FieldCategory.LoadingUnits,
          FieldCategory.Velocity,
          FieldCategory.FlowRate,
          FieldCategory.HeatLoss,
        ],
      },
      pressure: {
        name: "Pressure",
        enabled: false,
        drawingLayout: ["pressure", "ventilation"],
        category: [FieldCategory.Pressure],
      },
      "heat-loss": {
        name: "Heat Load",
        enabled: false,
        drawingLayout: ["pressure"],
        category: [
          FieldCategory.HeatLoss,
          FieldCategory.Volume,
          FieldCategory.Length,
        ],
      },
      "heat-load": {
        name: "Heat Load",
        enabled: false,
        drawingLayout: ["mechanical"],
        category: [
          FieldCategory.HeatLoss,
          FieldCategory.Volume,
          FieldCategory.Length,
          FieldCategory.Size,
        ],
      },
      "grade-fall": {
        name: "Grade/Fall",
        enabled: false,
        drawingLayout: ["pressure", "ventilation"],
        category: [
          FieldCategory.Location,
          FieldCategory.Size,
          FieldCategory.Length,
        ],
      },
    },
  },
};

export const initScheduleFilters: ScheduleFilters = {
  radiators: {
    name: "Radiators",
    enabled: false,
    scale: 0.5,
    includeAllLevels: false,
    offset: { x: 0, y: 0 },
    fields: {
      name: {
        name: "Name",
        enabled: true,
      },
      dimensions: {
        name: "Dimensions",
        enabled: true,
      },
      pressureDrop: {
        name: "Pressure Drop",
        enabled: true,
      },
      volume: {
        name: "Volume",
        enabled: true,
      },
      designRating: {
        name: "Design Rating",
        enabled: true,
      },
      fiftyDeltaRating: {
        name: "50ΔT Rating",
        enabled: true,
      },
      roomName: {
        name: "Room Name",
        enabled: true,
      },
      roomHeatLoss: {
        name: "Room Heat Loss",
        enabled: true,
      },
      levelName: {
        name: "Level",
        enabled: true,
      },
    },
  },
  grills: {
    name: "Grilles",
    enabled: false,
    scale: 0.5,
    includeAllLevels: false,
    offset: { x: 0, y: 0 },
    fields: {
      grillType: {
        name: "Type",
        enabled: true,
      },
      velocity: {
        name: "Velocity",
        enabled: true,
      },
      flowRate: {
        name: "Flow Rate",
        enabled: true,
      },
      dimensions: {
        name: "Dimensions",
        enabled: true,
      },
      pressureDrop: {
        name: "Pressure Drop",
        enabled: true,
      },
      roomName: {
        name: "Room Name",
        enabled: true,
      },
      levelName: {
        name: "Level",
        enabled: true,
      },
    },
  },
  manifolds: {
    name: "Manifolds",
    enabled: false,
    scale: 0.5,
    includeAllLevels: false,
    offset: { x: 0, y: 0 },
    fields: {
      manifoldName: {
        name: "Manifold",
        enabled: true,
      },
      index: {
        name: "Index",
        enabled: true,
      },
      loopId: {
        name: "Circuit",
        enabled: true,
      },
      balancing: {
        name: "Balancing",
        enabled: true,
      },
      flowRate: {
        name: "Flow Rate",
        enabled: true,
      },
      pipeLength: {
        name: "Circuit Length",
        enabled: true,
      },
      pipeSpacing: {
        name: "Pipe Spacing",
        enabled: true,
      },
      heatingArea: {
        name: "Heating Area",
        enabled: true,
      },
      roomName: {
        name: "Room",
        enabled: true,
      },
      roomArea: {
        name: "Room Area",
        enabled: true,
      },
      roomPerimeter: {
        name: "Room Perimeter",
        enabled: false,
      },
      roomDesignTemp: {
        name: "Room Design Temp",
        enabled: true,
      },
      roomHeatLoss: {
        name: "Heatloss",
        enabled: true,
      },
      roomDesignOutput: {
        name: "Design Output",
        enabled: true,
      },
      floorTemp: {
        name: "Floor Temp",
        enabled: true,
      },
      meanWaterTemp: {
        name: "Mean Water Temp",
        enabled: true,
      },
      floorType: {
        name: "Floor Type",
        enabled: true,
      },
      outputPerArea: {
        name: "Output Per Area",
        enabled: true,
      },
    },
  },
  coils: {
    name: "UFH Coils",
    enabled: false,
    scale: 0.5,
    includeAllLevels: false,
    offset: { x: 0, y: 0 },
    fields: {
      coilLength: {
        name: "Coil Length",
        enabled: true,
      },
      coilNumber: {
        name: "Coil Number",
        enabled: true,
      },
      loopId: {
        name: "Circuit ID",
        enabled: true,
      },
      loopLength: {
        name: "Circuit Length",
        enabled: true,
      },
      roomName: {
        name: "Room",
        enabled: true,
      },
    },
  },
};

export function initialUIState(locale: SupportedLocales): UIState {
  return {
    drawingMode: DrawingMode.FloorPlan,
    history: {
      mode: "full",
      entityUids: [],
      entityLevel: "ground",
    },
    operationsLoaded: false,
    stickyCalculationsLoaded: false,
    viewPort: null,
    toolHandler: null,
    toolHandlerName: null,
    draggingEntities: [],

    gridLines: GridLineMode.ORIGIN,

    lastUsedFixtureUid: null,
    lastUsedValveVid: null,

    selectedUids: [],
    hoveredResultsUid: null,
    historyIndex: 0,

    lastCalculationId: 0,
    lastCalculationUiSettings: {
      demandType: null,
    },
    lastCalculationSuccess: false,
    lastCalculationFdrLog: null,
    lastCalculationErrorMsg: null,
    isCalculating: false,
    calculationProgress: null,
    calculationFilters: cloneSimple(initCalculationFilters),
    calculationFilterSettings: cloneSimple(initCalculationFilterSettings),
    scheduleFilters: cloneSimple(initScheduleFilters),
    pinnedCalcEntityUids: [],
    warningFilter: cloneSimple(initialWarningFilter),
    lastLayoutFlowSystem: {
      drainage: null,
      pressure: null,
      mechanical: null,
      ventilation: null,
    },
    systemFilter: {
      hiddenSystemUids: [],
      tempVisibleSystemUids: [],
    },
    levelUid: null,
    hasErrorConnecting: false,
    isSuperUser: false,
    lastDrawn: {
      room: null,
      door: null,
      window: null,
      grillVariant: null,
    },
    highlightedGroups: [],

    isShareLink: false,
    isSandbox: false, // Sandboxes are also shared links, but they are editable.
    isGlobalSample: false,
    isEmbedded: false,
    showWarnings: false,
    alwaysShowCalculations: false,
    lockView: false,
    showAllLayouts: false,
    hideLevelSelector: false,
    hideGridLines: false,
    allowedFlowSystems: null,
    allowedComponents: null,
    allowedModes: null,
    allowedExports: null,
    disabledExports: null,
    hideCalculationsSidebar: false,
    expressAhaMomentExport: false,
    compactExportUi: false,
    hideKeyboardShortcuts: false,
    bigHitbox: [],
    hideFlowSystemPicker: false,
    hideDrawingLayoutPicker: false,

    viewOnlyReason: null,

    pastesByLevel: {},
    snapTarget: [],

    exportSettings: {
      paperSize: PAPER_SIZES[getDefaultPaperSizePdfUpload(locale)],
      scale: getDefaultPaperScalePdfUpload(locale),
      detail: -8,
      transparency: 50,
      coverSheet: true,
      metaPdf: {
        enabled: false,
        coverSheet: true,
        titleBlock: true,
        appendix: true,
      },
      floorPlans: true,
      includesHighlight: true,
      roomResultsSettings: DEFAULT_ROOM_RESULTS_SETTINGS,
      titleBlockView: DEFAULT_TITLE_BAR_VIEW,
    },
    costAndLUTableOpen: false,
    drawingLayout: "pressure",
    tempUids: [],
    heatmapMode: HeatmapMode.Off,
    heatmapSettings: {
      [HeatmapMode.ResidualPressure]: initialResidualPressureHeatmapSetting(
        initialDrawing(locale).metadata.units,
      ),
    },

    onboardingProgress: {
      signUp: true,
      createProject: true,
      uploadPDF: false,
      addNewLevel: false,
      openLevelsBox: false,
      insertHeatSource: false,
    },
    skippedCalculations: {
      skipFireCalcs: false,
    },
    isOnboardingActive: false,
    displayIndexCircuit: false,
    floorLockStatus: false,

    xMode: false,
    xModeRoles: null,
    showArchitecture: false,
    roomDrawingActive: false,
    clickableBounds: null,
    requiredMouseKeyCombo: null,
    newRoomSpecs: null,

    newRadiatorSpecs: null,
    ductView: "physical",
    replayState: { isReplaying: false },
    propertiesPath: null,
    activeOperationInfoPanel: {
      keyHandlers: [],
      information: [],
    },
  };
}

export function updateUiStateWithDocument(context: CanvasContext) {
  const firstRadiator = context.drawing.metadata.nodes.mechanical.filter(
    (n) => n.type === "radiator",
  )[0] as SingleMechanicalNodeProps;

  if (firstRadiator) {
    const manufacturer = context.drawing.metadata.catalog.heatEmitters[0]
      .manufacturer as RadiatorManufacturer;
    context.document.uiState.newRadiatorSpecs = createRadiatorFromNodeSpec(
      context,
      manufacturer,
      firstRadiator,
    );
  }

  // for (const [lvlId, lvl] of Object.entries(context.drawing.levels)) {
  //   for (const entity of Object.values(lvl.entities)) {
  //     if (entity.type === EntityType.ROOM) {
  //       context.document.uiState.showArchitecture = true;
  //       return;
  //     }
  //   }
  // }

  // always show rooms (SEED-1438)
  context.document.uiState.showArchitecture = true;

  // init drawingLayout
  {
    const enabledLayouts = getEnabledDrawingLayouts(
      context.drawing.metadata.workflows,
    );
    const currentLayout = context.document.uiState.drawingLayout;
    if (!enabledLayouts.includes(currentLayout)) {
      const suitableLayout = getSuitableDrawingLayout(
        context.drawing.metadata.workflows,
      ); // best by priority
      if (suitableLayout) {
        context.document.uiState.drawingLayout = suitableLayout;
      } else {
        // TODO: resolve undefined behaviour
        console.error(
          "no valid drawingLayout (user probably disabled all workflows). indeterminate behaviour as the field 'drawingLayout' does not allow nulls.",
        );
      }
    }
  }
}

export function blankDiffFilter() {
  return {
    shared: {},
    levels: {},
    metadata: false,
  };
}

export const initialDocumentState: DocumentState = {
  committedDrawing: initialDrawing(SupportedLocales.AU),
  drawing: initialDrawing(SupportedLocales.AU), // This gets replaced immediately upon loading after locales are known.
  undoStack: [],
  undoIndex: 0,
  diffFilter: blankDiffFilter(),
  optimisticHistory: [],
  stagedCommits: [],
  history: [],
  fullHistory: [],
  nextId: 1,
  uiState: initialUIState(SupportedLocales.AU),
  documentId: -1,
  shareToken: "",
  filterToken: "",
  receivedRemoteOperation: false,
  locale: SupportedLocales.AU,
  isPreview: false,
  activeflowSystemUid: StandardFlowSystemUids.ColdWater,
  documentRecord: null,
  documentLoaded: false,
  liveCalculationRenderCounter: 0,
};

export interface EntityParam {
  entity: DrawableEntityConcrete;
  levelUid: string;
}

export interface EntityParamNullable {
  entity: DrawableEntityConcrete;
  levelUid: string | null;
}

export enum EntityEvent {
  UPDATE_ENTITY = "update-entity",
  POST_DELETE_ENTITY = "post-delete-entity",
  ADD_ENTITY = "add-entity",
  DELETE_LEVEL = "delete-level",
  ADD_LEVEL = "add-level",
}
