import { PossibleFix } from "../../../../common/src/lib/possible-fix";

/**
 * At this stage severities have been defined as the standard severity levels.
 *
 * We can always change this if needed.
 */
export enum ProblemSeverity {
  // Does not alert user, will only show in the problem panel
  Info = "info",
  // Warns the user, shows orange in the header
  Warning = "warning",
  // Warns the user of an error, shows red in the header
  // These should be reserved for serious issues that require immediate user action
  Error = "error",
}

export interface Problem {
  title: string;
  details: string;
  severity: ProblemSeverity;
  possibleFixes?: PossibleFix[];
  helpArticleUrl?: string;
}
