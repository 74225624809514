import { Color } from "../../../../lib/color";
import {
  Choice,
  assertUnreachable,
  assertUnreachableAggressive,
} from "../../../../lib/utils";

export enum PlantType {
  RETURN_SYSTEM = "RETURN_SYSTEM",
  PUMP = "PUMP",
  TANK = "TANK",
  PUMP_TANK = "PUMP_TANK",
  DRAINAGE_PIT = "DRAINAGE_PIT",
  CUSTOM = "CUSTOM",
  DRAINAGE_GREASE_INTERCEPTOR_TRAP = "DRAINAGE_GREASE_INTERCEPTOR_TRAP",
  VOLUMISER = "VOLUMISER",

  // ACUs and FCUs are radiators too but with negative KW's.
  RADIATOR = "RADIATOR",
  MANIFOLD = "MANIFOLD",
  UFH = "UFH", // Underfloor heating
  AHU = "AHU", // Air handling unit

  // The new ventilation AHU.
  // While being developed, this is a separate type to the old AHU
  // so that features can be merged in.
  // Before release, the old AHU will be converted to this type.
  // And this type should be renamed back to AHU.
  AHU_VENT = "AHU_VENT",

  FCU = "FCU", // Fan coil unit

  FILTER = "FILTER",
  RO = "RO",

  DUCT_MANIFOLD = "DUCT_MANIFOLD",
}

export enum ReturnSystemType {
  HEAT_SOURCE = "HEAT_SOURCE",
  AIR_SOURCE_HEAT_PUMP = "AIR_SOURCE_HEAT_PUMP",
  GROUND_SOURCE_HEAT_PUMP = "GROUND_SOURCE_HEAT_PUMP",
  GAS_BOILER = "GAS_BOILER",
  DHW_CYLINDER = "DHW_CYLINDER",
  DHW_CYLINDER_W_STORAGE = "DHW_CYLINDER_W_STORAGE",
  HEADER = "HEADER",
  BUFFER_TANK = "BUFFER_TANK",
  CHILLER = "CHILLER",
  COOLING_TOWER = "COOLING_TOWER",
  HOT_WATER_PLANT_W_RETURN = "HOT_WATERPLANT_W_RETURN",
  CUSTOM = "CUSTOM",
}

export function returnSystemTypeToName(type: ReturnSystemType): string {
  switch (type) {
    case ReturnSystemType.HEAT_SOURCE:
      return "Heat Source";
    case ReturnSystemType.AIR_SOURCE_HEAT_PUMP:
      return "Air Source Heat Pump";
    case ReturnSystemType.GROUND_SOURCE_HEAT_PUMP:
      return "Ground Source Heat Pump";
    case ReturnSystemType.GAS_BOILER:
      return "Gas Boiler";
    case ReturnSystemType.DHW_CYLINDER:
      return "DHW Cylinder";
    case ReturnSystemType.DHW_CYLINDER_W_STORAGE:
      return "DHW Cylinder with Storage";
    case ReturnSystemType.HEADER:
      return "Header Manifold";
    case ReturnSystemType.BUFFER_TANK:
      return "Buffer Tank";
    case ReturnSystemType.CHILLER:
      return "Chiller";
    case ReturnSystemType.COOLING_TOWER:
      return "Cooling Tower";
    case ReturnSystemType.HOT_WATER_PLANT_W_RETURN:
      return "Hot Water Plant with Return";
    case ReturnSystemType.CUSTOM:
      return "Custom";
    default:
      assertUnreachableAggressive(type);
  }
}

export enum HotWaterPlantCatalogList {
  "hotWaterPlant" = "hotWaterPlant",
  "circulatingPumps" = "circulatingPumps",
}

export type CirculatingPumpsManufacturers = "generic" | "grundfos";

export enum HotWaterPlantGrundfosSettingsName {
  "20-60-1" = "Grundfos UPS 20-60 N Setting 1",
  "20-60-2" = "Grundfos UPS 20-60 N Setting 2",
  "20-60-3" = "Grundfos UPS 20-60 N Setting 3",
  "32-80-1" = "Grundfos UPS 32-80 N Setting 1",
  "32-80-2" = "Grundfos UPS 32-80 N Setting 2",
  "32-80-3" = "Grundfos UPS 32-80 N Setting 3",
}

export type HotWaterPlantManufacturers = "generic" | "rheem";

export enum RheemVariant {
  "continuousFlow" = "Continuous Flow",
  "tankpak" = "Tankpak",
  "electric" = "HD Electric",
  "heatPump" = "Heat Pump",
  "eclipse" = "Eclipse",
}

export enum RheemVariantValues {
  "continuousFlow" = "continuousFlow",
  "tankpak" = "tankpak",
  "electric" = "electric",
  "heatPump" = "heatPump",
  "eclipse" = "eclipse",
}

export enum HotWaterImmersionUse {
  NONE = "None",
  ONCE_PER_WEEK = "Once per week",
  ONCE_PER_DAY = "Once per day",
}

export interface Plant {
  type: PlantType;
  shape: PlantShapes | null;
  diameterMM: number | null;

  // below are omitted for return systems and dual systems mechanical nodes
  outletUid: string;
  outletSystemUid: string;
  outletHeightAboveFloorM: number | null;
  outletTemperatureC: number | null;
}

export enum PlantShapes {
  RECTANGULAR = "RECTANGULAR",
  CYLINDER = "CYLINDER",
}

export enum PressureMethod {
  PUMP_DUTY = "PUMP_DUTY",
  FIXED_PRESSURE_LOSS = "FIXED_PRESSURE_LOSS",
  STATIC_PRESSURE = "STATIC_PRESSURE",
  KV_PRESSURE_LOSS = "KV_PRESSURE_LOSS",
}

export interface StaticPressure {
  pressureMethod: PressureMethod.STATIC_PRESSURE;
  staticPressureKPA: number | null;
}

export interface FixedPressureLoss {
  pressureMethod: PressureMethod.FIXED_PRESSURE_LOSS;
  pressureLossKPA: number | null;
}

export interface PumpPressure {
  pressureMethod: PressureMethod.PUMP_DUTY;
  pumpPressureKPA: number | null;
}

export interface KvPressureLoss {
  pressureMethod: PressureMethod.KV_PRESSURE_LOSS;
  kvValue: number | null;
}

export interface AnyPressure {
  pressureMethod: PressureMethod;
  staticPressureKPA: number | null;
  pressureLossKPA: number | null;
  pumpPressureKPA: number | null;
  kvValue: number | null;
}

export interface FilterPlantBase extends Plant {
  type: PlantType.FILTER;
  pressureLoss: FixedPressureLoss;

  manufacturer: string | null;
  model: string | null;
  configuration: FilterConfiguration | null;
  customManufFields?: {
    [key: string]: string | number | null;
  };
}

export interface SoftenerPlant extends FilterPlantBase {
  type: PlantType.FILTER;
  filterType: "softener";
}

export interface BackWashFilterPlant extends FilterPlantBase {
  type: PlantType.FILTER;
  filterType: "backwash";
}

export interface BackWashRainwaterFilterPlant extends FilterPlantBase {
  type: PlantType.FILTER;
  filterType: "backwash-rainwater";
}

export interface UVFilterPlant extends FilterPlantBase {
  type: PlantType.FILTER;
  filterType: "uv";
}

export interface CartridgeFilterPlant extends FilterPlantBase {
  type: PlantType.FILTER;
  filterType: "cartridge";
}

export type FilterPlant =
  | SoftenerPlant
  | BackWashFilterPlant
  | UVFilterPlant
  | CartridgeFilterPlant
  | BackWashRainwaterFilterPlant;

export interface TankPlant extends Plant {
  type: PlantType.TANK;
  pressureLoss: StaticPressure;
  depthMM: number | null;

  capacityL: number | null;
  peakFlowRateStorageMinutes: number | null;
}

export interface PumpTank extends Plant {
  type: PlantType.PUMP_TANK;
  pressureLoss: StaticPressure;
  depthMM: number | null;

  configuration: PumpConfiguration | null;
  targetPressureKPA: number | null;

  peakFlowRateStorageMinutes: number | null;
  capacityL: number | null;

  manufacturer: string | null;
  model: string | null;
}

export interface ROPlant extends Plant {
  type: PlantType.RO;
  pressureLoss: StaticPressure;

  targetPressureKPA: number | null;

  manufacturer: string | null;
  model: string | null;
  customManufFields?: {
    [key: string]: string | number | null;
  };
}

export type HeatLoadRating =
  | {
      type: "energy";
      KW: number | null;
    }
  | {
      type: "flow-rate";
      LS: number | null;
    };

export type ExplicitHeatLoadRating =
  | {
      type: "energy";
      KW: number;
    }
  | {
      type: "flow-rate";
      LS: number;
    };

export type DimensionWithLimit =
  | {
      type: "exact";
      value: number | null;
    }
  | {
      type: "upper";
      value: number | null;
    };

export interface RadiatorBase extends SingleSystemMechanicalNodeBase {
  type: PlantType.RADIATOR;
  radiatorType: "fixed" | "specify";
  heatSourceOutletUid: string | null;
}

export interface ManifoldPlant
  extends Omit<SingleSystemMechanicalNodeBase, "rating"> {
  type: PlantType.MANIFOLD;
  rating: HeatLoadRating;
  widthMM: number | null;
  heightMM: number | null;
  color: Color;
  hasRecirculationPump: boolean;

  internalVolumeL: number | null;

  ufhFlowTempC: number | null;
  // Without a recirculation pump, the flow and return temperatures are
  // determined by the system they are connected to.   ufhFlowTempC: number | null;
  ufhReturnTempC: number | null;
  ufhMode: "approximate" | "full";
  ufhSystemUid: string;

  // manufacturer fields
  // TODO THE FOLLOWING FIELDS SHOULD BE ADDED IN THE FOLLOWING PRs
  manifoldManufacturer: string | null;
  manifoldRange: string | null;
  manifoldModel: string | null;

  actuatorManufacturer: string | null;
  actuatorModel: string | null;

  pumpPackManufacturer: string | null;
  pumpPackModel: string | null;

  pumpManufacturer: string | null;
  pumpModel: string | null;

  blendingValveManufacturer: string | null;
  blendingValveModel: string | null;

  ballValveManufacturer: string | null;
  ballValveModel: string | null;
}

export interface UnderfloorHeatingPlant extends SingleSystemMechanicalNodeBase {
  type: PlantType.UFH;
  widthMM: number | null;
  heightMM: number | null;
}

export interface AirHandlingUnitVentPlant extends DualSystemMechanicalNodeBase {
  type: PlantType.AHU_VENT;

  heightMM: number | null;
  widthMM: number | null;
  depthMM: number | null;
  supplyPressureDropPA: number;
  extractPressureDropPA: number;

  supplyHeightAboveFloorM: number;
  extractHeightAboveFloorM: number;
  intakeHeightAboveFloorM: number;
  exhaustHeightAboveFloorM: number;

  addSupplyIO: boolean;
  addExtractIO: boolean;
  addIntakeIO: boolean;
  addExhaustIO: boolean;

  supplyUid: string | null;
  extractUid: string | null;
  intakeUid: string | null;
  exhaustUid: string | null;

  supplySystemUid: string;
  extractSystemUid: string;
  intakeSystemUid: string;
  exhaustSystemUid: string;

  shape: PlantShapes | null;
  diameterMM: number | null;

  heatRecoveryEfficiencyPct: number | null;
  airTemperatureMVHR: number | null;
  increaseFlowRateBasedOnHeatLoad: boolean;
}

export interface AirHandlingUnitPlant extends DualSystemMechanicalNodeBase {
  type: PlantType.AHU;
}

export interface FanCoilUnitPlant extends DualSystemMechanicalNodeBase {
  type: PlantType.FCU;
}

export interface SingleSystemMechanicalNodeBase extends Plant {
  type: PlantType.RADIATOR | PlantType.MANIFOLD | PlantType.UFH;
  rating: HeatLoadRating;
  widthMM: number | DimensionWithLimit | null;
  heightMM: number | DimensionWithLimit | null;
  depthMM: number | null;
  capacityRateLKW: number | null;
  volumeL: number | null;
  pressureLoss: FixedPressureLoss | KvPressureLoss;
}

export type RadiatorManufacturer = "generic" | string;
export type GenericRadiatorRangeType = "11" | "21" | "22" | "33";

export interface RadiatorRangeTypeOption {
  key: string;
  label: string;
}
export interface FixedRadiatorPlant extends RadiatorBase {
  radiatorType: "fixed";
  widthMM: number | null;
  heightMM: number | null;
}

export interface SpecifyRadiatorPlant extends RadiatorBase {
  manufacturer: RadiatorManufacturer;
  radiatorType: "specify";
  model: string | null;
  rangeType: string;
  widthMM: DimensionWithLimit;
  heightMM: DimensionWithLimit;
  customManufFields?: {
    [key: string]: string | number | null;
  };
}

export type RadiatorPlant = FixedRadiatorPlant | SpecifyRadiatorPlant;

export interface DualSystemMechanicalNodeBase
  extends Omit<
    Plant,
    | "outletUid"
    | "outletSystemUid"
    | "outletHeightAboveFloorM"
    | "outletTemperatureC"
  > {
  type: PlantType.AHU | PlantType.FCU | PlantType.AHU_VENT;

  widthMM: number | null;
  heightMM: number | null;
  depthMM: number | null;
  color: Color;

  addHeatingIO: boolean;
  heatingInletUid: string | null;
  heatingOutletUid: string | null;
  heatingSystemUid: string;
  heatingHeightAboveFloorM: number | null;
  heatingRating: HeatLoadRating;
  heatingCapacityRateLKW: number;
  heatingPressureLoss: FixedPressureLoss;
  heatingRooms: string[] | null; // only old FCUs have null to supply the rooms they are on top of

  addChilledIO: boolean;
  chilledInletUid: string | null;
  chilledOutletUid: string | null;
  chilledSystemUid: string;
  chilledHeightAboveFloorM: number | null;
  chilledRating: HeatLoadRating;
  chilledCapacityRateLKW: number;
  chilledPressureLoss: FixedPressureLoss;
  chilledRooms: string[] | null; // only old FCUs have null to supply the rooms they are on top of
}

export interface DuctManifoldPlant
  extends Omit<
    Plant,
    | "outletUid"
    | "outletSystemUid"
    | "outletHeightAboveFloorM"
    | "outletTemperatureC"
  > {
  type: PlantType.DUCT_MANIFOLD;
  outlets: Array<{
    uid: string;
    pressureLoss: FixedPressureLoss;
    // System, height and temperature should match inlet. Manifold is passthrough.
  }>;
}

export interface ReturnSystemBase
  extends Omit<
    Plant,
    | "outletUid"
    | "outletSystemUid"
    | "outletHeightAboveFloorM"
    | "outletTemperatureC"
  > {
  type: PlantType.RETURN_SYSTEM;

  // heatSource also includes chilled sources which share many simlarities, include being closed.
  returnType: "pressure" | "heatSource";
  gasConsumptionMJH: number | null;
  gasPressureKPA: number | null;
  // minimumPressure limit
  gasNodeUid: string | null;
  diversity: number | null;

  preheats: PreheatInlet[];

  outlets: HotWaterOutlet[];
  // SEED-351 the new layout has fixed IO spacing
  newIoLayout: boolean | null;
}

export interface HotWaterOutlet {
  outletUid: string | null;
  outletSystemUid: string;
  outletTemperatureC: number | null;
  heightAboveFloorM: number | null;
  addRecirculation: boolean;
  recircPumpOnReturn: boolean;
  calculatePipeHeatLoad: boolean;
  outletReturnUid: string | null;

  returnLimitTemperatureC: number | null;
  returnVelocityMS: number | null;
  addReturnToPSDFlowRate: boolean;

  pressureDropKPA: number | null;
}

export interface PreheatInlet {
  inletUid: string;
  // Not optional, unlike the outlet.
  returnUid: string;
  heightAboveFloorM: number | null;

  pressureDropKPA: number | null;
  volumeL: number | null;

  ratingMode: "explicit" | "percentage";
  explicitRating: ExplicitHeatLoadRating;
  ratingPCT: number | null;
}

export type legionellaPurgeFrequencyType = "Daily" | "Weekly" | "Monthly";
export interface ReturnSystemBasePlant extends ReturnSystemBase {
  rheemVariant: RheemVariantValues | null;
  rheemPeakHourCapacity: number | null;
  rheemMinimumInitialDelivery: number | null;
  rheemkWRating: number | null;
  rheemStorageTankSize: number | null;
  addColdWaterInlet: boolean;
  addGasInlet: boolean;
  // preheatInletHeightAboveFloorM: number | null;
  rating: HeatLoadRating;
  domesticWaterLoadKW: number | null;
  gasInletSystemUid: string | null;
  preheatSystemUid: string | null;
  isCibseDiversified: boolean;
  returnSystemType: ReturnSystemType;
  noiseReportSetting: NoiseReportSetting | null;
  SCOP: number | null;
  dairyDomesticWaterLoadL: number | null;
  SPF: number | null;
  legionellaPurgeTemperatureC: number | null;
  legionellaPurgeFrequency: legionellaPurgeFrequencyType;
}

export type HeatPumpSCOPRating = {
  temperatureC: number;
  SCOP: number;
};

export interface HeatPumpPlant extends ReturnSystemBasePlant {
  returnSystemType:
    | ReturnSystemType.AIR_SOURCE_HEAT_PUMP
    | ReturnSystemType.GROUND_SOURCE_HEAT_PUMP;
  manufacturer: string | null;
  model: string | null;
  MCSCertificateNumber: string | null;
  SCOPRatings: HeatPumpSCOPRating[];
  minimumSystemVolumeL: number | null;
  maximumRecirculationPumpFlowLPS: number | null;
  maximumRecirculationPumpPressureKPA: number | null;
}

export interface DHWCylinderPlant extends ReturnSystemBasePlant {
  returnSystemType:
    | ReturnSystemType.DHW_CYLINDER
    | ReturnSystemType.DHW_CYLINDER_W_STORAGE;
  capacityL: number | null;
  hotWaterImmersionUse: HotWaterImmersionUse;
}

export type ReturnSystemPlant =
  | ReturnSystemBasePlant
  | HeatPumpPlant
  | DHWCylinderPlant;

export type PumpConfiguration =
  | "duty"
  | "duty-standby"
  | "duty-assist"
  | "duty-assist-standby"
  | "duty-assist-assist"
  | "duty-assist-assist-assist"
  | "duty-assist-assist-standby";

export type FilterConfiguration = "duty" | "duty-standby" | "duty-duty";

// We will start these with manufacturer records for every pump.
// Unlike current entities which can have manufacturers everywhere.
export interface PumpPlant extends Plant {
  type: PlantType.PUMP;
  pressureLoss: PumpPressure;

  manufacturer: string | null;
  model: string | null;
  configuration: PumpConfiguration | null;
  targetPressureKPA: number | null;
  customManufFields?: {
    [key: string]: string | number | null;
    // variant?: "standard" | "premium";
  };
}

export function configurationToName(
  config: PumpConfiguration | FilterConfiguration,
): string {
  switch (config) {
    case "duty":
      return "Duty";
    case "duty-duty":
      return "Duty Duty";
    case "duty-standby":
      return "Duty Standby";
    case "duty-assist":
      return "Duty Assist";
    case "duty-assist-standby":
      return "Duty Assist Standby";
    case "duty-assist-assist":
      return "Duty Assist Assist";
    case "duty-assist-assist-assist":
      return "Duty Assist Assist Assist";
    case "duty-assist-assist-standby":
      return "Duty Assist Assist Standby";
  }
  assertUnreachable(config);
}

export const PUMP_CONFIGURATION_CHOICES: Choice[] = [
  { key: "duty", name: "Duty" },
  { key: "duty-standby", name: "Duty Standby" },
  { key: "duty-assist", name: "Duty Assist" },
  { key: "duty-assist-standby", name: "Duty Assist Standby" },
  { key: "duty-assist-assist", name: "Duty Assist Assist" },
  { key: "duty-assist-assist-assist", name: "Duty Assist Assist Assist" },
  { key: "duty-assist-assist-standby", name: "Duty Assist Assist Standby" },
];

export interface DrainagePit extends Plant {
  type: PlantType.DRAINAGE_PIT;
  pressureLoss: StaticPressure;
}

export type GreaseInterceptorTrapManufacturers = "generic" | "viking";

export enum GreaseInterceptorTrapLocation {
  "nsw" = "NSW",
  "act" = "ACT",
  "vic" = "VIC",
  "qld" = "QLD",
  "sa" = "SA",
  "wa" = "WA",
  "tas" = "TAS",
  "nt" = "NT",
}

export enum GreaseInterceptorTrapPosition {
  "aboveGround" = "Above Ground",
  "belowGround" = "Below Ground",
}

export interface DrainageGreaseInterceptorTrap extends Plant {
  type: PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP;
  pressureLoss: StaticPressure;
  lengthMM: number | null;
  location: keyof typeof GreaseInterceptorTrapLocation | null;
  position: keyof typeof GreaseInterceptorTrapPosition | null;
  capacity: string | null;
  brand: string | null;
}

export interface CustomPlant extends Plant {
  type: PlantType.CUSTOM;
  pressureLoss: AnyPressure;
}

export interface VolumiserPlant extends Plant {
  type: PlantType.VOLUMISER;
  volumeL: number | null;
  pressureLoss: FixedPressureLoss;
}

export type PlantConcrete =
  | ReturnSystemPlant
  | TankPlant
  | PumpTank
  | CustomPlant
  | PumpPlant
  | DrainagePit
  | DrainageGreaseInterceptorTrap
  | VolumiserPlant
  | RadiatorPlant
  | ManifoldPlant
  | UnderfloorHeatingPlant
  | AirHandlingUnitPlant
  | FanCoilUnitPlant
  | FilterPlant
  | ROPlant
  | AirHandlingUnitVentPlant
  | DuctManifoldPlant;

export type PlantManufacturers =
  | HotWaterPlantManufacturers
  | CirculatingPumpsManufacturers
  | GreaseInterceptorTrapManufacturers
  | RadiatorManufacturer;

export type HeatLoadPlant =
  | ReturnSystemPlant
  | RadiatorPlant
  | ManifoldPlant
  | UnderfloorHeatingPlant
  | AirHandlingUnitPlant
  | AirHandlingUnitVentPlant
  | FanCoilUnitPlant;

export type HeatEmittingPlant = RadiatorPlant;

export type DualSystemPlant =
  | AirHandlingUnitPlant
  | FanCoilUnitPlant
  | AirHandlingUnitVentPlant;

// Noise Report Types
export enum Directivity {
  Q2 = "Q2",
  Q4 = "Q4",
  Q8 = "Q8",
}
export enum BarrierLevel {
  Complete = "Complete",
  Part = "Part",
  None = "None",
}
export type Decibel = number;
export type NoiseReportSetting = {
  soundPowerLevelDB: Decibel | null;
  directivity: Directivity | null;
  distanceMM: number | null;
  barrier: BarrierLevel | null;
};
export type NoiseReportResult = {
  soundPowerLevelDB: Decibel | null;
  directivity: Directivity | null;
  distanceMM: number | null;
  dBReductionDistanceDB: Decibel | null;
  dBReductionBarrierDB: Decibel | null;
  soundPressureLevelDB: Decibel | null;
  backgroundNoiseLevelDB: Decibel | null;
  diffBgPumpDB: Decibel | null;
  dBCorrectedDB: Decibel | null;
  permitted: boolean | null;
};
