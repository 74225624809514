import stringify from "json-stable-stringify";
import * as _ from "lodash";
import Vue from "vue";
import { MutationTree } from "vuex";
import { StandardFlowSystemUids } from "../../../../common/src/api/config";
import {
  DrawingState,
  Level,
  Workflow,
} from "../../../../common/src/api/document/drawing";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import ConduitEntity from "../../../../common/src/api/document/entities/conduit-entity";
import { PlantType } from "../../../../common/src/api/document/entities/plants/plant-types";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { initialDrawing } from "../../../../common/src/api/document/initial-drawing";
import * as OT from "../../../../common/src/api/document/operation-transforms";
import { OPERATION_NAMES } from "../../../../common/src/api/document/operation-transforms";
import { diffState } from "../../../../common/src/api/document/state-differ";
import { updateSpatialIndex } from "../../../../common/src/lib/globalstore/utils";
import {
  assertUnreachable,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { Document } from "../../../../common/src/models/Document";
import {
  DiffRecordT,
  LevelChangeRecordT,
  ToolEventRecordT,
  ViewPortRecordT,
} from "../../../../flatbuffers/generated/replays";
import { Sentry } from "../../api/integrations/Sentry";
import DrawableObjectFactory from "../../htmlcanvas/lib/drawable-object-factory";
import { DrawingMode } from "../../htmlcanvas/types";
import { replay } from "../../lib/replays/replays";
import { getCanvasViewport } from "../../lib/replays/replays-viewport";
import {
  getAvailableFlowSystems,
  getSuitableDrawingLayout,
} from "../../lib/workflows-utils";
import { getGlobalContext, globalStore } from "../globalCoreContext";
import { MainEventBus } from "../main-event-bus";
import store from "../store";
import LiveCalculationManager from "./liveCalculationManager";
import {
  applyDiffVue,
  applyOpOntoStateVue,
} from "./operation-transforms/state-ot-apply";
import {
  DocumentState,
  EntityEvent,
  EntityParam,
  EntityParamNullable,
  ReplayState,
  blankDiffFilter,
  initialDocumentState,
} from "./types";

let liveCalculationManager: LiveCalculationManager | null;

function getLiveCalculationManager(): LiveCalculationManager {
  if (!liveCalculationManager) {
    liveCalculationManager = new LiveCalculationManager(globalStore);
  }

  return liveCalculationManager;
}

function logEntityMutation(
  state: DocumentState,
  { entityUid, levelUid }: { entityUid: string; levelUid: string | null },
) {
  if (levelUid === null) {
    Vue.set(state.diffFilter.shared, entityUid, false);
  } else {
    if (!Object.hasOwn(state.diffFilter.levels, levelUid)) {
      Vue.set(state.diffFilter.levels, levelUid, {
        /**/
      });
    }
    if (state.diffFilter.levels[levelUid] === false) {
      // that's ok, the entire level was being manipulated
    } else {
      if (!Object.hasOwn(state.diffFilter.levels[levelUid], "entities")) {
        Vue.set(state.diffFilter.levels[levelUid], "entities", {
          /**/
        });
      }
      if (state.diffFilter.levels[levelUid].entities !== false) {
        Vue.set(state.diffFilter.levels[levelUid].entities, entityUid, false);
      }
    }
  }
}

function logLevelMutation(state: DocumentState, levelUid: string) {
  if (!Object.hasOwn(state.diffFilter.levels, levelUid)) {
    Vue.set(state.diffFilter.levels, levelUid, {
      /**/
    });
  }
  Object.keys(state.drawing.levels[levelUid]).forEach((key) => {
    if (key !== "entities") {
      Vue.set(state.diffFilter.levels[levelUid], key, false);
    }
  });
}

function onAddEntity({ entity, levelUid }: EntityParam, state: DocumentState) {
  try {
    // get entity form drawable
    if (levelUid === null) {
      entity = state.drawing.shared[entity.uid];
    } else {
      entity = state.drawing.levels[levelUid].entities[entity.uid];
    }

    if (!entity || !entity.uid || !entity.type) {
      // TODO: this is a quick fix to allow broken documents to resume.
      // A common corruption pattern happens when deletions happen before a concurrent
      // modification. This leaves behind a shell object.
      console.error("Invalid entity on level", levelUid, entity);
      return;
    }

    DrawableObjectFactory.build(entity, getGlobalContext(), state, levelUid, {
      onInteractionComplete: () =>
        MainEventBus.$emit("interaction-complete", entity),
      onSelect: (e) => MainEventBus.$emit("entity-select", { entity, e }),
    });
  } catch (e) {
    MainEventBus.$emit("critical-document-error", e);
  }
}

function onDeleteEntity({ entity }: EntityParam, state: DocumentState) {
  if (state.uiState.selectedUids.includes(entity.uid)) {
    state.uiState.selectedUids.splice(
      state.uiState.selectedUids.indexOf(entity.uid),
      1,
    );
  }
  globalStore.delete(entity.uid);
}

function onAddLevel(level: Level, state: DocumentState) {
  if (!globalStore.entitiesInLevel.has(level.uid)) {
    globalStore.entitiesInLevel.set(level.uid, new Set());
  }

  for (let entity of Object.values(level.entities)) {
    entity = proxyEntity(entity, entityHandler(state, level.uid, entity.uid));
    onAddEntity({ entity, levelUid: level.uid }, state);
  }
}

function onDeleteLevel(level: Level, state: DocumentState) {
  Object.keys(level.entities).forEach((uid) => {
    if (state.uiState.selectedUids.includes(uid)) {
      state.uiState.selectedUids.splice(
        state.uiState.selectedUids.indexOf(uid),
        1,
      );
    }
  });

  globalStore.onLevelDelete(level.uid);
}

function onUpdateEntity(uid: string) {
  if (globalStore.has(uid)) {
    globalStore.onEntityChange(uid);
  }
}

function beforeEvent(event: string, args: any, state: DocumentState) {
  if (event === EntityEvent.UPDATE_ENTITY) {
    onUpdateEntity(args);
  } else if (event === EntityEvent.ADD_ENTITY) {
    onAddEntity(args, state);
  } else if (event === EntityEvent.POST_DELETE_ENTITY) {
    onDeleteEntity(args, state);
  } else if (event === EntityEvent.ADD_LEVEL) {
    onAddLevel(args, state);
  } else if (event === EntityEvent.DELETE_LEVEL) {
    onDeleteLevel(args, state);
  }
}

function onSetPreviewMode(state: DocumentState, value: boolean) {
  state.isPreview = value;
}

function onSetActiveFlowSystem(
  state: DocumentState,
  value: StandardFlowSystemUids | string,
) {
  state.activeflowSystemUid = value;
}

function deleteEntityOn(
  state: DocumentState,
  { entity, levelUid }: EntityParamNullable,
) {
  if (levelUid === null) {
    // if (entity.type !== EntityType.RISER) {
    //     throw new Error(
    //         "Deleting a non shared object from the shared level " + levelUid + " " + JSON.stringify(entity)
    //     );
    // }
    Vue.delete(state.drawing.shared, entity.uid);
    logEntityMutation(state, { entityUid: entity.uid, levelUid: null });
  } else if (entity.uid in state.drawing.levels[levelUid].entities) {
    Vue.delete(state.drawing.levels[levelUid].entities, entity.uid);
    logEntityMutation(state, { entityUid: entity.uid, levelUid: null });
  } else {
    throw new Error(
      "Deleted an entity that doesn't exist " +
        JSON.stringify(entity) +
        " on level " +
        levelUid,
    );
  }

  logEntityMutation(state, { entityUid: entity.uid, levelUid });
  beforeEvent(EntityEvent.POST_DELETE_ENTITY, { entity, levelUid }, state);
  MainEventBus.$emit(EntityEvent.POST_DELETE_ENTITY, { entity, levelUid });
}

function addEntityOn(
  state: DocumentState,
  { entity, levelUid }: EntityParamNullable,
) {
  if (levelUid === null) {
    entity = proxyEntity(entity, entityHandler(state, null, entity.uid));
    Vue.set(state.drawing.shared, entity.uid, entity);
    logEntityMutation(state, { entityUid: entity.uid, levelUid });
  } else {
    entity = proxyEntity(entity, entityHandler(state, levelUid, entity.uid));
    Vue.set(state.drawing.levels[levelUid].entities, entity.uid, entity);
    logEntityMutation(state, { entityUid: entity.uid, levelUid });
  }

  beforeEvent(EntityEvent.ADD_ENTITY, { entity, levelUid }, state);
  MainEventBus.$emit(EntityEvent.ADD_ENTITY, { entity, levelUid });
}

function changeDrawing(
  state: DocumentState,
  newDrawing: DrawingState,
  filter: any,
  redraw: boolean,
) {
  // newDrawing = cloneSimple(newDrawing);
  const reverseDiff = cloneSimple(diffState(state.drawing, newDrawing, filter));

  reverseDiff.forEach((op) => {
    switch (op.type) {
      case OPERATION_NAMES.DIFF_OPERATION:
        const changes = marshalChanges(state.drawing, newDrawing, op.diff);
        applyOpOntoStateVue(state.drawing, op);
        proxyUpFromStateDiff(state, op.diff);
        changes.forEach(([e, v]) => {
          beforeEvent(e, v, state);
        });
        changes.forEach(([e, v]) => {
          MainEventBus.$emit(e, v);
        });
        break;
      case OPERATION_NAMES.COMMITTED_OPERATION:
        MainEventBus.$emit("committed", redraw);
        break;
    }
  });

  updateSpatialIndex(globalStore);
  Vue.set(state, "diffFilter", blankDiffFilter());
}

function applyDiff(state: DocumentState, diff: any) {
  try {
    getLiveCalculationManager().commitDiff(diff, state.nextId);
    globalStore.suppressSideEffects = true;
    const prevDrawing = cloneSimple(state.drawing);
    applyDiffVue(state.drawing, diff);
    const changes = marshalChanges(prevDrawing, state.drawing, diff, true);
    proxyUpFromStateDiff(state, diff);
    changes.forEach(([e, v]) => {
      beforeEvent(e, v, state);
    });
    changes.forEach(([e, v]) => {
      MainEventBus.$emit(e, v);
    });
  } finally {
    globalStore.suppressSideEffects = false;
  }
}

export const mutations: MutationTree<DocumentState> = {
  setReplayState(state, replayState: ReplayState) {
    state.uiState.replayState = replayState;
  },

  /**
   * Here we apply an operation to the current document.
   * This includes executing the effect of the operation, and
   * @param state
   * @param operation
   */
  applyRemoteOperation(state, operation: OT.OperationTransformConcrete) {
    state.history.push(operation);

    storeReplayData: {
      if (operation.type != OT.OPERATION_NAMES.DIFF_OPERATION)
        break storeReplayData;
      const userName = replay.getUserName();
      if (!userName) break storeReplayData;
      // STORE REPLAY DATA
      const timestamp = Date.now();
      const orderIndex = (() => {
        if (operation.id == 0) return 0; // initial document state
        if (operation.id % 2 == 0) return operation.id - 1; // on new session, point backwards at the previous legitimate diff that created this state
        return operation.id; // default case
      })();
      const documentId = state.documentId;
      {
        replay.storeDiffRecord(
          new DiffRecordT(
            BigInt(timestamp),
            userName,
            documentId,
            replay.previousOrderIndex === null
              ? undefined
              : replay.previousOrderIndex,
            orderIndex,
          ),
        );
      }

      // also need to store 'spare' data to make replay reconstruction easier.
      // specifically, viewport, level focus and tool state
      viewport: {
        const viewport = getCanvasViewport();
        if (!viewport) break viewport;
        const viewportInternals = viewport.copyInternals();
        replay.storeViewPortRecord(
          new ViewPortRecordT(
            BigInt(timestamp + 1),
            userName,
            documentId,
            orderIndex,
            Math.round(viewportInternals.surfaceToWorld_a),
            Math.round(viewportInternals.surfaceToWorld_b),
            Math.round(viewportInternals.surfaceToWorld_c),
            Math.round(viewportInternals.surfaceToWorld_d),
            Math.round(viewportInternals.surfaceToWorld_e),
            Math.round(viewportInternals.surfaceToWorld_f),
            Math.round(viewportInternals.width),
            Math.round(viewportInternals.height),
            viewportInternals.screenScale,
          ),
        );
      }
      levelFocus: {
        const levelUid = state.uiState.levelUid;
        if (!levelUid) {
          break levelFocus;
        }
        replay.storeLevelChangeEventRecord(
          new LevelChangeRecordT(
            BigInt(timestamp + 1),
            userName,
            documentId,
            orderIndex,
            levelUid,
          ),
        );
      }
      {
        const toolName = state.uiState.toolHandlerName;
        replay.storeToolEventEventRecord(
          new ToolEventRecordT(
            BigInt(timestamp + 1),
            userName,
            documentId,
            orderIndex,
            toolName,
          ),
        );
      }
    }

    let newData = false;

    if (state.optimisticHistory.length) {
      // optimistic history id typically would be 1. We trust the server to give us correct incrementing ids.
      state.optimisticHistory[0].id = operation.id;

      // Stringify both objects as a cheap[shot] way of dealing with float imprecision before comparing
      if (stringify(state.optimisticHistory[0]) === stringify(operation)) {
        // All g.
        state.optimisticHistory.splice(0, 1);
        state.nextId = Math.max(state.nextId, operation.id + 1);
        if (operation.type !== OPERATION_NAMES.COMMITTED_OPERATION) {
          return;
        }
      } else {
        // Revert optimistic history (because there is conflict!) and listen to server.
        for (let i = state.optimisticHistory.length - 1; i >= 0; i--) {
          const op = state.optimisticHistory[i];
          switch (op.type) {
            case OPERATION_NAMES.DIFF_OPERATION:
              applyDiff(state, op.inverse);
              applyDiffVue(state.committedDrawing, op.inverse);
              break;
            case OPERATION_NAMES.COMMITTED_OPERATION:
              getLiveCalculationManager().commit(op.id);
              break;
            default:
              assertUnreachable(op);
          }
        }

        state.optimisticHistory.splice(0, state.optimisticHistory.length);

        newData = true;
      }
    } else {
      newData = true;
    }

    const ogFilter = cloneSimple(state.diffFilter);

    if (operation.type === OT.OPERATION_NAMES.COMMITTED_OPERATION) {
      if (state.stagedCommits.length) {
        state.undoStack.splice(0);
        state.undoIndex = 0;
      }
      while (state.stagedCommits.length) {
        const toApply = state.stagedCommits[0];
        let handled: boolean = true;
        switch (toApply.type) {
          case OT.OPERATION_NAMES.DIFF_OPERATION: {
            if (state.uiState.drawingMode !== DrawingMode.History) {
              try {
                globalStore.suppressSideEffects = true;
                applyOpOntoStateVue(state.drawing, toApply);
                getLiveCalculationManager().commitDiff(
                  toApply.diff,
                  toApply.id,
                );
                proxyUpFromStateDiff(state, toApply.diff);
                const changes = marshalChanges(
                  state.committedDrawing,
                  state.drawing,
                  toApply.diff,
                );
                applyOpOntoStateVue(
                  state.committedDrawing,
                  cloneSimple(toApply),
                );
                changes.forEach(([e, v]) => {
                  beforeEvent(e, v, state);
                });
                changes.forEach(([e, v]) => {
                  MainEventBus.$emit(e, v);
                  updateOnboardingProgress(v.entity, e, state);
                });
              } finally {
                globalStore.suppressSideEffects = false;
              }
            } else {
              applyOpOntoStateVue(state.committedDrawing, cloneSimple(toApply));
            }
            break;
          }

          default:
            handled = false;
        }

        if (handled) {
          state.history.push(toApply);
          state.nextId = Math.max(state.nextId, toApply.id + 1);
          state.stagedCommits.splice(0, 1);
        } else {
          throw new Error("Invalid operation: " + JSON.stringify(toApply));
        }
      }
    } else {
      state.stagedCommits.push(operation);
    }

    Vue.set(state, "diffFilter", ogFilter);

    if (newData) {
      MainEventBus.$emit("committed", true);
    } // else, the data is already represented on screen

    state.receivedRemoteOperation = true;
  },

  applyDiff,

  swapDrawing(state: DocumentState, newState: DrawingState) {
    changeDrawing(state, newState, undefined, true);
    updateSpatialIndex(globalStore);
    getLiveCalculationManager().replaceDrawing(state.drawing, state.nextId - 1);
  },

  revert(state, redraw) {
    if (state.uiState.drawingMode === DrawingMode.History) {
      return;
    }
    changeDrawing(state, state.committedDrawing, state.diffFilter, redraw);
    getLiveCalculationManager().revert(state.nextId - 1);
  },

  revertFull(state) {
    changeDrawing(state, state.committedDrawing, undefined, true);
    getLiveCalculationManager().replaceDrawing(state.drawing, state.nextId - 1);
  },

  resetDrawing(state) {
    changeDrawing(state, initialDrawing(state.locale), undefined, true);
    console.log(
      "catalog after reset",
      JSON.stringify(state.drawing.metadata.catalog.pipes, null, 2),
      "supposed to be",
      initialDrawing(state.locale).metadata.catalog.pipes,
    );
    getLiveCalculationManager().replaceDrawing(state.drawing, state.nextId - 1);
  },

  closeAndReset(state) {
    console.log("closing and resetting");
    Object.assign(state, cloneSimple(initialDocumentState));
    globalStore.clear();
    getLiveCalculationManager().closeDocument();
  },

  resetPastes(state) {
    state.uiState.pastesByLevel = {};
  },

  addEntityOn,

  addEntity(state, entity: DrawableEntityConcrete) {
    if (entity.type === EntityType.RISER) {
      addEntityOn(state, { entity, levelUid: null });
    } else {
      addEntityOn(state, { entity, levelUid: state.uiState.levelUid! });
    }
  },

  setPreviewMode(state, value: boolean) {
    onSetPreviewMode(state, value);
  },
  setActiveFlowSystem(state, value: StandardFlowSystemUids | string) {
    onSetActiveFlowSystem(state, value);
  },
  setId(state, id: number) {
    replay.previousOrderIndex = null; // document has changed, reset diff state on replay system
    state.documentId = id;
    Sentry.updateDocument();
  },

  setDocumentRecord(state, record: Document) {
    Vue.set(state, "documentRecord", record);
    Sentry.updateDocument();
  },

  deleteEntity(state, entity) {
    if (entity.type === EntityType.RISER) {
      deleteEntityOn(state, { entity, levelUid: null });
    } else {
      deleteEntityOn(state, { entity, levelUid: state.uiState.levelUid! });
    }
  },

  deleteEntityOn,

  addLevel(state, level: Level) {
    level = proxyLevel(level, state, level.uid);
    Object.keys(level.entities).forEach((key) => {
      proxyEntity(level.entities[key], entityHandler(state, level.uid, key));
    });
    Vue.set(state.drawing.levels, level.uid, level);
    logLevelMutation(state, level.uid);
    beforeEvent(EntityEvent.ADD_LEVEL, level, state);
    MainEventBus.$emit(EntityEvent.ADD_LEVEL, level);
  },

  deleteLevel(state, level: Level) {
    if (level.uid in state.drawing.levels) {
      logLevelMutation(state, level.uid);
      Vue.delete(state.drawing.levels, level.uid);
    } else {
      throw new Error(
        "Deleted a level that doesn't exist " + JSON.stringify(level),
      );
    }
    beforeEvent(EntityEvent.DELETE_LEVEL, level, state);
    MainEventBus.$emit(EntityEvent.DELETE_LEVEL, level);
    if (level.uid === state.uiState.levelUid) {
      state.uiState.levelUid = null;
      MainEventBus.$emit("current-level-changed");
    }
  },

  setCurrentLevelUid(state, levelUid) {
    const userName = replay.getUserName();
    if (replay.previousOrderIndex != null && userName && levelUid) {
      replay.storeLevelChangeEventRecord(
        new LevelChangeRecordT(
          BigInt(Date.now()),
          userName,
          state.documentId,
          replay.previousOrderIndex,
          levelUid,
        ),
      );
    }
    if (!state.uiState) {
      return;
    }

    state.uiState.levelUid = levelUid;
    const targetLevelProperty =
      state.uiState.warningFilter.collapsedLevelType.find(
        (e) => e.levelUid === levelUid,
      );
    if (targetLevelProperty) {
      targetLevelProperty.visible = true;
    } else {
      state.uiState.warningFilter.collapsedLevelType.push({
        levelUid,
        visible: true,
        types: [],
      });
    }
    MainEventBus.$emit("current-level-changed");
  },

  updateHelpHub() {
    // @ts-ignore
    if (!window.CommandBar) return;

    // @ts-ignore
    const helpHubSymbol = Object.getOwnPropertySymbols(window.CommandBar).find(
      (symbol) => symbol.toString() === "Symbol(CommandBar::reloadHelpHub)",
    );
    if (helpHubSymbol) {
      console.log("updating help hub");
      setTimeout(() => {
        // @ts-ignore
        window.CommandBar[helpHubSymbol]();
      }, 500);
    }
  },

  // todo: this is unnecessary, just make globalstore available everywhere and update it directly.
  updatePipeEndpoints(
    state,
    {
      entity,
      endpoints,
    }: { entity: ConduitEntity; endpoints: [string, string] },
  ) {
    entity.endpointUid[0] = endpoints[0];
    entity.endpointUid[1] = endpoints[1];
    MainEventBus.$emit("update-pipe-endpoints", { entity, endpoints });
  },

  setShareToken(state, token: string) {
    state.shareToken = token;
  },

  documentLoaded(state) {
    getLiveCalculationManager().openDocument(
      state.nextId - 1,
      state.locale,
      JSON.parse(JSON.stringify(state.drawing)),
      store.getters["catalog/default"],
      // hate this dependency on another module, but perhaps
      // it shouldn't be a separate module.
      store.getters["customEntity/nodes"],
      store.getters["profile/featureAccess"],
    );
    state.documentLoaded = true;
  },

  liveCalcsCommit(state) {
    // we need to submit the live changes because they are only sent during render, so it
    // is possible that the user has made changes to the drawing since the last render.
    requestLiveCalcs(state, true);
  },

  replaceCatalog(state) {
    getLiveCalculationManager().replaceCatalog(
      store.getters["catalog/default"],
      state.nextId - 1,
    );
  },

  incrementLiveCalculationRenderCounter(state) {
    state.liveCalculationRenderCounter++;
  },

  setWorkflowEnabled(
    state,
    { workflowKey, enabled }: { workflowKey: Workflow; enabled: boolean },
  ) {
    state.drawing.metadata.workflows[workflowKey].enabled = enabled;

    // update drawing layout if required
    const suitableDrawingLayout = getSuitableDrawingLayout(
      state.drawing.metadata.workflows,
    );
    if (suitableDrawingLayout) {
      state.uiState.drawingLayout = suitableDrawingLayout;
    }
  },

  validateActiveFlowsystem(state: DocumentState) {
    const availableSystems = getAvailableFlowSystems(state);
    if (!availableSystems.length) {
      return;
    }

    if (!availableSystems.find((s) => s.uid === state.activeflowSystemUid)) {
      state.activeflowSystemUid = availableSystems[0].uid;
    }
  },

  requestLiveCalcs,
};

function requestLiveCalcs(state: DocumentState, commit: boolean = false) {
  const diff = diffState(
    state.committedDrawing,
    state.drawing,
    state.diffFilter,
  );
  for (const op of diff) {
    switch (op.type) {
      case OPERATION_NAMES.DIFF_OPERATION: {
        if (commit) {
          getLiveCalculationManager().commitDiff(op.diff, state.nextId);
        } else {
          getLiveCalculationManager().stageDiff(op, state.nextId);
        }
        break;
      }
      case OPERATION_NAMES.COMMITTED_OPERATION: {
        break;
      }
      default:
        assertUnreachable(op);
    }
  }
}

function entityHandler(
  state: DocumentState,
  levelUid: string | null,
  entityUid: string,
) {
  const handler = {
    get(target: any, key: string): any {
      if (key === "__custom_proxy__") {
        return true;
      }
      return target[key];
    },
    set(target: any, key: string, value: any) {
      if (key === "__proto__") {
        Object.setPrototypeOf(target, value);
        return true;
      }
      if (value !== target[key]) {
        if (key === "__proto__") {
          // Vue likes to assign __proto__ to arrays so it can observe the
          // push, pop etc methods. However this has caused the single `arrayMethods`
          // object they provide to be mutated after we wrap the array in a proxy.
          // I still don't understand why this is happening, but avoiding __proto__
          // and using Object.setPrototypeOf seems to fix it.
          Object.setPrototypeOf(target, value);
          return true;
        }
        logEntityMutation(state, { entityUid, levelUid });

        if (_.isObject(value as any) && value.__custom_proxy__ !== true) {
          // Reflect.set(target, key, proxyEntity(value, handler));
          target[key] = proxyEntity(value, handler);
        } else {
          // Reflect.set(target, key, value);
          target[key] = value;
        }

        if (!globalStore.suppressSideEffects) {
          beforeEvent(EntityEvent.UPDATE_ENTITY, entityUid, state);
          MainEventBus.$emit(EntityEvent.UPDATE_ENTITY, entityUid);
        }
      }
      return true;
    },
  };

  return handler;
}

function levelHandler(state: DocumentState, levelUid: string) {
  return {
    get(target: any, key: string): any {
      if (key === "__custom_proxy__") {
        return true;
      }
      return target[key];
    },
    set(target: any, key: string, value: any) {
      logLevelMutation(state, levelUid);
      target[key] = value;
      return true;
    },
  };
}

function proxyEntity<T>(obj: T, handler: ProxyHandler<any>): T {
  if (_.isObject(obj)) {
    if ((obj as any).__custom_proxy__) {
      return obj;
    }
    Object.keys(obj).forEach((k) => {
      Reflect.set(obj, k, proxyEntity((obj as any)[k], handler));
    });
    return new Proxy(obj, handler);
  } else {
    return obj;
  }
}

function proxyLevel(lvl: Level, state: DocumentState, levelUid: string) {
  if ((lvl as any).__custom_proxy__) {
    return lvl;
  } else {
    return new Proxy(lvl, levelHandler(state, levelUid));
  }
}

function proxyUpFromStateDiff(state: DocumentState, diff: any) {
  if (diff && diff.shared && state.drawing.shared) {
    Object.keys(diff.shared).forEach((uid) => {
      if (Object.hasOwn(state.drawing.shared, uid)) {
        state.drawing.shared[uid] = proxyEntity(
          state.drawing.shared[uid],
          entityHandler(state, null, uid),
        );
      }
      logEntityMutation(state, { entityUid: uid, levelUid: null });
    });
  }

  if (diff && diff.levels && state.drawing.levels) {
    Object.keys(diff.levels).forEach((lvlUid) => {
      if (
        state.drawing.levels[lvlUid] &&
        state.drawing.levels[lvlUid].entities
      ) {
        if (diff.levels[lvlUid] && diff.levels[lvlUid].entities) {
          Object.keys(diff.levels[lvlUid].entities).forEach((uid) => {
            if (Object.hasOwn(state.drawing.levels[lvlUid].entities, uid)) {
              state.drawing.levels[lvlUid].entities[uid] = proxyEntity(
                state.drawing.levels[lvlUid].entities[uid],
                entityHandler(state, lvlUid, uid),
              );
            }

            logEntityMutation(state, { entityUid: uid, levelUid: lvlUid });
          });
        }
        state.drawing.levels[lvlUid] = proxyLevel(
          state.drawing.levels[lvlUid],
          state,
          lvlUid,
        );
      }
    });
  }
}

// Call this before destroying the current state to figure out what we need to alert changes for.
export function marshalChanges(
  from: DrawingState,
  to: DrawingState,
  diff: any,
  fuzzy: boolean = false,
): Array<[EntityEvent, any]> {
  const res: Array<[EntityEvent, any]> = [];
  if (diff && diff.shared && from.shared) {
    Object.keys(diff.shared).forEach((uid) => {
      if (Object.hasOwn(to.shared, uid) && Object.hasOwn(from.shared, uid)) {
        res.push([EntityEvent.UPDATE_ENTITY, uid]);
      } else if (Object.hasOwn(from.shared, uid)) {
        res.push([
          EntityEvent.POST_DELETE_ENTITY,
          { entity: from.shared[uid], levelUid: null },
        ]);
      } else if (Object.hasOwn(to.shared, uid)) {
        res.push([
          EntityEvent.ADD_ENTITY,
          { entity: diff.shared[uid], levelUid: null },
        ]);
      } else {
        if (!fuzzy) {
          throw new Error(
            "invalid diff state - diffing something that no sides have",
          );
        }
      }
    });
  }

  if (diff && diff.levels && to.levels) {
    Object.keys(diff.levels).forEach((lvlUid) => {
      if (
        Object.hasOwn(from.levels, lvlUid) &&
        Object.hasOwn(to.levels, lvlUid)
      ) {
        // Diff elements here
        if (diff.levels[lvlUid].entities) {
          Object.keys(diff.levels[lvlUid].entities).forEach((uid) => {
            if (
              Object.hasOwn(to.levels[lvlUid].entities, uid) &&
              Object.hasOwn(from.levels[lvlUid].entities, uid)
            ) {
              res.push([EntityEvent.UPDATE_ENTITY, uid]);
            } else if (Object.hasOwn(from.levels[lvlUid].entities, uid)) {
              res.push([
                EntityEvent.POST_DELETE_ENTITY,
                { entity: from.levels[lvlUid].entities[uid], levelUid: lvlUid },
              ]);
            } else if (Object.hasOwn(to.levels[lvlUid].entities, uid)) {
              res.push([
                EntityEvent.ADD_ENTITY,
                { entity: diff.levels[lvlUid].entities[uid], levelUid: lvlUid },
              ]);
            } else {
              if (!fuzzy) {
                throw new Error(
                  "invalid diff state - diffing something that no sides have",
                );
              }
            }
          });
        }
      } else if (Object.hasOwn(from.levels, lvlUid)) {
        res.push([EntityEvent.DELETE_LEVEL, from.levels[lvlUid]]);
      } else if (Object.hasOwn(to.levels, lvlUid)) {
        res.push([EntityEvent.ADD_LEVEL, diff.levels[lvlUid]]);
      } else {
        if (!fuzzy) {
          throw new Error(
            "invalid diff state - diffing a level that doesn't exist on any",
          );
        }
      }
    });
  }

  // Delete entities first so not to trigger hydraulic layer's sorting edge case crash with missing
  // entities in uid list
  return res.sort(
    (a, b) =>
      (a[0] !== EntityEvent.POST_DELETE_ENTITY ? 1 : 0) -
      (b[0] !== EntityEvent.POST_DELETE_ENTITY ? 1 : 0),
  );
}

// Scan through the new entities and update OnboardProgress accordingly
export function updateOnboardingProgress(
  entity: DrawableEntityConcrete,
  event: EntityEvent,
  state: DocumentState,
) {
  if (event === EntityEvent.ADD_LEVEL) {
    state.uiState.onboardingProgress.addNewLevel = true;
  }

  if (!entity) {
    return;
  }

  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
      state.uiState.onboardingProgress.uploadPDF = true;
      break;

    case EntityType.CONDUIT:
      break;

    case EntityType.LOAD_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.FIXTURE:
      break;

    case EntityType.PLANT:
      switch (entity.plant.type) {
        case PlantType.RETURN_SYSTEM:
          if (entity.plant.returnType === "heatSource") {
            state.uiState.onboardingProgress.insertHeatSource = true;
          }
          break;
        case PlantType.RADIATOR:
        case PlantType.TANK:
        case PlantType.CUSTOM:
        case PlantType.DRAINAGE_PIT:
        case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
        case PlantType.PUMP:
        case PlantType.PUMP_TANK:
        case PlantType.VOLUMISER:
        case PlantType.AHU:
        case PlantType.AHU_VENT:
        case PlantType.FCU:
        case PlantType.MANIFOLD:
        case PlantType.UFH:
        case PlantType.FILTER:
        case PlantType.RO:
        case PlantType.DUCT_MANIFOLD:
          break;
        default:
          assertUnreachable(entity.plant);
      }
      break;

    case EntityType.BIG_VALVE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
      break;

    case EntityType.RISER:
    case EntityType.SYSTEM_NODE:
    case EntityType.FITTING:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      break;

    default:
      assertUnreachable(entity, false);
  }
}
