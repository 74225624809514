import axios from "axios";
import { APIResult } from "../../../common/src/api/document/types";
import {
  ProjectResult,
  RegionOption,
  SpecificationResult,
  SummaryResult,
} from "../../../common/src/api/manufacturers/dashboard";
import { PartAssociation } from "../../../common/src/models/manufacturers/PartAssociation";
import { PartCompetitor } from "../../../common/src/models/manufacturers/PartCompetitor";

import { Specification } from "../../../common/src/models/manufacturers/Specification";
import { getFailureFromAxiosRequestErrorEvent } from "../lib/axios-utils";

export interface DashboardParams {
  // TODO: confirm (why it's not camelCase)
  time_from?: string;
  time_to?: string;
  organizationId?: string;
  partType?: string[];
}
export interface DashboardSummaryParams extends DashboardParams {
  // TODO: confirm
  locale?: string[];
  organizationId?: string;
  partType?: string[];
  selectedRegions?: string[];
}

export interface DashboardProjectParams {
  count?: number;
  partType?: string[];
  locale?: string[];
  cursor?: string | null;
  organizationId?: string;
}

export async function getManufacturerDashboardProjects(
  params: DashboardProjectParams,
): Promise<APIResult<ProjectResult>> {
  try {
    return (await axios.get("/api/manufacturer/dashboard/projects", { params }))
      .data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export interface DashboardProjectParams2 extends DashboardProjectParams {
  createdAfterDate?: string;
}

export async function getManufacturerDashboardProjects2(
  params: DashboardProjectParams2,
): Promise<APIResult<ProjectResult>> {
  try {
    return (
      await axios.get("/api/manufacturer/dashboard/projects2", { params })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getManufacturerDashboardRegionOptions(): Promise<
  APIResult<RegionOption[]>
> {
  try {
    return (await axios.get("/api/manufacturer/dashboard/regionoptions")).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getManufacturerDashboardSummary(
  params: DashboardSummaryParams,
): Promise<APIResult<SummaryResult>> {
  try {
    return (await axios.get("/api/manufacturer/dashboard/summary", { params }))
      .data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getManufacturerDashboardSpecifications(
  params: DashboardParams,
): Promise<APIResult<SpecificationResult>> {
  try {
    return (
      await axios.get(`/api/manufacturer/dashboard/specifications`, {
        params,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getManufacturerSpecifications(params: {
  partType?: string;
  manufacturerUid?: string;
  documentId?: number;
}): Promise<APIResult<Specification[]>> {
  try {
    return (await axios.get("/api/manufacturer/specifications", { params }))
      .data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getAllPartAssociations(): Promise<
  APIResult<PartAssociation[]>
> {
  try {
    return (await axios.get("/api/manufacturer/partAssociations")).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getPartAssociationsByOrganization(
  organizationId: string,
): Promise<APIResult<PartAssociation[]>> {
  try {
    return (
      await axios.get(
        "/api/manufacturer/partAssociations/organization/" + organizationId,
      )
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getPartAssociationsByPartType(params: {
  partType: string;
  manufacturerUid?: string;
}): Promise<APIResult<PartAssociation[]>> {
  try {
    return (
      await axios.get(
        "/api/manufacturer/partAssociations/" +
          encodeURIComponent(params.partType) +
          (params.manufacturerUid
            ? "/" + encodeURIComponent(params.manufacturerUid)
            : ""),
      )
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getInMarketPartTypes(options?: {
  organizationId?: string;
}): Promise<APIResult<string[]>> {
  try {
    return (
      await axios.get("/api/manufacturer/dashboard/inMarketPartTypes", {
        params: options,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function createPartAssociation(
  partAssociation: Omit<
    PartAssociation,
    "updatedOn" | "updatedBy" | "organization"
  >,
): Promise<APIResult<PartAssociation>> {
  try {
    return (
      await axios.post(
        "/api/manufacturer/partAssociations/" +
          encodeURIComponent(partAssociation.partType) +
          "/" +
          encodeURIComponent(partAssociation.manufacturerUid),
        partAssociation,
      )
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function updatePartAssociation(
  partAssociation: PartAssociation,
): Promise<APIResult<PartAssociation[]>> {
  try {
    return (
      await axios.put(
        "/api/manufacturer/partAssociations/" +
          encodeURIComponent(partAssociation.partType) +
          "/" +
          encodeURIComponent(partAssociation.manufacturerUid),
        partAssociation,
      )
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function deletePartAssociation(params: {
  partType: string;
  manufacturerUid: string;
}): Promise<APIResult<PartAssociation[]>> {
  try {
    return (
      await axios.delete(
        "/api/manufacturer/partAssociations/" +
          encodeURIComponent(params.partType) +
          "/" +
          encodeURIComponent(params.manufacturerUid!),
      )
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getPartCompetitorByOrganization(
  organizationId: String,
): Promise<APIResult<PartCompetitor[]>> {
  try {
    return (
      await axios.get("/api/manufacturer/partCompetitor/" + organizationId)
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function updatePartCompetitors(
  organizationId: String,
  partCompetitors: String[],
): Promise<APIResult<PartCompetitor>> {
  try {
    return (
      await axios.put("/api/manufacturer/partCompetitor", {
        organizationId,
        partCompetitors,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}
