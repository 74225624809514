import { GetterTree } from "vuex";
import { RootState } from "../types";
import FeatureFlagState, { FrontendFeatureFlag } from "./types";

// Before adding a getter to return the full state of all feature flags consider whether it's actually useful.
// It might be (for something like calc engine)
// But remember, as soon as it leaves this getter, its no longer reactive, so be careful.
export const getters: GetterTree<FeatureFlagState, RootState> = {
  isFeatureFlagEnabled(state) {
    return (flag: FrontendFeatureFlag) => state.featureFlags[flag];
  },

  isFeatureFlagDisabled(state) {
    return (flag: FrontendFeatureFlag) => !state.featureFlags[flag];
  },

  getFeatureFlagValue(state) {
    return (flag: FrontendFeatureFlag) => state.featureFlags[flag];
  },

  all(state) {
    return state.featureFlags;
  },
};
