import {
  Units,
  UnitsContext,
  chooseByUnitsMetric,
  convertMeasurementSystem,
} from "../../lib/measurements";
import {
  DualSystemMechanicalNodeProps,
  FireNodeProps,
  MechanicalNodeProps,
} from "../../models/CustomEntity";
import {
  AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,
  AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
} from "./consts";
import { UnitsParameters } from "./drawing";
import { isDualSystemNode } from "./entities/plants/utils";

export function INITIAL_VENT_AHU_NODES(
  unitsParams: UnitsParameters,
): DualSystemMechanicalNodeProps[] {
  return [
    {
      name: "AHU (Indoor)",
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 600,
          [Units.Inches]: 24,
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      hasIntakeIO: true,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      extractHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heatingRatingKw: 0,
      heatingPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      heatingHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heatingCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],

      chilledRatingKw: 0,
      chilledPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      chilledHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      chilledCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "air-handling-vent",
    },
    {
      name: "AHU (Outdoor)",
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 600,
          [Units.Inches]: 24,
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      hasIntakeIO: false,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      extractHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      heatingRatingKw: 0,
      heatingPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      heatingHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heatingCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],

      chilledRatingKw: 0,
      chilledPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      chilledHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      chilledCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "air-handling-vent",
    },
    {
      name: "AHU (Indoor) w/ H/C",
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 600,
          [Units.Inches]: 24,
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      hasIntakeIO: true,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      extractHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      heatingRatingKw: 0,
      heatingPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      heatingHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heatingCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],

      chilledRatingKw: 0,
      chilledPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      chilledHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      chilledCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "air-handling-vent",
    },
    {
      name: "AHU (Outdoor) w/ H/C",
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 600,
          [Units.Inches]: 24,
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      hasIntakeIO: false,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      extractHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      heatingRatingKw: 0,
      heatingPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      heatingHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heatingCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],

      chilledRatingKw: 0,
      chilledPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      chilledHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      chilledCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "air-handling-vent",
    },
    {
      name: "AHU (MVHR)",
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 600,
          [Units.Inches]: 24,
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      hasIntakeIO: true,
      hasExhaustIO: true,
      hasExtractIO: true,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      extractHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      heatingRatingKw: 0,
      heatingPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      heatingHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heatingCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],

      chilledRatingKw: 0,
      chilledPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      chilledHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      chilledCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "air-handling-vent",
    },
    {
      name: "AHU (MVHR) w/ H/C",
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 600,
          [Units.Inches]: 24,
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      hasIntakeIO: true,
      hasExhaustIO: true,
      hasExtractIO: true,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      extractHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],

      heatingRatingKw: 0,
      heatingPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      heatingHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      heatingCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],

      chilledRatingKw: 0,
      chilledPressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      chilledHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      chilledCapacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "air-handling-vent",
    },
  ];
}

export function getInitialMechanicalNodeInOrder(
  unitsParams: UnitsParameters,
): MechanicalNodeProps[] {
  const nodes: MechanicalNodeProps[] = [
    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 0.25,
          [Units.Watts]: 250, // n/a
          [Units.BtuPerHour]: 850,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 5,
          [Units.Psi]: 0.73,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 700,
          [Units.Inches]: 28,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 0.5,
          [Units.Watts]: 500, // n/a
          [Units.BtuPerHour]: 1700,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 5,
          [Units.Psi]: 0.73,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 850,
          [Units.Inches]: 34,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 0.75,
          [Units.Watts]: 750, // n/a
          [Units.BtuPerHour]: 2500,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 1,
          [Units.Watts]: 1000, // n/a
          [Units.BtuPerHour]: 3400,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 6,
          [Units.Psi]: 0.87,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 1.5,
          [Units.Watts]: 1500, // n/a
          [Units.BtuPerHour]: 5100,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 8,
          [Units.Psi]: 1.16,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1250,
          [Units.Inches]: 50,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },

    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 2,
          [Units.Watts]: 2000, // n/a
          [Units.BtuPerHour]: 6800,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 10,
          [Units.Psi]: 1.45,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1500,
          [Units.Inches]: 60,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 2.5,
          [Units.Watts]: 2500, // n/a
          [Units.BtuPerHour]: 8500,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 12,
          [Units.Psi]: 1.74,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1750,
          [Units.Inches]: 70,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 3,
          [Units.Watts]: 3000, // n/a
          [Units.BtuPerHour]: 10500,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 15,
          [Units.Psi]: 2.18,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1850,
          [Units.Inches]: 74,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloWatts]: 3.5,
          [Units.Watts]: 3500, // n/a
          [Units.BtuPerHour]: 12000,
          [Units.MegajoulesPerHour]: 0, // n/A
        },
        UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      )[1],
      pressureDropKPA: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.KiloPascals]: 15,
          [Units.Psi]: 2.18,
          [Units.Pascals]: 5000, // n/a
          [Units.Bar]: 0.05, // n/a
          [Units.Mbar]: 50, // n/a
          [Units.GasKiloPascals]: 5, // n/a
        },
        UnitsContext.NONE,
      )[1],
      widthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 2000,
          [Units.Inches]: 80,
        },
        UnitsContext.NONE,
      )[1],
      depthMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 150,
          [Units.Inches]: 6,
        },
        UnitsContext.NONE,
      )[1],
      heightMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 1000,
          [Units.Inches]: 40,
        },
        UnitsContext.NONE,
      )[1],
      inletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      outletHeightAboveFloorMM: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.Millimeters]: 10,
          [Units.Inches]: 0.4,
        },
        UnitsContext.NONE,
      )[1],
      capacityRateLKW: chooseByUnitsMetric(
        unitsParams,
        {
          [Units.LitersPerKiloWatts]: 11,
          [Units.USGallonsPerKiloWatts]: 3,
          [Units.GallonsPerKiloWatts]: 2.4,
        },
        UnitsContext.NONE,
      )[1],
      type: "radiator",
    },
    ...INITIAL_VENT_AHU_NODES(unitsParams),
  ];

  nodes.sort((a, b) => {
    const aRating = isDualSystemNode(a) ? a.heatingRatingKw : a.ratingKW;
    const bRating = isDualSystemNode(b) ? b.heatingRatingKw : b.ratingKW;

    return `${a.name} ${aRating}`.localeCompare(`${b.name} ${bRating}`);
  });
  return nodes;
}

export function getInitialFireNodes(
  unitsParams: UnitsParameters,
): FireNodeProps[] {
  return [
    {
      name: "Fire Hydrant",
      customEntityId: "Fire Hydrant",

      subGroups: [
        {
          name: (() => {
            const [u, v] = chooseByUnitsMetric(
              unitsParams,
              {
                [Units.Liters]: 5,
                [Units.USGallons]: 1.5,
                [Units.Gallons]: 1.2,
              },
              UnitsContext.NONE,
            );
            const [uu, vv] = convertMeasurementSystem(
              unitsParams,
              u,
              v,
              undefined,
              UnitsContext.NONE,
            );
            return `${vv} ${uu}`;
          })(),
          subGroupId: "fire_hydrant_default_5",
          maxPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 1300,
              [Units.Psi]: 190,
              [Units.Pascals]: 1300000,
              [Units.Bar]: 13,
              [Units.Mbar]: 1300,
              [Units.GasKiloPascals]: 1300,
            },
            UnitsContext.NONE,
          )[1],
          minPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 200,
              [Units.Psi]: 29,
              [Units.Pascals]: 20000,
              [Units.Bar]: 0.2,
              [Units.Mbar]: 200,
              [Units.GasKiloPascals]: 200,
            },
            UnitsContext.NONE,
          )[1],
          continuousFlowRateLS: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.LitersPerSecond]: 5,
              [Units.USGallonsPerMinute]: 80,
              [Units.GallonsPerMinute]: 1.5, // n/a
              [Units.CubicFeetPerMinute]: 0.02, // n/a
              [Units.CubicFeetPerHour]: 300, // n/a
              [Units.MetersCubedPerHour]: 0.0003, // n/a
              [Units.LitersPerMinute]: 300, // n/a
            },
            UnitsContext.NONE,
          )[1],
          nodeGroupHex: "#FF5733",
          maxiumumSimutaneousNode: 2,
          kvValue: 0.3,
        },
        {
          name: (() => {
            const [u, v] = chooseByUnitsMetric(
              unitsParams,
              {
                [Units.Liters]: 10,
                [Units.USGallons]: 3,
                [Units.Gallons]: 2.4,
              },
              UnitsContext.NONE,
            );
            const [uu, vv] = convertMeasurementSystem(
              unitsParams,
              u,
              v,
              undefined,
              UnitsContext.NONE,
            );
            return `${vv} ${uu}`;
          })(),
          subGroupId: "fire_hydrant_default_10",
          maxPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 1300,
              [Units.Psi]: 190,
              [Units.Pascals]: 1300000,
              [Units.Bar]: 13,
              [Units.Mbar]: 1300,
              [Units.GasKiloPascals]: 1300,
            },
            UnitsContext.NONE,
          )[1],
          minPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 200,
              [Units.Psi]: 29,
              [Units.Pascals]: 20000,
              [Units.Bar]: 0.2,
              [Units.Mbar]: 200,
              [Units.GasKiloPascals]: 200,
            },
            UnitsContext.NONE,
          )[1],
          continuousFlowRateLS: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.LitersPerSecond]: 10,
              [Units.USGallonsPerMinute]: 160,
              [Units.GallonsPerMinute]: 1.5, // n/a
              [Units.CubicFeetPerMinute]: 0.02, // n/a
              [Units.CubicFeetPerHour]: 300, // n/a
              [Units.MetersCubedPerHour]: 0.0003, // n/a
              [Units.LitersPerMinute]: 300, // n/a
            },
            UnitsContext.NONE,
          )[1],
          nodeGroupHex: "#DDF611",
          maxiumumSimutaneousNode: 2,
          kvValue: 0.3,
        },
      ],
    },
    {
      name: "Fire Hose Reel",
      customEntityId: "Fire Hose Reel",
      subGroups: [
        {
          name: "Default",
          subGroupId: "fire_hose_reel_default",
          maxPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 1000,
              [Units.Psi]: 145,
              [Units.Pascals]: 1000000,
              [Units.Bar]: 10,
              [Units.Mbar]: 1000,
              [Units.GasKiloPascals]: 1000,
            },
            UnitsContext.NONE,
          )[1],
          minPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 250,
              [Units.Psi]: 36,
              [Units.Pascals]: 250000,
              [Units.Bar]: 2.5,
              [Units.Mbar]: 250,
              [Units.GasKiloPascals]: 250,
            },
            UnitsContext.NONE,
          )[1],
          continuousFlowRateLS: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.LitersPerSecond]: 0.33,
              [Units.USGallonsPerMinute]: 5.5,
              [Units.GallonsPerMinute]: 1.5, // n/a
              [Units.CubicFeetPerMinute]: 0.02, // n/a
              [Units.CubicFeetPerHour]: 300, // n/a
              [Units.MetersCubedPerHour]: 0.0003, // n/a
              [Units.LitersPerMinute]: 300, // n/a
            },
            UnitsContext.NONE,
          )[1],
          nodeGroupHex: "#DAF7A6",
          maxiumumSimutaneousNode: 2,
          kvValue: 0.3,
        },
      ],
    },
    {
      name: "Safety Shower",
      customEntityId: "Safety Shower",
      subGroups: [
        {
          name: "Default",
          subGroupId: "safety_shower_default",
          maxPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 500,
              [Units.Psi]: 73,
              [Units.Pascals]: 500000,
              [Units.Bar]: 5,
              [Units.Mbar]: 500,
              [Units.GasKiloPascals]: 500,
            },
            UnitsContext.NONE,
          )[1],
          minPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 210,
              [Units.Psi]: 31,
              [Units.Pascals]: 210000,
              [Units.Bar]: 2.1,
              [Units.Mbar]: 210,
              [Units.GasKiloPascals]: 210,
            },
            UnitsContext.NONE,
          )[1],
          continuousFlowRateLS: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.LitersPerSecond]: 1.25,
              [Units.USGallonsPerMinute]: 20,
              [Units.GallonsPerMinute]: 1.5, // n/a
              [Units.CubicFeetPerMinute]: 0.02, // n/a
              [Units.CubicFeetPerHour]: 300, // n/a
              [Units.MetersCubedPerHour]: 0.0003, // n/a
              [Units.LitersPerMinute]: 300, // n/a
            },
            UnitsContext.NONE,
          )[1],
          nodeGroupHex: "#FFC300",
          maxiumumSimutaneousNode: 2,
          kvValue: 0.3,
        },
      ],
    },
    {
      name: "Fire Sprinkler",
      customEntityId: "Fire Sprinkler",
      subGroups: [
        {
          name: "Light Hazard",
          subGroupId: "fire_sprinkler_light_hazard_default",
          maxPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 1200,
              [Units.Psi]: 175,
              [Units.Pascals]: 1200000,
              [Units.Bar]: 12,
              [Units.Mbar]: 1200,
              [Units.GasKiloPascals]: 1200,
            },
            UnitsContext.NONE,
          )[1],
          minPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 70,
              [Units.Psi]: 10,
              [Units.Pascals]: 70000,
              [Units.Bar]: 0.7,
              [Units.Mbar]: 700,
              [Units.GasKiloPascals]: 700,
            },
            UnitsContext.NONE,
          )[1],
          continuousFlowRateLS: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.LitersPerSecond]: 0.8,
              [Units.USGallonsPerMinute]: 12.5,
              [Units.GallonsPerMinute]: 1.5, // n/a
              [Units.CubicFeetPerMinute]: 0.02, // n/a
              [Units.CubicFeetPerHour]: 300, // n/a
              [Units.MetersCubedPerHour]: 0.0003, // n/a
              [Units.LitersPerMinute]: 300, // n/a
            },
            UnitsContext.NONE,
          )[1],
          nodeGroupHex: "#C70039",
          maxiumumSimutaneousNode: 6,
          kvValue: 0.3,
        },
        {
          name: "Medium Hazard",
          subGroupId: "fire_sprinkler_medium_hazard_default",
          maxPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 1200,
              [Units.Psi]: 175,
              [Units.Pascals]: 1200000,
              [Units.Bar]: 12,
              [Units.Mbar]: 1200,
              [Units.GasKiloPascals]: 1200,
            },
            UnitsContext.NONE,
          )[1],
          minPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 70,
              [Units.Psi]: 10,
              [Units.Pascals]: 70000,
              [Units.Bar]: 0.7,
              [Units.Mbar]: 700,
              [Units.GasKiloPascals]: 700,
            },
            UnitsContext.NONE,
          )[1],
          continuousFlowRateLS: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.LitersPerSecond]: 1,
              [Units.USGallonsPerMinute]: 15,
              [Units.GallonsPerMinute]: 1.5, // n/a
              [Units.CubicFeetPerMinute]: 0.02, // n/a
              [Units.CubicFeetPerHour]: 300, // n/a
              [Units.MetersCubedPerHour]: 0.0003, // n/a
              [Units.LitersPerMinute]: 300, // n/a
            },
            UnitsContext.NONE,
          )[1],
          nodeGroupHex: "#900C3F",
          maxiumumSimutaneousNode: 18,
          kvValue: 0.3,
        },
        {
          name: "High Hazard",
          subGroupId: "fire_sprinkler_high_hazard_default",
          maxPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 1200,
              [Units.Psi]: 175,
              [Units.Pascals]: 1200000,
              [Units.Bar]: 12,
              [Units.Mbar]: 1200,
              [Units.GasKiloPascals]: 1200,
            },
            UnitsContext.NONE,
          )[1],
          minPressureKPA: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.KiloPascals]: 70,
              [Units.Psi]: 10,
              [Units.Pascals]: 70000,
              [Units.Bar]: 0.7,
              [Units.Mbar]: 700,
              [Units.GasKiloPascals]: 700,
            },
            UnitsContext.NONE,
          )[1],
          continuousFlowRateLS: chooseByUnitsMetric(
            unitsParams,
            {
              [Units.LitersPerSecond]: 1,
              [Units.USGallonsPerMinute]: 15,
              [Units.GallonsPerMinute]: 1.5, // n/a
              [Units.CubicFeetPerMinute]: 0.02, // n/a
              [Units.CubicFeetPerHour]: 300, // n/a
              [Units.MetersCubedPerHour]: 0.0003, // n/a
              [Units.LitersPerMinute]: 300, // n/a
            },
            UnitsContext.NONE,
          )[1],
          nodeGroupHex: "#581845",
          maxiumumSimutaneousNode: 30,
          kvValue: 0.3,
        },
      ],
    },
  ];
}
