import { getFastLiveCalculationFields } from "../../../../common/src/api/calculations/utils";
import { Catalog } from "../../../../common/src/api/catalog/types";
import { isCalculated } from "../../../../common/src/api/document/calculations-objects";
import { applyCalcFieldSelection } from "../../../../common/src/api/document/calculations-objects/utils";
import { DrawingState } from "../../../../common/src/api/document/drawing";
import { DiffOperation } from "../../../../common/src/api/document/operation-transforms";
import { SupportedLocales } from "../../../../common/src/api/locale";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import {
  assertUnreachable,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { NodeProps } from "../../../../common/src/models/CustomEntity";
import { FeatureAccess } from "../../../../common/src/models/FeatureAccess";
import { trackEvent } from "../../api/mixpanel";
import { getLiveCalcsWebworkerUrl } from "../../webworker-utils";
import {
  CalculateResultLive,
  LiveCalculationMessage,
} from "../../workers/types";
import { MainEventBus } from "../main-event-bus";
import store from "../store";

export type LiveCalcWorker = Omit<Worker, "postMessage" | "onmessage"> & {
  postMessage: (
    message: LiveCalculationMessage,
    options?: StructuredSerializeOptions,
  ) => void;
  onmessage:
    | ((this: Worker, ev: MessageEvent<CalculateResultLive>) => any)
    | null;
};

export default class LiveCalculationManager {
  full: LiveCalcWorker | null = null;
  fast: LiveCalcWorker | null = null;
  globalStore: GlobalStore;
  lastClearCommitId: number = 0;
  currCommitId: number = 0;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    MainEventBus.$on("committed", this.handleCommit.bind(this));
  }

  handleCommit() {
    this.currCommitId += 1;
  }

  closeDocument() {
    if (this.full) {
      this.full.postMessage({ type: "close-document" });
      if (process.env.NODE_ENV !== "development") {
        this.full.terminate();
      }
    }
    if (this.fast) {
      this.fast.postMessage({ type: "close-document" });
      if (process.env.NODE_ENV !== "development") {
        this.fast.terminate();
      }
    }
    this.full = null;
    this.fast = null;
  }

  handleFastThreadResults(e: MessageEvent<CalculateResultLive>) {
    if (e.data.success) {
      trackEvent({
        type: "Live Calculations Succeeded",
        props: {
          mode: e.data.type,
        },
      });
    } else {
      trackEvent({
        type: "Live Calculations Failed",
        props: {
          mode: e.data.type,
          message: e.data.errorMessage ?? "Unknown",
        },
      });
    }

    switch (e.data.type) {
      case "result-fast-live": {
        if (!e.data.success) {
          console.error("Error calculating fast live calculations", {
            message: e.data.errorMessage,
          });
          return;
        }

        for (const uid of this.globalStore.keys()) {
          this.globalStore.bustDependencies(uid);
        }

        for (const uid of this.globalStore.keys()) {
          this.globalStore.onVisualUpdate(uid);
        }

        const newResults = new Map(e.data.calculations);
        if (this.lastClearCommitId !== this.currCommitId) {
          for (const uid of this.globalStore.liveCalculationStore.keys()) {
            if (!newResults.has(uid)) {
              this.globalStore.liveCalculationStore.delete(uid);
            }
          }
          this.lastClearCommitId = this.currCommitId;
        }

        for (const [uid, calc] of newResults) {
          if (!this.globalStore.liveCalculationStore.has(uid)) {
            this.globalStore.liveCalculationStore.set(uid, calc);
          }
          const original = this.globalStore.liveCalculationStore.get(uid)!;
          const o = this.globalStore.getCalculatable(uid);
          if (!o) {
            continue;
          }
          const fastFields = getFastLiveCalculationFields(o.entity);
          if (!fastFields) {
            continue;
          }

          this.globalStore.liveCalculationStore.set(
            uid,
            applyCalcFieldSelection(original, calc, fastFields),
          );
        }
        MainEventBus.$emit("redraw");
        store.dispatch("document/incrementLiveCalculationRenderCounter");
        break;
      }
      case "result-full-live": {
        throw new Error("Should not receive full results from fast thread");
      }
      default: {
        assertUnreachable(e.data);
      }
    }
  }

  handleFullThreadResults(e: MessageEvent<CalculateResultLive>) {
    switch (e.data.type) {
      case "result-fast-live": {
        // even though we may get fast calculations here, we shouldn't use them
        // because they are not necessarily as up to date as the fast calculation
        // thread.
        break;
      }
      case "result-full-live": {
        if (!e.data.success) {
          console.error("Error calculating full live calculations");
          return;
        }

        for (const uid of this.globalStore.keys()) {
          this.globalStore.onVisualUpdate(uid);
        }

        const newResults = new Map(e.data.calculations);

        if (this.lastClearCommitId !== this.currCommitId) {
          for (const uid of this.globalStore.liveCalculationStore.keys()) {
            if (!newResults.has(uid)) {
              this.globalStore.liveCalculationStore.delete(uid);
            }
          }
          this.lastClearCommitId = this.currCommitId;
        }

        // Keep the existing fast results, as they are more up to date.
        for (const [uid, fullCalc] of newResults) {
          if (!this.globalStore.liveCalculationStore.has(uid)) {
            this.globalStore.liveCalculationStore.set(uid, fullCalc);
          }
          const original = this.globalStore.liveCalculationStore.get(uid)!;
          const o = this.globalStore.get(uid);
          if (!o || !isCalculated(o.entity)) {
            continue;
          }
          const fastFields = getFastLiveCalculationFields(o.entity);
          if (!fastFields) {
            continue;
          }

          this.globalStore.liveCalculationStore.set(
            uid,
            applyCalcFieldSelection(fullCalc, original, fastFields),
          );
        }
        MainEventBus.$emit("redraw");
        store.dispatch("document/incrementLiveCalculationRenderCounter");
        break;
      }
      default:
        assertUnreachable(e.data);
    }
  }

  openDocument(
    opId: number,
    locale: SupportedLocales,
    drawing: DrawingState,
    catalog: Catalog,
    customNodes: NodeProps[],
    featureAccess: FeatureAccess | null,
  ) {
    this.closeDocument();
    this.full = new Worker(getLiveCalcsWebworkerUrl(), {
      type: "module",
    });
    this.fast = new Worker(getLiveCalcsWebworkerUrl(), {
      type: "module",
    });

    this.full.onmessage = this.handleFullThreadResults.bind(this);
    this.fast.onmessage = this.handleFastThreadResults.bind(this);

    this.full.postMessage({
      type: "open-document",
      mode: "full-live",
      opId,
      drawing: cloneSimple(drawing),
      locale,
      catalog,
      customNodes,
      featureAccess,
    });
    this.fast.postMessage({
      type: "open-document",
      mode: "fast-live",
      opId,
      drawing: cloneSimple(drawing),
      locale,
      catalog,
      customNodes,
      featureAccess,
    });
  }

  commit(opId: number) {
    if (this.full) {
      this.full.postMessage({ type: "commit-pending-changes", opId });
    }
    if (this.fast) {
      this.fast.postMessage({ type: "commit-pending-changes", opId });
    }
  }

  replaceDrawing(drawing: DrawingState, opId: number) {
    if (this.full) {
      this.full.postMessage({
        type: "replace-drawing",
        drawing: cloneSimple(drawing),
        opId,
      });
    }
    if (this.fast) {
      this.fast.postMessage({
        type: "replace-drawing",
        drawing: cloneSimple(drawing),
        opId,
      });
    }
  }

  replaceCatalog(catalog: Catalog, opId: number) {
    if (this.full) {
      this.full.postMessage({
        type: "replace-catalog",
        catalog: cloneSimple(catalog),
        opId,
      });
    }
    if (this.fast) {
      this.fast.postMessage({
        type: "replace-catalog",
        catalog: cloneSimple(catalog),
        opId,
      });
    }
  }

  stageDiff(diff: DiffOperation, opId: number) {
    if (this.full) {
      this.full.postMessage({
        type: "stage-diff",
        diff: cloneSimple(diff),
        opId,
      });
    }
    if (this.fast) {
      this.fast.postMessage({
        type: "stage-diff",
        diff: cloneSimple(diff),
        opId,
      });
    }
  }

  commitDiff(diff: any, opId: number) {
    if (this.full) {
      this.full.postMessage({
        type: "commit-diff",
        diff: cloneSimple(diff),
        opId,
      });
    }
    if (this.fast) {
      this.fast.postMessage({
        type: "commit-diff",
        diff: cloneSimple(diff),
        opId,
      });
    }
  }

  revert(opId: number) {
    if (this.full) {
      this.full.postMessage({ type: "revert-staged-diff", opId });
    }
    if (this.fast) {
      this.fast.postMessage({ type: "revert-staged-diff", opId });
    }
  }
}
