<template>
  <div style="height: 100%">
    <MainNavBar />
    <div
      style="overflow: auto; max-height: calc(100% - 61px); min-height: 500px"
    >
      <b-container class="home">
        <h4>Active Users</h4>

        <br />
        <br />
        <br />
        <b-form inline>
          <label class="mx-2">From</label>
          <b-form-datepicker v-model="fromDate" :max="today" />

          <label class="mx-2">To</label>
          <b-form-datepicker v-model="toDate" :max="today" />

          <label class="mx-2">Filters</label>
          <b-form-select
            v-model="paidFilter"
            :options="['Free', 'Paid', 'Churned']"
          />

          <b-button variant="primary" class="ml-5" @click="search">
            <span v-if="!loading">Search</span>
            <span v-else>
              <b-spinner small type="grow" />
              Loading...
            </span>
          </b-button>
        </b-form>

        <br />
        <br />
        <b-table :items="data" :fields="fields" striped hover>
          <template #cell(username)="cellData">
            {{ cellData.item.name }}
            <a :href="`/users/username/${cellData.item.username}`">
              ({{ cellData.item.username }})
            </a>
          </template>

          <template #cell(organizationId)="cellData">
            {{ cellData.item.organizationName }}
            <a :href="`/organizations/id/${cellData.item.organizationId}`">
              ({{ cellData.item.organizationId }})
            </a>
          </template>

          <template #cell(showDetails)="row">
            <b-button size="sm" class="mr-2" @click="row.toggleDetails">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details
            </b-button>
          </template>

          <template #row-details="row">
            <b-card class="text-left">
              Modified Documents:
              <ul>
                <li v-for="doc in row.item.modifiedDocumentIds" :key="doc">
                  <a :href="`/document/${doc}`">{{ doc }}</a>
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>

        <br />
        <span v-if="!data.length">No results</span>
      </b-container>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AdminActiveUser } from "../../../common/src/api/users/activeUsers";
import { OrganizationSubscriptionStatus } from "../../../common/src/models/Organization";
import MainNavBar from "../../src/components/MainNavBar.vue";

export default defineComponent({
  components: {
    MainNavBar,
  },
  data() {
    const data: AdminActiveUser[] = [];
    const paidFilter: OrganizationSubscriptionStatus =
      OrganizationSubscriptionStatus.FREE;
    const toDate: Date = new Date();
    const fromDate: Date = new Date();

    return {
      fromDate,
      toDate,
      paidFilter,
      today: new Date(),
      data,
      loading: false,
    };
  },
  computed: {
    fields() {
      return [
        { key: "username", label: "User" },
        { key: "organizationId", label: "Org" },
        { key: "subscriptionStatus", label: "Subscription Status" },
        { key: "eventsCount", label: "Events" },
        { key: "operationsCount", label: "Operations" },
        {
          key: "modifiedDocumentIds",
          label: "Modified Documents",
          formatter: (_value: string, _key: string, item: AdminActiveUser) => {
            return item.modifiedDocumentIds.length;
          },
        },
        { key: "showDetails", label: "Details" },
      ];
    },
  },
  mounted() {
    alert(
      "message from the dev team: this page is likely to cause issues with the performance of the app so we have disabled the functionality for now. it will be back up soon once we can fix the issue.",
    );
    return;
  },
  methods: {
    async search() {
      alert(
        "message from the dev team: this page is likely to cause issues with the performance of the app so we have disabled the functionality for now. it will be back up soon once we can fix the issue.",
      );
      return;
    },
  },
});
</script>

<style lang="less"></style>
