import axios from "axios";
import { APIResult } from "../../../common/src/api/document/types";
import {
  ActiveUsersOnDateResult,
  AdminActiveUsersRequest,
  AdminActiveUsersResult,
  DailyActiveUsersResult,
} from "../../../common/src/api/users/activeUsers";
import {
  AccessLevel,
  AdminsCreate,
  User,
} from "../../../common/src/models/User";
import { UserBlock } from "../../../common/src/models/UserBlocks";
import { getFailureFromAxiosRequestErrorEvent } from "../lib/axios-utils";

export async function getUsers(): Promise<APIResult<User[]>> {
  try {
    return (await axios.get("/api/users/")).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getUser(username: string): Promise<APIResult<User>> {
  try {
    return (await axios.get("/api/users/" + encodeURIComponent(username))).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function createUser(data: AdminsCreate): Promise<APIResult<User>> {
  try {
    return (await axios.post("/api/users/", data)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function updateUser(
  username: string,
  name: string,
  email: string | undefined,
  subscribed: boolean,
  accessLevel: AccessLevel,
  activated: boolean,
  organization?: string,
): Promise<APIResult<User>> {
  try {
    return (
      await axios.put("/api/users/" + encodeURIComponent(username), {
        name,
        accessLevel,
        email,
        organization,
        subscribed,
        activated,
      })
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function updateLastNoticeSeen(): Promise<APIResult<User>> {
  try {
    return (await axios.get("/api/changeLogMessage/updateNotice")).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function dailyActiveUsers(
  from: Date,
  to: Date,
): Promise<APIResult<DailyActiveUsersResult>> {
  try {
    return (
      await axios.post("/api/users/daily-active-users", {
        from,
        to,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function activeUsersOnDate(
  date: Date,
): Promise<APIResult<ActiveUsersOnDateResult>> {
  try {
    return (
      await axios.post("/api/users/daily-active-users-on-date", {
        date,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getAdminActiveUsers(
  payload: AdminActiveUsersRequest,
): Promise<APIResult<AdminActiveUsersResult>> {
  try {
    return (await axios.post("/api/users/admin-active-users", payload)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function blockUser(
  username: string,
  reason: string,
): Promise<APIResult<{ user: User; block: UserBlock }>> {
  try {
    return (
      await axios.post(
        "/api/users/" + encodeURIComponent(username) + "/block",
        {
          username,
          reason,
        },
      )
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function unblockUser(
  username: string,
  reason: string,
): Promise<APIResult<User>> {
  try {
    return await (
      await axios.post(
        "/api/users/" + encodeURIComponent(username) + "/unblock",
        { reason },
      )
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getUserBlocks(
  username: string,
): Promise<APIResult<Array<UserBlock>>> {
  try {
    return (
      await axios.get("/api/users/" + encodeURIComponent(username) + "/blocks")
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function adminResetUserPassword(
  username: string,
): Promise<APIResult<string>> {
  try {
    return (await axios.post("/api/cognito/reset-password", { username })).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function adminDisableMFA(
  username: string,
): Promise<APIResult<string>> {
  try {
    return (
      await axios.post(
        "/api/cognito/disable-mfa/" + encodeURIComponent(username),
      )
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function listRemoteUsers(props: {
  remoteBase: string;
  remoteUsername: string;
  remotePassword: string;
  orgId: string;
}): Promise<APIResult<User[]>> {
  try {
    return (await axios.post("/api/users/remote/list", props)).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function importRemoteUsers(props: {
  remoteBase: string;
  remoteUsername: string;
  remotePassword: string;
  usernames: string[];
  mapToOrgId: string;
}): Promise<APIResult<string>> {
  try {
    return (await axios.post("/api/users/remote/import", props)).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}
