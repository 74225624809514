<template>
  <div>
    <MainNavBar />
    <div>
      <b-container>
        <b-row>
          <b-col>
            <h1 class="title">Multi-factor Authentication</h1>
          </b-col>
        </b-row>

        <br />
        <b-row>
          <b-col>
            <p style="font-size: 1.1rem">
              To enhance security, your organization requires the use of
              Multi-Factor Authentication (MFA). This additional layer of
              protection helps safeguard your account and sensitive information.
              Please enable MFA before continuing to use the app.
            </p>
          </b-col>
        </b-row>

        <br />
        <b-row>
          <b-col id="acceptDeclineBtn">
            <b-button
              variant="primary"
              style="margin-right: 20px"
              @click="goToProfile"
            >
              Enable MFA
              <b-icon class="ml-1" icon="arrow-right" />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { User } from "../../../common/src/models/User";
import MainNavBar from "../../src/components/MainNavBar.vue";

@Component({
  components: {
    MainNavBar,
  },
})
export default class EnforceMFA extends Vue {
  get profile(): User {
    return this.$store.getters["profile/profile"];
  }

  goToProfile() {
    this.$router.push({ name: "user", params: { id: this.profile.username } });
  }
}
</script>

<style></style>
