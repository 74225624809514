<template>
  <label v-if="!shouldHide" id="version-label">Version: {{ appVersion }}</label>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
    shouldHide() {
      if (this.$route.query.hideVersion === "true") {
        return true;
      }
      return false;
    },
  },
});
</script>
<style lang="less">
#version-label {
  font-size: 11px;
  position: absolute;
  bottom: 10px;
  left: 20px;
}
</style>
