import { GetterTree } from "vuex";
import { buildNodes } from "../../../../common/src/api/context";
import { UnitsParameters } from "../../../../common/src/api/document/drawing";
import { RootState } from "../types";
import CustomEntityState from "./types";

export const getters: GetterTree<CustomEntityState, RootState> = {
  nodes(state, getters, rootState, rootGetters) {
    const units = rootGetters["document/units"] as UnitsParameters;
    return buildNodes(state.nodes || [], units);
  },
  loaded(state) {
    return state.loaded;
  },
};
