export const UFH_DEFAULT_INLET_HEIGHT_MM = 450;
export const UFH_DEFAULT_INLET_HEIGHT_FT = 1.5;
export const UFH_DEFAULT_OUTLET_HEIGHT_MM = 500;
export const UFH_DEFAULT_OUTLET_HEIGHT_FT = 1.5;
export const UFH_DEFAULT_HEATING_PIPE_HEIGHT_MM = 500;
export const UFH_BELOW_FLOOR_HEIGHT_MM = -50;
export const UFH_FLOOR_TEMP_LIMIT_DIFF = 9;
export const UFH_DEFAULT_TRANSIT_SPACING_MM = 25;
export const UFH_DEFAULT_COLOR_PALETTE = [
  "#FF3360",
  "#00D12A",
  "#22A0EE",
  "#AA33FF",
  "#3333DD",
  "#A0DD44",
  "#DD88A0",
  "#DD00A0",
];
