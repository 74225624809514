import { getGPUTier } from "detect-gpu";
import { PERFORMANCE_ARTICLE } from "./help-articles";
import { Problem, ProblemSeverity } from "./types";

export async function getGPUProblem(): Promise<Problem | undefined> {
  const gpuTierResult = await getGPUTier();
  if (gpuTierResult.tier <= 2) {
    return {
      title: "Boost performance",
      details: `
        We have detected that you are using an integrated graphics card. If your
        computer has dedicated graphics card we suggest that you change your
        computer settings to make use of it.
      `,
      severity: ProblemSeverity.Warning,
      helpArticleUrl: PERFORMANCE_ARTICLE,
    };
  }
  return undefined;
}
