import Flatten from "@flatten-js/core";
import { Color } from "../../../lib/color";
import { Coord } from "../../../lib/coord";
import { Units } from "../../../lib/measurements";
import { assertUnreachable, cloneSimple } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { getEffectiveHeatLoad } from "../../calculations/utils";
import {
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "./property-field";
import {
  DrawableEntity,
  NamedEntity,
  VirtualEdgeEntity,
} from "./simple-entities";
import { EntityType } from "./types";
import {
  getAvailableDoorTypes,
  getAvailableHeatLoadComponentMaterial,
} from "./utils";

export enum FenType {
  WINDOW = "WINDOW",
  DOOR = "DOOR",
  LOOP_ENTRY = "LOOP_ENTRY",
}

export const UFH_ENTRY_FEN_TYPES = new Set([FenType.DOOR, FenType.LOOP_ENTRY]);

export function isFenType(type: string): type is FenType {
  return Object.values(FenType).includes(type as FenType);
}
export enum DoorType {
  SINGLE = "Single",
  BI_FOLDING = "Bi-folding",
}

export enum WindowType {
  WINDOW = "Window",
  VELUX = "Velux", // aka Skylight
}

export interface FenEntityBase
  extends DrawableEntity,
    NamedEntity,
    VirtualEdgeEntity {
  type: EntityType.FENESTRATION;
  fenType: FenType;
  color: Color | null;

  polygonEdgeUid: [string] | null;
  offsetPosMM: number | null;
  fenestration: FenConcrete;
  rotation: number;
}

export interface WindowFenEntity extends FenEntityBase {
  fenType: FenType.WINDOW;
  fenestration: WindowFen;
}

export interface DoorFenEntity extends FenEntityBase {
  fenType: FenType.DOOR;
  fenestration: DoorFen;
}

export interface LoopEntryFenEntity extends FenEntityBase {
  fenType: FenType.LOOP_ENTRY;
  fenestration: LoopEntryFen;
}

export interface CommonFen {
  lengthM: number | null;
  heightM: number | null;
  materialUid: string | null;
  uValueW_M2K: number | null;
  externalTemperatureC: number | null;
}

export interface WindowFen extends CommonFen {}

export interface DoorFen extends CommonFen {
  doorType: DoorType;
}

export interface LoopEntryFen extends CommonFen {}

export interface FensEntityMap {
  [FenType.DOOR]: DoorFenEntity;
  [FenType.WINDOW]: WindowFenEntity;
  [FenType.LOOP_ENTRY]: LoopEntryFenEntity;
}

export type FenEntity = WindowFenEntity | DoorFenEntity | LoopEntryFenEntity;
export type FenConcrete = WindowFen | DoorFen | LoopEntryFen;
export function fillDefaultFenFields<T extends FenEntity>(
  context: CoreContext,
  entity: T,
): T {
  const result = cloneSimple(entity);
  switch (result.fenType) {
    case FenType.WINDOW:
      fillDefaultWindowFenFields(context, result);
      break;
    case FenType.DOOR:
      let coreDoor = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.FENESTRATION,
        entity.uid,
      );

      if (coreDoor.isInternalFene()) {
        fillDefaultDoorFenFields(context, result, "Internal Door");
      } else {
        fillDefaultDoorFenFields(context, result, "External Door");
      }
      break;
    case FenType.LOOP_ENTRY:
      fillDefaultLoopEntryFenFields(context, result);
      break;
    default:
      assertUnreachable(result);
  }
  return result;
}

export function getFenestrationName(type: FenType) {
  switch (type) {
    case FenType.WINDOW:
      return "Window";
    case FenType.DOOR:
      return "Door";
    case FenType.LOOP_ENTRY:
      return "Loop Entry";
  }
  assertUnreachable(type);
}

export function fillDefaultLoopEntryFenFields(
  context: CoreContext,
  entity: LoopEntryFenEntity,
) {
  if (entity.color === null) {
    entity.color = {
      hex: "#000000",
    };
  }
  if (entity.entityName === null) {
    entity.entityName = "Loop Entry";
  }

  if (entity.fenestration.lengthM === null) {
    entity.fenestration.lengthM = 1000;
  }
  if (entity.fenestration.heightM === null) {
    entity.fenestration.heightM = 0;
  }

  if (entity.fenestration.uValueW_M2K === null) {
    entity.fenestration.uValueW_M2K = 0;
  }

  if (entity.fenestration.externalTemperatureC === null) {
    entity.fenestration.externalTemperatureC = 0;
  }
}

export function fillDefaultWindowFenFields(
  context: CoreContext,
  entity: WindowFenEntity,
) {
  if (entity.color === null) {
    entity.color = context.drawing.metadata.heatLoss.defaultColor["Window"];
  }
  if (entity.entityName === null) {
    entity.entityName = "Window";
  }

  let { windowSpec } = context.drawing.metadata.heatLoss;
  if (entity.fenestration.lengthM === null) {
    entity.fenestration.lengthM = windowSpec.Window.lengthM;
  }
  if (entity.fenestration.heightM === null) {
    entity.fenestration.heightM = windowSpec.Window.heightM;
  }
  if (entity.fenestration.materialUid === null) {
    entity.fenestration.materialUid =
      context.drawing.metadata.heatLoss.defaultMaterial["Window"];
  }

  if (entity.fenestration.uValueW_M2K === null) {
    const windowMaterial = getEffectiveHeatLoad(
      context.catalog,
      context.drawing,
    ).material["Window"];
    const uValueWM2K =
      windowMaterial?.table[
        entity.fenestration.materialUid
      ]?.thermal_transmittance_W_per_m2K?.toFixed(2);
    entity.fenestration.uValueW_M2K = uValueWM2K ? +uValueWM2K : null;
  }

  if (entity.fenestration.externalTemperatureC === null) {
    entity.fenestration.externalTemperatureC =
      context.drawing.metadata.heatLoss.externalWinterTemperatureC;
  }
}

export function fillDefaultDoorFenFields(
  context: CoreContext,
  entity: DoorFenEntity,
  materialUid: "Internal Door" | "External Door",
) {
  let heatLoad = getEffectiveHeatLoad(context.catalog, context.drawing);
  if (entity.color === null) {
    entity.color = context.drawing.metadata.heatLoss.defaultColor[materialUid];
  }
  if (entity.entityName === null) {
    entity.entityName = "Door";
  }
  if (entity.fenestration.doorType === null) {
    entity.fenestration.doorType = DoorType.SINGLE;
  }

  let { doorSpec } = context.drawing.metadata.heatLoss;
  if (entity.fenestration.lengthM === null) {
    entity.fenestration.lengthM =
      doorSpec.lengthM[entity.fenestration.doorType];
  }
  if (entity.fenestration.heightM === null) {
    entity.fenestration.heightM = doorSpec.heightM;
  }

  if (
    entity.fenestration.materialUid === null ||
    heatLoad.material[materialUid]?.table[entity.fenestration.materialUid] ===
      undefined
  ) {
    entity.fenestration.materialUid =
      context.drawing.metadata.heatLoss.defaultMaterial[materialUid];
  }
  if (entity.fenestration.uValueW_M2K === null) {
    const uValue_M2K =
      getEffectiveHeatLoad(context.catalog, context.drawing).material[
        materialUid
      ]?.table[
        entity.fenestration.materialUid
      ].thermal_transmittance_W_per_m2K?.toFixed(2) ?? null;
    entity.fenestration.uValueW_M2K = uValue_M2K ? +uValue_M2K : null;
  }

  if (entity.fenestration.externalTemperatureC === null) {
    entity.fenestration.externalTemperatureC =
      context.drawing.metadata.heatLoss.externalWinterTemperatureC;
  }
}

export function isWindowFenEntity(
  entity: FenEntity,
): entity is WindowFenEntity {
  return entity.fenType === FenType.WINDOW;
}

export function isLoopEntryFenEntity(
  entity: FenEntity,
): entity is LoopEntryFenEntity {
  return entity.fenType === FenType.LOOP_ENTRY;
}

export function isDoorFenEntity(entity: FenEntity): entity is DoorFenEntity {
  return entity.fenType === FenType.DOOR;
}

export function makeFenFields(
  context: CoreContext,
  entity: FenEntity,
): PropertyField[] {
  const { catalog, drawing } = context;

  let fenType: "Window" | "Door" = "Window";
  let materialType: "Window" | "Internal Door" | "External Door" = "Window";
  if (isDoorFenEntity(entity)) {
    fenType = "Door";
    const coreDoor = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.FENESTRATION,
      entity.uid,
    );
    materialType = coreDoor.isInternalFene()
      ? "Internal Door"
      : "External Door";
  }

  const propertyField: PropertyField[] = [
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },
    {
      property: "fenestration.lengthM",
      title: "Length",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      units: Units.Meters,
      params: { min: 0, max: null },
      multiFieldId: "fenestration-lengthM",
    },
    {
      property: "fenestration.heightM",
      title: "Height",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      units: Units.Meters,
      params: { min: 0, max: null },
      multiFieldId: "fenestration-heightM",
    },
    {
      property: "fenestration.materialUid",
      title: `${fenType} Material`,
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: getAvailableHeatLoadComponentMaterial(context, materialType),
      },
      multiFieldId: "fenestration-materialUid",
    },
    {
      property: "fenestration.uValueW_M2K",
      title: "U Value",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "fenestration-uValueW_M2K",
    },
    {
      property: "fenestration.externalTemperatureC",
      title: "External Temperature",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: -100, max: null },
      multiFieldId: "fenestration-externalTemperatureC",
      units: Units.Celsius,
    },
  ];

  if (isDoorFenEntity(entity)) {
    propertyField.push({
      property: "fenestration.doorType",
      title: "Door Type",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: getAvailableDoorTypes(),
      },
      multiFieldId: "fenestration-doorType",
    });
    propertyField.push({
      property: "rotation",
      title: "Rotation: (Degrees)",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Rotation,
      params: {
        step: 90,
        disableFreeInput: true,
      },
      multiFieldId: "rotation",
    });
  }
  return propertyField.map((field: PropertyField) =>
    withPropertyTracking(context, entity)(field),
  );
}

export function getDoorPolygon(
  drawCoords: [Coord, Coord],
  width: number,
  isOpenTowardInside: boolean,
  isClockWise: boolean,
): Flatten.Polygon {
  const [end, start] = drawCoords;

  // Calculate the angle of the door
  const angle = Math.atan2(end.y - start.y, end.x - start.x);

  // Calculate the length of the door
  const length = Math.sqrt(
    Math.pow(end.y - start.y, 2) + Math.pow(end.x - start.x, 2),
  );

  // Vertices of the door in local coordinate system (assuming door is at origin and horizontal)
  let localVertices = [
    [0, 0],
    [length, 0],
    [length, length],
    [0, length],
  ];

  // If the drawing needs to be clockwise, we flip the y-axis
  if (isClockWise) {
    localVertices = localVertices.map((vertex) => [vertex[0], -vertex[1]]);
  }

  // If the door is open toward inside, we reflect the vertices over x-axis
  if (isOpenTowardInside) {
    localVertices = localVertices.map((vertex) => [-vertex[0], vertex[1]]);
  }

  // Rotate and translate the vertices to match the actual door's position and orientation
  let globalVertices = localVertices.map((vertex) => [
    start.x + vertex[0] * Math.cos(angle) - vertex[1] * Math.sin(angle),
    start.y + vertex[0] * Math.sin(angle) + vertex[1] * Math.cos(angle),
  ]);

  // Generate the door polygon based on its vertices
  let doorPolygon: Flatten.Polygon = new Flatten.Polygon();
  doorPolygon.addFace(
    globalVertices.map((vertex) => new Flatten.Point(vertex[0], vertex[1])),
  );

  return doorPolygon;
}
