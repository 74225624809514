import { CoreContext } from "../api/calculations/types";
import {
  HotWaterOutlet,
  PlantType,
  ReturnSystemPlant,
} from "../api/document/entities/plants/plant-types";
import { EntityType } from "../api/document/entities/types";

type KeysMatching<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];

/**
 * Example:
 *
 * type T = {
 *  a: number;
 *  b: string;
 *  c: number;
 * };
 *
 * KeysMatching<T, number> =
 *  "a" | "c"
 *
 * KeysMatching<T, string> =
 *  "b"
 */

export function makeOutletTemperatureValidationRule(
  // Names for the warning message
  currentPropertyName: string,
  otherPropertyName: string,
  context: CoreContext,
  uid: string,
  index: number,
  otherPropertyKey: KeysMatching<HotWaterOutlet, number | null>,
  // if true, checks currentProperty > otherProperty, otherwise checks currentProperty < otherProperty
  checkGT: boolean,
) {
  return {
    predicate: (currentProperty: number) => {
      const obj = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.PLANT,
        uid,
      );
      const plant = obj.entity.plant as ReturnSystemPlant;
      if (plant.type !== PlantType.RETURN_SYSTEM) {
        console.warn(
          `Plant ${uid} is not a return system, but is used in a return system validation rule`,
        );
        return true;
      }
      const currentOutlet = plant.outlets[index];
      const otherProperty = currentOutlet[otherPropertyKey];
      return (
        !currentOutlet.outletReturnUid ||
        otherProperty === null ||
        (checkGT
          ? currentProperty > otherProperty
          : currentProperty < otherProperty)
      );
    },
    warning: `${currentPropertyName} must be ${
      checkGT ? "greater" : "less"
    } than ${otherPropertyName}`,
  };
}
