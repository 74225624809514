<template>
  <span>
    <b-navbar type="light">
      <b-navbar-nav>
        <b-navbar-brand v-b-tooltip.hover="{ title: 'Home Page' }" href="/">
          <img
            src="@/assets/h2x.png"
            class="d-inline-block mr-0 align-top"
            height="30"
          />
        </b-navbar-brand>
      </b-navbar-nav>

      <template v-if="isAdmin">
        <b-navbar-nav>
          <b-nav-item
            data-testid="projects-button"
            :to="{ name: 'home' }"
            active-class="active"
            exact
            >D-Projects</b-nav-item
          >
          <b-nav-item
            :to="{
              name: 'manufacturer/dashboard',
              query: { organizationId: $route.query.organizationId },
            }"
            active-class="active"
            exact
            >M-Dashboard</b-nav-item
          >
          <b-nav-item :to="newManufacturerProjectTo" active-class="active" exact
            >M-Projects</b-nav-item
          >
        </b-navbar-nav>
      </template>
      <template v-else-if="isBuildingServices">
        <!-- building services menu -->
        <b-navbar-nav>
          <b-nav-item
            data-testid="projects-button"
            :to="{ name: 'home' }"
            active-class="active"
            exact
            >Projects</b-nav-item
          >
        </b-navbar-nav>
      </template>
      <template v-else-if="isManufacturer">
        <!-- manufacturer menu -->
        <b-navbar-nav>
          <b-nav-item
            :to="{ name: 'manufacturer/dashboard' }"
            active-class="active"
            exact
            >Dashboard</b-nav-item
          >
          <b-nav-item
            :to="{ name: 'manufacturer/projects' }"
            active-class="active"
            exact
            >Projects</b-nav-item
          >
        </b-navbar-nav>
      </template>
      <b-navbar-nav class="ml-auto">
        <ProfileMenuItem :disabled="false" />
      </b-navbar-nav>
    </b-navbar>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  defaultPasswordRequirements,
  OrganizationType,
} from "../../../common/src/models/Organization";
import { AccessLevel, User } from "../../../common/src/models/User";
import ProfileMenuItem from "../../src/components/ProfileMenuItem.vue";
import { isFeatureFlagEnabled } from "../lib/hooks/use-feature-flag";

export default defineComponent({
  components: { ProfileMenuItem },
  data() {
    const form: {
      confirmEmail: string;
      isLoading: boolean;
    } = {
      confirmEmail: "",
      isLoading: false,
    };
    const isLoading: boolean = false;
    const emailVerificationSent: boolean = false;

    return {
      emailVerificationSent,
      isLoading,
      form,
    };
  },
  computed: {
    profile(): User {
      const profile = this.$store.getters["profile/profile"];
      if (!profile) {
        return {
          username: "",
          accessLevel: AccessLevel.USER,
          organization: {
            id: "",
            name: "",
            type: OrganizationType.BUILDING_SERVICES,
            passwordRequirements: defaultPasswordRequirements,
          },
          email: "",
          subscribed: false,
          name: "",
          passwordHash: "",
          eulaAcceptedOn: null,
          lastActivityOn: null,
          lastNoticeSeenOn: null,
          temporaryOrganizationName: null,
          temporaryUser: false,
        } as User;
      } else {
        return profile;
      }
    },
    isAdmin() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    isManufacturer() {
      return this.profile.organization?.type === OrganizationType.MANUFACTURER;
    },
    isBuildingServices() {
      return (
        this.profile.organization?.type === OrganizationType.BUILDING_SERVICES
      );
    },
    newManufacturerProjectTo() {
      const organization =
        this.$route.query.organizationId ?? this.profile.organization?.id;
      return {
        name: isFeatureFlagEnabled(
          "release-frontend-filtering-in-manufacturer-project",
        )
          ? "manufacturer/projects2"
          : "manufacturer/projects",
        query: { organizationId: organization },
      };
    },
  },
});
</script>

<style lang="less">
.navbar {
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid lightgray;
}

#email-verification {
  padding: 4px;
  background: #e3eaf1;

  p {
    margin-bottom: 0px;
  }
}
</style>
