import { User } from "../../../models/User";

// more info here https://developers.canny.io/install/custom-fields
export type CannyUserData = {
  email: string;
  id: string;
  name: string;
  avatarUrl?: string;
  created?: string; // ISOString
  customFields?: {
    [key: string]: string;
  };
  companies?: {
    id: string;
    name: string;
    monthlySpend?: number;
    created?: string; // ISOString
    customFields?: {
      [key: string]: string | number;
    };
  }[];
};

export function createCannyUserData(user: User): CannyUserData {
  return {
    email: user.email ?? "",
    id: user.username,
    name: user.name + " " + (user.lastname ?? ""),
    ...(user.organization && {
      companies: [
        {
          id: user.organization.id,
          name: user.organization.name,
          ...(user.organization.type && {
            customFields: {
              orgType: user.organization.type,
            },
          }),
        },
      ],
    }),
  };
}
