<template>
  <b-nav-item-dropdown
    right
    :disabled="disabled"
    class="dropdown-profile"
    data-testid="profile-menu-item"
  >
    <template slot="button-content">
      <FontAwesomeIcon :icon="faUser" style="margin-top: -3px" />
      <span class="ml-1" data-testid="profile-menu-text">{{
        profile.name
      }}</span>
    </template>

    <b-dropdown-item
      :to="'/users/username/' + encodeURIComponent(profile.username)"
    >
      Profile
    </b-dropdown-item>
    <b-dropdown-item :to="'/organizations/id/' + profile.organization?.id">
      Organization
    </b-dropdown-item>
    <b-dropdown-item @click="changePassword"> Change Password </b-dropdown-item>
    <!-- <b-dropdown-item @click="handleOnboardingClick" v-if="!isViewOnly">
      {{ onboardingActive ? "Disable" : "Activate" }} Onboarding Guide
    </b-dropdown-item> -->
    <template v-if="profile.accessLevel <= AccessLevel.MANAGER">
      <b-dropdown-divider />
      <!--Admin Panel controls-->
      <b-badge style="font-size: 12px; margin-left: 10px"> MANAGER </b-badge>
      <b-dropdown-item to="/users"> Users </b-dropdown-item>
    </template>
    <template v-if="profile.accessLevel <= AccessLevel.ADMIN">
      <b-dropdown-divider />
      <b-badge style="font-size: 12px; margin-left: 10px"> h2x ADMIN </b-badge>
      <b-dropdown-item to="/active-users"> Active Users </b-dropdown-item>
      <b-dropdown-item to="/organizations"> All Organizations </b-dropdown-item>
      <b-dropdown-item to="/admin/manufacturer-associations">
        Manufacturer Associations
      </b-dropdown-item>
      <b-dropdown-item to="/admin/subscriptions">
        Subscription Associations
      </b-dropdown-item>
    </template>
    <template v-if="profile.accessLevel <= AccessLevel.SUPERUSER">
      <b-dropdown-divider />
      <b-badge style="font-size: 12px; margin-left: 10px">
        h2x SUPERUSER
      </b-badge>
      <b-dropdown-item to="/errors"> Auto Error Reports </b-dropdown-item>
      <b-dropdown-item to="/changeLogs"> Change Logs </b-dropdown-item>
    </template>

    <b-dropdown-divider />
    <b-dropdown-item :to="{ name: 'eula' }">
      License Agreement
    </b-dropdown-item>
    <b-dropdown-item data-testid="logout-button" @click="logout">
      Logout
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script lang="ts">
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { signOut } from "aws-amplify/auth";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import { defineComponent } from "vue";
import { SupportedLocales } from "../../../common/src/api/locale";
import { OrganizationType } from "../../../common/src/models/Organization";
import { AccessLevel, User } from "../../../common/src/models/User";
import router from "../../src/router";
import CalculationReportManager from "../api/calculationReportManager";
import { ENABLE_MIXPANEL } from "../config";
import { DocumentState } from "../store/document/types";
import { isViewOnly } from "../store/document/utils";

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  props: {
    disabled: { required: true, type: Boolean },
  },
  data() {
    return {
      faUser: faUser,
    };
  },
  computed: {
    profile(): User {
      const profile = this.$store.getters["profile/profile"];
      if (!profile) {
        return {
          username: "",
          accessLevel: AccessLevel.USER,
          organization: {
            id: "",
            name: "",
            type: OrganizationType.BUILDING_SERVICES,
          },
          email: "",
          subscribed: false,
          name: "",
          passwordHash: "",
          eulaAcceptedVersion: 0,
          eulaAcceptedOn: null,
          lastActivityOn: null,
          lastNoticeSeenOn: null,
          temporaryOrganizationName: null,
          temporaryUser: false,
        } as User;
      } else {
        return profile;
      }
    },
    isViewOnly() {
      return isViewOnly(this.document.uiState);
    },
    AccessLevel() {
      return AccessLevel;
    },
    isManufacturer() {
      return this.profile.organization?.type === OrganizationType.MANUFACTURER;
    },
    isBuildingServices() {
      return (
        this.profile.organization?.type === OrganizationType.BUILDING_SERVICES
      );
    },
    document(): DocumentState {
      return this.$store.getters["document/document"];
    },
    onboardingActive() {
      return this.document.uiState.isOnboardingActive;
    },
    locale(): SupportedLocales {
      return this.$store.getters["profile/locale"];
    },
  },
  mounted() {
    this.fetchOnboardingStatus();
  },
  methods: {
    async fetchOnboardingStatus() {
      if (!this.isViewOnly) {
        const { onboardingActive } = await this.$store.dispatch(
          "profile/getProperties",
          ["onboardingActive"],
        );
        if (onboardingActive) {
          // disable the old onboarding for now
          // this.document.uiState.isOnboardingActive = true;
        }
      }
    },
    async logout() {
      try {
        await signOut();
        delete axios.defaults.headers.common["access-token"];
        CalculationReportManager.onLogout();
        if (ENABLE_MIXPANEL) {
          mixpanel.reset();
        }
        this.$store
          .dispatch("profile/setProfile", null)
          .then(() => router.push({ name: "login" }));
      } catch (error: any) {
        this.$bvToast.toast(error.message, {
          title: "Could not log out",
          variant: "danger",
        });
      }
    },
    changePassword() {
      router.push({
        name: "changePassword",
        query: {
          next: this.$router.currentRoute.fullPath,
        },
      });
    },
    handleOnboardingClick() {
      this.$store.dispatch("profile/setProperties", {
        onboardingActive: !this.document.uiState.isOnboardingActive,
      });
      if (document) {
        this.document.uiState.isOnboardingActive =
          !this.document.uiState.isOnboardingActive;
      }
    },
  },
});
</script>
