import Flatten from "@flatten-js/core";
import axios from "axios";
import axiosRetry from "axios-retry";
import * as TM from "transformation-matrix";
import {
  CoreContext,
  DrawingLayout,
  PipeConfiguration,
} from "../../../../common/src/api/calculations/types";
import {
  VALVE_HEIGHT_MM,
  getEdgeLikeHeightAboveFloorM,
  getFloorHeight,
  getRpzdBigValveHeightMM,
} from "../../../../common/src/api/coreObjects/utils";
import {
  AttachableBackgroundEntityConcrete,
  ConnectableEntityConcrete,
  DrawableEntityConcrete,
  EdgeLikeEntity,
} from "../../../../common/src/api/document/entities/concrete-entity";
import {
  RoomEntity,
  RoomType,
} from "../../../../common/src/api/document/entities/rooms/room-entity";
import { SystemNodeEntity } from "../../../../common/src/api/document/entities/system-node-entity";
import {
  EntityType,
  EntityTypeMap,
} from "../../../../common/src/api/document/entities/types";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { STROKES } from "../../../../common/src/api/linetypes";
import { Color } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import {
  assertUnreachable,
  cloneNaive,
} from "../../../../common/src/lib/utils";
import { User } from "../../../../common/src/models/User";
import CanvasContext from "../../../src/htmlcanvas/lib/canvas-context";
import { getOrgLogoUrl } from "../../api/organizations";
import { color2rgb, getPropertyByString, rgb2style } from "../../lib/utils";
import { DocumentState } from "../../store/document/types";
import { prepareFill, prepareStroke } from "../helpers/draw-helper";
import { DrawableObjectConcrete } from "../objects/concrete-object";
import DrawableDirectedValve from "../objects/drawableDirectedValve";
import { DrawingMode } from "../types";
import { DrawingContext } from "./types";

export function getBackgroundAttachments(
  context: CanvasContext,
  wc: Coord,
  levelUid: string,
): [AttachableBackgroundEntityConcrete, Coord][] {
  const floors = context.backgroundLayer.getBackgroundsAt(wc, levelUid);
  const results: [AttachableBackgroundEntityConcrete, Coord][] = [];

  for (const floor of floors) {
    let oc = cloneNaive(wc);
    const o = context.globalStore.get(floor.uid)!;
    oc = o.toObjectCoord(wc);
    results.push([floor, oc]);
  }
  return results;
}

export function getVisibleBoundingBox(
  context: CoreContext,
  objects: DrawableObjectConcrete[],
) {
  let l = Infinity;
  let r = -Infinity;
  let t = Infinity;
  let b = -Infinity;

  for (const obj of objects) {
    const bb = obj.shape;
    if (bb) {
      if (
        isNaN(bb.box.xmin) ||
        isNaN(bb.box.xmax) ||
        isNaN(bb.box.ymin) ||
        isNaN(bb.box.ymax)
      ) {
        console.error("NaN in bounding box for object", obj.type, obj.uid);
        continue;
      }
      l = Math.min(l, bb.box.xmin);
      r = Math.max(r, bb.box.xmax);
      t = Math.min(t, bb.box.ymin);
      b = Math.max(b, bb.box.ymax);
    }
  }

  return { l, r, t, b };
}

export function resolveProperty(prop: string, obj: any): any {
  if (prop.indexOf(".") === -1) {
    return obj[prop];
  }

  return resolveProperty(
    prop.split(".").splice(1).join("."),
    obj[prop.split(".")[0]],
  );
}

export function getSystemNodeHeightM(
  entity: SystemNodeEntity,
  context: CanvasContext,
): number {
  const po = context.globalStore.get(entity.parentUid!)!;
  return getEdgeLikeHeightAboveFloorM(
    po.entity as EdgeLikeEntity,
    entity,
    context,
  );
}

/**
 * Determines whether a pipe can be attached to another pipe or not.
 * @param offer the pipe currently being drawn/manipulated
 * @param target the other pipe being drawn onto
 * @returns
 */
export function pipeConfigurationCompatible(
  offer: PipeConfiguration | null,
  target: PipeConfiguration | null,
) {
  if (!offer) {
    return true;
  }
  switch (offer) {
    case PipeConfiguration.NORMAL:
    case PipeConfiguration.RING_MAIN:
      return target === null || target === offer;
    case PipeConfiguration.RETURN_IN:
      // The RETURN_IN and RETURN_OUT configurations are not stable and
      // are automatically maintained in calculations. They are therefore
      // possibly incorrect during the drawing stage. Preventing IN and
      // OUT from connecting altogether becomes too cumbersome during pipe
      // drawing even when trying to make valid connections, so we allow
      // some leeway here to connect the two.
      return (
        target === PipeConfiguration.RETURN_IN ||
        target === PipeConfiguration.RETURN_OUT
      );
    case PipeConfiguration.RETURN_OUT:
      return target !== PipeConfiguration.RETURN_IN;
  }
  assertUnreachable(offer);
}

export function maxHeightOfConnection(
  entity: ConnectableEntityConcrete,
  context: CanvasContext,
) {
  let height = -Infinity;
  if (entity.type === EntityType.SYSTEM_NODE) {
    height = getSystemNodeHeightM(entity, context);
  } else if (entity.type === EntityType.FLOW_SOURCE) {
    if (entity.heightAboveGroundM !== null) {
      height =
        entity.heightAboveGroundM -
        getFloorHeight(context.globalStore, context.drawing, entity);
    }
  }

  context.globalStore.getConnections(entity.uid).forEach((cuid) => {
    const o = context.globalStore.get(cuid)!;
    if (o.entity.type === EntityType.CONDUIT) {
      height = Math.max(o.entity.heightAboveFloorM, height);
    }
  });
  if (height !== -Infinity) {
    return height;
  }
  return null;
}

export function minHeightOfConnection(
  entity: ConnectableEntityConcrete,
  context: CanvasContext,
) {
  let height = Infinity;
  if (entity.type === EntityType.SYSTEM_NODE) {
    height = getSystemNodeHeightM(entity, context);
  }
  context.globalStore.getConnections(entity.uid).forEach((cuid) => {
    const o = context.globalStore.get(cuid)!;
    if (o.entity.type === EntityType.CONDUIT) {
      height = Math.min(o.entity.heightAboveFloorM, height);
    }
  });
  if (height !== Infinity) {
    return height;
  }
  return null;
}

export function tm2flatten(m: TM.Matrix): Flatten.Matrix {
  return new Flatten.Matrix(m.a, m.b, m.c, m.d, m.e, m.f);
}

export const VALVE_LINE_WIDTH_MM = 10;

export const VALVE_SIZE_MM = 98;

export function getRpzdValveHeightMM(object: DrawableObjectConcrete) {
  switch (object.type) {
    case EntityType.BIG_VALVE:
      return getRpzdBigValveHeightMM(object.entity);
    case EntityType.DIRECTED_VALVE:
      return (object as DrawableDirectedValve).valveHeightMM;
    default:
      return VALVE_HEIGHT_MM;
  }
}

export function drawRpzdDouble(
  context: DrawingContext,
  colors: [string, string],
  highlightColor?: Color,
  entity?: DrawableObjectConcrete,
) {
  const s = context.vp.currToSurfaceScale(context.ctx);
  const baseWidth = Math.max(
    2.0 / s,
    VALVE_LINE_WIDTH_MM / context.vp.surfaceToWorldLength(1),
  );
  const ctx = context.ctx;
  ctx.lineWidth = baseWidth;
  let valveHeightMM = VALVE_HEIGHT_MM;
  if (entity) {
    valveHeightMM = getRpzdValveHeightMM(entity);
  }
  const valveWidthMM = (valveHeightMM * VALVE_SIZE_MM) / VALVE_HEIGHT_MM;

  ctx.fillStyle = "#ffffff";
  if (entity) prepareFill(entity, ctx);
  ctx.fillRect(
    -valveHeightMM * 1.3,
    -valveHeightMM * 2.3,
    valveHeightMM * 2.6,
    valveHeightMM * 4.6,
  );

  if (highlightColor) {
    ctx.fillStyle = rgb2style(color2rgb(highlightColor), 0.3);
    if (entity) prepareFill(entity, ctx);

    ctx.fillRect(
      -valveHeightMM * 1.5,
      -valveHeightMM * 2.5,
      valveHeightMM * 3,
      valveHeightMM * 5,
    );
  }

  if (colors[0] !== colors[1]) {
    ctx.strokeStyle = "#444444";
  }

  ctx.beginPath();
  ctx.rect(
    -valveHeightMM * 1.3,
    -valveHeightMM * 2.3,
    valveHeightMM * 2.6,
    valveHeightMM * 4.6,
  );
  if (entity) prepareStroke(entity, ctx);

  ctx.stroke();

  let i = 0;
  for (
    let off = -valveHeightMM;
    off <= valveHeightMM;
    off += valveHeightMM * 2
  ) {
    ctx.fillStyle = colors[i];
    i++;
    ctx.beginPath();
    ctx.moveTo(-valveHeightMM, -valveWidthMM / 2 + off);
    ctx.lineTo(-valveHeightMM, valveWidthMM / 2 + off);
    ctx.lineTo(valveHeightMM, 0 + off);
    ctx.closePath();
    if (entity) prepareFill(entity, ctx);

    ctx.fill();
  }
}

export function getLineDash(
  entity: DrawableEntityConcrete,
  document: DocumentState,
  strokeWidth = 1,
) {
  const height = getPropertyByString(entity, "heightAboveFloorM");
  const lines = Object.entries(document.committedDrawing.metadata.lines);

  const line =
    lines.find((e) => {
      const [_key, l] = e;
      const { gte, lt } = l.level;
      return (
        (gte !== undefined &&
          lt !== undefined &&
          height >= gte &&
          height < lt) ||
        (lt === undefined && gte !== undefined && height >= gte) ||
        (lt !== undefined && gte === undefined && height < lt)
      );
    }) || [];

  const stroke = STROKES.find((s) => s.id === line[1]?.strokeId);
  return (stroke?.dash && stroke.dash.map((w) => w * strokeWidth)) || [];
}

export function getHighlightColor(
  selected: boolean,
  overridden: Color[],
  theme?: Color,
) {
  const mergeList = Array.from(overridden);
  if (selected) {
    if (!theme) {
      theme = { hex: "#6464ff" };
    }
    mergeList.push(theme);
  }

  if (!mergeList.length) {
    return {
      r: 0,
      g: 0,
      b: 0,
    };
  }

  const tot = { r: 0, g: 0, b: 0 };
  for (const c of mergeList) {
    const nxt = color2rgb(c);
    tot.r += nxt.r;
    tot.g += nxt.g;
    tot.b += nxt.b;
  }
  return {
    r: Math.round(tot.r / mergeList.length),
    g: Math.round(tot.g / mergeList.length),
    b: Math.round(tot.b / mergeList.length),
  };
}

// If you need to mutate the entity that is being added by addEntity after it is added,
// you must retrieve it from the store and mutate it. This is because the entity is
// proxied and will not be caught if you mutate the entity that is passed in.
export function addEntityToStore<T extends DrawableEntityConcrete>(
  context: CanvasContext,
  entity: T,
  levelUid?: string,
): EntityTypeMap[T["type"]] {
  if (levelUid) {
    context.$store.dispatch("document/addEntityOn", {
      entity,
      levelUid,
    });
  } else {
    context.$store.dispatch("document/addEntity", entity);
  }
  return context.globalStore.get(entity.uid)!
    .entity as EntityTypeMap[T["type"]];
}

export async function getSquareOrgLogoBufferForUser(
  user: User,
  size: "small" | "medium" | "large" = "medium",
): Promise<Buffer | undefined> {
  const orgId = user.organizationId;
  if (!orgId) {
    console.log("No Org");
    return undefined;
  }
  return await getSquareOrgLogoBuffer(orgId, size);
}

export async function getSquareOrgLogoBuffer(
  orgId: string,
  size: "small" | "medium" | "large" = "medium",
): Promise<Buffer | undefined> {
  console.log("getSquareOrgLogoBuffer: init");
  const urlRes = await getOrgLogoUrl(orgId, size, "square");
  if (!urlRes.success) {
    console.error("getSquareOrgLogoBuffer: failed to get url", urlRes.message);
    return undefined;
  }

  const url = urlRes.data;
  if (url === null) {
    console.warn("getSquareOrgLogoBuffer: org has no logo");
    return undefined;
  }

  console.log("getSquareOrgLogoBuffer: url is", url);

  // make a custom axios client with retries
  const _axios = axios.create();
  axiosRetry(_axios, {
    retries: 6,
    retryDelay: axiosRetry.exponentialDelay,
  });

  const response = await _axios.get(url, {
    responseType: "arraybuffer",
    validateStatus: () => true, // prevent axios throwing exceptions if icon not found at url.
  });
  if (response.status != 200) {
    console.error("getSquareOrgLogoBuffer: failed to get logo", response);
    return undefined;
  }

  const buffer = Buffer.from(response.data);
  console.log("getSquareOrgLogoBuffer: buffer is", buffer);
  return buffer;
}

export function isEntityDeletable(
  context: CoreContext,
  entity: DrawableEntityConcrete,
  document: DocumentState,
) {
  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FITTING:
    case EntityType.GAS_APPLIANCE:
    case EntityType.CONDUIT:
    case EntityType.RISER:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.PLANT:
    case EntityType.COMPOUND:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return true;
    case EntityType.SYSTEM_NODE:
      return false;
    case EntityType.ROOM:
      return isRoomDeletable(context, entity, document);
  }
  assertUnreachable(entity);
}

export function isRoomDeletable(
  context: CoreContext,
  entity: RoomEntity,
  document?: DocumentState,
): boolean {
  if (entity.room.roomType === RoomType.ROOM) {
    if (
      context.featureAccess.fullUnderfloorHeatingLoops &&
      document &&
      document.uiState.drawingMode === DrawingMode.Design
    ) {
      return false;
    }
  }
  return true;
}

export function isValidDrawingLayout(layout: string): layout is DrawingLayout {
  switch (layout) {
    case "mechanical":
    case "pressure":
    case "drainage":
    case "ventilation":
      return true;
    default:
      return false;
  }
}

export function isDetailedUFHLoopsEditable(
  context: CoreContext,
  document: DocumentState,
): boolean {
  if (!context.drawing.metadata.workflows.mech_underfloor_heating) {
    return false;
  }

  if (!context.featureAccess.fullUnderfloorHeatingLoops) {
    return false;
  }

  if (document.uiState.drawingMode === DrawingMode.FloorPlan) {
    // Even with room drawing disabled, rooms can still be dragged around,
    // so we need to assume that the user can edit the loops.
    // return document.uiState.roomDrawingActive;
    return true;
  } else if (document.uiState.drawingMode === DrawingMode.Design) {
    const activeFlowSystem = getFlowSystem(
      context.drawing,
      document.activeflowSystemUid,
    );
    return (
      activeFlowSystem?.type === "underfloor" ||
      // manifolds are editable in the mechanical layout
      activeFlowSystem?.type === "mechanical"
    );
  }

  return false;
}
