<script setup lang="ts">
import { ref } from "vue";
import "vue-tel-input/dist/vue-tel-input.css";
import { validateTokens } from "../../api/magicplan";
import { useBvModal, useBvToast } from "../../composables";
import store from "../../store/store";

const emit = defineEmits(["hide", "token-saved"]);
const loading = ref(false);
const customerId = ref("");
const secretKey = ref("");
const bvToast = useBvToast();
const bvModal = useBvModal();

async function validateAndSave() {
  loading.value = true;
  const res = await validateTokens({
    customerId: customerId.value,
    secretKey: secretKey.value,
  });
  if (res.success) {
    if (res.data) {
      return save();
    }
    bvToast.toast("Please make sure you are tokens are entered correctly", {
      title: "Invalid credentials",
      variant: "danger",
    });
  } else {
    bvToast.toast(res.message, {
      title: "Something went wrong",
      variant: "danger",
    });
  }
  loading.value = false;
}

async function save() {
  await store.dispatch("profile/setProperties", {
    magicplanCustomerId: customerId.value,
    magicplanSecretKey: secretKey.value,
  });
  bvToast.toast("Magicplan credentials saved", {
    title: "Success",
    variant: "success",
  });
  loading.value = false;
  emit("token-saved");
  bvModal.hide("magicplan-token-modal");
}

function emitHide() {
  emit("hide");
}
</script>

<template>
  <b-modal
    id="magicplan-token-modal"
    title="Manage Magicplan Credentials"
    size="lg"
    centered
    hide-footer
    @hide="emitHide"
  >
    <b-img src="/img/guides/magicplan-token-guide.jpg" fluid rounded />
    <br /><br />

    <!-- step by step guide -->
    <b-alert show>
      Follow the steps below to get your Magicplan credentials. To use this
      feature, you need to have a paid subscription plan with Magicplan (<i
        >minimum subscription tier required is Report</i
      >).
    </b-alert>
    <ol>
      <li>
        Go to
        <a href="https://cloud.magicplan.app/integrations" target="_blank">
          magicplan.com/integrations
        </a>
      </li>
      <li>Copy <b>API key</b> and <b>Customer ID</b></li>
      <li>Paste into the form below</li>
      <li>Click <b>Validate and Save</b></li>
    </ol>

    <br />

    <b-form-group
      label="API key:"
      label-for="secret-key"
      label-cols-sm="2"
      label-align-sm="left"
    >
      <b-form-input
        id="secret-key"
        v-model="secretKey"
        style="max-width: 400px"
      />
    </b-form-group>

    <b-form-group
      label="Customer ID:"
      label-for="customer-id"
      label-cols-sm="2"
      label-align-sm="left"
    >
      <b-form-input
        id="customer-id"
        v-model="customerId"
        style="max-width: 400px"
      />
    </b-form-group>

    <br />

    <div class="d-flex justify-content-center">
      <b-button
        v-model="secretKey"
        :disabled="loading"
        @click="validateAndSave"
      >
        <b-spinner v-if="loading" small />
        Validate and Save
      </b-button>
    </div>
  </b-modal>
</template>
