import { DUCT_FITTINGS } from "./default-duct-fittings";
import { HeatLoadMaterialRoleType } from "./heatload/types";
import { PriceTable } from "./price-table";

export const defaultPriceTable: PriceTable = {
  Insulation: {
    15: 14.25,
    20: 15.75,
    25: 21.5,
    30: 23,
    35: 27.5,
    50: 44,
  },
  Pipes: {
    PEX: {
      16: 40,
      20: 45,
      25: 41,
      32: 51,
      40: 62,
      50: 75,
      63: 84,
      75: 100,
      110: 150,
      160: 230,
    },
    Copper: {
      10: 50,
      12: 52,
      15: 54,
      18: 54,
      20: 56,
      22: 56,
      25: 65,
      28: 65,
      32: 67,
      35: 67,
      40: 75,
      42: 75,
      50: 92,
      54: 92,
      65: 110,
      67: 110,
      76: 152,
      80: 152,
      89: 152,
      100: 200,
      108: 200,
      125: 300,
      133: 320,
      150: 320,
      159: 370,
      200: 420,
    },
    "Stainless Steel": {
      13: 48,
      15: 51,
      16: 51,
      18: 52,
      20: 53,
      22: 53,
      25: 65,
      28: 65,
      32: 65,
      35: 65,
      39: 72,
      40: 72,
      42: 72,
      50: 92,
      51: 86,
      54: 92,
      65: 92,
      71: 92,
      72: 92,
      76: 92,
      76.1: 92,
      80: 134,
      85: 138,
      88.9: 138,
      89: 138,
      100: 176,
      104: 176,
      108: 176,
      125: 208,
      135: 236,
      139.7: 236,
      140: 236,
      150: 290,
      164: 290,
      166: 290,
      168: 290,
      168.3: 290,
      200: 410,
      250: 588,
      300: 700,
    },
    CPVC: {
      15: 27,
      20: 30,
      25: 33,
      32: 40,
      40: 47,
      50: 56,
      65: 74,
      80: 93,
      100: 121,
      110: 136,
      150: 204,
    },
    HDPE: {
      16: 37,
      20: 39,
      25: 42,
      32: 44,
      40: 46,
      50: 56,
      63: 69,
      75: 75,
      90: 102,
      110: 124,
      125: 145,
      160: 190,
      180: 215,
      200: 235,
      225: 260,
      315: 340,
    },
    Polybutylene: {
      10: 37,
      12: 37,
      15: 36,
      22: 40,
      28: 43,
    },
    GMS: {
      15: 48,
      20: 54,
      25: 57,
      32: 61,
      40: 66,
      50: 82,
      65: 108,
      80: 124,
      100: 148,
      125: 180,
      150: 215,
      200: 270,
      250: 320,
      300: 430,
    },
    "Cast Iron": {
      50: 104,
      70: 132,
      100: 164,
      150: 240,
      200: 350,
      225: 425,
      250: 480,
      300: 570,
    },
    "Stainless Steel (Drainage)": {
      40: 72,
      50: 92,
      65: 110,
      75: 134,
      80: 138,
      100: 156,
      110: 176,
      125: 208,
      150: 280,
      160: 290,
      200: 200,
      225: 230,
      250: 250,
      300: 300,
      315: 315,
    },
    "uPVC (Drainage)": {
      30: 38,
      40: 42,
      50: 52,
      60: 57,
      65: 62,
      75: 65,
      80: 70,
      100: 84,
      125: 100,
      150: 124,
      225: 280,
      // TODO: get Jonny pricing below.
      300: 0,
      375: 0,
      450: 0,
      525: 0,
      600: 0,
      750: 0,
      900: 0,
    },
    "HDPE (Drainage)": {
      40: 42,
      50: 46,
      65: 55,
      75: 61,
      80: 68,
      90: 76,
      100: 84,
      110: 136,
      125: 145,
      150: 190,
      160: 210,
      225: 260,
      250: 320,
      // TODO: get Jonny pricing below.
      300: 0,
      315: 0,
      400: 0,
      450: 0,
      500: 0,
      560: 0,
      630: 0,
    },
    "Cast Iron (Drainage)": {
      50: 104,
      70: 132,
      100: 164,
      125: 205,
      150: 240,
      200: 350,
      250: 480,
      300: 570,
    },
    PPR: {
      16: 41,
      20: 45,
      25: 41,
      32: 51,
      40: 62,
      50: 75,
      63: 84,
      75: 75,
      90: 100,
      110: 120,
      125: 130,
      160: 170,
      200: 250,
      250: 300,
    },
    MLCP: {
      16: 40,
      20: 45,
      25: 41,
      26: 41,
      32: 51,
      40: 62,
      50: 75,
      63: 84,
      75: 100,
    },
    "Carbon Steel": {
      3: 3,
      6: 6,
      10: 9,
      13: 11,
      15: 14, // TODO: get Jonny pricing
      19: 17,
      20: 18, // TODO: get Jonny pricing
      25: 22,
      32: 29,
      38: 34,
      40: 36, // TODO: get Jonny pricing
      50: 45, // TODO: get Jonny pricing
      51: 46,
      64: 58,
      65: 59, // TODO: get Jonny pricing
      76: 66,
      80: 70, // TODO: get Jonny pricing
      89: 80,
      100: 90, // TODO: get Jonny pricing
      102: 92,
      125: 116, // TODO: get Jonny pricing
      127: 120,
      150: 216, // TODO: get Jonny pricing
      152: 220,
      203: 270,
      254: 320,
      305: 432,
      356: 476,
      406: 455,
      457: 642,
      508: 612,
      610: 722,
    },
    MDPE: {
      // per Jonny advice "could you do MDPE as 15% cheaper than the equivalent sized HDPE pipes and fittings"
      20: Math.round(39 * 0.85),
      25: Math.round(42 * 0.85),
      32: Math.round(44 * 0.85),
      40: Math.round(46 * 0.85),
      50: Math.round(56 * 0.85),
      63: Math.round(69 * 0.85),
    },
    "PERT/AL": {
      12: 10,
      15: 12,
      16: 15,
    },
    "PERT/EVOH": {
      10: 12,
      12: 15,
      16: 25,
      20: 30,
    },
  },
  Ducts: {
    //TODO, add Ducts prize
    "Hot Dip Galvanised Steel": {
      circularByDiameter: {
        63: 63,
        75: 75,
        80: 80,
        90: 90,
        100: 100,
        125: 125,
        160: 160,
        200: 200,
        250: 250,
        315: 315,
        355: 355,
        400: 400,
        450: 450,
        500: 500,
        560: 560,
        630: 630,
        710: 710,
        800: 800,
        900: 900,
        1000: 1000,
        1120: 1120,
        1250: 1250,
      },
      rectangularByHeightWidth: {
        100: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        150: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        200: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        250: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        300: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        400: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
      },
    },
    "Flexible Duct": {
      circularByDiameter: {
        63: 63,
        75: 75,
        80: 80,
        90: 90,
        100: 100,
        125: 125,
        160: 160,
        200: 200,
        250: 250,
        315: 315,
        355: 355,
        400: 400,
        450: 450,
        500: 500,
        560: 560,
        630: 630,
        710: 710,
        800: 800,
        900: 900,
        1000: 1000,
        1120: 1120,
        1250: 1250,
      },
      rectangularByHeightWidth: {
        100: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        150: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        200: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        250: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        300: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
        400: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
        },
      },
    },
    "Galvanised Steel": {
      circularByDiameter: {
        63: 63,
        75: 75,
        80: 80,
        90: 90,
        100: 100,
        125: 125,
        160: 160,
        200: 200,
        250: 250,
        315: 315,
        355: 355,
        400: 400,
        450: 450,
        500: 500,
        560: 560,
        630: 630,
        710: 710,
        800: 800,
        900: 900,
        1000: 1000,
        1120: 1120,
        1250: 1250,
      },
      rectangularByHeightWidth: {
        100: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
        },
        150: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
        },
        200: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
        },
        250: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
        },
        300: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        400: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
        },
        500: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        600: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        800: {
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1000: {
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1200: {
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1400: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1600: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1800: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        2000: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
      },
    },
    "Carbon Steel": {
      circularByDiameter: {
        63: 63,
        75: 75,
        80: 80,
        90: 90,
        100: 100,
        125: 125,
        160: 160,
        200: 200,
        250: 250,
        315: 315,
        355: 355,
        400: 400,
        450: 450,
        500: 500,
        560: 560,
        630: 630,
        710: 710,
        800: 800,
        900: 900,
        1000: 1000,
        1120: 1120,
        1250: 1250,
      },
      rectangularByHeightWidth: {
        100: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
        },
        150: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
        },
        200: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
        },
        250: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
        },
        300: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        400: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
        },
        500: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        600: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        800: {
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1000: {
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1200: {
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1400: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1600: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1800: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        2000: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
      },
    },
    PVC: {
      circularByDiameter: {
        63: 63,
        75: 75,
        80: 80,
        90: 90,
        100: 100,
        125: 125,
        160: 160,
        200: 200,
        250: 250,
        315: 315,
        355: 355,
        400: 400,
        450: 450,
        500: 500,
        560: 560,
        630: 630,
        710: 710,
        800: 800,
        900: 900,
        1000: 1000,
        1120: 1120,
        1250: 1250,
      },
      rectangularByHeightWidth: {
        100: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
        },
        150: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
        },
        200: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
        },
        250: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
        },
        300: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        400: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
        },
        500: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        600: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        800: {
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1000: {
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1200: {
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1400: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1600: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1800: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        2000: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
      },
    },
    Aluminium: {
      circularByDiameter: {
        63: 63,
        75: 75,
        80: 80,
        90: 90,
        100: 100,
        125: 125,
        160: 160,
        200: 200,
        250: 250,
        315: 315,
        355: 355,
        400: 400,
        450: 450,
        500: 500,
        560: 560,
        630: 630,
        710: 710,
        800: 800,
        900: 900,
        1000: 1000,
        1120: 1120,
        1250: 1250,
      },
      rectangularByHeightWidth: {
        100: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
        },
        150: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
        },
        200: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
        },
        250: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
        },
        300: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        400: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
        },
        500: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        600: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        800: {
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1000: {
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1200: {
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1400: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1600: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1800: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        2000: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
      },
    },
    "Fibrous Glass Duct": {
      circularByDiameter: {
        63: 63,
        75: 75,
        80: 80,
        90: 90,
        100: 100,
        125: 125,
        160: 160,
        200: 200,
        250: 250,
        315: 315,
        355: 355,
        400: 400,
        450: 450,
        500: 500,
        560: 560,
        630: 630,
        710: 710,
        800: 800,
        900: 900,
        1000: 1000,
        1120: 1120,
        1250: 1250,
      },
      rectangularByHeightWidth: {
        100: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
        },
        150: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
        },
        200: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
        },
        250: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
        },
        300: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        400: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
        },
        500: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        600: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        800: {
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1000: {
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1200: {
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1400: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1600: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1800: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        2000: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
      },
    },
    "Semi-Rigid": {
      circularByDiameter: {
        63: 63,
        75: 75,
        80: 80,
        90: 90,
        100: 100,
        125: 125,
        160: 160,
        200: 200,
        250: 250,
        315: 315,
        355: 355,
        400: 400,
        450: 450,
        500: 500,
        560: 560,
        630: 630,
        710: 710,
        800: 800,
        900: 900,
        1000: 1000,
        1120: 1120,
        1250: 1250,
      },
      rectangularByHeightWidth: {
        100: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
        },
        150: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
        },
        200: {
          100: 100,
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
        },
        250: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
        },
        300: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        400: {
          100: 100,
          150: 150,
          200: 500,
          250: 600,
          300: 800,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
        },
        500: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        600: {
          150: 100,
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        800: {
          200: 100,
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1000: {
          250: 100,
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1200: {
          300: 100,
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
          1400: 100,
          1600: 100,
          1800: 100,
          2000: 100,
        },
        1400: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1600: {
          400: 100,
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        1800: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
        2000: {
          500: 100,
          600: 100,
          800: 100,
          1000: 100,
          1200: 100,
        },
      },
    },
  },
  DuctFittings: DUCT_FITTINGS,
  Valves: {
    "Brass Ball Valve": {
      15: 59,
      20: 69,
      25: 94,
      32: 146,
      40: 235,
      50: 290,
      65: 640,
      80: 900,
      100: 1000,
      150: 1500,
      200: 1800,
      250: 2200,
      300: 2600,
    },
    "Brass Gate Valve": {
      15: 59,
      20: 69,
      25: 94,
      32: 146,
      40: 235,
      50: 290,
      65: 640,
      80: 900,
      100: 1000,
      150: 1500,
      200: 1800,
      250: 2200,
      300: 2600,
    },
    "Butterfly Valve": {
      15: 59,
      20: 69,
      25: 94,
      32: 146,
      40: 235,
      50: 290,
      65: 640,
      80: 900,
      100: 1000,
      150: 1500,
      200: 1800,
      250: 2200,
      300: 2600,
    },
    "Water Meter": {
      15: 75,
      20: 100,
      25: 140,
      32: 190,
      40: 240,
      50: 300,
      65: 500,
      80: 600,
      100: 800,
      150: 1500,
      200: 1800,
      250: 2200,
      300: 2600,
    },
    Strainer: {
      15: 65,
      20: 90,
      25: 130,
      32: 185,
      40: 230,
      50: 285,
      65: 450,
      80: 520,
      100: 680,
      150: 920,
      200: 1200,
      250: 1600,
      300: 2100,
    },
    "Pressure Relief Valve": {
      15: 300,
      20: 375,
      25: 480,
      32: 640,
      40: 900,
      50: 1010,
      65: 1300,
      80: 1300,
      100: 1850,
      150: 2760,
      200: 4120,
      250: 4120,
      300: 7050,
    },
    "Check Valve": {
      15: 76,
      20: 92,
      25: 132,
      32: 194,
      40: 280,
      50: 370,
      65: 750,
      80: 1095,
      100: 1350,
      150: 1600,
      200: 2300,
      250: 2900,
      300: 3500,
    },
    "Custom Valve": 600,
  },
  Fittings: {
    Tee: {
      PEX: {
        16: 18,
        20: 22,
        25: 34,
        32: 57,
        40: 120,
        50: 182,
        63: 240,
      },
      Copper: {
        15: 18,
        20: 22,
        22: 22,
        25: 25,
        28: 25,
        32: 30,
        35: 30,
        40: 35,
        42: 35,
        50: 53,
        54: 53,
        65: 59,
        67: 59,
        76: 74,
        80: 74,
        100: 102,
        108: 102,
        133: 255,
        150: 255,
        159: 255,
        200: 280,
      },
      "Stainless Steel": {
        15: 50,
        20: 55,
        22: 55,
        25: 60,
        28: 60,
        32: 68,
        35: 68,
        40: 74,
        42: 74,
        50: 82,
        54: 82,
        65: 82,
        71: 82,
        76: 112,
        80: 112,
        100: 156,
        108: 156,
        150: 300,
        166: 300,
      },
      CPVC: {
        15: 9,
        20: 11,
        25: 12.5,
        32: 15,
        40: 17.5,
        50: 26.5,
      },
      HDPE: {
        16: 15,
        20: 18,
        25: 20,
        32: 39,
        40: 52,
        50: 68,
        63: 84,
        75: 122,
        90: 170,
        110: 285,
      },
      Polybutylene: {
        10: 12,
        15: 15,
        22: 19,
        28: 26,
      },
      GMS: {
        15: 18,
        20: 22,
        25: 25,
        40: 69,
        50: 77,
        65: 96,
        80: 136,
        100: 186,
        150: 215,
      },
      "Cast Iron": {
        50: 80,
        70: 90,
        100: 126,
        150: 235,
        200: 700,
        250: 1400,
        300: 2100,
      },
      "Stainless Steel (Drainage)": {
        50: 82,
        75: 112,
        110: 156,
        160: 300,
        200: 300,
        250: 350,
        315: 315,
      },
      "uPVC (Drainage)": {
        40: 28.5,
        50: 34,
        65: 45,
        80: 62,
        100: 59,
        150: 112,
        300: 0,
        375: 0,
        450: 0,
        525: 0,
        600: 0,
        750: 0,
        900: 0,
      },
      "HDPE (Drainage)": {
        50: 98,
        75: 114,
        90: 126,
        110: 156,
        160: 230,
        250: 520,
        // TODO: get Jonny pricing below.
        315: 0,
        400: 0,
        450: 0,
        500: 0,
        560: 0,
        630: 0,
      },
      "Cast Iron (Drainage)": {
        50: 80,
        70: 90,
        100: 126,
        150: 235,
        200: 700,
        250: 1400,
        300: 2100,
      },
      PPR: {
        20: 20,
        25: 25,
        32: 35,
        40: 50,
        50: 65,
        63: 80,
        75: 120,
        90: 150,
        110: 200,
        125: 230,
        160: 280,
        200: 300,
        250: 350,
      },
      MLCP: {
        16: 40,
        20: 45,
        25: 41,
        32: 51,
        40: 62,
        50: 75,
        63: 84,
      },
      "Carbon Steel": {
        3: 3,
        6: 6,
        10: 9,
        13: 11,
        19: 17,
        25: 22,
        32: 29,
        38: 34,
        51: 46,
        64: 58,
        76: 66,
        89: 80,
        102: 92,
        127: 120,
        152: 220,
        203: 270,
        254: 320,
        305: 432,
        356: 476,
        406: 455,
        457: 642,
        508: 612,
        610: 722,
      },
      MDPE: {
        20: Math.round(18 * 0.85),
        25: Math.round(20 * 0.85),
        32: Math.round(39 * 0.85),
        40: Math.round(52 * 0.85),
        50: Math.round(68 * 0.85),
        63: Math.round(84 * 0.85),
      },
      "PERT/AL": {
        12: 10,
        15: 12,
        16: 15,
      },
      "PERT/EVOH": {
        10: 12,
        12: 15,
        16: 25,
        20: 30,
      },
    },
    Elbow: {
      PEX: {
        16: 15,
        20: 18,
        25: 27,
        32: 49,
        40: 78,
        50: 134,
        63: 196,
      },
      Copper: {
        15: 15,
        20: 18,
        22: 18,
        25: 20,
        28: 20,
        32: 25.5,
        35: 25.5,
        40: 33,
        42: 33,
        50: 41,
        54: 41,
        65: 51,
        67: 51,
        76: 67,
        80: 67,
        100: 92,
        108: 92,
        133: 240,
        150: 240,
        159: 290,
        200: 290,
      },
      "Stainless Steel": {
        15: 30,
        20: 37,
        22: 37,
        25: 41,
        28: 41,
        32: 47,
        35: 47,
        40: 52,
        42: 52,
        50: 59,
        54: 59,
        65: 59,
        71: 59,
        76: 80,
        80: 80,
        100: 114,
        108: 114,
        150: 200,
        166: 200,
      },
      CPVC: {
        15: 7.5,
        20: 9,
        25: 10,
        32: 13,
        40: 16.5,
        50: 20.5,
      },
      HDPE: {
        16: 15,
        20: 18,
        25: 22,
        32: 27.5,
        40: 39.5,
        50: 50,
        63: 61,
        75: 88,
        90: 122,
        110: 180,
      },
      Polybutylene: {
        10: 12,
        15: 15,
        22: 19,
        28: 26,
      },
      GMS: {
        15: 15,
        20: 18,
        25: 20,
        40: 51,
        50: 54,
        65: 68,
        80: 86,
        100: 118,
        150: 176,
      },
      "Cast Iron": {
        50: 63,
        70: 77,
        100: 86,
        150: 142,
        200: 265,
        250: 580,
        300: 910,
      },
      "Stainless Steel (Drainage)": {
        50: 59,
        75: 80,
        110: 114,
        160: 200,
        200: 200,
        250: 250,
        315: 315,
      },
      "uPVC (Drainage)": {
        40: 22.5,
        50: 28,
        65: 36.5,
        80: 48,
        100: 54,
        150: 84,
        // TODO: get Jonny pricing below.
        300: 0,
        375: 0,
        450: 0,
        525: 0,
        600: 0,
        750: 0,
        900: 0,
      },
      "HDPE (Drainage)": {
        50: 75,
        75: 86,
        90: 102,
        110: 116,
        160: 200,
        250: 680,
        // TODO: get Jonny pricing below.
        315: 0,
        400: 0,
        450: 0,
        500: 0,
        560: 0,
        630: 0,
      },
      "Cast Iron (Drainage)": {
        50: 63,
        70: 77,
        100: 88,
        150: 154,
        200: 275,
        250: 870,
        300: 1185,
      },
      PPR: {
        20: 15,
        25: 20,
        32: 25,
        40: 35,
        50: 45,
        63: 60,
        75: 80,
        90: 110,
        110: 160,
        125: 180,
        160: 210,
        200: 250,
        250: 300,
      },
      MLCP: {
        16: 40,
        20: 45,
        25: 41,
        32: 51,
        40: 62,
        50: 75,
        63: 84,
      },
      "Carbon Steel": {
        3: 3,
        6: 6,
        10: 9,
        13: 11,
        19: 17,
        25: 22,
        32: 29,
        38: 34,
        51: 46,
        64: 58,
        76: 66,
        89: 80,
        102: 92,
        127: 120,
        152: 220,
        203: 270,
        254: 320,
        305: 432,
        356: 476,
        406: 455,
        457: 642,
        508: 612,
        610: 722,
      },
      MDPE: {
        20: Math.round(18 * 0.85),
        25: Math.round(22 * 0.85),
        32: Math.round(27.5 * 0.85),
        40: Math.round(39.5 * 0.85),
        50: Math.round(50 * 0.85),
        63: Math.round(61 * 0.85),
      },
      "PERT/AL": {
        12: 10,
        15: 12,
        16: 15,
      },
      "PERT/EVOH": {
        10: 12,
        12: 15,
        16: 25,
        20: 30,
      },
    },
    Reducer: {
      PEX: {
        20: 62,
        25: 85,
        32: 131,
        40: 212,
        50: 261,
        63: 576,
        80: 810,
        100: 900,
        150: 1350,
        200: 1620,
        250: 1980,
        300: 2340,
      },
      Copper: {
        15: 50,
        20: 62,
        22: 62,
        25: 85,
        28: 85,
        32: 131,
        35: 131,
        40: 212,
        42: 212,
        50: 261,
        54: 261,
        65: 576,
        67: 576,
        76: 810,
        80: 810,
        100: 900,
        108: 900,
        150: 1350,
        159: 1350,
        200: 1620,
        250: 1980,
        300: 2340,
      },
      "Stainless Steel": {
        15: 62,
        20: 62,
        22: 62,
        25: 85,
        28: 85,
        32: 131,
        35: 131,
        40: 212,
        42: 212,
        50: 261,
        54: 261,
        65: 576,
        71: 576,
        76: 810,
        80: 810,
        100: 900,
        108: 900,
        150: 1350,
        166: 1350,
        200: 1620,
        250: 1980,
        300: 2340,
      },
      CPVC: {
        15: 25,
        20: 31,
        25: 42.5,
        32: 65.5,
        40: 106,
        50: 130.5,
      },
      HDPE: {
        20: 62,
        25: 85,
        32: 131,
        40: 212,
        50: 261,
        65: 576,
        80: 810,
        100: 900,
        150: 1350,
        200: 1620,
        250: 1980,
        300: 2340,
      },
      Polybutylene: {
        10: 30,
        15: 45,
        22: 70,
        28: 105,
      },
      GMS: {
        20: 62,
        25: 85,
        32: 131,
        40: 212,
        50: 261,
        65: 576,
        80: 810,
        100: 900,
        150: 1350,
        200: 1620,
        250: 1980,
        300: 2340,
      },
      "Cast Iron": {
        20: 62,
        25: 85,
        32: 131,
        40: 212,
        50: 261,
        65: 576,
        80: 810,
        100: 900,
        150: 1350,
        200: 1620,
        250: 1980,
        300: 2340,
      },
      PPR: {
        20: 62,
        25: 85,
        32: 131,
        40: 212,
        50: 261,
        63: 576,
        75: 610,
        90: 720,
        110: 845,
        125: 910,
        160: 1100,
        200: 1420,
        250: 1850,
      },
      MLCP: {
        16: 40,
        20: 45,
        25: 41,
        32: 51,
        40: 62,
        50: 75,
        63: 84,
      },
      "Stainless Steel (Drainage)": {
        50: 0,
        75: 0,
        110: 0,
        160: 0,
        200: 0,
        250: 0,
        315: 0,
      },
      "uPVC (Drainage)": {
        40: 0,
        50: 0,
        65: 0,
        80: 0,
        100: 0,
        150: 0,
      },
      "HDPE (Drainage)": {
        50: 0,
        75: 0,
        90: 0,
        110: 0,
        160: 0,
        250: 0,
      },
      "Cast Iron (Drainage)": {
        50: 0,
        70: 0,
        100: 0,
        150: 0,
        200: 0,
        250: 0,
        300: 0,
      },
      "Carbon Steel": {
        3: 3,
        6: 6,
        10: 9,
        13: 11,
        19: 17,
        25: 22,
        32: 29,
        38: 34,
        51: 46,
        64: 58,
        76: 66,
        89: 80,
        102: 92,
        127: 120,
        152: 220,
        203: 270,
        254: 320,
        305: 432,
        356: 476,
        406: 455,
        457: 642,
        508: 612,
        610: 722,
      },
      MDPE: {
        20: Math.round(62 * 0.85),
        25: Math.round(85 * 0.85),
        32: Math.round(131 * 0.85),
        40: Math.round(212 * 0.85),
        50: Math.round(261 * 0.85),
        63: Math.round(576 * 0.85),
      },
      "PERT/AL": {
        12: 10,
        15: 12,
        16: 15,
      },
      "PERT/EVOH": {
        10: 12,
        12: 15,
        16: 25,
        20: 30,
      },
    },
  },
  Plants: {
    "Hot Water Plant": 15000,
    "Storage Tank": 100000,
    Pump: 20000,
    Custom: 10000,
    "Pump/Tank": 10000,
    "Recirculation Pump": 3000,
    Volumiser: 6500,
    "Water Softener": 10000,
    "Backwash Filter": 10000,
    "UV Filter": 10000,
    "Cartridge Filter": 10000,
    "Backwash Filter (Rainwater)": 10000,
    "RO Plant": 50000,
  },
  Equipment: {
    PRV: {
      15: 102,
      20: 116,
      25: 190,
      32: 530,
      40: 900,
      50: 1035,
      65: 1200,
      80: 1450,
      100: 1700,
      150: 1900,
    },
    TMV: 1200,
    RPZD: {
      15: 490,
      20: 520,
      25: 600,
      32: 970,
      40: 1110,
      50: 1215,
      65: 4500,
      80: 8000,
      100: 10000,
      150: 12500,
    },
    CSV: {
      15: 250,
      20: 290,
      25: 350,
      32: 410,
      40: 525,
      50: 625,
    },
    "Tempering Valve": 600,
    "Balancing Valve": {
      15: 250,
      20: 290,
      25: 350,
      32: 410,
      40: 525,
      50: 625,
    },
    "Lockshield Valve": {
      15: 250,
      20: 290,
      25: 350,
      32: 410,
      40: 525,
      50: 625,
    },
    PICV: {
      15: 500,
      20: 580,
      25: 700,
      32: 820,
      40: 1050,
      50: 1250,
    },
    "Diverter Valve": {
      15: 210,
      20: 220,
      25: 250,
      32: 300,
      40: 400,
      50: 480,
      65: 600,
      80: 750,
      100: 1200,
      150: 2030,
    },
    "Thermostatic Control Valve": {
      15: 180,
      20: 240,
      25: 285,
    },
    "Reflux Valve": {
      40: 280,
      50: 370,
      65: 750,
      70: 850,
      75: 920,
      80: 1095,
      90: 1200,
      100: 1350,
      110: 1420,
      150: 1600,
      160: 1800,
      200: 2300,
      250: 2900,
      300: 3500,
    },
    "Gas Filter": 3000,
    "Gas Regulator": 3000,
    "Gas Meter": 2000,

    "Inspection Opening": 0,
    "Floor Waste": 350,
    "Drainage Pit": 10000,
    "Grease Interceptor Trap": 10000,
    Emitter: 900,
    Damper: 300,
    "Smoke Damper": 300,
    "Fire Damper": 300,
    "Volume Control Damper": 300,
    Attenuator: 300,
    Fan: 300,
    "Duct Manifold": 800,
    "Underfloor Heating Actuators": 100,
    "Room Underfloor Heating": 50,
    "Underfloor Heating Coils": 0,
    "Underfloor Manifold": 300,
    "Underfloor Ball Valve": 0,
    "Underfloor Pump Pack": 1000,
    "Underfloor Pump": 500,
    "Underfloor Blending Valve": 500,
    "Underfloor Edge Expansion Foam": 0,
  },
  Fixtures: {
    Basin: 1655,
    "Kitchen Sink": 1340,
    Shower: 3275,
    WC: 1425,
    "WC DDA": 2000,
    "Cleaners Sink": 1560,
    Bath: 1700,
    "Birthing Pool": 8000,
    "Washing Machine": 2200,
    Dishwasher: 2200,
    "Laundry Trough": 1320,
    "Ablution Trough": 2200,
    "Beverage Bay": 3500,
    "Hose Tap": 500,
    "Flushing Rim Sink": 3000,
    "Bedpan Sanitiser": 3000,
    "Drinking Fountain": 2200,
    Urinal: 2000,
  },
  Node: {
    "Dwelling Node - Hot": 20000,
    "Dwelling Node - Cold": 20000,
    "Dwelling Node - Other": 20000,
    "Load Node - Hot": 10000,
    "Load Node - Cold": 10000,
    "Load Node - Other": 10000,
    "Continuous Flow Node": 2500,
    "Fire Node - Cold": 2500,
    Grille: 1500,
    Diffuser: 1500,
    Exhaust: 1500,
    Intake: 1500,
    "Fan Exhaust": 1500,
  },
  HeadLoad: {
    "External Wall": {
      "Solid Brick": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_brick_external",
        pricePerSqrtMeter: 50,
      },
      "Solid Brick Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_brick_insulated_external",
        pricePerSqrtMeter: 70,
      },
      "Solid Stone": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_stone_external",
        pricePerSqrtMeter: 60,
      },
      "Solid Stone Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_stone_insulated_external",
        pricePerSqrtMeter: 80,
      },
      "Solid Concrete": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_concrete_external",
        pricePerSqrtMeter: 40,
      },
      "Solid Concrete Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_concrete_insulated_external",
        pricePerSqrtMeter: 60,
      },
      "Cavity Wall Uninsulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "cavity_wall_uninsulated",
        pricePerSqrtMeter: 45,
      },
      "Cavity Wall Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "cavity_wall_insulated",
        pricePerSqrtMeter: 75,
      },
      Hardwood: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "hardwood_external",
        pricePerSqrtMeter: 80,
      },
      Softwood: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "softwood_external",
        pricePerSqrtMeter: 85,
      },
      Plasterboard: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "plasterboard_external",
        pricePerSqrtMeter: 90,
      },
      "Partition Uninsulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "partition_uninsulated_external",
        pricePerSqrtMeter: 30,
      },
      "Partition Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "partition_insulated_external",
        pricePerSqrtMeter: 55,
      },
    },
    "Internal Wall": {
      "Solid Brick": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_brick_internal",
        pricePerSqrtMeter: 30,
      },
      "Solid Brick Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_brick_insulated_internal",
        pricePerSqrtMeter: 50,
      },
      "Solid Stone": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_stone_internal",
        pricePerSqrtMeter: 40,
      },
      "Solid Stone Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_stone_insulated_internal",
        pricePerSqrtMeter: 60,
      },
      "Solid Concrete": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_concrete_internal",
        pricePerSqrtMeter: 20,
      },
      "Solid Concrete Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_concrete_insulated_internal",
        pricePerSqrtMeter: 40,
      },
      "Cavity Wall Uninsulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "cavity_wall_uninsulated_internal",
        pricePerSqrtMeter: 25,
      },
      "Cavity Wall Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "cavity_wall_insulated_internal",
        pricePerSqrtMeter: 55,
      },
      Hardwood: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "hardwood_internal",
        pricePerSqrtMeter: 60,
      },
      Softwood: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "softwood_internal",
        pricePerSqrtMeter: 65,
      },
      Plasterboard: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "plasterboard_internal",
        pricePerSqrtMeter: 70,
      },
      "Partition Uninsulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "partition_uninsulated_internal",
        pricePerSqrtMeter: 10,
      },
      "Partition Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "partition_insulated_internal",
        pricePerSqrtMeter: 35,
      },
    },
    "Party Wall": {
      "Solid Brick": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_brick_external",
        pricePerSqrtMeter: 50,
      },
      "Solid Brick Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_brick_insulated_external",
        pricePerSqrtMeter: 70,
      },
      "Solid Stone": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_stone_external",
        pricePerSqrtMeter: 60,
      },
      "Solid Stone Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_stone_insulated_external",
        pricePerSqrtMeter: 80,
      },
      "Solid Concrete": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_concrete_external",
        pricePerSqrtMeter: 40,
      },
      "Solid Concrete Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "solid_concrete_insulated_external",
        pricePerSqrtMeter: 60,
      },
      "Cavity Wall Uninsulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "cavity_wall_uninsulated",
        pricePerSqrtMeter: 45,
      },
      "Cavity Wall Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "cavity_wall_insulated",
        pricePerSqrtMeter: 75,
      },
      Hardwood: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "hardwood_external",
        pricePerSqrtMeter: 80,
      },
      Softwood: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "softwood_external",
        pricePerSqrtMeter: 85,
      },
      Plasterboard: {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "plasterboard_external",
        pricePerSqrtMeter: 90,
      },
      "Partition Uninsulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "partition_uninsulated_external",
        pricePerSqrtMeter: 30,
      },
      "Partition Insulated": {
        type: HeatLoadMaterialRoleType.WALL,
        uid: "partition_insulated_external",
        pricePerSqrtMeter: 55,
      },
    },
    Window: {
      "Metal Single Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "metal_single_glazed",
        pricePerItem: 200,
      },
      "Metal Double Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "metal_double_glazed",
        pricePerItem: 400,
      },
      "Metal Triple Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "metal_triple_glazed",
        pricePerItem: 600,
      },
      "Wood Single Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "wood_single_glazed",
        pricePerItem: 300,
      },
      "Wood Double Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "wood_double_glazed",
        pricePerItem: 500,
      },
      "Wood Triple Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "wood_triple_glazed",
        pricePerItem: 700,
      },
      "PVC Single Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "pvc_single_glazed",
        pricePerItem: 250,
      },
      "PVC Double Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "pvc_double_glazed",
        pricePerItem: 450,
      },
      "PVC Triple Glazed": {
        type: HeatLoadMaterialRoleType.WINDOW,
        uid: "pvc_triple_glazed",
        pricePerItem: 650,
      },
    },
    "External Door": {
      "Solid Wood Door": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "solid_wood_door_external",
        pricePerItem: 600,
      },
      "Glazed Wood Single": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "glazed_wood_single_external",
        pricePerItem: 800,
      },
      "Glazed Wood Double": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "glazed_wood_double_external",
        pricePerItem: 1000,
      },
      "Glazed Wood Triple": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "glazed_wood_triple_external",
        pricePerItem: 1200,
      },
      "Metal Single": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "metal_single_external",
        pricePerItem: 650,
      },
      "Metal Double": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "metal_double_external",
        pricePerItem: 850,
      },
      "Metal Triple": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "metal_triple_external",
        pricePerItem: 1050,
      },
      "Insulated Internal": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "insulated_internal_external",
        pricePerItem: 750,
      },
    },
    "Internal Door": {
      "Solid Wood Door": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "solid_wood_door_internal",
        pricePerItem: 500,
      },
      "Glazed Wood Single": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "glazed_wood_single_internal",
        pricePerItem: 700,
      },
      "Glazed Wood Double": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "glazed_wood_double_internal",
        pricePerItem: 900,
      },
      "Glazed Wood Triple": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "glazed_wood_triple_internal",
        pricePerItem: 1100,
      },
      "Metal Single": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "metal_single_internal",
        pricePerItem: 550,
      },
      "Metal Double": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "metal_double_internal",
        pricePerItem: 750,
      },
      "Metal Triple": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "metal_triple_internal",
        pricePerItem: 950,
      },
      "Insulated Internal": {
        type: HeatLoadMaterialRoleType.DOOR,
        uid: "insulated_internal",
        pricePerItem: 650,
      },
    },
    Roof: {
      "Aerated Concrete": {
        type: HeatLoadMaterialRoleType.ROOF,
        uid: "aerated_concrete",
        pricePerSqrtMeter: 45,
      },
      Aerated: {
        type: HeatLoadMaterialRoleType.ROOF,
        uid: "aerated",
        pricePerSqrtMeter: 50,
      },
      "Felt/Bitumen": {
        type: HeatLoadMaterialRoleType.ROOF,
        uid: "felt_bitumen",
        pricePerSqrtMeter: 35,
      },
      Screed: {
        type: HeatLoadMaterialRoleType.ROOF,
        uid: "screed",
        pricePerSqrtMeter: 60,
      },
      "Stone Chippings": {
        type: HeatLoadMaterialRoleType.ROOF,
        uid: "stone_chippings",
        pricePerSqrtMeter: 40,
      },
      "Clay Tiles": {
        type: HeatLoadMaterialRoleType.ROOF,
        uid: "clay_tiles",
        pricePerSqrtMeter: 65,
      },
      "Concrete Tiles": {
        type: HeatLoadMaterialRoleType.ROOF,
        uid: "concrete_tiles",
        pricePerSqrtMeter: 70,
      },
      "Wood/Wool": {
        type: HeatLoadMaterialRoleType.ROOF,
        uid: "wood_wool",
        pricePerSqrtMeter: 80,
      },
    },
    "Bottom Floor": {
      Concrete: {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "concrete_floor",
        pricePerSqrtMeter: 40,
      },
      "Concrete Insulated": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "concrete_insulated_floor",
        pricePerSqrtMeter: 60,
      },
      "Suspended Timber": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "suspended_timber_floor",
        pricePerSqrtMeter: 50,
      },
      "Suspended Timber Insulated": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "suspended_timber_insulated_floor",
        pricePerSqrtMeter: 70,
      },
    },
    "Suspended Floor": {
      Concrete: {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "concrete_floor",
        pricePerSqrtMeter: 40,
      },
      "Concrete Insulated": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "concrete_insulated_floor",
        pricePerSqrtMeter: 60,
      },
      "Suspended Timber": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "suspended_timber_floor",
        pricePerSqrtMeter: 50,
      },
      "Suspended Timber Insulated": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "suspended_timber_insulated_floor",
        pricePerSqrtMeter: 70,
      },
    },
    "Party Floor": {
      Concrete: {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "concrete_floor",
        pricePerSqrtMeter: 40,
      },
      "Concrete Insulated": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "concrete_insulated_floor",
        pricePerSqrtMeter: 60,
      },
      "Suspended Timber": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "suspended_timber_floor",
        pricePerSqrtMeter: 50,
      },
      "Suspended Timber Insulated": {
        type: HeatLoadMaterialRoleType.FLOOR,
        uid: "suspended_timber_insulated_floor",
        pricePerSqrtMeter: 70,
      },
    },
  },
};
