import Flatten from "@flatten-js/core";
import { v4 } from "uuid";
import { goldenRatioColor } from "../../../../lib/color";
import {
  isParallelRad,
  pointInsidePolygon,
  polygonClipping,
  polygonDifference,
  polygonDirectedAreaM2,
  polygonIntersectionAreaM2,
  removeDuplicates,
} from "../../../../lib/mathUtils/mathutils";
import { EPS, assertType } from "../../../../lib/utils";
import CoreAreaSegment from "../../../coreObjects/coreAreaSegment";
import CoreFen from "../../../coreObjects/coreFenestration";
import CorePlant from "../../../coreObjects/corePlant";
import CoreRoom from "../../../coreObjects/coreRoom";
import { RoomCalculation } from "../../../document/calculations-objects/room-calculation";
import { UnderfloorHeatingLoopCalculation } from "../../../document/calculations-objects/underfloor-heating-loop-calculation";
import {
  HeatedAreaSegmentEntity,
  fillDefaultAreaSegmentFields,
} from "../../../document/entities/area-segment-entity";
import { UFH_ENTRY_FEN_TYPES } from "../../../document/entities/fenestration-entity";
import { ManifoldPlant } from "../../../document/entities/plants/plant-types";
import {
  RoomRoomEntity,
  RoomType,
  fillDefaultRoomFields,
} from "../../../document/entities/rooms/room-entity";
import { getUFHLoopDesignFlowSystem } from "../../../document/entities/shared-fields/ufh-fields";
import { EntityType } from "../../../document/entities/types";
import CalculationEngine from "../../calculation-engine";
import { Edge } from "../../graph";
import {
  CoilCoord3D,
  underTheFloor,
} from "../../underfloor-heating/coil-coords";
import {
  UFH_DEFAULT_INLET_HEIGHT_MM,
  UFH_DEFAULT_OUTLET_HEIGHT_MM,
  UFH_DEFAULT_TRANSIT_SPACING_MM,
} from "../../underfloor-heating/consts";
import { isFenUFHCompatible as isFenUFHThrough } from "../../underfloor-heating/utils";
import { getWallByFen } from "../../utils";
import { LoopGenerator } from "../loop-generator/loop-generator";
import { LoopObstacle } from "../loop-generator/loop-obstacles";
import { CableEdge, CableGraph, CablePathEdge } from "./cable-graph";
import { LevelGraph, LevelGraphEdge, LevelGraphNode } from "./level-graph";
import { RoomGraph } from "./room-graph";
import {
  GraphNode,
  IntentionLine,
  Polygon,
  getLargestPolygonGap,
  snapPolygonToSite,
  toPolygon,
  tweakEntranceAndFinishPath,
} from "./utils";

// Graph representation: bit further than physical representation now.
// room edges are properly split by doors, and nearby unheated or heated areas that augment the room.
// doors are represented by two nodes - one for each room they connect.
// room edges spanning doors are connected to the door node that is in the room.
// Door nodes remember the details of the connections to and fro to help ordering and detangling.

export type LoopID = string;

// Here, from and to's are from the manifold to the loop.
// interface LoopDoorInstance {
//   loopId: LoopID;
//   from: "far" | "near";
//   to: "far" | "near" | "terminal"; // terminal means it is a loop itself, and takes up both near and far.
// }
//
// interface DoorRecord {
//   doorUid: string;
//
//   /*
//       If a door has pipes going through it, it will all be from the same side.
//
//       "from" side
//       ---->-------\   *** "near" side. "LHS" as we enter the door.
//       ===========| \ |===========
//                     \---->---
//       "to" side
//   */
//
//   // redundant, but for convenience:
//   nearFrom: string;
//   nearTo: string;
//   farFrom: string;
//   farTo: string;
//
//   // In order from near to far. Wherever you are.
//   loopsInOrder: LoopDoorInstance[];
// }

export default class TransitGenerator {
  // Main entry point.
  static generateManifoldLoopConnections(
    context: CalculationEngine,
    manifoldCache: Map<string, CoreRoom[]>,
    isRoomLoopLayoutFrozen: boolean,
  ): {
    obstaclesByRoom: Map<string, LoopObstacle[]>;

    // wish it was loopID, but they are identified by siteUid instead.
    manifoldLoopLayout: Map<string, string[]>;
  } {
    const obstaclesByRoom = new Map<string, LoopObstacle[]>();
    const manifoldLoopLayout = new Map<string, string[]>();
    for (const [manifoldUid] of manifoldCache) {
      const manifold = context.globalStore.getObjectOfType(
        EntityType.PLANT,
        manifoldUid,
      );
      if (!manifold) {
        continue;
      }
      assertType<ManifoldPlant>(manifold.entity.plant);
      if (manifold.entity.plant.ufhMode === "full") {
        const { loopLayout, newObstacles } =
          this.generateTransitPipesForManifold(
            context,
            manifoldUid,
            isRoomLoopLayoutFrozen,
          );
        manifoldLoopLayout.set(manifoldUid, loopLayout);
        for (const [roomUid, thisObstacles] of newObstacles) {
          if (!obstaclesByRoom.has(roomUid)) {
            obstaclesByRoom.set(roomUid, []);
          }
          obstaclesByRoom.get(roomUid)!.push(...thisObstacles);
        }
      }
    }
    return { obstaclesByRoom, manifoldLoopLayout };
  }

  static generateTransitPipesForManifold(
    context: CalculationEngine,
    targetManifoldUid: string,
    isRoomLoopLayoutFrozen: boolean,
  ): {
    newObstacles: Map<string, LoopObstacle[]>;
    loopLayout: string[];
  } {
    const { graph, manifoldNode, manifoldNormal, debugObj, roomPs } =
      this.levelGraphFromContext(context, targetManifoldUid);

    const cableGraph = this.generateCableGraph(
      context,
      graph,
      manifoldNode,
      targetManifoldUid,
      debugObj,
    );

    cableGraph.solve();

    if (!cableGraph.nodes.get(manifoldNode.uid)) {
      console.error("Missing ManifoldNode in cable graph", { manifoldNode });
    }

    const manifoldFlowOuts =
      cableGraph.nodes.get(manifoldNode.uid)?.flowOuts ?? [];
    const loopLayout = manifoldFlowOuts.flatMap((out) => out.targets);

    const newObstacles = isRoomLoopLayoutFrozen
      ? new Map<string, LoopObstacle[]>()
      : this.generateTransitPipes(
          context,
          cableGraph,
          context.globalStore.getObjectOfTypeOrThrow(
            EntityType.PLANT,
            targetManifoldUid,
          ),
          manifoldNode,
          manifoldNormal,
          roomPs,
          debugObj,
        );

    return {
      newObstacles,
      loopLayout,
    };
  }

  static buildWallLoopLayout(cableGraph: CableGraph) {
    const result: Map<string, LoopID[]> = new Map();

    // for now just debug print the wireings
    for (const node of cableGraph.nodes.values()) {
      for (const out of node.flowOuts) {
        if (out.terminal) continue;
        const edge = cableGraph.edges.get(out.edgeUid)!;
        const targets =
          out.wall === "ccw" ? out.targets : out.targets.slice().reverse();

        result.set(edge.uid, targets);
      }
    }
    return result;
  }

  static getUfhCalcBySite(
    context: CalculationEngine,
    siteUid: string,
  ): UnderfloorHeatingLoopCalculation[] {
    const site = context.globalStore.getPolygonObjectOrThrow(siteUid);
    if (site.type === EntityType.AREA_SEGMENT) {
      const calc = context.globalStore.getOrCreateCalculation(site.entity);
      return calc.loopsStats;
    } else {
      const calc = context.globalStore.getOrCreateCalculation(site.entity);
      return calc.underfloorHeating.loopsStats;
    }
  }

  static getUfhRecordBySite(context: CalculationEngine, siteUid: string) {
    const site = context.globalStore.getPolygonObjectOrThrow(siteUid);
    if (site.type === EntityType.AREA_SEGMENT) {
      const filled = fillDefaultAreaSegmentFields(
        context,
        site.entity as HeatedAreaSegmentEntity,
      );
      return filled.underfloorHeating;
    } else {
      const filled = fillDefaultRoomFields(
        context,
        site.entity as RoomRoomEntity,
      );
      return filled.room.underfloorHeating;
    }
  }

  static getRoomUidBySite(context: CalculationEngine, siteUid: string): string {
    const site = context.globalStore.getPolygonObjectOrThrow(siteUid);
    if (site.type === EntityType.AREA_SEGMENT) {
      const calc = context.globalStore.getOrCreateCalculation(site.entity);
      return calc.roomUid!;
    } else {
      return siteUid;
    }
  }

  static generateTransitPipes(
    context: CalculationEngine,
    cableGraph: CableGraph,
    manifold: CorePlant,
    manifoldNode: LevelGraphNode,
    manifoldNormal: Flatten.Vector | null,
    roomPs: Polygon[],
    debugObj?: RoomCalculation["debug"],
  ): Map<string, LoopObstacle[]> {
    const flowSystem = getUFHLoopDesignFlowSystem(context, manifold.uid);
    const transitSpacingMM =
      flowSystem?.transitSpacingMM ?? UFH_DEFAULT_TRANSIT_SPACING_MM;
    const obstaclesByRoom = new Map<string, LoopObstacle[]>();
    const wallLoopLayout = this.buildWallLoopLayout(cableGraph);

    const manifoldAngleRAD = (manifold.entity.rotation / 180) * Math.PI;
    const manifoldVector = Flatten.vector(0, 1).rotate(manifoldAngleRAD);

    if (!manifoldNormal) {
      manifoldNormal = manifoldVector.rotate90CCW();
    }

    const initialManifoldLine = {
      start: manifoldNode.point,
      end: manifoldNode.point.translate(manifoldNormal.rotate90CCW()),
      normal: null,
      adjAlign: null,
      adjRawDir: null,
      shiftIndex: 0,
    } as const;

    let pathIndex = 0;

    const debug: any[] = [];
    for (const [siteUid, { path, entry }] of cableGraph.pathsBySite) {
      const siteEntity = context.globalStore.get(siteUid);
      debug.push({
        uid: siteUid,
        type: siteEntity?.type,
        entryType: entry.type,
        entry,
      });

      pathIndex++;
      const intentionLines: IntentionLine[] = [initialManifoldLine];
      let lastEdge: CableEdge | null = null;
      let lastCrowdedness = 0;
      for (let i = 0; i < path.length; i++) {
        const p = path[i];

        const nextP = path[i + 1] ?? null;
        const edge = cableGraph.edges.get(p.uid)!;

        const layout = wallLoopLayout.get(edge.uid) ?? [];
        const index = layout.indexOf(siteUid);
        lastCrowdedness = Math.min(index, layout.length - index - 1);

        let shiftIndex = index - (layout.length - 1) / 2;
        if (edge.justification === "ccw" || edge.justification === "ccw-thru") {
          shiftIndex = index + 1;
        } else if (
          edge.justification === "cw" ||
          edge.justification === "cw-thru"
        ) {
          shiftIndex = -layout.length + index;
        }
        // double pipes, thus multiply by 2
        const shift = edge.normal.multiply(shiftIndex * transitSpacingMM * 2);

        intentionLines.push({
          start: edge.start.translate(shift),
          end: edge.end.translate(shift),
          normal: edge.normal,
          adjAlign: p.adjAlign,
          adjRawDir: p.adjRawDir,
          shiftIndex,
        });
        lastEdge = edge;
      }

      const site = this.getUfhRecordBySite(context, siteUid);

      const { normal, center } = tweakEntranceAndFinishPath(
        entry,
        intentionLines,
        lastCrowdedness,
        transitSpacingMM,
        site.loopSpacingMM!,
      );

      for (const intentionLine of intentionLines) {
        debugObj?.push({
          chain: [intentionLine.start, intentionLine.end],
          dash: [],
          color: goldenRatioColor(pathIndex).hex,
          role: "transit",
        });
      }

      for (let i = 0; i < intentionLines.length; i++) {
        const intention = intentionLines[i];
        if (intention.start.distanceTo(intention.end)[1].length < 1e-4) {
          intentionLines.splice(i, 1);
          i--;
        }
      }
      for (let i = 0; i < intentionLines.length - 1; i++) {
        const i1 = intentionLines[i];
        const i2 = intentionLines[i + 1];

        const line1 = Flatten.line(i1.start, i1.end);
        if (i2.start.distanceTo(i2.end)[1].length < 1e-4) {
          console.log("zero length line", { i1, i2 });
        }
        const line2 = Flatten.line(i2.start, i2.end);

        const angle = Flatten.vector(i1.start, i1.end).angleTo(
          Flatten.vector(i2.start, i2.end),
        );

        if (isParallelRad(0, angle, Math.PI * 0.01)) {
          const vector = Flatten.vector(i1.start, i1.end).normalize();
          const proj = i1.end.distanceTo(line2);

          if (proj[1].length < 10) {
            // If incident line, just deduplicate.
            intentionLines[i] = {
              start: i1.start,
              end: i2.end,
              normal: i1.normal,
              adjAlign: i2.adjAlign,
              adjRawDir: i2.adjRawDir,
              shiftIndex: i2.shiftIndex,
            };
            intentionLines.splice(i + 1, 1);
            i--;
          } else {
            // If "changing tracks", add a perpendicular line to connect them.
            let shiftIndex = i1.shiftIndex + i2.shiftIndex;
            const i1Cw = i1.adjAlign === "cw" || i1.adjAlign === "cw-thru";
            const i1Ccw = i1.adjAlign === "ccw" || i1.adjAlign === "ccw-thru";
            if (i1Cw || i1Ccw) {
              if (i1Cw === (i1.adjRawDir === "cw")) {
                shiftIndex = i2.shiftIndex * 2;
              } else {
                shiftIndex = i1.shiftIndex * 2;
              }
            }
            const shift = vector.multiply(transitSpacingMM * shiftIndex);

            intentionLines.splice(i + 1, 0, {
              start: i1.end.translate(shift),
              end: proj[1].end.translate(shift),
              normal: i1.normal,
              adjAlign: null,
              adjRawDir: null,
              shiftIndex: i1.shiftIndex,
            });
          }
        }
      }

      const calc = this.getUfhCalcBySite(context, siteUid);
      const record = this.getUfhRecordBySite(context, siteUid);

      if (!calc || !calc[0]) continue;

      calc[0].normal = normal;
      calc[0].center = center;

      // Produce the runs - one for each side of the intention.
      for (const direc of [1, -1]) {
        const intentionPoints: Flatten.Point[] = [];
        for (let i = 0; i < intentionLines.length - 1; i++) {
          const i1 = intentionLines[i];
          const i2 = intentionLines[i + 1];

          let line1 = Flatten.line(i1.start, i1.end);
          let line2 = Flatten.line(i2.start, i2.end);

          if (i !== 0) {
            line1 = Flatten.line(
              line1.pt.translate(
                line1.norm.multiply((direc * transitSpacingMM) / 2),
              ),
              line1.norm,
            );
          }
          if (i !== intentionLines.length - 2) {
            line2 = Flatten.line(
              line2.pt.translate(
                line2.norm.multiply((direc * transitSpacingMM) / 2),
              ),
              line2.norm,
            );
          }
          const ix = line1.intersect(line2);
          if (ix && ix.length > 0) {
            intentionPoints.push(ix[0]);

            const roomUids: Set<string> = new Set();
            roomUids.add(this.getRoomUidBySite(context, siteUid));

            for (const thisRoom of roomPs) {
              const roomPolygon = new Flatten.Polygon();
              roomPolygon.addFace(thisRoom.vertices);
              if (
                roomPolygon.contains(ix[0]) ||
                (i > 0 &&
                  roomPolygon.contains(
                    intentionPoints[intentionPoints.length - 2],
                  ))
              ) {
                roomUids.add(thisRoom.uid);
              }
            }

            for (const roomUid of roomUids) {
              if (!obstaclesByRoom.has(roomUid)) {
                obstaclesByRoom.set(roomUid, []);
              }

              if (intentionPoints.length >= 2) {
                if (
                  intentionPoints[intentionPoints.length - 2].distanceTo(
                    ix[0],
                  )[0] > 10
                ) {
                  obstaclesByRoom.get(roomUid)!.push({
                    radiusMM: transitSpacingMM / 2,
                    segment: Flatten.segment(
                      intentionPoints[intentionPoints.length - 2],
                      ix[0],
                    ),
                  });
                }
              }
            }
          } else {
            console.warn({
              i,
              line1,
              line2,
              msg: "this shouldn't happen, because adjacent parallel lines should have already been removed",
            });
          }
        }

        if (direc === 1) {
          const outlet = manifold
            .getInletsOutletSpecs()
            .find((x) => x.type === "outlet");
          calc[0].fromManifold = [
            {
              x: intentionPoints.at(0)!.x,
              y: intentionPoints.at(0)!.y,
              z: outlet
                ? outlet.heightAboveFloorM * 1000
                : UFH_DEFAULT_OUTLET_HEIGHT_MM,
              turnRadiusMM: 0,
            },
            ...this.sanitizeRun(
              underTheFloor(
                intentionPoints.map((p) => ({
                  x: p.x,
                  y: p.y,
                  // turnRadiusMM: record.minBendRadiusMM!,
                  turnRadiusMM: 75, // ensure uniform bend radius for now just for visuals
                })),
              ),
              transitSpacingMM,
            ),
          ];
        } else {
          const inlet = manifold
            .getInletsOutletSpecs()
            .find((x) => x.type === "inlet");

          calc[0].toManifold = [
            ...this.sanitizeRun(
              underTheFloor(
                intentionPoints
                  .slice()
                  .reverse()
                  .map((p) => ({
                    x: p.x,
                    y: p.y,
                    turnRadiusMM: 75, // ensure uniform bend radius for now just for visuals
                    // turnRadiusMM: record.minBendRadiusMM!,
                  })),
              ),
              transitSpacingMM,
            ),
            {
              x: intentionPoints.at(0)!.x,
              y: intentionPoints.at(0)!.y,
              z: inlet
                ? inlet.heightAboveFloorM * 1000
                : UFH_DEFAULT_INLET_HEIGHT_MM,
              turnRadiusMM: 0,
            },
          ];
        }
      }
    }

    return obstaclesByRoom;
  }

  static sanitizeRun(
    run: CoilCoord3D[],
    transitSpacingMM: number,
  ): CoilCoord3D[] {
    // LoopGenerator.removeZeroLengthCoils(run);
    LoopGenerator.correctTurnRadii(
      run.map((m) => ({ ...m, type: "transit" })),
      {
        loopSpacingMM: transitSpacingMM,
        avoidCenterBulb: true,
        minRadiusMM: 0,
        expandRadiusWhenPossible: true,
        flattenInitial: 1,
        correctLongKnots: true,
      },
    );
    for (let i = 0; i < run.length - 1; i++) {
      const p1 = run[i];
      const p2 = run[i + 1];
      const v = Flatten.vector(
        Flatten.point(p1.x, p1.y),
        Flatten.point(p2.x, p2.y),
      );
      if (v.length < 40) {
        p1.turnRadiusMM = 0;
        p2.turnRadiusMM = 0;
      }
    }
    return run;
  }

  static levelGraphFromContext(
    context: CalculationEngine,
    targetManifoldUid: string,
  ) {
    const manifold = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.PLANT,
      targetManifoldUid,
    );
    const manifoldWC = manifold.toWorldCoord();
    const manifoldCenter = Flatten.point(manifoldWC.x, manifoldWC.y);
    const levelUid = context.globalStore.levelOfEntity.get(targetManifoldUid);

    // Manifolds in rooms without underfloor heating can connect directly to the doors.
    const rooms: CoreRoom[] = [];
    let debugObj: RoomCalculation["debug"];
    const heatedAreas: CoreAreaSegment[] = [];
    const unheatedAreas: CoreAreaSegment[] = [];

    // We will class all doors and assigned entries as doors, and assigned entries only as entries.
    const openings: CoreFen[] = [];
    // Specifically room, we don't do fens of heated areas anymore.
    const roomsOfFen = new Map<string, string[]>();

    // But the 3 way vertices connecting room edges to heated area edges (3 way because it can pass through)
    // should actually just be doors. After connecting for the first time,

    // heated areas - just based on proximity to walls, connect vertices directly. Discard edges that have
    // both endpoints connected to a wall.
    // Unheated areas - take intersection polygon with room, connect to room edges via vertices directly too.
    // Edges are marked as "blockers" and must be traversed around, including other blockers

    const interestedRooms = new Set<string>();

    for (const obj of context.networkObjects()) {
      const thisLvl = context.globalStore.levelOfEntity.get(obj.uid);
      if (thisLvl !== levelUid) continue;

      if (obj.type === EntityType.ROOM) {
        if (obj.entity.room.roomType === RoomType.ROOM) {
          const includesManifold =
            obj.entity.room.underfloorHeating.manifoldUid === targetManifoldUid;

          const calc = context.globalStore.getOrCreateCalculation(obj.entity);
          if (includesManifold) {
            interestedRooms.add(obj.uid);
          }
          if (!debugObj) {
            calc.debug = debugObj = [];
          }
          rooms.push(obj);
        }
      } else if (obj.type === EntityType.AREA_SEGMENT) {
        if (obj.entity.areaType === "heated-area") {
          const filled = fillDefaultAreaSegmentFields(context, obj.entity);
          if (filled.underfloorHeating.manifoldUid === targetManifoldUid) {
            heatedAreas.push(obj);
          }
        } else {
          unheatedAreas.push(obj);
        }
      } else if (obj.type === EntityType.FENESTRATION) {
        if (UFH_ENTRY_FEN_TYPES.has(obj.entity.fenType)) {
          openings.push(obj);
        }
      }
    }

    for (const fenUid of context.globalStore.getAllFens()) {
      const fen = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.FENESTRATION,
        fenUid,
      );
      if (!isFenUFHThrough(context, fen.entity)) {
        continue;
      }
      const wallUid = getWallByFen(context.globalStore, fen);
      if (!wallUid) continue;
      const wall = context.globalStore.getObjectOfType(
        EntityType.WALL,
        wallUid,
      );
      if (!wall || !wall.isManifested) continue;
      for (const edgeUid of wall.entity.polygonEdgeUid) {
        const roomUid = context.globalStore.getPolygonsByEdge(edgeUid)[0];
        if (roomUid && interestedRooms.has(roomUid)) {
          if (!roomsOfFen.has(fenUid)) {
            roomsOfFen.set(fenUid, []);
          }
          roomsOfFen.get(fenUid)!.push(roomUid);
        }
      }
    }

    debugObj = debugObj!;

    const roomPs = rooms.map((r) => toPolygon(r));
    const heatedAreaPs = heatedAreas.map((r) => toPolygon(r));
    const unheatedAreaPs = unheatedAreas.map((r) => toPolygon(r));
    const room2ClippedHA = new Map<string, Polygon[]>();

    // Augment sites - remove unheated areas around the border.
    for (const site of [...roomPs, ...heatedAreaPs]) {
      for (const ua of unheatedAreaPs) {
        if (polygonIntersectionAreaM2(site.vertices, ua.vertices) < EPS) {
          continue;
        }
        const target = snapPolygonToSite(ua.vertices, site.vertices, 200);
        const currentArea = polygonDirectedAreaM2(site.vertices);
        const intersection = polygonDifference(site.vertices, target);
        let bestPolygon: Flatten.Point[] = site.vertices;
        let bestArea = 0;
        for (const clip of intersection) {
          const thisArea = polygonDirectedAreaM2(clip);
          if (thisArea < 0) {
            clip.reverse();
          }

          if (Math.abs(thisArea) > bestArea) {
            bestPolygon = clip.map((p) => Flatten.point(p.x, p.y));
            bestArea = Math.abs(thisArea);
          }
        }

        // Ollie's right, the polygon difference algo is incomplete.
        // It doesn't handle case well where polygon is outside and shares an edge
        // - it creates a couple of back and forth edges..
        // Instead of fixing the edge cases, I will work around it here and test
        // again with the GeoJSON library when that's in.
        if (Math.abs(bestArea - Math.abs(currentArea)) > 0.01) {
          site.vertices = removeDuplicates(bestPolygon!);
        }
      }
    }

    for (const ha of heatedAreaPs) {
      const haObj = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.AREA_SEGMENT,
        ha.uid,
      );
      const calc = context.globalStore.getOrCreateCalculation(haObj.entity);
      if (calc.roomUid) {
        if (!room2ClippedHA.has(calc.roomUid)) {
          room2ClippedHA.set(calc.roomUid, []);
        }
        const clipped = polygonClipping(
          roomPs.find((r) => r.uid === calc.roomUid)!.vertices,
          ha.vertices,
        );
        let bestArea = 0;
        let bestPolygon: Flatten.Point[] | null = null;

        for (const clip of clipped) {
          const thisArea = polygonDirectedAreaM2(clip);
          if (thisArea < 0) {
            clip.reverse();
          }

          if (Math.abs(thisArea) > bestArea) {
            bestPolygon = clip.map((p) => Flatten.point(p.x, p.y));
            bestArea = Math.abs(thisArea);
          }
        }

        if (bestPolygon) {
          ha.vertices = removeDuplicates(bestPolygon);
          room2ClippedHA.get(calc.roomUid)!.push(ha);
        }
      }
    }

    // Connect heated areas to rooms.
    // Currently, we are only going to support heated areas that
    // are connected to the boundary of the room. No "Island" heated areas.

    const roomGraphs: RoomGraph[] = [];
    const levelGraph = new LevelGraph(context);
    for (const room of roomPs) {
      const roomGraph = new RoomGraph(room);
      // Find remaining polygon of room.
      const finalHAPolygons: Polygon[] = [];

      for (const ha of room2ClippedHA.get(room.uid) ?? []) {
        const target = snapPolygonToSite(ha.vertices, room.vertices, 200);
        // intersect them vertices
        // Ignore loop entry for these guys, they will be auto generated.
        const thisPolygon = {
          uid: ha.uid,
          vertices: target,
        };
        roomGraph.addHeatedArea(thisPolygon, 200);
        finalHAPolygons.push(thisPolygon);
      }

      const largestRoomHole = getLargestPolygonGap(room, finalHAPolygons);

      roomGraph.addHeatedArea(
        {
          uid: room.uid,
          vertices: largestRoomHole,
        },
        200,
      );

      debugObj.push({
        color: goldenRatioColor(Math.floor(Math.random() * 100)).hex,
        chain: largestRoomHole,
        role: "room-hole",
      });

      roomGraphs.push(roomGraph);
      levelGraph.addRoom(roomGraph, room.uid);
    }

    // Connect up the doors.

    const node2entry: Record<string, CoreFen> = {};
    const fen2Edge: Record<string, Edge<LevelGraphNode, LevelGraphEdge>> = {};
    const node2FenEdge: Record<
      string,
      Edge<LevelGraphNode, LevelGraphEdge>
    > = {};
    for (const opening of openings) {
      let roomUids = roomsOfFen.get(opening.uid);
      if (roomUids) {
        roomUids = Array.from(roomUids).reverse();
      }
      const result = levelGraph.addOpening(opening, roomUids ?? []);
      if (result) {
        for (const node of result.nodes) {
          if (!node2entry[node.uid]) {
            node2entry[node.uid] = opening;
          }
        }
        if (result.edge) {
          fen2Edge[opening.uid] = result.edge;
          for (const node of result.nodes) {
            node2FenEdge[node.uid] = result.edge;
          }
        }
      }
    }

    // connect up manifold

    // We will route everything to the nearest wall, as manifolds should be
    // against walls. We would think of the manifold vector as going from the wall
    // into the room, rather than from the room to the wall - this gives correct
    // transit line ordering just out of the manifold.

    let bestDist = Infinity;
    let bestEdge: Edge<LevelGraphNode, LevelGraphEdge> | null = null;
    let bestIx: Flatten.Point | null = null;

    for (const edge of levelGraph.edgeList.values()) {
      // We will disregard the manifold's angle and just connect to the nearest wall.
      const edgeSegment = Flatten.segment(edge.from.point, edge.to.point);
      const thisDist = edgeSegment.distanceTo(manifoldCenter);
      if (thisDist[0] < bestDist) {
        bestDist = thisDist[0];
        bestEdge = edge;
        bestIx = thisDist[1].ps;
      }
    }

    let manifoldNormal: Flatten.Vector | null = null;
    let manifoldNode: LevelGraphNode = {
      point: Flatten.point(0, 0), // we will set this later
      uid: v4(),
      roomUid: "manifold",
    };

    if (bestEdge && bestIx) {
      // Check if the point is "on" a door/entry. In that case, we will
      // connect to the door instead.
      let fenOverrideNode: LevelGraphNode | null = null;
      if (bestEdge.from.uid in node2FenEdge) {
        const fen = node2entry[bestEdge.from.uid];
        const dist = bestIx.distanceTo(bestEdge.from.point)[0];
        if (dist < fen.getFenLengthMM() / 2) {
          fenOverrideNode = bestEdge.from;
        }
      }
      if (bestEdge.to.uid in node2entry) {
        const fen = node2entry[bestEdge.to.uid];
        const dist = bestIx.distanceTo(bestEdge.to.point)[0];
        if (dist < fen.getFenLengthMM() / 2) {
          fenOverrideNode = bestEdge.to;
        }
      }

      manifoldNormal = bestEdge.value.normal;

      if (fenOverrideNode) {
        const fen = node2entry[fenOverrideNode.uid];
        if (fen.uid in fen2Edge) {
          // we can have pipes coming in both sides of the fen,
          // so just easiest way to get this working is to get the
          // manifold IN the fen halfway. Probably.
          const edge = fen2Edge[fen.uid];

          const midpoint = Flatten.point(
            (edge.from.point.x + edge.to.point.x) / 2,
            (edge.from.point.y + edge.to.point.y) / 2,
          );

          manifoldNode.point = midpoint;

          levelGraph.splitUndirectedEdge(edge, manifoldNode);
        } else {
          // One roomed fen (like outer door). Pipes can only come
          // from within - we don't have to deal with multi-directions
          // so we can just connect to the door.

          const oldManifoldNode = { ...fenOverrideNode };

          const newManifoldPos = oldManifoldNode.point.translate(
            bestEdge.value.normal.multiply(10),
          );
          manifoldNode = {
            ...oldManifoldNode,
            point: newManifoldPos,
            roomUid: "manifold-fen",
          };
          levelGraph.displaceVertex(oldManifoldNode, manifoldNode);
        }
      } else {
        // Manifold is against the wall as usual.

        // Making an edge from pre (towards the outside of the room) to the manifold
        // will do, as conceptually for the purposes of transit pipe ordering, the manifold
        // is the "outside" of the room going in.

        const newNode: LevelGraphNode = {
          point: bestIx,
          uid: v4(),
          roomUid: "manifold",
        };
        manifoldNode.point = bestIx.translate(
          bestEdge.value.normal.multiply(-10),
        );
        levelGraph.splitUndirectedEdge(bestEdge, newNode);
        levelGraph.addEdge(manifoldNode, newNode, {
          align: "center",
          normal: bestEdge.value.normal.rotate90CCW().normalize(),
          siteUid: null,
          roomUid: "manifold",
        });

        // Now we will make the entry for the room the manifold is in
        const inRoom = context.globalStore.getObjectOfType(
          EntityType.ROOM,
          bestEdge.value.roomUid,
        );
        if (inRoom && interestedRooms.has(inRoom.uid)) {
          const clearanceMM = 200 * 4;
          let isCleared = true;

          const myPolygon = roomPs.find((r) => r.uid === inRoom.uid)!;

          for (
            let thisClearance = 50;
            thisClearance < clearanceMM;
            thisClearance += 50
          ) {
            const point = newNode.point.translate(
              bestEdge.value.normal.multiply(thisClearance),
            );
            if (!pointInsidePolygon(point, myPolygon.vertices)) {
              isCleared = false;
              break;
            }
          }

          if (isCleared) {
            // Fen entry here for the manifold as it effectively behaves the same.
            // We only do it if the manifold is not obstructed by an obstacle - if it
            // is, excluding this will allow the transit to start from the nearest wall
            // instead.

            // Which heated area is the manifold adjacent to?
            // not always inRoom.uid, i.e.,
            //   not always the "remaining" part of room that doesn't belong to any heated area.
            // Sometimes, entrySiteUid should be a specific heated area.

            let foundTheHA: string | null = null;

            for (const ha of heatedAreaPs) {
              if (
                pointInsidePolygon(
                  newNode.point.translate(bestEdge.value.normal.multiply(100)),
                  ha.vertices,
                )
              ) {
                foundTheHA = ha.uid;
                break;
              }
            }

            levelGraph.addEntryToNode(newNode, {
              type: "fen-entry",
              entrySiteUid: foundTheHA ?? inRoom.uid,
              normal: bestEdge.value.normal,
              numEntries: 1,
            });
          }
        }
      }
    }

    // Debug
    let i = 0;
    for (const edge of levelGraph.edgeList.values()) {
      i++;
      debugObj.push({
        color: goldenRatioColor(i).hex,
        // text: edge.from.uid.substring(0, 4),
        chain: [edge.from.point, edge.to.point],
        dash: [5, 2 + (i % 3) * 2],
        role: "level-graph-edge",
      });
      // draw the normal as well
      const vector = Flatten.vector(edge.from.point, edge.to.point);
      const midpoint = edge.from.point.translate(vector.multiply(0.5));
      debugObj.push({
        color: "#00FF00",
        chain: [midpoint, midpoint.translate(edge.value.normal.multiply(100))],
        role: "level-graph-edge",
      });
    }

    for (const node of levelGraph.id2Node.values()) {
      const entries = levelGraph.node2entry.get(node.uid);
      if (entries) {
        for (const entry of entries) {
          if (entry.type === "fen-entry") {
            const vector = entry.normal.normalize();
            const direction = node.point.translate(vector.multiply(300));
            debugObj.push({
              color: "#0000FF",
              chain: [node.point, direction],
              role: "loop-entry",
            });
          } else if (entry.type === "auto-entry") {
            let dir1 = Flatten.vector(entry.pivot, entry.neighbor1);
            if (dir1.length > 0) {
              dir1 = dir1.normalize();
            }
            let dir2 = Flatten.vector(entry.pivot, entry.neighbor2);
            if (dir2.length > 0) {
              dir2 = dir2.normalize();
            }
            debugObj.push({
              color: "#FF00FF",
              chain: [entry.pivot, entry.pivot.translate(dir1.multiply(300))],
              role: "loop-entry",
            });
            debugObj.push({
              color: "#FF77FF",
              chain: [entry.pivot, entry.pivot.translate(dir2.multiply(300))],
              role: "loop-entry",
            });
          }
        }
      }
    }

    levelGraph.writeToDebug(debugObj);

    return {
      graph: levelGraph,
      manifoldNode,
      manifoldNormal,
      debugObj,
      roomPs,
    };
  }

  static generateCableGraph(
    context: CalculationEngine,
    graph: LevelGraph,
    manifoldNode: LevelGraphNode,
    manifoldUid: string, // used to filter eligible entries only.
    debugObj?: RoomCalculation["debug"],
  ) {
    const cableGraph = new CableGraph();

    const prevEdgeOfNode = new Map<
      string,
      Edge<LevelGraphNode, LevelGraphEdge>
    >();
    const siteLooped = new Set<string>();

    graph.dijkstra({
      curr: manifoldNode,
      getDistance: (e) => {
        let penalty = 0;
        if (graph.node2entry.has(e.to.uid)) {
          if (e.from.roomUid === e.to.roomUid) {
            // edge came from within - assign a big penalty to force it to come from
            // other side instead. But don't block it completely as we still need this for
            // the room entry the manifold itself is in - where the transit is going to
            // start inside the room.
            penalty = 200;
          }
        }
        if (e.from.roomUid !== e.to.roomUid) {
          // penalize going through doors slightly to prevent flipping back and forth
          penalty += 70;
        }
        return Flatten.vector(e.from.point, e.to.point).length + penalty;
      },
      visitNode: (n) => {
        if (n.parent) {
          prevEdgeOfNode.set(n.node.uid, n.parent);
        }

        if (graph.node2entry.has(n.node.uid)) {
          // The site has to be selected as our manifold.

          // This is a terminal node, generate a path to supply it.
          // But first, check that we are visiting it the right direction.

          let curr: GraphNode = n.node;
          let currEdge: Edge<LevelGraphNode, LevelGraphEdge> | null = null;
          const path: CablePathEdge[] = [];

          while (prevEdgeOfNode.has(curr.uid)) {
            const edge = prevEdgeOfNode.get(curr.uid)!;
            if (path.length === 0) {
              path.push({ ...edge, adjAlign: null, adjRawDir: null });
            } else {
              assertType<Edge<LevelGraphNode, LevelGraphEdge>>(currEdge);
              const adj = graph.adjacencyList.get(graph.sn(edge.to))!;
              const other = adj.find(
                (x) => x.uid !== edge.uid && x.uid !== currEdge?.uid,
              );
              if (other) {
                const myDir = Flatten.vector(edge.from.point, edge.to.point);

                // We have to determine the direction of this edge as it is
                // not necessarily in the direction of our path since it didn't
                // come from a graph search.
                // const otherFrom = [other.from, other.to].find(
                //   (x) => x.uid !== edge.to.uid,
                // )!;
                // const otherTo = [other.from, other.to].find(
                //   (x) => x.uid !== otherFrom.uid,
                // )!;
                // Actually, we DON't! We need the raw direction of the edge, re
                // gardless of the direction of the path.

                const otherDir = other.value.normal.rotate90CW().normalize();
                path.push({
                  ...edge,
                  adjAlign: other.value.align,
                  adjRawDir: myDir.cross(otherDir) > 0 ? "ccw" : "cw",
                });
              } else {
                path.push({ ...edge, adjAlign: null, adjRawDir: null });
              }
            }
            curr = edge.from;
            currEdge = edge;
          }
          path.reverse();
          const entries = graph.node2entry.get(n.node.uid)!;
          for (const entry of entries) {
            if (siteLooped.has(entry.entrySiteUid)) {
              continue;
            }

            const site = this.getUfhRecordBySite(context, entry.entrySiteUid);
            if (site.manifoldUid !== manifoldUid) {
              continue;
            }

            siteLooped.add(entry.entrySiteUid);
            cableGraph.insertLoop(path, entry, debugObj);
          }
        }
      },
    });

    return cableGraph;
  }
}
