import { DrawingState } from "../api/document/drawing";
import { MaterialResource } from "../api/document/types";

export function createMissingCustomHeatLossMaterial(
  drawing: DrawingState,
  resource: MaterialResource,
) {
  if (
    drawing.metadata.heatLoss.customMaterial[resource.role][resource.uid] !==
    undefined
  ) {
    // Material already exists, do nothing, perhaps the user clicked the fix button twice
    return;
  }
  drawing.metadata.heatLoss.customMaterial[resource.role][resource.uid] = {
    name: resource.uid,
    uid: resource.uid,
    abbreviation: resource.uid,
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "Generic",
        uid: "generic",
        priceTableName: "Concrete Tiles",
      },
    ],
    thermal_transmittance_W_per_m2K: 0.1, // This is just a random placeholder value.
  };
}
