import { Color } from "../../lib/color";
import {
  assertType,
  assertUnreachable,
  assertUnreachableAggressive,
  betterObjectValues,
  isNotNullAndUndefined,
} from "../../lib/utils";
import { FireNodeProps, NodeProps } from "../../models/CustomEntity";
import { CoreContext, PipeConfiguration } from "../calculations/types";
import { StandardFlowSystemUids, isSewer } from "../config";
import { getInletsOutletSpecsInPlant } from "../coreObjects/utils";
import { DrawingState } from "./drawing";
import {
  fillDefaultAnnotationFields,
  makeAnnotationFields,
} from "./entities/annotations/annotation-entity";
import {
  ArchitectType,
  fillDefaultArcFields,
  makeArchitectureFields,
} from "./entities/architectureElement-entity";
import {
  fillDefaultAreaSegmentFields,
  isHeatedAreaSegmentEntity,
  makeAreaSegmentFields,
} from "./entities/area-segment-entity";
import { makeBackgroundFields } from "./entities/background-entity";
import {
  fillDefaultBigValveFields,
  makeBigValveFields,
} from "./entities/big-valve/big-valve-entity";
import {
  fillDefaultCompoundEntityFields,
  makeCompoundEntityFields,
} from "./entities/compound-entities/compound-entity";
import { DrawableEntityConcrete } from "./entities/concrete-entity";
import {
  fillDefaultConduitFields as fillConduitDefaults,
  makeConduitFields,
} from "./entities/conduit-entity";
import {
  fillDefaultDamperFields,
  makeDamperFields,
} from "./entities/damper-entity";
import {
  fillDirectedValveFields,
  makeDirectedValveFields,
} from "./entities/directed-valves/directed-valve-entity";
import {
  fillDefaultRoomEdgeFields,
  makeRoomEdgeFields,
} from "./entities/edge-entity";
import {
  FenType,
  fillDefaultFenFields,
  makeFenFields,
} from "./entities/fenestration-entity";
import {
  fillFittingDefaultFields,
  makeFittingFields,
} from "./entities/fitting-entity";
import {
  fillFixtureFields,
  makeFixtureFields,
} from "./entities/fixtures/fixture-entity";
import {
  fillFlowSourceDefaults,
  makeFlowSourceFields,
} from "./entities/flow-source-entity";
import {
  fillGasApplianceFields,
  makeGasApplianceFields,
} from "./entities/gas-appliance";
import {
  fillDefaultLineFields,
  makeLineFields,
} from "./entities/lines/line-entity";
import {
  NodeType,
  fillDefaultLoadNodeFields,
  makeLoadNodesFields,
} from "./entities/load-node-entity";
import {
  fillMultiwayValveFields,
  makeMultiwayValveFields,
} from "./entities/multiway-valves/multiway-valve-entity";
import { fillPlantDefaults } from "./entities/plants/plant-defaults";
import { makePlantEntityFields } from "./entities/plants/plant-fields";
import { fillRiserDefaults, makeRiserFields } from "./entities/riser-entity";
import {
  RoomRoomEntity,
  RoomType,
  fillDefaultRoomFields,
  makeRoomFields,
} from "./entities/rooms/room-entity";
import { getRoomFloorMaterialType } from "./entities/rooms/utils";
import { DrawableEntity } from "./entities/simple-entities";
import { EntityType } from "./entities/types";
import {
  fillDefaultVertexFields,
  makeVertexFields,
} from "./entities/vertices/vertex-entity";
import { fillDefaultWallFields, makeWallFields } from "./entities/wall-entity";
import {
  FireFlowSystem,
  FlowSystem,
  FlowSystemType,
  FlowSystemTypeMap,
  GasFlowSystem,
  MechanicalFlowSystem,
  PressureFlowSystem,
  StormwaterFlowSystem,
} from "./flow-systems";
import { EntityResource, EntityResourceType } from "./types";

export function getFlowSystem(
  flowSystems:
    | Record<StandardFlowSystemUids | string, FlowSystem>
    | DrawingState,
  uid:
    | StandardFlowSystemUids.ColdWater
    | StandardFlowSystemUids.WarmWater
    | StandardFlowSystemUids.HotWater,
): PressureFlowSystem;
export function getFlowSystem(
  flowSystems:
    | Record<StandardFlowSystemUids | string, FlowSystem>
    | DrawingState,
  uid: StandardFlowSystemUids.Gas,
): GasFlowSystem;
export function getFlowSystem(
  flowSystems:
    | Record<StandardFlowSystemUids | string, FlowSystem>
    | DrawingState,
  uid:
    | StandardFlowSystemUids.FireSprinkler
    | StandardFlowSystemUids.FireHoseReel
    | StandardFlowSystemUids.FireHydrant,
): FireFlowSystem;
export function getFlowSystem(
  flowSystems:
    | Record<StandardFlowSystemUids | string, FlowSystem>
    | DrawingState,
  uid:
    | StandardFlowSystemUids.Heating
    | StandardFlowSystemUids.UnderfloorHeating
    | StandardFlowSystemUids.Chilled
    | StandardFlowSystemUids.Condenser,
): MechanicalFlowSystem;
export function getFlowSystem(
  flowSystems:
    | Record<StandardFlowSystemUids | string, FlowSystem>
    | DrawingState,
  uid:
    | StandardFlowSystemUids.SewerDrainage
    | StandardFlowSystemUids.SanitaryPlumbing
    | StandardFlowSystemUids.TradeWaste
    | StandardFlowSystemUids.GreaseWaste
    | StandardFlowSystemUids.RisingMain,
): FireFlowSystem;
export function getFlowSystem(
  flowSystems:
    | Record<StandardFlowSystemUids | string, FlowSystem>
    | DrawingState,
  uid:
    | StandardFlowSystemUids.StormWater
    | StandardFlowSystemUids.BackupStormWater,
): StormwaterFlowSystem;
export function getFlowSystem<T extends FlowSystemType>(
  flowSystems:
    | Record<StandardFlowSystemUids | string, FlowSystem>
    | DrawingState,
  uid: StandardFlowSystemUids | string | null | undefined,
): FlowSystemTypeMap[T] | undefined;
export function getFlowSystem<T extends FlowSystemType>(
  flowSystems:
    | Record<StandardFlowSystemUids | string, FlowSystem>
    | DrawingState,
  uid: StandardFlowSystemUids | string | null | undefined,
): FlowSystemTypeMap[T] | undefined {
  if (!uid) {
    return undefined;
  }

  if ("metadata" in flowSystems) {
    flowSystems = (flowSystems as DrawingState).metadata.flowSystems;
  }
  return flowSystems[uid] as FlowSystemTypeMap[T];
}

export function getFlowSystemColor(
  flowSystem: FlowSystem,
  network: string,
  configuration?: PipeConfiguration | null,
): Color {
  if (isSewer(flowSystem)) {
    if (network === "vents") {
      return flowSystem.ventColor;
    }
  }
  if ("hasReturnSystem" in flowSystem && flowSystem.hasReturnSystem) {
    if (configuration === PipeConfiguration.RETURN_IN) {
      return flowSystem.return.color;
    }
  }
  return flowSystem.color;
}

export function makeInertEntityFields(
  context: CoreContext,
  entity: DrawableEntity,
  shouldThrow: boolean = false,
) {
  assertType<DrawableEntityConcrete>(entity);
  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
      return makeBackgroundFields(context, entity);
    case EntityType.FITTING:
      return makeFittingFields(context, entity);
    case EntityType.GAS_APPLIANCE:
      return makeGasApplianceFields(context, entity);
    case EntityType.CONDUIT:
      return makeConduitFields(context, entity);
    case EntityType.RISER:
      return makeRiserFields(context, entity);
    case EntityType.BIG_VALVE:
      return makeBigValveFields(context, entity);
    case EntityType.FIXTURE:
      return makeFixtureFields(context, entity);
    case EntityType.DIRECTED_VALVE:
      return makeDirectedValveFields(context, entity);
    case EntityType.MULTIWAY_VALVE:
      return makeMultiwayValveFields(context, entity);
    case EntityType.SYSTEM_NODE:
      if (shouldThrow) {
        throw new Error("Invalid object in multi select");
      }
      return [];
    case EntityType.LOAD_NODE:
      return makeLoadNodesFields(context, entity);
    case EntityType.FLOW_SOURCE:
      return makeFlowSourceFields(context, entity);
    case EntityType.PLANT:
      return makePlantEntityFields(context, entity);
    case EntityType.COMPOUND:
      return makeCompoundEntityFields(context, entity);
    case EntityType.EDGE:
      return makeRoomEdgeFields(context, entity);
    case EntityType.VERTEX:
      return makeVertexFields(context, entity);
    case EntityType.ROOM:
      return makeRoomFields(context, entity);
    case EntityType.WALL:
      return makeWallFields(context, entity);
    case EntityType.FENESTRATION:
      return makeFenFields(context, entity);
    case EntityType.LINE:
      return makeLineFields(context, entity);
    case EntityType.ANNOTATION:
      return makeAnnotationFields(context, entity);
    case EntityType.ARCHITECTURE_ELEMENT:
      return makeArchitectureFields(context, entity);
    case EntityType.DAMPER:
      return makeDamperFields(context, entity);
    case EntityType.AREA_SEGMENT:
      return makeAreaSegmentFields(context, entity);
  }
  assertUnreachable(entity);
}

export function fillEntityDefaults<T extends DrawableEntityConcrete>(
  context: CoreContext,
  entity: T,
): T {
  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
      return entity;
    case EntityType.FITTING:
      return fillFittingDefaultFields(context, entity) as T;
    case EntityType.CONDUIT:
      return fillConduitDefaults(context, entity) as T;
    case EntityType.RISER:
      return fillRiserDefaults(context, entity) as T;
    case EntityType.SYSTEM_NODE:
      return entity as T;
    case EntityType.BIG_VALVE:
      return fillDefaultBigValveFields(context, entity) as T;
    case EntityType.FIXTURE:
      return fillFixtureFields(context, entity) as T;
    case EntityType.DIRECTED_VALVE:
      return fillDirectedValveFields(context, entity) as T;
    case EntityType.MULTIWAY_VALVE:
      return fillMultiwayValveFields(context, entity) as T;
    case EntityType.LOAD_NODE:
      return fillDefaultLoadNodeFields(context, entity) as T;
    case EntityType.PLANT:
      return fillPlantDefaults(context, entity) as T;
    case EntityType.FLOW_SOURCE:
      return fillFlowSourceDefaults(context, entity) as T;
    case EntityType.GAS_APPLIANCE:
      return fillGasApplianceFields(context, entity) as T;
    case EntityType.COMPOUND:
      return fillDefaultCompoundEntityFields(context, entity) as T;
    case EntityType.EDGE:
      return fillDefaultRoomEdgeFields(context, entity) as T;
    case EntityType.VERTEX:
      return fillDefaultVertexFields(context, entity) as T;
    case EntityType.ROOM:
      return fillDefaultRoomFields(context, entity) as T;
    case EntityType.WALL:
      return fillDefaultWallFields(context, entity) as T;
    case EntityType.FENESTRATION:
      return fillDefaultFenFields(context, entity) as T;
    case EntityType.LINE:
      return fillDefaultLineFields(context, entity) as T;
    case EntityType.ANNOTATION:
      return fillDefaultAnnotationFields(context, entity) as T;
    case EntityType.ARCHITECTURE_ELEMENT:
      return fillDefaultArcFields(context, entity) as T;
    case EntityType.DAMPER:
      return fillDefaultDamperFields(context, entity) as T;
    case EntityType.AREA_SEGMENT:
      return fillDefaultAreaSegmentFields(context, entity) as T;
  }
  assertUnreachable(entity);
}

/**
 * Find the dependent non-entity resources.
 * e.g. Customised flowsystem, material, custom node id
 * @param context
 * @param e
 * @returns {EntityResource[]} The list of dependent resources which may include duplicates.
 */
export function getEntityResourcesForCopy(
  context: CoreContext,
  e: DrawableEntityConcrete,
): EntityResource[] {
  const entityResources: EntityResource[] = [];
  switch (e.type) {
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.BIG_VALVE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.LINE:
      return [];

    // Flow System
    case EntityType.FITTING:
    case EntityType.RISER:
    case EntityType.SYSTEM_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.CONDUIT:
      return [{ type: EntityResourceType.FLOW_SYSTEM, uid: e.systemUid }];

    case EntityType.PLANT:
      getInletsOutletSpecsInPlant(context, e).forEach((spec) =>
        entityResources.push({
          type: EntityResourceType.FLOW_SYSTEM,
          uid: spec.systemUid,
        }),
      );
      return entityResources;

    case EntityType.FIXTURE:
      for (const systemUid in e.roughIns) {
        entityResources.push({
          type: EntityResourceType.FLOW_SYSTEM,
          uid: systemUid,
        });
      }
      return entityResources;

    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
      if (e.systemUidOption)
        return [
          { type: EntityResourceType.FLOW_SYSTEM, uid: e.systemUidOption },
        ];
      return [];

    case EntityType.LOAD_NODE:
      if (e.systemUidOption)
        entityResources.push({
          type: EntityResourceType.FLOW_SYSTEM,
          uid: e.systemUidOption,
        });
      switch (e.node.type) {
        case NodeType.LOAD_NODE:
          if (e.customNodeId) {
            entityResources.push({
              type: EntityResourceType.LOAD_NODE,
              customNodeId: e.customNodeId,
            });
          }
        case NodeType.DWELLING:
          break;
        case NodeType.FIRE:
          entityResources.push({
            type: EntityResourceType.FIRE_NODE,
            customNodeId: e.node.customEntityId,
          });
      }
      return entityResources;

    // Material
    case EntityType.ROOM:
      switch (e.room.roomType) {
        case RoomType.ROOF:
          const roofExternalWallMat: EntityResource | undefined = e.room
            .externalWallMaterialUid
            ? {
                type: EntityResourceType.MATERIAL,
                uid: e.room.externalWallMaterialUid,
                role: "External Wall",
              }
            : undefined;
          const roofMaterial: EntityResource | undefined = e.room
            .roofMaterialUid
            ? {
                type: EntityResourceType.MATERIAL,
                uid: e.room.roofMaterialUid,
                role: "Roof",
              }
            : undefined;
          return [roofExternalWallMat, roofMaterial].filter(
            isNotNullAndUndefined,
          );
        case RoomType.ROOM:
          const entityResources: EntityResource[] = [];
          const filled = fillDefaultRoomFields(context, e);
          const floorType = getRoomFloorMaterialType(filled);
          if (e.room.floorMaterialUid) {
            entityResources.push({
              type: EntityResourceType.MATERIAL,
              uid: e.room.floorMaterialUid,
              role: floorType,
            });
          }
          if (e.room.roofMaterialUid) {
            entityResources.push({
              type: EntityResourceType.MATERIAL,
              uid: e.room.roofMaterialUid,
              role: "Roof",
            });
          }
          return entityResources;
        default:
          return [];
      }
    case EntityType.WALL:
      if (!e.wallMaterialUid) {
        return [];
      }
      const wall = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.WALL,
        e.uid,
      );

      // Caution, Party wall must come first, since they are also considered internal walls
      if (wall.isPartyWall()) {
        return [
          {
            type: EntityResourceType.MATERIAL,
            uid: e.wallMaterialUid,
            role: "Party Wall",
          },
        ];
      } else if (wall.isInternalWall()) {
        return [
          {
            type: EntityResourceType.MATERIAL,
            uid: e.wallMaterialUid,
            role: "Internal Wall",
          },
        ];
      } else {
        return [
          {
            type: EntityResourceType.MATERIAL,
            uid: e.wallMaterialUid,
            role: "External Wall",
          },
        ];
      }
    case EntityType.FENESTRATION:
      switch (e.fenType) {
        case FenType.DOOR:
          if (e.fenestration.materialUid) {
            const door = context.globalStore.getObjectOfTypeOrThrow(
              EntityType.FENESTRATION,
              e.uid,
            );
            if (door.isInternalFene()) {
              return [
                {
                  type: EntityResourceType.MATERIAL,
                  uid: e.fenestration.materialUid,
                  role: "Internal Door",
                },
              ];
            }
            return [
              {
                type: EntityResourceType.MATERIAL,
                uid: e.fenestration.materialUid,
                role: "External Door",
              },
            ];
          }
          return [];
        case FenType.WINDOW:
          if (e.fenestration.materialUid) {
            return [
              {
                type: EntityResourceType.MATERIAL,
                uid: e.fenestration.materialUid,
                role: "Window",
              },
            ];
          }
          return [];
      }
      return [];
    case EntityType.ARCHITECTURE_ELEMENT:
      switch (e.arcType) {
        case ArchitectType.VELUX:
          if (e.architecture.materialUid) {
            return [
              {
                type: EntityResourceType.MATERIAL,
                uid: e.architecture.materialUid,
                role: "Window",
              },
            ];
          }
          return [];
        default:
          assertUnreachableAggressive(e.arcType);
      }
      return [];
    case EntityType.ANNOTATION:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return [];
    default:
      assertUnreachable(e);
      return [];
  }
}
/**
 * Check if the required resource is available in the given context
 */
export function contextContainsResource(
  context: CoreContext,
  resource: EntityResource,
): boolean {
  switch (resource.type) {
    case EntityResourceType.FLOW_SYSTEM:
      const flowSystems = context.drawing.metadata.flowSystems;
      return !!flowSystems[resource.uid];
    case EntityResourceType.MATERIAL:
      const heatLoad = context.drawing.metadata.heatLoss;
      return (
        resource.uid in heatLoad.material[resource.role] ||
        resource.uid in heatLoad.customMaterial[resource.role]
      );
    case EntityResourceType.FIRE_NODE:
      const fire = context.drawing.metadata.nodes.fire;
      return fire.some(
        (fire: FireNodeProps) => fire.customEntityId == resource.customNodeId,
      );
    case EntityResourceType.LOAD_NODE:
      return context.nodes.some(
        (node: NodeProps) =>
          node.id == resource.customNodeId ||
          node.dbid === resource.customNodeId ||
          node.uid === resource.customNodeId,
      );
  }

  return true;
}

export function isUuidLength(uid: string): boolean {
  return uid.length === 36;
}
export function findEntitiesOfTypeOnLevel<T extends DrawableEntityConcrete>(
  drawing: DrawingState,
  levelUid: string,
  f: (e: DrawableEntityConcrete) => e is T,
): T[] {
  const entities = betterObjectValues(drawing.levels[levelUid].entities);
  return entities.filter(f);
}

export function findHeatingAreasForRoom(
  drawing: DrawingState,
  level: string | null,
  room: RoomRoomEntity,
) {
  if (!level) {
    return [];
  }

  const heatingAreas = findEntitiesOfTypeOnLevel(
    drawing,
    level,
    isHeatedAreaSegmentEntity,
  );
  return heatingAreas.filter((x) => x.roomUid === room.uid);
}
