<template>
  <div>
    <MainNavBar />
    <b-container>
      <b-row>
        <b-col>
          <b-button class="float-left" to="/organizations"> Back </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2>Create Organization</h2>
        </b-col>
      </b-row>
      <b-row style="margin-top: 30px; margin-bottom: 30px">
        <b-col>
          <template v-if="org">
            <b-form>
              <b-form-group :label-cols="2" label="Organization ID">
                <b-form-input v-model="org.id" />
              </b-form-group>

              <b-form-group :label-cols="2" label="Organization Name">
                <b-form-input v-model="org.name" />
              </b-form-group>

              <b-form-group :label-cols="2" label="Organization Type">
                <b-radio-group v-model="org.type">
                  <b-radio :value="types.BUILDING_SERVICES">
                    Building Services
                  </b-radio>
                  <b-radio :value="types.MANUFACTURER"> Manufacturer </b-radio>
                </b-radio-group>
              </b-form-group>

              <b-form-group :label-cols="2" label="Subscription Type">
                <b-radio-group v-model="org.subscriptionStatus">
                  <b-radio :value="organizationSubscriptionStatus.FREE">
                    Free
                  </b-radio>
                  <b-radio :value="organizationSubscriptionStatus.PAID">
                    Paid
                  </b-radio>
                  <b-radio :value="organizationSubscriptionStatus.CHURNED">
                    Churned
                  </b-radio>
                </b-radio-group>
              </b-form-group>

              <b-form-group :label-cols="2" label="Max number of accounts">
                <b-form-input
                  v-model.number="org.maxAccountsNum"
                  type="number"
                />
              </b-form-group>
            </b-form>
            <b-button variant="primary" @click="create"> Create </b-button>
          </template>
          <b-alert v-else variant="primary" show> Loading... </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { defaultFeatureAccess } from "../../../common/src/models/FeatureAccess";
import {
  Organization as IOrganization,
  OrganizationSubscriptionStatus,
  OrganizationType,
  defaultPasswordRequirements,
} from "../../../common/src/models/Organization";
import { User } from "../../../common/src/models/User";
import { createOrganization } from "../api/organizations";
import MainNavBar from "../components/MainNavBar.vue";

export default defineComponent({
  components: { MainNavBar },
  data() {
    const org: IOrganization = {
      id: "",
      name: "",
      address: "",
      phoneNumber: "",
      type: OrganizationType.BUILDING_SERVICES,
      maxAccountsNum: null,
      subscriptionStatus: OrganizationSubscriptionStatus.FREE,
      maxPasswordAttemptsBeforeLock: null,
      passwordRequirements: defaultPasswordRequirements,
      featureAccess: defaultFeatureAccess,
      enforceMFA: false,
    } as any;

    return {
      org,
    };
  },
  computed: {
    types() {
      return OrganizationType;
    },
    profile(): User {
      return this.$store.getters["profile/profile"];
    },
    organizationSubscriptionStatus() {
      return OrganizationSubscriptionStatus;
    },
  },
  methods: {
    create() {
      createOrganization(
        this.org.id,
        this.org.name,
        this.org.type,
        this.org.maxAccountsNum || null,
        this.org.subscriptionStatus,
      ).then((res) => {
        if (res.success) {
          this.$router.push("/organizations");
        } else {
          this.$bvToast.toast(res.message, {
            title: "Error creating organization",
            variant: "danger",
          });
        }
      });
    },
  },
});
</script>
