import axios from "axios";
import { APIResult } from "../../../common/src/api/document/types";
import { SupportedLocales } from "../../../common/src/api/locale";
import { UpdateOrganizationRequestData } from "../../../common/src/api/organization";
import { objectifyError } from "../../../common/src/lib/utils";
import {
  Organization,
  OrganizationRestrictedView,
  OrganizationSubscriptionStatus,
  OrganizationType,
  mergeOrganizationResult,
} from "../../../common/src/models/Organization";
import { getFailureFromAxiosRequestErrorEvent } from "../../src/lib/axios-utils";

export async function getOrganizations(params?: {
  type: OrganizationType;
}): Promise<APIResult<Organization[]>> {
  return axios
    .get("/api/organizations/", { params })
    .then((response) => response.data)
    .catch((e) => getFailureFromAxiosRequestErrorEvent(e));
}

export async function getOrganization(
  id: string,
): Promise<APIResult<Organization>> {
  return axios
    .get("/api/organizations/" + id)
    .then((response) => response.data)
    .catch((e) => getFailureFromAxiosRequestErrorEvent(e));
}

export async function getOrganizationRestricted(
  id: string,
): Promise<APIResult<OrganizationRestrictedView>> {
  return axios
    .get("/api/organizations/restricted/" + id)
    .then((response) => response.data)
    .catch((e) => getFailureFromAxiosRequestErrorEvent(e));
}

export async function getLocalesSupplied(
  id: string,
): Promise<APIResult<SupportedLocales[]>> {
  return axios
    .get("/api/organizations/localesupplied/" + id)
    .then((response) => response.data)
    .catch((e) => getFailureFromAxiosRequestErrorEvent(e));
}

export async function createOrganization(
  id: string,
  name: string,
  type: OrganizationType,
  maxAccountsNum: number | null,
  subscriptionStatus: OrganizationSubscriptionStatus,
): Promise<APIResult<Organization>> {
  return axios
    .post("/api/organizations/", {
      id,
      name,
      type,
      maxAccountsNum,
      subscriptionStatus,
    })
    .then((response) => response.data)
    .catch((e) => getFailureFromAxiosRequestErrorEvent(e));
}

export async function updateOrganization(
  id: string,
  updateData: UpdateOrganizationRequestData,
): Promise<APIResult<Organization>> {
  return axios
    .put("/api/organizations/" + id, updateData)
    .then((response) => response.data)
    .catch((e) => getFailureFromAxiosRequestErrorEvent(e));
}

export async function uploadOrgLogo(
  orgId: string,
  file: File,
  variant: "square" | "wide",
): Promise<APIResult<string>> {
  const formData = new FormData();
  formData.append("logo", file);
  return axios
    .post(`/api/organizations/${orgId}/logo?variant=${variant}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => ({ success: true as true, data: "success" }))
    .catch((e) => getFailureFromAxiosRequestErrorEvent(e));
}

export const getOrgLogoUrl = async (
  orgId: string,
  size?: "small" | "medium" | "large" | "raw",
  variant?: "square" | "wide",
): Promise<APIResult<string | null>> => {
  const querySize = !size ? "small" : size;
  const queryVariant = !variant ? "square" : variant;
  return axios
    .get<APIResult<string>>(
      `/api/organizations/${orgId}/logourl?size=${querySize}&variant=${queryVariant}`,
    )
    .then((logoUrlRequestResult) => {
      if (logoUrlRequestResult.status == 404) {
        return {
          success: true as true,
          data: null,
        };
      }
      return logoUrlRequestResult.data;
    })
    .catch((e) => {
      console.error(
        `Failed to get org logo url for orgId ${orgId}`,
        objectifyError(e),
      );
      return getFailureFromAxiosRequestErrorEvent(e);
    });
};

export async function mergeOrganization(
  currentOrgID: string,
  targetOrgID: string,
): Promise<APIResult<mergeOrganizationResult>> {
  return axios
    .post("/api/organizations/merge-organization", {
      currentOrgID,
      targetOrgID,
    })
    .then((response) => response.data)
    .catch((e) => getFailureFromAxiosRequestErrorEvent(e));
}
